<div class="child-content-wrapper d-flex-wrap">
  <div class="heading">
    <h2>Training Videos</h2>
    <!-- <div class="pull-right"><a data-bs-target="#previewTour" data-bs-toggle="modal" class="btn btn-round btn-bordered btn-primary btn-3">TAKE A TOUR <i class="icon-circle-question margin-left-10"></i></a></div> -->
  </div>
  <div class="page-content container-fluid">
    <div class="row">
      <input type="text" class="form-control" [(ngModel)]="accordion" placeholder="Search..." />

      <div *ngFor="let video of videos" class="col-lg-12 panel-row basic">
        <div class="row">
          <div class="col-md-3 col-sm-4">
            <h4 class="text-uppercase">{{ video.title }}</h4>
            <p>
              {{ video.description }}
            </p>
          </div>

          <div class="col-md-9 col-sm-8">
            <div class="row text-center">
              <div *ngFor="let video_item of video.video_items | accordion: accordion" class="col-md-4">
                <a (click)="onPreview(video_item.url)" data-bs-target="#previewElement" data-bs-toggle="modal">
                  <div class="panel">
                    <div class="panel-body container-fluid">
                      <span class="icon-play-circle-white"></span>
                      <h2 *ngIf="video_item.title" class="margin-top-30 margin-bottom-20 text-uppercase">
                        {{ video_item.title }}
                      </h2>
                      <h2 *ngIf="!video_item.title" class="margin-top-30 margin-bottom-20 text-uppercase">PLAY</h2>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #previewModal id="previewElement" class="modal fade edit-box preview" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-center modal-lg" role="document">
      <div class="modal-content">
        <button type="button" #previewElementModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <div class="modal-body">
          <iframe width="100%" height="480px" src="" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  <div #previewTour id="previewTour" class="modal fade edit-box preview" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-center modal-lg modal-video">
      <div class="modal-content">
        <button type="button" #previewTourModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>Test</button>
        <div class="modal-body">Tour Modal</div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>