<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Analytics</h2>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Export to CSV</h4>
        <form (ngSubmit)="onSubmit()" [formGroup]="exportForm" class="form-horizontal">
          <div class="form-group">
            <label for="type" class="col-sm-2">Type</label>
            <div class="col-sm-6">
              <select formControlName="type" (change)="onSelectionChange($event)" class="form-control">
                <option value="template">Template Report</option>
                <option value="login">Login Report</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="selection" class="col-sm-2">Selection</label>
            <div class="col-sm-6">
              <select formControlName="selection" (change)="onSelectionChange($event)" class="form-control">
                <option value="all">ALL</option>
                <option value="group">Group</option>
                <option value="client">Client</option>
              </select>
            </div>
          </div>
          <div *ngIf="selection === 'group'" class="form-group">
            <label for="select_group" class="col-sm-2">Select group</label>
            <div class="col-sm-6">
              <select formControlName="select_group" class="form-control">
                <option value="" disabled>Select Group</option>
                <option *ngFor="let group of groups" [value]="group.ID">{{ group.title }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="selection === 'client'" class="form-group">
            <label for="select_client" class="col-sm-2">Select client</label>
            <div class="col-sm-6">
              <select formControlName="select_client" class="form-control">
                <option value="" disabled>Select Client</option>
                <ng-container *ngFor="let client of clients">
                    <option *ngIf="client.company_name !== ''" [value]="client.ID">{{ client.company_name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="date_from" class="col-sm-2">Date From</label>
            <div class="col-sm-6">
              <input id="date_from" formControlName="date_from" data-toggle="datepicker" autocomplete="off" placeholder="Pick date range from">
            </div>
          </div>
          <div class="form-group">
            <label for="date_to" class="col-sm-2">Date To</label>
            <div class="col-sm-6">
              <input id="date_to" formControlName="date_to" data-toggle="datepicker" autocomplete="off" placeholder="Pick date range to">
            </div>
          </div>
          <br>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!exportForm.valid" class="btn-admin btn-admin-primary">Export</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
