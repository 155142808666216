<div class="">
  <div class="heading">
    <h2 class="pull-left">Users - {{ client?.name }}</h2>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <!-- <label class="control">
          <input type="text" class="form-control admin-input" placeholder="Search..." [(ngModel)]="filter">
      </label> -->
      <table id="datatable-clients" class="datatable-clients table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width: 100%">
        <thead>
          <tr>
            <th>Agent ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Admin</th>
            <th>Logged-in before</th>
            <th>Group Admin</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>{{ user?.agent_id }}</td>
            <td>{{ user?.firstname }}</td>
            <td>{{ user?.lastname }}</td>
            <td>{{ user?.email }}</td>
            <td>
              <span *ngIf="user?.is_admin" class="label label-success">Yes</span>
              <span *ngIf="!user?.is_admin" class="label label-danger">No</span>
            </td>
            <td>
              <span *ngIf="user?.is_first" class="label label-danger">NO</span>
              <span *ngIf="!user?.is_first" class="label label-success">YES</span>
            </td>
            <td>
              <span *ngIf="user?.is_group_admin" class="label label-success">YES</span>
              <span *ngIf="!user?.is_group_admin" class="label label-danger">NO</span>
            </td>
            <td style="text-align: right">
              <button (click)="resetFirstLogin(user?.ID)" type="button" class="btn btn-success btn-sm mr-2">Reset to First Login</button> 
              <button *ngIf="user?.is_group_admin" (click)="onRemoveGroupAdmin(user?.ID)" type="button" class="btn btn-warning btn-sm">Remove as Group Admin</button>
              <button *ngIf="!user?.is_group_admin" (click)="onSetGroupAdmin(user?.ID)" type="button" class="btn btn-info btn-sm">Set as Group Admin</button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
