import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientService } from '../../../../services/client.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../../../../services/account.service';
import { UserService } from '../../../../services/user.service';

declare var swal: any;

@Component({
  selector: 'app-users-dashboard',
  templateUrl: './users-dashboard.component.html',
  styleUrls: ['./users-dashboard.component.scss']
})
export class UsersDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  clientId: number;
  accounts = [];

  client: any;
  users = [];
  constructor(
    private clientService: ClientService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.clientId = +params['id'];

        this.clientService.httpGetClient(this.clientId);
        this.loading = true;
      }
    );

    this.userService.usersChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.loading = false;
            this.users = response.data;
          }
        }
      }
    );

    this.clientService.clientChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (client: any) => {
        if (typeof client !== 'undefined' && typeof client.ID !== 'undefined') {
          this.userService.httpGetUsers({
            client_id: client.ID
          });

          this.client = client;
        }
      }
    );

    this.userService.userOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.ID !== 'undefined') {
            this.userService.httpGetUsers({
              client_id: this.client.ID
            });
          }
        }
      }
    );

    this.userService.userAccessChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (users: any) => {
        if ( typeof users !== 'undefined' && users.status === 'success' ) {
          this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      }
    );

  }

  resetFirstLogin(ID: number) {
    this.userService.httpPutUser(ID, <any>{
      is_first: 1,
      template_popup: 0
    });

    this.loading = true;
  }

  onSetGroupAdmin(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to make this user as a group admin?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.userService.httpPutSetGroupAdmin(ID, 'set');
      $this.loading = true;
    }).catch(swal.noop);
  }

  onRemoveGroupAdmin(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to remove this user as a group admin?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.userService.httpPutSetGroupAdmin(ID, 'remove');
      $this.loading = true;
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
