import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { FrontService } from '../front.service';

declare var _bugHerd: any;
declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-analytics-front',
  templateUrl: './analytics-front.component.html',
  styleUrls: ['./analytics-front.component.scss']
})
export class AnalyticsFrontComponent implements OnInit {
  menus: Menu[] = [];
  title = 'Analytics';
  supportLink = '';

  constructor(private frontService: FrontService) { }

  ngOnInit() {
    this.supportLink = this.frontService.supportLink;
    this.frontService.loadMenu(this.menus);
  }

  onFeedback(){
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html: '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
      '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' + this.supportLink+ '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>'
      } );

      jQuery('.btn-bh-feedback').click(function() {
        swal.close();
        _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
      });

  }

}

