import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { AdminComponent } from './admin.component';
import { ClientsComponent } from './clients/clients.component';
import { TemplatesComponent } from './templates/templates.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { HelpComponent } from './help/help.component';
import { AdminRouteModule } from './admin.routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarAdminComponent } from './sidebar-admin/sidebar-admin.component';
import { SharedModule } from '../shared/shared.module';
import { AdminService } from './admin.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BlockService } from '../services/block.service';

import { ClientEditComponent } from './clients/client-edit/client-edit.component';
import { ClientDashboardComponent } from './clients/client-dashboard/client-dashboard.component';
import { ClientService } from '../services/client.service';

import { ElementsComponent } from './templates/elements/elements.component';
import { ElementEditComponent } from './templates/elements/element-edit/element-edit.component';
import { ElementService } from '../services/element.service';

import { GroupsComponent } from './groups/groups.component';
import { GroupService } from '../services/group.service';
import { GroupEditComponent } from './groups/group-edit/group-edit.component';
import { GroupDashboardComponent } from './groups/group-dashboard/group-dashboard.component';

import { Select2Module } from 'ng2-select2';
import { QuickDesignService } from '../services/quick-deisgn.service';
import { SettingAdminComponent } from './setting-admin/setting-admin.component';
import { MediaAttributesComponent } from './setting-admin/media-attributes/media-attributes.component';
import { DepartmentsComponent } from './setting-admin/departments/departments.component';
import { MediaAttributeService } from '../services/media-attribute.service';
import { DepartmentService } from '../services/department.service';
import { MastersComponent } from './templates/masters/masters.component';
import { MasterEditComponent } from './templates/masters/master-edit/master-edit.component';


import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { MasterService } from '../services/master.service';
import { ElementHtmlComponent } from './templates/elements/element-html/element-html.component';
import { StylesComponent } from './styles/styles.component';
import { StyleDashboardComponent } from './styles/style-dashboard/style-dashboard.component';
import { StyleEditComponent } from './styles/style-edit/style-edit.component';
import { StyleService } from '../services/style.service';
import { ClientGalleryComponent } from './clients/client-gallery/client-gallery.component';
import { StorageService } from '../services/storage.service';
import { StorageRevampService } from '../services/storage-revamp.service';

import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { UsersComponent } from './users/users.component';
import { TrainingVideosComponent } from './help/training-videos/training-videos.component';
import { SupportComponent } from './help/support/support.component';
import { FaqsComponent } from './help/faqs/faqs.component';
import { TrainingVideosEditComponent } from './help/training-videos/training-videos-edit/training-videos-edit.component';
import { TrainingVideosDashboardComponent } from './help/training-videos/training-videos-dashboard/training-videos-dashboard.component';
import { FaqDashboardComponent } from './help/faq-dashboard/faq-dashboard.component';
import { FaqEditComponent } from './help/faq-edit/faq-edit.component';
import { IconsComponent } from './icons/icons.component';
import { IconsEditComponent } from './icons/icons-edit/icons-edit.component';
import { IconsDashboardComponent } from './icons/icons-dashboard/icons-dashboard.component';
import { IconService } from '../services/icon.service';
import { FaqCategoryEditComponent } from './help/faq-category-edit/faq-category-edit.component';
import { FaqCategoryDashboardComponent } from './help/faq-category-dashboard/faq-category-dashboard.component';
import { FaqCategoryService } from '../services/faqcategory.service';
import { AnalyticUserLogsComponent } from './analytics/analytic-user-logs/analytic-user-logs.component';
import { AnalyticTemplateUsageComponent } from './analytics/analytic-template-usage/analytic-template-usage.component';

import { ChangelogDashboardComponent } from './help/changelog-dashboard/changelog-dashboard.component';
import { ChangelogEditComponent } from './help/changelog-edit/changelog-edit.component';
import { ChangelogService } from '../services/changelogs.service';
import { ImageLibraryService } from '../shared/imagelibrary.service';

import { WhiteBlackListingComponent } from './setting-admin/white-black-listing/white-black-listing.component';
import { SettingService } from '../services/setting.service';

import { StringFilterPipe } from '../pipes/string-filter.pipe';

import {NgxPaginationModule} from 'ngx-pagination';

import { SubgroupsComponent } from './subgroups/subgroups.component';
import { SubgroupsDashboardComponent } from './subgroups/subgroups-dashboard/subgroups-dashboard.component';
import { SubgroupsEditComponent } from './subgroups/subgroups-edit/subgroups-edit.component';
import { SubgroupService } from '../services/subgroup.service';
import { WebsiteService } from '../services/website.service';
import { WebsitesComponent } from './websites/websites.component';
import { WebsiteDashboardComponent } from './websites/website-dashboard/website-dashboard.component';
import { WebsiteEditComponent } from './websites/website-edit/website-edit.component';
import { WebsiteLogsComponent } from './websites/website-logs/website-logs.component';
import { VaultWhiteListingComponent } from './setting-admin/vault-white-listing/vault-white-listing.component';
import { AdminBlacklistsComponent } from './admin-blacklists/admin-blacklists.component';
import { BlacklistsEditComponent } from './admin-blacklists/blacklists-edit/blacklists-edit.component';
import { BlacklistsDashboardComponent } from './admin-blacklists/blacklists-dashboard/blacklists-dashboard.component';
import { BlacklistService } from '../services/blacklist.service';
import { ClientUsersComponent } from './clients/client-users/client-users.component';
import { UsersDashboardComponent } from './clients/client-users/users-dashboard/users-dashboard.component';
import { UsersEditComponent } from './clients/client-users/users-edit/users-edit.component';
import { AdminPopupsComponent } from './admin-popups/admin-popups.component';
import { AdminPopupsDashboardComponent } from './admin-popups/admin-popups-dashboard/admin-popups-dashboard.component';
import { AdminPopupsEditComponent } from './admin-popups/admin-popups-edit/admin-popups-edit.component';
import { TemplateBlocksComponent } from './templates/template-blocks/template-blocks.component';
import { TemplateBlocksEditComponent } from './templates/template-blocks/template-blocks-edit/template-blocks-edit.component';
import { GroupsVendorReportsComponent } from './groups/groups-vendor-reports/groups-vendor-reports.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { TrainingVideosVideosComponent } from './help/training-videos/training-videos-videos/training-videos-videos.component';
import { TrainingVideosVideosDashboardComponent } from './help/training-videos/training-videos-videos/training-videos-videos-dashboard/training-videos-videos-dashboard.component';
import { TrainingVideosVideosEditComponent } from './help/training-videos/training-videos-videos/training-videos-videos-edit/training-videos-videos-edit.component';
import { AdminProsComponent } from './admin-pros/admin-pros.component';
import { AdminProsBlocksComponent } from './admin-pros/admin-pros-blocks/admin-pros-blocks.component';
import { AdminProsBlocksDashboardComponent } from './admin-pros/admin-pros-blocks/admin-pros-blocks-dashboard/admin-pros-blocks-dashboard.component';
import { AdminProsBlocksEditComponent } from './admin-pros/admin-pros-blocks/admin-pros-blocks-edit/admin-pros-blocks-edit.component';
import { DataTablesModule } from 'angular-datatables';
import { AdminTableComponentComponent } from './admin-table-component/admin-table-component.component';
import { AdminProsElementsComponent } from './admin-pros/admin-pros-elements/admin-pros-elements.component';
import { AdminProsElementsDashboardComponent } from './admin-pros/admin-pros-elements/admin-pros-elements-dashboard/admin-pros-elements-dashboard.component';
import { AdminProsElementsEditComponent } from './admin-pros/admin-pros-elements/admin-pros-elements-edit/admin-pros-elements-edit.component';

import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AdminProsThemesComponent } from './admin-pros/admin-pros-themes/admin-pros-themes.component';
import { AdminProsThemesDashboardComponent } from './admin-pros/admin-pros-themes/admin-pros-themes-dashboard/admin-pros-themes-dashboard.component';
import { AdminProsThemesEditComponent } from './admin-pros/admin-pros-themes/admin-pros-themes-edit/admin-pros-themes-edit.component';
import { AdminProsBuilderTypesComponent } from './admin-pros/admin-pros-builder-types/admin-pros-builder-types.component';
import { AdminProsBuilderTypesDashboardComponent } from './admin-pros/admin-pros-builder-types/admin-pros-builder-types-dashboard/admin-pros-builder-types-dashboard.component';
import { AdminProsBuilderTypesEditComponent } from './admin-pros/admin-pros-builder-types/admin-pros-builder-types-edit/admin-pros-builder-types-edit.component';
import { AdminProsBuilderMergeFieldsComponent } from './admin-pros/admin-pros-builder-merge-fields/admin-pros-builder-merge-fields.component';
import { AdminProsBuilderMergeFieldsDashboardComponent } from './admin-pros/admin-pros-builder-merge-fields/admin-pros-builder-merge-fields-dashboard/admin-pros-builder-merge-fields-dashboard.component';
import { AdminProsBuilderMergeFieldsEditComponent } from './admin-pros/admin-pros-builder-merge-fields/admin-pros-builder-merge-fields-edit/admin-pros-builder-merge-fields-edit.component';

@NgModule({
  declarations: [
    AdminComponent,
    ClientsComponent,
    TemplatesComponent,
    AnalyticsComponent,
    HelpComponent,
    DashboardComponent,
    SidebarAdminComponent,
    ClientEditComponent,
    ElementsComponent,
    ClientDashboardComponent,
    ElementEditComponent,
    GroupsComponent,
    GroupEditComponent,
    GroupDashboardComponent,
    SettingAdminComponent,
    MediaAttributesComponent,
    DepartmentsComponent,
    MastersComponent,
    MasterEditComponent,
    ElementHtmlComponent,
    StylesComponent,
    StyleDashboardComponent,
    StyleEditComponent,
    ClientGalleryComponent,
    UsersComponent,
    TrainingVideosComponent,
    TrainingVideosEditComponent,
    TrainingVideosDashboardComponent,
    FaqsComponent,
    SupportComponent,
    FaqDashboardComponent,
    FaqEditComponent,
    IconsComponent,
    IconsEditComponent,
    IconsDashboardComponent,
    FaqCategoryEditComponent,
    FaqCategoryDashboardComponent,
    AnalyticUserLogsComponent,
    AnalyticTemplateUsageComponent,
    ChangelogDashboardComponent,
    ChangelogEditComponent,
    StringFilterPipe,
    WhiteBlackListingComponent,
    SubgroupsComponent,
    SubgroupsDashboardComponent,
    SubgroupsEditComponent,
    WebsitesComponent,
    WebsiteDashboardComponent,
    WebsiteEditComponent,
    WebsiteLogsComponent,
    VaultWhiteListingComponent,
    AdminBlacklistsComponent,
    BlacklistsDashboardComponent,
    BlacklistsEditComponent,
    ClientUsersComponent,
    UsersDashboardComponent,
    UsersEditComponent,
    AdminPopupsComponent,
    AdminPopupsDashboardComponent,
    AdminPopupsEditComponent,
    TemplateBlocksComponent,
    TemplateBlocksEditComponent,
    GroupsVendorReportsComponent,
    TrainingVideosVideosComponent,
    TrainingVideosVideosDashboardComponent,
    TrainingVideosVideosEditComponent,
    AdminProsComponent,
    AdminProsBlocksComponent,
    AdminProsBlocksDashboardComponent,
    AdminProsBlocksEditComponent,
    AdminTableComponentComponent,
    AdminProsElementsComponent,
    AdminProsElementsDashboardComponent,
    AdminProsElementsEditComponent,
    AdminProsThemesComponent,
    AdminProsThemesDashboardComponent,
    AdminProsThemesEditComponent,
    AdminProsBuilderTypesComponent,
    AdminProsBuilderTypesDashboardComponent,
    AdminProsBuilderTypesEditComponent,
    AdminProsBuilderMergeFieldsComponent,
    AdminProsBuilderMergeFieldsDashboardComponent,
    AdminProsBuilderMergeFieldsEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRouteModule,
    SharedModule,
    BrowserAnimationsModule,
    Select2Module,
    NgxPaginationModule,
    LoadingModule.forRoot({
      // animationType: ANIMATION_TYPES.rectangleBounce,
      animationType: ANIMATION_TYPES.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      backdropBorderRadius: '3px',
      primaryColour: 'transparent',
      secondaryColour: '#fff',
      // tertiaryColour: '#FFF'
    }),
    MalihuScrollbarModule.forRoot(),
    ColorPickerModule,
    DataTablesModule,
    MonacoEditorModule.forRoot()
  ],
  providers: [
    AdminService,
    ClientService,
    GroupService,
    ElementService,
    BlockService,
    QuickDesignService,
    MediaAttributeService,
    DepartmentService,
    MasterService,
    StyleService,
    StorageService,
    StorageRevampService,
    IconService,
    FaqCategoryService,
    ChangelogService,
    ImageLibraryService,
    SettingService,
    SubgroupService,
    WebsiteService,
    BlacklistService
  ]
})
export class AdminModule { }
