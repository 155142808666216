<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">Help Center - Training Videos Items</h2>

        <button routerLink="/admin/help/training-videos" type="button" class="btn-admin">
                <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
        <button routerLink="new" type="button" class="btn-admin">
            <i class="icon feather icon-plus"></i><span>Add Video Item</span>
        </button>
    </div>
</div>
<div id="box" class="row">
    <div class="col-md-12">
        <div class="table-box">
            <h2>{{ video?.title }}</h2>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Group</th>
                        <th>Excluded Groups</th>
                        <th>Title</th>
                        <th>Preview</th>
                        <th>Sort</th>
                        <th style="text-align: right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let videoItem of video?.video_items">
                        <td>
                            <span *ngIf="videoItem?.group_whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="videoItem?.group_whitelist === 'selected'">
                                <ng-container *ngFor="let group of videoItem?.groups">
                                <span class="badge badge-primary">{{group?.group?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="!videoItem?.excluded_groups?.length">None</span>
                            <ng-container *ngIf="videoItem?.excluded_groups?.length">
                              <ng-container *ngFor="let group of videoItem?.excluded_groups">
                                <span class="badge badge-danger">{{group?.group?.title}}</span><br />
                              </ng-container>
                            </ng-container>
                        </td>
                        <td>{{ videoItem?.title }}</td>
                        <td><a href="{{videoItem?.url}}" target="_blank">Preview</a></td>
                        <td>{{ videoItem?.sort }}</td>
                        <td style="text-align: right">
                            <button type="button" routerLink="{{ videoItem?.UID }}/edit" class="btn-table"
                                title="Edit"><i class="icon feather icon-edit"></i></button>
                            <button type="button" (click)="onDelete(videoItem?.UID)" class="btn-table" title="Delete"><i
                                    class="icon feather icon-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>