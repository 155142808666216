import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { WebsiteLog } from '../../../models/websitelog.model';

declare var $: any;

@Component({
  selector: 'app-website-logs',
  templateUrl: './website-logs.component.html',
  styleUrls: ['./website-logs.component.scss']
})
export class WebsiteLogsComponent implements OnInit, AfterViewInit {
  loading = false;
  type: any;
  dataTableHtml: any;

  websiteLogs: WebsiteLog[];
  websiteLogsSubscription: Subscription;

  isFiltered = false;

  constructor(
    private websiteService: WebsiteService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.type = params['type'];

        if ( this.type !== 'undefined' ) {
          this.isFiltered = false;
          $('#date_from').val('');
          $('#date_to').val('');
          this.websiteService.httpGetWebsiteLogs({
            type: this.type
          });
          this.loading = true;
        }

      }
    );

    this.websiteLogsSubscription = this.websiteService.websiteLogGetAll.subscribe(
      (websitelogs: any) => {
        if ( typeof websitelogs !== 'undefined' && websitelogs.status === 'success' ) {
          this.loading = false;
          this.websiteLogs = websitelogs.data;

          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    );
  }

  formatTitle(type) {
    if ( type === 'agency-website' ) {
      return 'Agency Website';
    }

    if ( type === 'agent-only-website' ) {
      return 'Agent Only Website';
    }

    if ( type === 'lead-generating-website' ) {
      return 'Lead Generating Website';
    }

    return '';
  }

  initDT() {
    const $this = this;
    setTimeout(() => {
      this.dataTableHtml = $('#datatable-logs').DataTable({
        'pagingType': 'full_numbers',
        'lengthMenu': [
          [50, 100, 200, -1],
          [50, 100, 200, 'All']
        ],
        'pageLength': 50,
        // responsive: true,
        stateSave: true,
        dom: 'Bfrtip',
        buttons: [
          {
            extend: 'csv',
            customize: function(csv) {
              let header = '';

              if ( $this.isFiltered ) {
                header = 'From ' + $('#date_from').val() + ' to ' + $('#date_to').val();
              }

              return header + '\n' + csv;
            }
          }
        ],
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
          'paginate': {
            'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
          }
        },
        'fnDrawCallback': function (oSettings) {
          $('.dataTables_wrapper').find('input[type=search]').addClass('btn-search');
          $('.dataTables_wrapper').find('select').addClass('select_datatable');
        },
      });

    }, 500);
  }

  onFilter() {
    this.isFiltered = true;
    const searchParams = {
      date_from: $('#date_from').val(),
      date_to: $('#date_to').val(),
      type: this.type
    };

    this.loading = true;
    this.websiteService.httpGetWebsiteLogs(searchParams);
  }

  onReset() {
    this.websiteService.httpGetWebsiteLogs({
      type: this.type
    });
    this.loading = true;
    this.isFiltered = false;
    $('#date_from').val('');
    $('#date_to').val('');
  }

  ngAfterViewInit() {
    $('.datePicker').datepicker({
      format: 'yyyy-mm-dd',
      autoHide: true
    });
  }

}
