import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../admin.service';
import { ChangelogService } from '../../../services/changelogs.service';
import { Changelog } from '../../../models/changelog.model';
import { Subscription } from 'rxjs';

declare var swal: any;
@Component({
  selector: 'app-changelog-dashboard',
  templateUrl: './changelog-dashboard.component.html',
  styleUrls: ['./changelog-dashboard.component.scss']
})
export class ChangelogDashboardComponent implements OnInit {
  loading = true;
  changelogs: Changelog[];

  subscription: Subscription;

  dtOptions: any;

  selectedRow = 0;
  selectedRowDataId = [];

  constructor(private changelogService: ChangelogService, private adminService: AdminService) { }

  ngOnInit() {

    this.changelogService.httpGetChangelogs();

    this.subscription = this.changelogService.changelogsChanged.subscribe(
      (Changelogs: Changelog[]) => {
        if(typeof Changelogs != 'undefined'){
          this.changelogs = Changelogs;
          this.loading = false;

          this.dtOptions = {
            order: [],
            pagingType: 'full_numbers',
            language: {
              search: '_INPUT_',
              searchPlaceholder: 'Search',
              paginate: {
                first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
              }
            },
            pageLength: 50,
            retrieve: true,
            select: {
              'style': 'multiple',
            },
          };

          setTimeout(
            () => {
              this.loading = false;
              $('table').DataTable(this.dtOptions);

              jQuery('table').DataTable().column(0).visible(false);

              const $this = this;

              $('table:not(.static)').on('select.dt', function ( e, dt, type, indexes ) {
                $this.selectedRow = $this.selectedRow + 1;
                const data = dt.rows('.selected' ).data();

                for (let i = 0; i < $this.selectedRow; i++) {
                  if ($this.selectedRowDataId.includes(data[i][0])) {
                    continue;
                  } else {
                    $this.selectedRowDataId.push(data[i][0]);
                  }
                }
              });

              $('table:not(.static)').on('deselect.dt', function ( e, dt, type, indexes ) {
                $this.selectedRow = $this.selectedRow - 1;
                const data = dt.rows(indexes).data();
                const groupID = data[0][0];

                $this.selectedRowDataId.forEach((value, index) => {
                    if (value === groupID) {
                      $this.selectedRowDataId.splice(index, 1);
                    }
                });
              });

              $('input[type=search]').addClass('btn-search');
              $('input[type=search]').attr('placeholder','Search');
              $('select').addClass('select_datatable');
              $('select').append('<option selected value="-1">Filter</option>');
            }, 1000
          );
        }
      }
    );
  }

  htmlToPlaintext(text) {
    let content = text ? String(text).replace(/<[^>]+>/gm, '') : '';

    return content.substring(0, 60);
  }

  visibilityToText(code){
    return (code == 0 ? 'Visible' : 'Hidden');
  }

  onDelete(id) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.changelogService.httpDeleteChangelog(id);
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this selected log/s?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.changelogService.httpDeleteChangelog(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }

}
