<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Add Blacklist</h2>

    <button routerLink="/admin/blacklists" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form (ngSubmit)="onSubmit()" [formGroup]="blacklistForm" class="form-horizontal">
          <div class="form-group">
            <label for="title" class="col-sm-2">Provider</label>
            <div class="col-sm-6">
              <select class="form-control" formControlName="provider">
                <option value="mydesktop">MyDesktop</option>
                <option value="vaultre">VaultRE</option>
                <option value="idashboard">iDashboard</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Type</label>
            <div class="col-sm-6">
              <select class="form-control" formControlName="type">
                <option value="office">Office</option>
                <option value="franchise">Franchise</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">ID</label>
            <div class="col-sm-6">
              <input formControlName="external_id" required type="text" class="form-control" id="title" autocomplete="off" maxlength="80">
            </div>
          </div>
          <br>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!blacklistForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>