import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-assets-elements',
  templateUrl: './brand-assets-elements.component.html',
  styleUrls: ['./brand-assets-elements.component.scss']
})
export class BrandAssetsElementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
