import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OptionService } from '../../../services/option.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

declare var swal: any;

@Component({
  selector: 'app-maintenance-status',
  templateUrl: './maintenance-status.component.html',
  styleUrls: ['./maintenance-status.component.scss']
})
export class MaintenanceStatusComponent implements OnInit {
  currentStatus: String;

  subscriptionStatus: Subscription;
  subscriptionOperation: Subscription;

  constructor(private optionService: OptionService, private authService: AuthService) { }

  @ViewChild('code', { static: true }) code: ElementRef;

  ngOnInit() {
    this.optionService.httpGetMaintenance();

    this.subscriptionStatus = this.optionService.maintenanceChanged.subscribe(
      (maintenance: any) => {
        if (typeof maintenance.ID !== 'undefined') {
          if (maintenance.value === '0') {
            this.currentStatus = 'Online';
          } else {
            this.currentStatus = 'Offline';
          }
        }
      }
    );

    this.subscriptionOperation = this.optionService.optionOperation.subscribe(
      (maintenance: any) => {
        if (typeof maintenance.ID !== 'undefined') {
          location.reload();
        }
      }
    );

  }

  onChangeStatus() {
    const data = {
      code: this.code.nativeElement.value,
      app: this.authService.getApp()
    };

    this.optionService.httpPutMaintenance(data);
  }

}
