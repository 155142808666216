import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subgroups',
  templateUrl: './subgroups.component.html',
  styleUrls: ['./subgroups.component.scss']
})
export class SubgroupsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
