import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Menu } from '../../../models/menu.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IconService } from '../../../services/icon.service';
import { Icon } from '../../../models/icon.model';
import { Subscription } from 'rxjs/Subscription';


declare var swal: any;

@Component({
  selector: 'app-icons-edit',
  templateUrl: './icons-edit.component.html',
  styleUrls: ['./icons-edit.component.scss']
})
export class IconsEditComponent implements OnInit {
  menus: Menu[] = [];
  loading = false;
  id: number;
  editMode = false;
  backUrl: string;
  icon: Icon;
  path = '';

  iconForm: FormGroup;

  subscription: Subscription;
  subscriptionIcon: Subscription;

  @ViewChild('itemFile', { static: true }) itemFile: ElementRef;

  constructor(private adminService: AdminService, private route: ActivatedRoute,
    private router: Router, private iconService: IconService) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;
        this.backUrl = this.editMode ? '../../' : '../';

        if (this.id) {
          this.loading = true;

          this.iconService.httpGetIcon(this.id);
        }

      }
    );

    this.adminService.loadMenu(this.menus);

    this.subscription = this.iconService.iconChanged.subscribe(
      (icon: Icon) => {
        this.loading = false;
        
        this.icon = icon;
      }
    );

    this.subscriptionIcon = this.iconService.iconOperation.subscribe(
      (icon: Icon) => {
          this.loading = false;

          this.router.navigate([this.backUrl], {relativeTo: this.route});
      }
    );


  }

    

  onSubmit(event: Event){
    var $this = this;
    if(jQuery('#category_id').val() == null || jQuery('#type_id').val() == null){
      swal(
        'Oops...',
        'Category or Type can\'t be blank',
        'error'
      )
      return false;
    } else {
      var target: HTMLInputElement = event.target as HTMLInputElement;
      for(var i=0; i< target.files.length; i++){
        if ( this.editMode) {
          $this.iconService.httpPutIcon($this.id, jQuery('#category_id').val(), jQuery('#type_id').val(), jQuery('#sort_order').val(), target.files[i]);
        } else {
          $this.loading = true;
          $this.iconService.httpPostIcon(jQuery('#category_id').val(), jQuery('#type_id').val(), jQuery('#sort_order').val(), target.files[i]);
        }
      }
    }
  }

  onUpload() {
    let values = [];
    
    const fileSingle = this.itemFile.nativeElement;

    if (fileSingle.files.length > 0) {
      values['file'] = fileSingle.files[0];
      values['name'] = fileSingle.files[0].name;
    }

    values['type'] = 'file';
    values['path'] = this.path;

    var $this = this;
    if(jQuery('#category_id').val() == null || jQuery('#type_id').val() == null || jQuery('#sort_order').val() == null){
      swal(
        'Oops...',
        'Category, Type and Sort Order can\'t be blank',
        'error'
      )
      return false;
    } else {
      
    if ( this.editMode) {
      $this.loading = true;      
      $this.iconService.httpPutIcon($this.id, jQuery('#category_id').val(), jQuery('#type_id').val(), jQuery('#sort_order').val(), values);
    } else {
      $this.loading = true;
      $this.iconService.httpPostIcon(jQuery('#category_id').val(), jQuery('#type_id').val(), jQuery('#sort_order').val(), values);
    }


  }

  }

}
