import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'client'
})
export class ClientPipe implements PipeTransform {

  transform(value: any, searchText: string): any {
    if (!value) return [];
    if (searchText === undefined) return value;

    return value.filter(function(val) {
      return val.company_name.toLowerCase().includes(searchText.toLowerCase());
    });

  }

}
