import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomRequest } from '../../models/customrequest.model';
import { Client } from '../../models/client.model';
import { Auth } from '../../models/auth.model';
import { FrontService } from '../front.service';
import { SupportService } from '../../services/support.service';
import { Subscription } from 'rxjs';

declare var swal: any;

@Component({
  selector: 'app-custom-request',
  templateUrl: './custom-request.component.html',
  styleUrls: ['./custom-request.component.scss']
})
export class CustomRequestComponent implements OnInit {
  loading = false;

  requestForm: FormGroup;
  subscription: Subscription;
  subscriptionResponse: Subscription;

  authUser: Auth;
  client: Client;
  

  @ViewChild('fileAttached', {static: true}) fileAttached: ElementRef;
  constructor(
    private supportService: SupportService,
    public frontService: FrontService,
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.client = this.authUser.client;

    this.requestForm = new FormGroup({
      'agencyname': new FormControl(this.client.company_name),
      'firstname': new FormControl('',Validators.required),
      'lastname': new FormControl('',Validators.required),
      'email': new FormControl('',Validators.required),
      'phone': new FormControl(''),
      'elementemail': new FormControl(''),
      'elementprintable': new FormControl(''),
      'elementsocialmedia': new FormControl(''),
      'elementother': new FormControl(''),
      'fileAttached': new FormControl(''),
      'message': new FormControl('',Validators.required)
    });

  }

  onSubmit(){
    const requestValues = this.requestForm.value;

    const screenshots = [];
    const thumbnail = this.fileAttached.nativeElement;
    if (thumbnail.files.length > 0) {
      for ( const thumb of thumbnail.files ) {
        screenshots.push(thumb);
      }
    }

    this.supportService.httpPostCustomRequest(requestValues, screenshots);

    swal(
      'Success!',
      'Your request has been sent to Professionals Corporate for approval.',
      'success'
    );

    this.requestForm.reset();
  }

}
