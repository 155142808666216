import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ElementService } from '../../../../services/element.service';
import { Element } from '../../../../models/element.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';
import { Menu } from '../../../../models/menu.model';
import { AdminService } from '../../../admin.service';

declare var ace: any;
declare var swal: any;
@Component({
  selector: 'app-element-html',
  templateUrl: './element-html.component.html',
  styleUrls: ['./element-html.component.scss']
})
export class ElementHtmlComponent implements OnInit, OnDestroy {
  loading = false;
  id: number;
  editMode = false;
  backUrl: string;
  menus: Menu[] = [];

  @ViewChild('html', { static: false }) html_data;

  subscription: Subscription;

  elementForm: FormGroup;
  element: Element;

  element_title = '';

  aceEditor: any;
  scriptEditor: any;

  constructor(private elementService: ElementService,
              private adminService: AdminService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.fullPageContent = true;
    this.adminService.loadMenu(this.menus);

    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;
        this.backUrl = this.editMode ? '../../' : '../../';

        if (this.id) {
          this.loading = true;
          this.elementService.httpGetElement(this.id);
        }
      }

    );

 
    this.subscription = this.elementService.elementChanged.subscribe(
      (element: Element) => {
        this.element = element;

        if ( this.editMode && typeof element !== 'undefined' ) {
          this.loading = false;
          this.initFormEdit();
        }
      }
    );

    this.aceEditor = ace.edit('html_editor', {
      mode: 'ace/mode/html',
      autoScrollEditorIntoView: true,
      copyWithEmptySelection: true,
      vScrollBarAlwaysVisible: true,
      minLines: 2,
      tabSize: 1,
      useSoftTabs: true
    });

    this.scriptEditor = ace.edit('script_editor', {
      mode: 'ace/mode/javascript',
      autoScrollEditorIntoView: true,
      copyWithEmptySelection: true,
      vScrollBarAlwaysVisible: true,
      minLines: 2,
      tabSize: 1,
      useSoftTabs: true
    });

    this.initForm();
  }

  private initForm() {
    this.elementForm = new FormGroup({
      'html': new FormControl('', Validators.required),
      'script': new FormControl('')
    });

  }

  private initFormEdit() {
    this.element_title = this.element.title;
    this.elementForm.patchValue({
      'html': this.element.html,
      'script': this.element.script
    });

    this.aceEditor.setValue(this.element.html, 1);
    const $this = this;
    this.aceEditor.getSession().on('change', function() {
      $this.elementForm.patchValue({
        'html': $this.aceEditor.getValue(),
      });
    });
    // this.aceEditor.setAutoScrollEditorIntoView(true);
    this.aceEditor.setOption('maxLines', 100);
    this.aceEditor.session.setUseWrapMode(false);
    this.aceEditor.setTheme('ace/theme/chrome');

    this.scriptEditor.setValue(this.element.script, -1);
    this.scriptEditor.getSession().on('change', function(){
      $this.elementForm.patchValue({
        'script': $this.scriptEditor.getValue(),
      });
    });
    // this.scriptEditor.setAutoScrollEditorIntoView(true);
    this.scriptEditor.setOption('maxLines', 100);
    this.scriptEditor.session.setUseWrapMode(false);
    this.scriptEditor.setTheme('ace/theme/chrome');

  }

  onSubmit() {
    const elementValues = this.elementForm.value;
    this.loading = true;

    if ( this.editMode) {
      this.elementService.httpPutElement(this.id, elementValues);
    } else {
      this.elementService.httpPostElement(elementValues);
    }

    setTimeout(() => {
      this.loading = false;
      swal(
        'Success',
        'Element saved successfully',
        'success'
      );
    }, 1000);
  }

  onClose() {
    window.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
