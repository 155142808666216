<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">Merge Fields</h2>

        <button routerLink="new" type="button" class="btn-admin">
            <i class="icon feather icon-plus"></i><span>Add New Merge Field</span>
        </button>
    </div>
</div>
<div id="box" class="row">
    <div class="col-md-12">
        <div class="table-box">
            <app-admin-table-component [rowData]="mergeFields">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Reference</th>
                        <th>Name</th>
                        <th>Merge Field</th>
                        <th>Merge Field Output</th>
                        <th>Last Updated</th>
                        <th class="text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let mergeField of mergeFields">
                        <td>{{mergeField?.merge_fields_category?.name}}</td>
                        <td>{{mergeField?.reference}}</td>
                        <td>{{mergeField?.name}}</td>
                        <td>{{mergeField?.merge_field}}</td>
                        <td>{{mergeField?.merge_field_output}}</td>
                        <td>{{mergeField?.updated_at | date:'medium'}}</td>
                        <td class="text-end">
                            <a routerLink="/admin/pros/builder/mergefields/{{mergeField?.uuid}}/edit" class="btn btn-primary btn-sm">Edit</a>
                        </td>
                    </tr>
                </tbody>
            </app-admin-table-component>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>