import { Injectable, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Menu } from '../models/menu.model';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '../app.config';
import { AuthService } from '../services/auth.service';

let tour_video_link;

@Injectable()
export class FrontService {
  menuChanged = new Subject<Menu[]>();
  routeData = new Subject<any>();
  fullPageContent = false;
  supportLink = 'https://websites.formstack.com/forms/mydesign_support_form';

  private menus: Menu[] = [];

  public paste_alert = false;
  public new_notification = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig,
    public authService: AuthService,
    private router: Router
  ) {}


  loadMenu(menus) {
    this.menus = menus;
    this.menuChanged.next(menus);
  }

  hasMenu() {
    this.fullPageContent = false;

    return this.menus.length > 0 ? true : false;
  }

  tourVideoLink() {
    tour_video_link = 'https://youtube.com';

    return tour_video_link;
  }

}
