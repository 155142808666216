import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Block } from '../models/block.model';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class BlockService {
  subscription: Subscription;
  blocksChanged = new Subject<Block[]>();
  blockChanged = new Subject<Block>();
  onAdd = new Subject();
  onPut = new Subject();

  private blocks: Block[] = [];

  public block_id = 0;
  public group_id = 0;
  public category_id = 0;
  public search: '';

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setBlocks(blocks: Block[]) {
    this.blocks = blocks;
    this.blocksChanged.next(this.blocks.slice());
  }

  getBlocks() {
    return this.blocks.slice();
  }

  getIdIndex(id: number) {
    return this.blocks.findIndex(e => e.ID === id);
  }

  httpGetBlocks(params: any = [], columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/blocks',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Block[]) => {
        this.setBlocks(response);
      }
    );
  }

  httpGetBlock(id: number, params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/blocks/' + id,
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Block) => {
        this.blockChanged.next(response);
      }
    );
  }

  httpPostBlock(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/blocks`, data)
    .subscribe(
      (response: any) => {
        this.onAdd.next(response);
      },
      (response: any) => {
        this.onAdd.next(response.error);
      }
    );
  }

  httpPutBlock(id: number, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/blocks/${id}`, data)
    .subscribe(
      (response: any) => {
        this.onPut.next(response);
      },
      (response: any) => {
        this.onPut.next(response.error);
      }
    );
  }

  httpDeleteBlock(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/blocks/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetBlocks();
      }
    );
  }
}
