<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Site': 'Edit Site' }}</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="websiteForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="name" class="col-sm-2">Client Name</label>
            <div class="col-sm-6">
              <input formControlName="name" type="text" class="form-control" id="name" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="thumbnail" class="col-sm-2">Image</label>
            <div class="col-sm-6">
              <input #fileImage type="file" class="form-control" id="thumbnail" accept="image/*">
              <img *ngIf="editMode" src="{{ website?.image }}">
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="is_active" class="col-sm-2">Parent Element</label>
            <div class="col-sm-3">
              <select formControlName="parent_id" class="form-control" id="parent_id">
                <option value="" disabled>Select an Element</option>
                <option value="0">None</option>
              </select>
            </div>
          </div> -->
          <div class="form-group">
            <label for="sort" class="col-sm-2">Sort #</label>
            <div class="col-sm-3">
              <input formControlName="sort" type="number" class="form-control" id="sort" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!websiteForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>