import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { Subscription } from 'rxjs/Subscription';
import { FrontService } from '../front.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../../models/client.model';
import slugify from 'slugify';
import { MalihuScrollbarModule, MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Auth } from '../../models/auth.model';
import { DomainService } from '../../services/domain.service';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';


declare var swal: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  $destroy: Subject<boolean> = new Subject();
  subscription: Subscription;
  menus: Menu[];
  currentRoute: ActivatedRoute;
  defaultSubMenu = '';
  menuType = '';
  urlFragment = '';
  isUserPro: Boolean;

  defaultRoute = '';

  user = {
    ID: 1,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  authUser: Auth;
  client: Client;

  isMultipleOffice = false;
  isDomainMultipleOffice = false;

  @ViewChild('sidebarBox', { static: true }) sidebarBox: ElementRef;
  @ViewChild('sidebarSubBox', { static: true }) sidebarSubBox: ElementRef;

  selectedLogo = '';

  constructor(public frontService: FrontService,
              private route: ActivatedRoute,
              private router: Router,
              private mScrollbarService: MalihuScrollbarService,
              private domainService: DomainService,
              private userService: UserService
            ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.isUserPro = this.authUser.proAddons.includes('Pro Builder') || this.authUser.proAddons.includes('Bundle');
    this.client = this.authUser.client;

    if (this.client.is_account_master === 1) {
      this.defaultRoute = 'logos';
    } else  {
      if (this.client.group.setting_menu_link === 1) {
        this.defaultRoute = 'website-links';
      }
      if (this.client.group.setting_menu_icon === 1) {
        this.defaultRoute = 'icons';
      }
      if (this.client.group.setting_menu_font === 1) {
        this.defaultRoute = 'fonts-colors';
      }
      if (this.client.group.setting_menu_color === 1) {
        this.defaultRoute = 'colours';
      }
      if (this.client.group.setting_menu_logo === 1) {
        this.defaultRoute = 'logos';
      }
    }

    if ( this.frontService.authService.getApp() === 'MyDesign3') {
      this.selectedLogo = 'mydesign-logo-2020.svg';
    } else {
      if ( this.authUser.brandid === '6' ) {
        this.selectedLogo = 'designlyplus-logo-white.svg';
      } else if (this.frontService.appConfig.SKIN === 'signifi') {
        this.selectedLogo = 'signify_design_logo.svg';
      } else if (this.frontService.appConfig.SKIN === 'raywhite') {
        this.selectedLogo = 'rw-logo.svg';
      } else if (this.frontService.appConfig.SKIN === 'prd') {
        this.selectedLogo = 'PRD.REALESTATE_Logo_White_SVG.svg';
      } else if (this.frontService.appConfig.SKIN === 'professionals') {
        this.selectedLogo = 'Professionals_Asset_Library_Inverted.svg';
      } else {
        this.selectedLogo = 'designly-logo-inverted.svg';
      }

      if (this.authUser.provider === 'designly'
      || (this.frontService.appConfig.SKIN === 'designly' && this.authUser.provider === 'domain')) {
        this.selectedLogo = 'designly-logo-inverted.svg';
      }
    }

    if ( this.authUser.multiple.length > 1 ) {
      this.isMultipleOffice = true;
    }

    this.user = {
      ID: this.authUser.user.agent_id,
      name: this.authUser.user.firstname + ' ' + this.authUser.user.lastname,
      photo: this.authUser.user.imageurl,
      email: this.authUser.user.email,
      mobile: this.authUser.user.mobile,
      telephone: this.authUser.user.telephone,
      fax: this.authUser.user.fax
    };

    this.subscription = this.frontService.menuChanged.subscribe(
      (menus: Menu[]) => {
        this.menus = menus;
      }
    );

    this.frontService.routeData.subscribe(
      (data: any) => {
        this.currentRoute = data.route;
        this.urlFragment = '';
        this.defaultSubMenu = data.default;
        this.menuType = data.type;
      }
    );

    this.userService.onCrmLogin
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            window.location.href = `${this.frontService.appConfig.DESIGNLYMAIL_URL}/auth/login?reference=${response.data.uuid}`;
          }
          if (typeof response !== 'undefined' && response.status === 'error') {
            swal({
              title: 'Error logging in to Designly CRM',
              type: 'error',
            });
          }
        }
      }
    );

    if (this.authUser.provider === 'domain' && this.frontService.authService.getDomainMulti() === 'true') {
      this.isDomainMultipleOffice = true;
    }

  }

  nagivateLink(url) {
    this.router.navigate([], {relativeTo: this.currentRoute, fragment: url});
    this.urlFragment = url;
  }

  slugLink(link: string) {
    return slugify((link).trim());
  }

  goToHelpPortal() {
    this.router.navigateByUrl('/help');
  }

  setTemplatePage() {
    localStorage.setItem('isTemplatePage', '1');
  }

  onLogout() {
    localStorage.clear();

    let loginLink = '/login';

    if (this.frontService.authService.isSignifi() === 'no' && this.authUser.provider === 'domain') {
      loginLink = '/domain/login';
    }

    if (this.authUser.brandid === '6') {
      loginLink += '?RH=1';
      loginLink += localStorage.getItem('is_corporate') === 'yes' ? loginLink += '&cv=1' : '';
    }

    window.location.href = loginLink;
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar('#malihusb', { axis: 'y', theme: 'minimal', scrollInertia: 600 });
  }

  getDomainMulti() {
    console.log(this.frontService.authService.getDomainMulti());
  }

  getAgencies() {
    this.domainService.httpGetAgencies();
  }

  onCrmLogin() {
    if (this.authUser.provider === 'designly') {
      window.location.href = this.frontService.appConfig.DESIGNLYMAIL_URL;
      return;
    }

    swal({
      title: 'Please wait while we connect your account to Designly CRM',
      type: 'warning',
    });

    const loginData = {
      office: this.authUser.client,
      user: this.authUser.user,
      builderToken: this.frontService.authService.getToken()
    };

    this.userService.httpCrmLogin(loginData);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.mScrollbarService.destroy('#malihusb');

    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
