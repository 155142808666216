import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { APP_CONFIG } from '../../../app/app.config';
import { IdashboardService } from '../../services/idashboard.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';

import swal from 'sweetalert2';

@Component({
  selector: 'app-idashboard-login',
  templateUrl: './idashboard-login.component.html',
  styleUrls: ['./idashboard-login.component.scss']
})
export class IdashboardLoginComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  loading = false;
  oauthUrl = 'https://api.idashboard.com.au/oauth/authorize?response_type=code';
  authCode: string;

  loginFailed = false;
  constructor(
    @Inject(APP_CONFIG) public appConfig,
    private globalService: GlobalService,
    private idashboardService: IdashboardService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const clientId = this.globalService.getEnvironmentKeys('IDASHBOARD_CLIENT_ID');
    this.oauthUrl = `${this.oauthUrl}&client_id=${clientId}&redirect_uri=${this.appConfig.HTML_ENDPOINT}/login`;

    this.authCode = this.route.snapshot.queryParamMap.get('code');

    if (this.authCode) {
      this.loading = true;
      this.idashboardService.httpLogin({
        code: this.authCode
      });
    }

    this.idashboardService.onLogin
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          this.authService.setAuthStorage(response);
          const $this = this;

          swal({
            title: 'Preparing this device...',
            timer: 3000,
            onOpen: function () {
              swal.showLoading ();
            }
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === 'timer') {
                $this.authService.getAuthCookie();

                if (typeof response.is_first !== 'undefined' && response.is_first) {
                  return window.location.href = '/settings?setup=true';
                }

                const redirectUrl = $this.authService.cookieService.get('redirect');
                if (redirectUrl !== '' && redirectUrl !== ' ' && redirectUrl !== '/') {
                  $this.authService.cookieService.delete('redirect', '/');
                  return window.location.href = redirectUrl;
                }

                if ($this.authService.auth.first === '1') {
                  return window.location.href = '/settings?setup=true';
                }

                return window.location.href = '/templates';
              }
            }
          );
        } else {
          this.loading = false;
          this.loginFailed = true;
        }
      }
    );

    this.idashboardService.onGetLoginUrl
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
          if (response.status === 'success') {
            return window.location.href = response.data;
          }
          if (response.status === 'error') {
            this.loading = false;
            swal('Third-party service offline', 'Try again later', 'error');
          }
        }
      }
    );
  }

  getLoginUrl() {
    this.idashboardService.httpGetLoginUrl();
    this.loading = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
