import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-popups',
  templateUrl: './admin-popups.component.html',
  styleUrls: ['./admin-popups.component.scss']
})
export class AdminPopupsComponent implements OnInit {
  loading = false;
  menus: Menu[] = [];

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.loadMenu(this.menus);
  }

}
