<div class="container-login" [class.raywhite-custom-bg]="authService.isRaywhite() === 'yes'">
  <div class="login-wrap">
    <div class="_login">
      <div class="logo-holder">
        <img src="/assets/img/{{ selectedLogo }}" class="logo" [class.is-raywhite-logo]="authService.isRaywhite() === 'yes'">
      </div>
  
      <div *ngIf="isEmptyUser">
        <form [formGroup]="agentLoginForm" (ngSubmit)="onLoginAgent()">
          <select formControlName="agent_id" class="form-control agent-list">
            <option value="">Select agent</option>
            <option *ngFor="let agent of agents" [value]="agent.agent_id">{{agent.firstname}} {{agent.lastname}} ({{agent.agent_id}})</option>
          </select>
          <div class="provider-logins">
            <button type="submit" [disabled]="!agentLoginForm.valid" class="btn btn-tagged btn-lg-domain provider-domain">Login</button>
          </div>
        </form>
      </div>
      
      <h4>Sign into your Designly Account</h4>
      <div *ngIf="multiAgency">
        <form [formGroup]="agencyLoginForm" (ngSubmit)="onLoginAgency()">
          <select #agencySelect (change)="onChangeAgency(agencySelect.value)" formControlName="agency_id" class="form-control agent-list">
            <option value="">Select agency</option>
            <option *ngFor="let agency of agencies" [value]="agency.id">{{agency.name}} ({{agency.id}})</option>
          </select>
          <select formControlName="agent_id" class="form-control agent-list">
            <option value="">Select agent</option>
            <option *ngFor="let agent of agents" [value]="agent?.id">{{agent?.firstName}} {{agent?.lastName}} ({{agent?.id}})</option>
          </select>
          <div class="provider-logins">
            <button type="submit" [disabled]="!agencyLoginForm.valid" class="btn btn-tagged btn-lg-domain provider-domain">Login</button>
          </div>
        </form>
      </div>
      
      <div *ngIf="!isEmptyUser && !multiAgency">
        <p *ngIf="isSignifi" class="sub-heading">In order to utilise Signifi Design we need to connect your Domain Group account</p>
        <p *ngIf="!isSignifi" class="sub-heading">In order to utilise Designly we need to connect your Domain Group account</p>
        <div class="provider-logins">
          <a href="{{ domain_login_link }}" class="btn btn-tagged btn-lg-domain provider-domain">
            Sign in with <img class="domain-logo-login" src="/assets/img/partners/domain-logo.svg"></a>
        </div>
      </div>
    </div>
  </div>
  <div class="login-bg" style="background-image: url('/assets/img/designly-login-background.jpg');">
    <div class="login-bg-text">Signify Design is the all-in-one design platform creating emarketing emails, window cards, property guides and more</div>
  </div>
</div>
<!-- <div class="height-100" id="sign-form-box" [class.raywhite-custom-bg]="authService.isRaywhite() === 'yes'">
  <div class="col-xs-12 col-sm-6 col-md-3 box">
    <div class="logo-holder">
      <img src="/assets/img/{{ selectedLogo }}" class="logo" [class.is-raywhite-logo]="authService.isRaywhite() === 'yes'">
    </div>

    <div *ngIf="isEmptyUser">
      <form [formGroup]="agentLoginForm" (ngSubmit)="onLoginAgent()">
        <select formControlName="agent_id" class="form-control agent-list">
          <option value="">Select agent</option>
          <option *ngFor="let agent of agents" [value]="agent.agent_id">{{agent.firstname}} {{agent.lastname}} ({{agent.agent_id}})</option>
        </select>
        <div class="provider-logins">
          <button type="submit" [disabled]="!agentLoginForm.valid" class="btn btn-tagged btn-lg-domain provider-domain">Login</button>
        </div>
      </form>
    </div>

    <div *ngIf="multiAgency">
      <form [formGroup]="agencyLoginForm" (ngSubmit)="onLoginAgency()">
        <select #agencySelect (change)="onChangeAgency(agencySelect.value)" formControlName="agency_id" class="form-control agent-list">
          <option value="">Select agency</option>
          <option *ngFor="let agency of agencies" [value]="agency.id">{{agency.name}} ({{agency.id}})</option>
        </select>
        <select formControlName="agent_id" class="form-control agent-list">
          <option value="">Select agent</option>
          <option *ngFor="let agent of agents" [value]="agent?.id">{{agent?.firstName}} {{agent?.lastName}} ({{agent?.id}})</option>
        </select>
        <div class="provider-logins">
          <button type="submit" [disabled]="!agencyLoginForm.valid" class="btn btn-tagged btn-lg-domain provider-domain">Login</button>
        </div>
      </form>
    </div>

    <div *ngIf="!isEmptyUser && !multiAgency">
      <p *ngIf="isSignifi" class="sub-heading">In order to utilise Signifi Design we need to connect your Domain Group account</p>
      <p *ngIf="!isSignifi" class="sub-heading">In order to utilise Designly we need to connect your Domain Group account</p>
      <div class="provider-logins">
        <a href="{{ domain_login_link }}" class="btn btn-tagged btn-lg-domain provider-domain">
          Sign in with <img class="domain-logo-login" src="/assets/img/partners/domain-logo.svg"></a>
      </div>
    </div>
  </div>
</div> -->
<ngx-loading [show]="loading"></ngx-loading>