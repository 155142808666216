import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../../services/department.service';
import { AdminService } from '../../admin.service';
import { Department } from '../../../models/department.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-setting-admin-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit, OnDestroy {
  loading = false;
  departments: Department[] = [];

  dtOptions: any;

  departmentForm: FormGroup;

  subscription: Subscription;
  subscriptionResponse: Subscription;

  selectedRow = 0;
  selectedRowDataId = [];

  @ViewChild('newDepartmentModalClose', { static: true }) newDepartmentModalClose: ElementRef;

  constructor(private adminService: AdminService,
    private departmentService: DepartmentService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.subscription = this.departmentService.departmentsChanged.subscribe(
      (departments: Department[]) => {
        if (typeof departments !== 'undefined') {
          this.loading = false;
          this.departments = departments;

          this.initDT();
        }
      }
    );

    this.subscriptionResponse = this.departmentService.departmentOperation.subscribe(
      (department: Department) => {
        if ( typeof department.ID !== 'undefined' ) {
          this.loading = true;
          this.departmentService.httpGetDepartments();
        }
      }
    );

    this.initForm();

    this.departmentService.httpGetDepartments();

    this.initDT();
  }

  initDT() {
    this.dtOptions = {
      order: [],
      pagingType: 'full_numbers',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
        paginate: {
          first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
          previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
          next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
          last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
        }
      },
      pageLength: 50,
      retrieve: true,
      select: {
        'style': 'multiple',
      },
    };

    setTimeout(
      () => {
        if ( ! jQuery.fn.DataTable.isDataTable( '#tbl_department' ) ) {
          $('#tbl_department').DataTable(this.dtOptions);
          jQuery('#tbl_department').DataTable().column(0).visible(false);

          const $this = this;

          $('#tbl_department').on('select.dt', function ( e, dt, type, indexes ) {
            $this.selectedRow = $this.selectedRow + 1;
            const data = dt.rows('.selected' ).data();

            for (let i = 0; i < $this.selectedRow; i++) {
              if ($this.selectedRowDataId.includes(data[i][0])) {
                continue;
              } else {
                $this.selectedRowDataId.push(data[i][0]);
              }
            }
          });

          $('#tbl_department').on('deselect.dt', function ( e, dt, type, indexes ) {
            $this.selectedRow = $this.selectedRow - 1;
            const data = dt.rows(indexes).data();
            const groupID = data[0][0];

            $this.selectedRowDataId.forEach((value, index) => {
                if (value === groupID) {
                  $this.selectedRowDataId.splice(index, 1);
                }
            });
          });

          $('input[type=search]').addClass('btn-search');
          $('input[type=search]').attr('placeholder','Search');
          $('select').addClass('select_datatable');
          $('select').append('<option selected value="-1">Filter</option>');
        }
      }, 1000
    );
  }


  private initForm() {
    this.departmentForm = new FormGroup({
      'title': new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    if (this.departmentForm.valid) {
      this.loading = true;
      const departmentValues = this.departmentForm.value;

      this.departmentService.httpPostDepartment(departmentValues);

      swal(
        'Added!',
        'Department added successfully',
        'success'
      ).then(setTimeout(
          () => {
            this.departmentForm.reset();
            this.newDepartmentModalClose.nativeElement.click();
          }, 200
        )
      );

    }
  }

  onDelete(id: number) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.departmentService.httpDeleteDepartment(id);
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this data selected?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.departmentService.httpDeleteDepartment(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }

}
