import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PopupService } from '../../../services/popup.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

declare var swal: any;

@Component({
  selector: 'app-admin-popups-edit',
  templateUrl: './admin-popups-edit.component.html',
  styleUrls: ['./admin-popups-edit.component.scss']
})
export class AdminPopupsEditComponent implements OnInit, OnDestroy {
  $destroy: Subject<boolean> = new Subject();
  loading = false;

  popupForm: FormGroup;
  editMode = false;
  ID: number;
  popup: any;

  @ViewChild('popupImage', { static: true }) popupImage: ElementRef;
  constructor(
    private popupService: PopupService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.ID = +params['id'];
        this.editMode = params['id'] != null;

        if (this.editMode) {
          this.loading = true;
          this.popupService.httpGet(this.ID);
        }
      }
    );

    this.popupService.onAdd
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            swal('Popup Created', '', 'success');
            this.router.navigateByUrl('/admin/popups/dashboard');
          }
          if (typeof response !== 'undefined' && response.status === 'error') {
            this.loading = false;
            swal('Error creating popup', 'Contact Dev Team', 'error');
          }
        }
      }
    );

    this.popupService.onPut
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            swal('Popup Updated', '', 'success');
            this.router.navigateByUrl('/admin/popups/dashboard');
          }
          if (typeof response !== 'undefined' && response.status === 'error') {
            this.loading = false;
            swal('Error updating popup', 'Contact Dev Team', 'error');
          }
        }
      }
    );

    this.popupService.onGet
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            this.loading = false;
            this.popup = response.data;

            this.popupForm.patchValue({
              'provider': this.popup.provider,
              'findout_url': this.popup.findout_url
            });
          }
          if (typeof response !== 'undefined' && response.status === 'error') {
            this.loading = false;
            swal('Error getting popup', 'Contact Dev Team', 'error');
          }
        }
      }
    );

    this.popupForm = new FormGroup({
      'provider': new FormControl('', Validators.required),
      'image': new FormControl(''),
      'findout_url': new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    const popupFormValues = this.popupForm.value;

    if (this.popupForm.valid) {
      if (!this.editMode && this.popupImage.nativeElement.files.length === 0) {
        swal('Image is required', '', 'error');

        return false;
      }

      if (this.editMode) {
        // tslint:disable-next-line: max-line-length
        this.popupService.httpPut(this.ID, popupFormValues['provider'], popupFormValues['findout_url'], this.popupImage.nativeElement.files);
      } else {
        this.popupService.httpAdd(popupFormValues['provider'], popupFormValues['findout_url'], this.popupImage.nativeElement.files);
      }
      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
