import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { VideoService } from '../../../services/video.service';
import { Subscription } from 'rxjs/Subscription';
import { Video } from '../../../models/video.model';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-training-videos',
  templateUrl: './training-videos.component.html',
  styleUrls: ['./training-videos.component.scss']
})
export class TrainingVideosComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  menus: Menu[] = [];
  subscription: Subscription;
  videos: Video[] = [];

  accordion;

  @ViewChild('previewModal', { static: true }) previewModal: ElementRef;

  constructor(
    private frontService: FrontService,
    private videoService: VideoService
  ) { }

  ngOnInit() {
    const $this = this;
    this.frontService.loadMenu(this.menus);
    this.videoService.httpGetVideosPublic();
    this.loading = true;

    this.videoService.publicOnGetVideos
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.videos = response.data;
            }
          }
        }
      }
    );

    jQuery('#previewElement').on('hide.bs.modal', function (e) {
      $this.previewModal.nativeElement.querySelector('.modal-body iframe').src = '';
    });

  }

  onPreview(link: string) {
    this.previewModal.nativeElement.querySelector('.modal-body iframe').src = link;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
