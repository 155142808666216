<div class="billing-wrapper">
    <div class="row mb-4">
        <div class="col-md-12">
            <h2 class="fw-bold">My Billing</h2>
            <p>Manage your billing details here.</p>
        </div>
    </div>

    <div class="row billing-items">
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Account Information</h6>
                            <p class="mb-0">Manage your Designly payment account here.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('user', 'ACCOUNT_DETAILS')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Address</h6>
                            <p class="mb-0">Keep your billing address up to date here.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('user', 'ADDRESS')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Credit Card</h6>
                            <p class="mb-0">Manage your preferred payment method here.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('user', 'PAYMENT_SOURCES')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Billing History</h6>
                            <p class="mb-0">View your billing records.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('user', 'BILLING_HISTORY')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-md-12">
            <h2 class="fw-bold">Office Billing</h2>
            <p>Manage office billing details here.</p>
        </div>
    </div>

    <div class="row billing-items">
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Account Information</h6>
                            <p class="mb-0">Manage your Designly payment account here.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('client', 'ACCOUNT_DETAILS')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Address</h6>
                            <p class="mb-0">Keep your billing address up to date here.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('client', 'ADDRESS')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Credit Card</h6>
                            <p class="mb-0">Manage your preferred payment method here.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('client', 'PAYMENT_SOURCES')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 billing-item">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill">
                            <h6 class="mt-0">Billing History</h6>
                            <p class="mb-0">View your billing records.</p>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="openPortal('client', 'BILLING_HISTORY')">Manage</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>