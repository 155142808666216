import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Setting } from '../models/setting.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class SettingService {
  settingsChanged = new Subject<Setting[]>();
  settingChanged = new Subject<Setting>();
  settingOperation = new Subject<Setting>();
  settingListings = new Subject<Setting>();
  settingExport = new Subject<any>();

  private settings: Setting[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setSettings(settings: Setting[]) {
    this.settings = settings;
    this.settingsChanged.next(this.settings.slice());
  }

  getSettings() {
    return this.settings.slice();
  }

  getIdIndex(id: number) {
    return this.settings.findIndex(c => c.ID === id);
  }

  httpGetSetting(id: number) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/settings/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Setting) => {
        this.settingChanged.next(response);
      }
    );
  }

  httpGetSettingListings(id: number) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/settings/' + id + '/listings',
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Setting) => {
        this.settingListings.next(response);
      }
    );
  }

  httpPutSetting(id: number, data: Setting) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/settings/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.settingOperation.next(response);
      }
    );
  }

  httpExport(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/settings/export',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.settingExport.next(response);
      },
      (response: any) => {
        this.settingExport.next(response.error);
      }
    );
  }
}
