import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class PricefinderService {
    onSuburbSearch = new Subject();
    onGetSuburb = new Subject();
    onGetRecents = new Subject();
    onGenerateReport = new Subject();
    onGenerateMap = new Subject();
    onSearch = new Subject();
    onGetStats = new Subject();
    onGetStatsChart = new Subject();
    onGetSegmentations = new Subject();
    onGetProperties = new Subject();
    onGetProperty = new Subject();
    onGetPropertyListings = new Subject();
    onGetSingleProperty = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    httpSearch(params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/suburbs`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onSuburbSearch.next(response);
            },
            (response: any) => {
                this.onSuburbSearch.next(response.error);
            }
        );
    }

    httpGetSuburb(suburbId: string) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/suburb/${suburbId}`)
        .subscribe(
            (response: any) => {
                this.onGetSuburb.next(response);
            },
            (response: any) => {
                this.onGetSuburb.next(response.error);
            }
        );
    }

    httpGetRecents(suburbId: string, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/suburb/${suburbId}/recents`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetRecents.next(response);
            },
            (response: any) => {
                this.onGetRecents.next(response.error);
            }
        );
    }

    httpGetStats(suburbId: string, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/suburb/${suburbId}/stats`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetStats.next(response);
            },
            (response: any) => {
                this.onGetStats.next(response.error);
            }
        );
    }

    httpGetSegmentations(suburbId: string, data: any, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/pricefinder/suburb/${suburbId}/segmentations`, data, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetSegmentations.next(response);
            },
            (response: any) => {
                this.onGetSegmentations.next(response.error);
            }
        );
    }

    httpGetStatsChart(suburbId: string, data: any, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/pricefinder/suburb/${suburbId}/statschart`, data, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetStatsChart.next(response);
            },
            (response: any) => {
                this.onGetStatsChart.next(response.error);
            }
        );
    }

    httpGenerateMap(data: any) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/pricefinder/map`, data)
        .subscribe(
            (response: any) => {
                this.onGenerateMap.next(response);
            },
            (response: any) => {
                this.onGenerateMap.next(response.error);
            }
        );
    }

    httpSearchProperty(propertyId: number) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/properties/${propertyId}`)
        .subscribe(
            (response: any) => {
                this.onGetProperty.next(response);
            },
            (response: any) => {
                this.onGetProperty.next(response.error);
            }
        );
    }

    httpSearchProperties(params: any = {}) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/properties`, {
            params
        })
        .subscribe(
            (response: any) => {
                this.onGetProperties.next(response);
            },
            (response: any) => {
                this.onGetProperties.next(response.error);
            }
        );
    }

    httpPropertyListings(propertyId: number, params: any = {}) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/properties/${propertyId}/listings`, {
            params
        })
        .subscribe(
            (response: any) => {
                this.onGetPropertyListings.next(response);
            },
            (response: any) => {
                this.onGetPropertyListings.next(response.error);
            }
        );
    }

    httpSingleProperty(propertyId: number, params: any = {}) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/pricefinder/single/property/${propertyId}`, {
            params
        })
        .subscribe(
            (response: any) => {
                this.onGetSingleProperty.next(response);
            },
            (response: any) => {
                this.onGetSingleProperty.next(response.error);
            }
        );
    }
}
