import { Component, OnInit, ViewEncapsulation, SecurityContext, Input, OnDestroy, ChangeDetectorRef, Inject, AfterViewInit } from '@angular/core';
import { ParticaService } from '../../services/partica.service';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import * as md5 from 'md5';
import { environment } from '../../../environments/environment.staging';
import { APP_CONFIG } from '../../app.config';
import { UserService } from '../../services/user.service';
import { ClientService } from '../../services/client.service';

declare var $: any;
declare var Stripe: any;
declare var $script: any;
declare var introJs: any;

@Component({
  selector: 'app-partica',
  templateUrl: './partica.component.html',
  styleUrls: ['./partica.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParticaComponent implements OnInit, OnDestroy, AfterViewInit {
  authUser: any;
  particaAccountId: any;

  loading = false;
  isRegistered: boolean;

  user_token = '';

  article: any;
  articles = [];
  facets = [];
  streams = [];
  selectedStream: any;

  purchasedArticles = [];

  tmpIssueGuid = '';

  cartItems: any[];

  currentKeyword = 'real estate';
  currentFilter = [];

  availableFilters = [];

  creditCardForm: FormGroup;

  stripe: any;
  cardDetails: any;

  currentTab = 'search';
  currentPage = 1;
  maxPage = 1;

  totalArticles = 1;
  scrollEnable = true;

  purchasedCommand = 'read';
  outsideClick = false;

  chargebeeUrl = '';

  destroy$: Subject<boolean> = new Subject<boolean>();

  scriptsLoaded = false;

  subscribeForm: FormGroup;

  openBillingType = 'home';
  purchaseType = 'article';

  subscribedList = [];

  viewerUrl = '';

  rssArticles = [];
  rssArticle: any;

  userForm: FormGroup;
  clientForm: FormGroup;

  articleSearchInput: FormControl;
  searchKeyword = '';

  myVoiceEnabled = false;

  showOfficeForm = false;
  showUserForm = false;
  showGroupForm = false;

  rssForm: FormGroup;
  rssFeeds = [];

  rssFeed: any;
  editMode = false;

  raywhiteGroupIds = [5, 11, 31, 32];

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private authService: AuthService,
    private particaService: ParticaService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private clientService: ClientService
  ) {
    this.viewerUrl = this.appConfig.PARTICA_VIEWER_URL;
    this.articleSearchInput = new FormControl('');
  }

  @Input() page: string;
  ngOnInit() {
    this.authUser = this.authService.auth;

    this.particaService.httpGetFeeds();

    this.articleSearchInput.setValue('', {
      emitEvent: false
    });

    this.particaService.onGetRss
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          this.rssArticles = response.data;

          this.changeDetector.detectChanges();
        }
      }
    );

    this.userService.userOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && typeof response.ID !== 'undefined') {
          this.loading = false;

          if (response.rss_feed !== null && response.rss_feed !== '') {
            this.myVoiceEnabled = true;
          } else {
            this.myVoiceEnabled = false;
          }

          swal('RSS Feed URL Updated', '', 'success');
          $('#updateRssFeed').modal('hide');
          this.changeDetector.detectChanges();
        }
      }
    );

    this.clientService.clientOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && typeof response.ID !== 'undefined') {
          this.particaService.httpGetRss(false);

          swal('Office RSS Feed URL Updated', '', 'success');
          $('#updateRssFeed').modal('hide');
          this.changeDetector.detectChanges();
        }
      }
    );

    this.particaService.onAddFeed
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.particaService.httpGetFeeds();

          swal('New feed created', '', 'success');
          $('#updateRssFeed').modal('hide');
          this.rssForm.reset();
          this.changeDetector.detectChanges();
        }
      }
    );

    this.particaService.onGetFeeds
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          this.rssFeeds = response.data;

          if (this.editMode && !this.raywhiteGroupIds.includes(this.authUser.client.group_id)) {
            this.onChangeTab('realestatevoice');
            this.editMode = false;
          }

          if (this.raywhiteGroupIds.includes(this.authUser.client.group_id) && this.rssFeeds.length > 0) {
            setTimeout(() => {
              this.triggerClick(`rssFeed-${this.rssFeeds[0].ID}`);
              this.loadFeed(this.rssFeeds[0]);
            }, 1000);
          }

          this.changeDetector.detectChanges();
        }
      }
    );

    this.particaService.onUpdateFeed
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.particaService.httpGetFeeds();

          swal('Feed updated', '', 'success');
          $('#updateRssFeed').modal('hide');
          this.rssForm.reset();
          this.changeDetector.detectChanges();
        }
      }
    );

    this.particaService.onDeleteFeed
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.particaService.httpGetFeeds();

          swal(
            'Deleted!',
            'The feed has been deleted.',
            'success'
          );
          $('#updateRssFeed').modal('hide');
          this.rssForm.reset();
          this.changeDetector.detectChanges();
        }
      }
    );

    this.articleSearchInput.valueChanges
    .takeUntil(this.destroy$)
    .debounceTime(500)
    .subscribe(searchText => {
      if (typeof searchText !== 'undefined') {
        this.searchKeyword = searchText;

        this.getArticles();
      }
    });

    this.initForms();
  }

  initForms() {
    this.subscribeForm = new FormGroup({
      'selection': new FormControl('', Validators.required)
    });

    this.userForm = new FormGroup({
      'rss_feed': new FormControl(this.authUser.user.rss_feed)
    });

    this.clientForm = new FormGroup({
      'rss_feed': new FormControl(this.authUser.client.rss_feed)
    });

    this.rssForm = new FormGroup({
      'title': new FormControl('', Validators.required),
      'url': new FormControl('', Validators.required)
    });
  }

  getArticles() {
    let articleQuery = <any>{
      keyword: '',
    };

    if (this.searchKeyword !== '') {
      articleQuery.keyword = this.searchKeyword;
    }

    this.particaService.httpGetRss(
      false,
      articleQuery
    );
  }

  readArticle(article) {
    this.rssArticle = article;
    this.outsideClick = true;

    setTimeout(() => {
      $('.read-article-rss').hide();
      $('.read-article-rss').removeClass('hidden').toggle('slide', {
        direction: 'right'
      });

      this.loading = false;
      this.changeDetector.detectChanges();
    }, 1000);

    this.loading = true;
    this.changeDetector.detectChanges();
  }

  closeArticle() {
    $('.read-article').addClass('hidden');
    $('.read-article-rss').addClass('hidden');
  }

  closeShoppingCart() {
    $('.shopping-cart').addClass('hidden');
  }

  closeContentStream() {
    $('.content-subscribe').addClass('hidden');
  }

  htmlToPlaintext(html) {
    return html;
    return html ? String(html).replace(/<(?!\s*\/?\s*x\b)[^>]*>/gi, '') : '';
  }

  onChangeTab(val) {
    this.rssFeed = null;
    this.currentTab = val;

    if (this.currentTab === 'realestatevoice') {
      this.rssArticles = [];
      this.particaService.httpGetRss(false);
    }

    if (this.currentTab === 'myvoice') {
      this.rssArticles = [];
      this.particaService.httpGetRss(true);
    }

    this.loading = true;
    this.changeDetector.detectChanges();
  }

  mainContentClick() {
    if ( this.outsideClick ) {
      this.outsideClick = false;
      $('.side-container').each( (i, element) => {
        if ( !$(element).hasClass('hidden') ) {
          $(element).addClass('hidden');
        }
      });
    }
  }

  closeBuilderModal() {
    this.particaService.onBuilderModalClose.next(true);
  }

  insertArticle(article, image, provider = 'partica') {
    if (provider === 'partica') {
      $('#selectPictureModal').modal('hide');
      article.image_src = image;
    }

    if (provider === 'rss') {
      article.text = article.content;
    }

    article.provider = provider;
    this.particaService.onBuilderInsertArticle.next(article);
  }

  startTour() {
    const intro = introJs();

    intro.setOption('disableInteraction', true);
    intro.setOption('exitOnOverlayClick', false);
    intro.setOption('hidePrev', true);
    intro.setOption('hideNext', true);
    intro.setOption('showStepNumbers', false);
    intro.setOption('overlayOpacity', '0.5');

    if ( this.page === 'builder' ) {
      intro.setOption('tooltipClass', 'partica-tour-tools');
      intro.setOption('highlightClass', 'partica-builder');
    }

    intro.addSteps([
      {
        element: document.querySelector('.intro-1'),
        intro: 'Here you will find all articles that are available for purchase, scroll down to load more'
      },
      {
        element: document.querySelector('.intro-2'),
        intro: 'Articles can be filtered by how many words an article contains, common categories, price and common keywords'
      },
      {
        element: document.querySelector('.intro-3'),
        intro: 'You can search articles by keywords using the search filter'
      },
      {
        element: document.querySelector('.intro-4'),
        intro: 'Each article you can see the Article title as well as the article image'
      },
      {
        element: document.querySelector('.intro-5'),
        intro: 'The Article author is shown here'
      },
      {
        element: document.querySelector('.intro-6'),
        intro: 'How many words the article contains'
      },
      {
        element: document.querySelector('.intro-7'),
        intro: 'Licence type'
      },
      {
        element: document.querySelector('.intro-8'),
        intro: 'Price of article'
      },
      {
        element: document.querySelector('.intro-9'),
        intro: 'Read the full article in a slide in window'
      },
      {
        element: document.querySelector('.intro-10'),
        intro: 'Add article to your shopping cart'
      },
      {
        element: document.querySelector('.intro-11'),
        intro: 'Tab to view all you puchased articles'
      }
      // {
      //   element: document.querySelector('.intro-12'),
      //   intro: 'Here will display articles from subcriptions'
      // }
    ]);

    intro.start();
  }

  particaHelp() {
    window.open('https://www.therealestatevoice.com.au/articles-for-real-estate/', '_blank');
  }

  triggerClick(tab) {
    $(`#${tab}`).trigger('click');

    if (tab === 'realEstateVoiceLibrary') {
      this.particaService.httpGetRss(false);
    }

    this.loading = true;
    this.changeDetector.detectChanges();
  }

  isSubscribed(contentId: number) {
    return this.subscribedList.includes(contentId);
  }

  formatDecimalPrice(price: number) {
    return price / Math.pow(10, 2);
  }

  cleanTitle(content: String) {
    return content.replace(/<[^>]*>/g, '');
  }

  resizeImage(url: String, size = 600) {
    if ( url === null ) {
      return false;
    }

    const imageExtension = url.split('.').reverse()[0];
    const imageUrlWithoutExtension = url.split(`.${imageExtension}`);

    url = `${imageUrlWithoutExtension[0]}.${size}.${imageExtension}`;

    return url;
  }

  cutText(description: string) {
    return description.substr(0, 250) + '...';
  }

  onSaveRssFeed() {
    const userFormValues = this.userForm.value;

    if (this.userForm.valid) {
      this.userService.httpPutUser(this.authUser.user.ID, userFormValues);
      this.loading = true;
    }
  }

  onClientSaveRssFeed() {
    const clientFormValues = this.clientForm.value;

    if (this.clientForm.valid) {
      this.clientService.httpPutClient(this.authUser.client.ID, clientFormValues);
      this.loading = true;
    }
  }

  ngAfterViewInit() {
    if (!this.raywhiteGroupIds.includes(this.authUser.client.group_id)) {
      this.triggerClick('realEstateVoiceLibrary');
    }
  }

  onBackRefresh() {
    window.location.href = '/templates';
  }

  onOpenRssForm(isNew = true) {
    this.showOfficeForm = false;
    this.showUserForm = false;
    this.showGroupForm = false;

    this.editMode = false;
    this.rssForm.reset();

    if (!isNew && typeof this.rssFeed !== 'undefined' && this.rssFeed) {
      if (typeof this.rssFeed.client_id !== 'undefined' && this.rssFeed.client_id) {
        this.showOfficeForm = true;
      }

      if (typeof this.rssFeed.user_id !== 'undefined' && this.rssFeed.user_id) {
        this.showUserForm = true;
      }

      if (typeof this.rssFeed.group_id !== 'undefined' && this.rssFeed.group_id) {
        this.showGroupForm = true;
      }

      this.editMode = true;
      this.rssForm.patchValue({
        title: this.rssFeed.title,
        url: this.rssFeed.url
      });
    }

    $('#updateRssFeed').modal('show');
  }

  onShowRssForm(type: string) {
    if (type === 'office') {
      this.showOfficeForm = true;
      this.showUserForm = false;
    }

    if (type === 'user') {
      this.showUserForm = true;
      this.showOfficeForm = false;
    }

    if (type === 'group') {
      this.showGroupForm = true;
      this.showUserForm = false;
      this.showOfficeForm = false;
    }
  }

  onSaveRss() {
    const rssFormValues = this.rssForm.value;

    if (this.showOfficeForm) {
      rssFormValues['type'] = 'office';
    }

    if (this.showUserForm) {
      rssFormValues['type'] = 'user';
    }

    if (this.showGroupForm) {
      rssFormValues['type'] = 'group';
    }

    if (this.rssForm.valid) {
      if (this.editMode) {
        this.particaService.httpUpdateFeed(this.rssFeed.ID, rssFormValues);
      } else {
        this.particaService.httpAddFeed(rssFormValues);
      }

      this.loading = true;
    }
  }

  loadFeed(rssFeed: any) {
    this.rssArticles = [];
    this.particaService.httpGetRss(false, {
      feedId: rssFeed.ID
    });

    this.rssFeed = rssFeed;
    this.loading = true;
  }

  deleteFeed(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.particaService.httpDeleteFeed(ID);
        $this.loading = true;
      }
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
