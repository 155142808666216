import { Component, OnInit, OnDestroy } from '@angular/core';
import { Auth } from '../../models/auth.model';
import { FrontService } from '../front.service';
import { ClientService } from '../../services/client.service';
import { Subject } from 'rxjs';
import { Client } from '../../models/client.model';
import { UserService } from '../../services/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { MyDesktopService } from '../../services/mydesktop.service';
import { DomainService } from '../../services/domain.service';
import { VaultService } from '../../services/vault.service';
import { Router } from '@angular/router';

declare const swal: any;
declare const $: any;

@Component({
  selector: 'app-front-corporate',
  templateUrl: './front-corporate.component.html',
  styleUrls: ['./front-corporate.component.scss']
})
export class FrontCorporateComponent implements OnInit, OnDestroy {
  destroy: Subject<boolean> = new Subject();
  loading = false;
  authUser: Auth;

  clients: Client[];
  dataTableHtml: any;

  tmpClientId: number;
  tmpClientName: string;

  loginAsForm: FormGroup;
  constructor(
    private frontService: FrontService,
    private userService: UserService,
    private globalService: GlobalService,
    private mydesktopService: MyDesktopService,
    private domainService: DomainService,
    private vaultService: VaultService,
    private router: Router
  ) {
    const $this = this;
    window['loginAs'] = function(id, client) {
      $this.onLoginAs(id, client);
    };
  }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.loading = true;

    if (!this.authUser.is_master) {
      return this.router.navigateByUrl('/templates');
    }

    this.initDT();

    this.userService
    .usersChanged
    .takeUntil(this.destroy)
    .subscribe(
      (users: any) => {
        if ( typeof users !== 'undefined' && users.status === 'success' ) {
          this.loading = false;
          const $this = this;

          if ( users.count > 0 ) {
            const userOptions = [];

            for (const user of users.data) {
              if ( user.firstname !== '' ) {
                userOptions[ user.agent_id ] = this.globalService.cleanAgentName(user.firstname + ' ' + user.lastname);
              }
            }

            swal({
              title: 'Are you sure you want to login as \n' + $this.tmpClientName,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              input: 'select',
              inputOptions: userOptions,
            }).then(function () {
              const agent_id = jQuery('.swal2-container.swal2-shown').find('select.swal2-select').val();

              swal({
                title: 'Login as ' + $this.tmpClientName,
                onOpen: function () {
                  swal.showLoading ();
                }
              });
              const loginAsForm: FormGroup = new FormGroup({
                'client_id': new FormControl($this.tmpClientId, Validators.required),
                'agent_id': new FormControl(agent_id, Validators.required)
              });

              const token = $this.authUser.token;
              const loginAsValues = loginAsForm.value;
              loginAsValues['corporate'] = true;

              if ( $this.frontService.authService.getApp() === 'MyDesign3' ) {
                $this.mydesktopService.httpPostLoginAs(loginAsValues, token);
              } else if ($this.frontService.authService.auth.provider === 'domain') {
                $this.domainService.httpLoginAs(loginAsValues);
              } else {
                $this.vaultService.httpLoginAs(loginAsValues);
              }
            });
          } else {
            swal(
              'Error',
              'No Users found',
              'error'
            );
          }
        }
      }
    );

    this.mydesktopService
    .myDesktopLoginAs
    .takeUntil(this.destroy)
    .subscribe(
      (authLoginAs: any) => {
        if (typeof authLoginAs !== 'undefined' && authLoginAs
          && authLoginAs.status === 'success'
          && typeof authLoginAs.data.token !== 'undefined' ) {
            this.frontService.authService.setAuthStorage(authLoginAs.data);

          location.href = '/templates';
        }
      }
    );

    this.vaultService
    .onLoginAs
    .takeUntil(this.destroy)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.frontService.authService.setAuthStorage(response.data, false, true);
          location.href = '/templates';
        }
      }
    );

    this.domainService
    .onLoginAs
    .takeUntil(this.destroy)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.frontService.authService.setAuthStorage(response.data, false, true);
          location.href = '/templates';
        }
      }
    );
  }

  initDT() {
    const queryParams = `?provider=${this.authUser.provider}&group_id=${this.authUser.client.group_id}&is_account_active=1`;

    const $this = this;
    setTimeout(() => {
      this.dataTableHtml = $('#datatable-clients').DataTable({
        'pagingType': 'full_numbers',
        'processing': true,
        'serverSide': true,
        'ajax': {
          // tslint:disable-next-line:max-line-length
          'url': $this.frontService.appConfig.API_ENDPOINT + '/clients/datatable' + queryParams,
          'type': 'GET',
          'beforeSend': function (request) {
            request.setRequestHeader('Authorization', 'Bearer ' + $this.frontService.authService.auth.__token);
            request.setRequestHeader('MD-Authorization', $this.frontService.authService.auth.token);
          }
        },
        'pageLength': 50,
        'columns' : [
          { 'data': 'ID' },
          { 'data': function(row) {
            return row['name'];
          } },
          {
            className: 'text-right',
            data: function (row) {
                let html = '';

                let officeName = row['name'];
                officeName = officeName.replace(/'/g, '_');

                // tslint:disable-next-line:max-line-length
                html += '<button type="button" onclick="loginAs(' + row['ID'] + ', \'' + officeName + '\')" class="btn btn-default btn-sm" title="Login As">Login As</button>';

                return html;
            }
          }
        ],
        'columnDefs': [ {
          'targets': [ 0 ],
          'visible': false,
          'searchable': false
        }, {
          'targets': [ 1 ],
          'name': 'name',
          'searchable': true
        }, {
          'targets': [ 2 ],
          'searchable': false
        }],
        'order': [],
        language: {
          'paginate': {
            'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
          }
        },
      });

      this.loading = false;
    }, 500);
  }

  onLoginAs(ID: number, client: string) {
    this.tmpClientId = ID;
    this.tmpClientName = client;

    this.loading = true;
    this.userService.httpGetUsers({
      'client_id': ID
    });
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
