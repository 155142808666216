import {HttpParams,  HttpClient,   HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';

@Injectable()
export class OptionService {
  maintenanceChanged = new Subject<any>();
  optionOperation = new Subject<any>();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  httpPutMaintenance(data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/options/status',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.optionOperation.next(response);
      }
    );
  }

  httpGetMaintenance() {
    const req = new HttpRequest(
        'GET',
        `${this.appConfig.API_ENDPOINT}/options/maintenance?app=${this.authService.getApp()}`,
    );

    return this.httpClient.request(
        req
    )
    .map(
        (response: any) => {
        if (response.body) {
            if (response.body.status === 'success') {
            return response.body.data;
            }
        }

        return [];
        }
    )
    .subscribe(
        (response: any) => {
        this.maintenanceChanged.next(response);
        }
    );
    }

}
