import { HttpParams, HttpClient, HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Support } from '../models/support.model';
import { CustomRequest } from '../models/customrequest.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';
import { Auth } from '../models/auth.model';

@Injectable()
export class SupportService {
  supportsChanged = new Subject<Support[]>();
  supportChanged = new Subject<Support>();
  supportOperation = new Subject<Support>();
  supportHtml = new Subject<Support>();

  onScreenshot: Subject<boolean> = new Subject();
  onCapture: Subject<boolean> = new Subject();
  onProcessed: Subject<boolean> = new Subject();

  onActivepipe = new Subject();

  private supports: Support[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig,
              private httpClient: HttpClient,
              private authService: AuthService) {}

  setSupports(supports: Support[]) {
    this.supports = supports;
    this.supportsChanged.next(this.supports.slice());
  }

  getSupports() {
    return this.supports.slice();
  }

  getIdIndex(id: number) {
    return this.supports.findIndex(e => e.ID === id);
  }

  httpGetSupports(params: any = [], columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/supports',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Support[]) => {
        this.setSupports(response);
      }
    );
  }

  httpGetSupport(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/supports/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Support) => {
        this.supportChanged.next(response);
      }
    );
  }

  httpPostSupport(data: Support, screenshots = []) {

    const formData = new FormData();

    for ( const key in data ) {
      if ( key === 'fileAttached' ) {
        // const thumbnail = data[key];
        // formData.append('file', thumbnail, thumbnail.name);
      } else {
        formData.append(key, data[key]);
      }
    }

    if ( screenshots.length > 0 ) {
      for ( const screenshot of screenshots ) {
        formData.append('file' + screenshot.lastModified, screenshot, screenshot.name);
      }
    }

    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/supports',
      formData
    );
    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            const responseData = response.body.data;

            return responseData;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.supportOperation.next(response);
      }
    );
  }

  httpPutSupport(id: number, data: Support) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/supports/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            if ( typeof data['fileAttached'] !== 'undefined' ) {

              const icon = data['iconFile'];
              const thumbnail = data['fileAttached'];
              const formData = new FormData();

              if ( typeof icon !== 'undefined' ) {
                formData.append('icon', icon, icon.name);
              }

              if ( typeof thumbnail !== 'undefined' ) {
                formData.append('file', thumbnail, thumbnail.name);
              }

              const uploadSupportReq = new HttpRequest(
                  'POST',
                  this.appConfig.API_ENDPOINT + '/supports/upload/' + id,
                  formData
              );

              this.httpClient.request<any>(uploadSupportReq).toPromise();
            }

            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.supportOperation.next(response);
      }
    );
  }

  httpDeleteSupport(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/supports/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetSupports();
      }
    );
  }

  httpPostActivePipe(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/supports/activepipe',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onActivepipe.next(response);
      },
      (response: any) => {
        this.onActivepipe.next(response.error);
      }
    );
  }

  httpPostCustomRequest(data: CustomRequest, screenshots = []) {
    const formData = new FormData();

    for ( const key in data ) {
      if ( key === 'fileAttached' ) {
        // const thumbnail = data[key];
        // formData.append('file', thumbnail, thumbnail.name);
      } else {
        formData.append(key, data[key]);
      }
    }

    if ( screenshots.length > 0 ) {
      for ( const screenshot of screenshots ) {
        formData.append('file' + screenshot.lastModified, screenshot, screenshot.name);
      }
    }

    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/supports/customrequest',
      formData
    );
    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            const responseData = response.body.data;
            
            return responseData;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.supportOperation.next(response);
      }
    );
  }
}
