import { Component, OnInit, OnDestroy } from '@angular/core';
import { Template } from '../../../models/template.model';
import { Subscription } from 'rxjs/Subscription';
import { TemplateService } from '../../../services/template.service';
import { FrontService } from '../../front.service';

declare var jQuery: any;

@Component({
  selector: 'app-analytics-template-report',
  templateUrl: './analytics-template-report.component.html',
  styleUrls: ['./analytics-template-report.component.scss']
})
export class AnalyticsTemplateReportComponent implements OnInit, OnDestroy {
  loading = false;
  analytics: Template[] = [];
  subscriptionLatest: Subscription;
  subscriptionDelete: Subscription;

  constructor(private frontService: FrontService, private templateService: TemplateService) { }

  ngOnInit() {
    
    this.templateService.httpGetLatestTemplate(
      {
        client_id: this.frontService.authService.auth.client.ID
      }
    );

    this.subscriptionLatest = this.templateService.templatesLatestChanged.subscribe(
      (analytics: Template[]) => {
        this.analytics = analytics;

      }
    )

    

  }
  ngOnDestroy() {
    this.subscriptionLatest.unsubscribe();
  }

  exportClick(){
   jQuery('table').tableToCSV();
  }

}
