import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingService } from '../../../services/setting.service';
import { AdminService } from '../../admin.service';
import { Setting } from '../../../models/setting.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-vault-white-listing',
  templateUrl: './vault-white-listing.component.html',
  styleUrls: ['./vault-white-listing.component.scss']
})
export class VaultWhiteListingComponent implements OnInit, OnDestroy {
  loading = false;
  setting: Setting;
  settings: Setting[] = [];

  settingVaultFranchiseIDForm: FormGroup;
  settingVaultAccountIDForm: FormGroup;

  subscription: Subscription;
  subscriptionResponse: Subscription;

  @ViewChild('settingWhiteListOfficeIDModalClose', { static: true }) settingWhiteListOfficeIDModalClose: ElementRef;
  @ViewChild('settingBlackListBrandIDModalClose', { static: true }) settingBlackListBrandIDModalClose: ElementRef;

  constructor(private adminService: AdminService,
    private settingService: SettingService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.subscription = this.settingService.settingChanged.subscribe(
      (setting: Setting) => {
        if (typeof setting !== 'undefined') {
          this.loading = false;
          this.setting = setting;

          this.editForm();
        }
      }
    );

    this.subscriptionResponse = this.settingService.settingOperation.subscribe(
      (setting: Setting) => {
        if ( typeof setting.ID !== 'undefined' ) {
          this.loading = true;
          this.settingService.httpGetSetting(1);
        }
      }
    );

    this.initForm();

    this.settingService.httpGetSetting(1);
  }

  private initForm() {
    this.settingVaultFranchiseIDForm = new FormGroup({
      'vault_allowed_franchise_id': new FormControl('')
    });

    this.settingVaultAccountIDForm = new FormGroup({
      'vault_allowed_account_id': new FormControl('')
    });
  }

  private editForm() {
    this.settingVaultFranchiseIDForm.patchValue({
      'vault_allowed_franchise_id': this.setting.vault_allowed_franchise_id
    });

    this.settingVaultAccountIDForm.patchValue({
      'vault_allowed_account_id': this.setting.vault_allowed_account_id
    });
  }

  onSubmit(type) {
    if (this.settingVaultFranchiseIDForm.valid || this.settingVaultAccountIDForm.valid) {
      this.loading = true;

      let settingValues: Setting;
      if (type === 'vault_allowed_franchise_id') {
        settingValues = this.settingVaultFranchiseIDForm.value;
      } else {
        settingValues = this.settingVaultAccountIDForm.value;
      }

      this.settingService.httpPutSetting(1, settingValues);

      setTimeout(
        () => {
          this.settingWhiteListOfficeIDModalClose.nativeElement.click();
          this.settingBlackListBrandIDModalClose.nativeElement.click();
        }, 200
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }

}
