<div class="page-header">
  <h1 class="page-title">Frequently Asked Questions</h1>
</div>
<div class="page-content container-fluid">
  <div class="row">
    <div class="col-xl-3 col-md-4">
      <div class="panel">
        <div class="panel-body">
          <div class="list-group faq-list" role="tablist">
            <a *ngFor="let faqsCategory of faqsCategories; let i = index" [class.active]="!i" class="list-group-item"
              data-bs-toggle="tab" href="#category-{{faqsCategory?.UID}}" aria-controls="category-2"
              role="tab">{{faqsCategory?.title}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-9 col-md-8">
      <div class="panel">
        <div class="panel-body">
          <div class="tab-content">
            <div *ngFor="let faqsCategory of faqsCategories; let i = index" [class.active]="!i"
              class=" tab-pane animation-fade" id="category-{{faqsCategory?.UID}}" role="tabpanel">
              <div class="panel-group panel-group-simple panel-group-continuous" [id]="'accordion-' + faqsCategory?.UID"
                aria-multiselectable="true" role="tablist">
                <div *ngFor="let faq of faqsCategory?.faqs; let i = index" class="panel">
                  <div class="panel-heading" [id]="'question-' + faq?.UID" role="tab">
                    <a class="panel-title" [attr.aria-controls]="'answer-' + faq?.UID" aria-expanded="true"
                      data-bs-toggle="collapse" [href]="'#answer-' + faq?.UID" [attr.data-parent]="'#accordion-' + faqsCategory?.UID">
                      {{faq?.title}}
                    </a>
                  </div>
                  <div class="panel-collapse collapse" [class.show]="!i" [id]="'answer-' + faq?.UID"
                    [attr.aria-labelledby]="'question-' + faq?.UID" role="tabpanel">
                    <div class="panel-body" [innerHtml]="faq?.description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>