import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { MasterService } from '../../../services/master.service';
import { Master } from '../../../models/master.model';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemplateService } from '../../../services/template.service';
import { Template } from '../../../models/template.model';
import { Auth } from '../../../models/auth.model';

import * as moment from 'moment';
declare const $: any;
declare const swal: any;
declare const jQuery: any;
declare const _bugHerd: any;
@Component({
  selector: 'app-template-front-archive',
  templateUrl: './template-front-archive.component.html',
  styleUrls: ['./template-front-archive.component.scss']
})
export class TemplateFrontArchiveComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];

  master: Master;
  masters: Master[] = [];
  master_id: number;

  template: Template;
  templates: Template[];
  template_count = 0;
  templateFilter;
  dateFilter;
  term;
  templatesSubscription: Subscription;
  subscriptionResponse: Subscription;
  subscriptionTemplateDeletion: Subscription;

  templateAddForm: FormGroup;
  templateUpdateForm: FormGroup;

  pane_open = false;

  public edit_title_id: number;
  public edit_title_name: string;

  TEMPLATE_THUMB_S3_URL = '';

  @ViewChild('categoryContent', { static: false }) categoryContent: ElementRef;
  @ViewChild('editTitle', { static: false }) editTitle: ElementRef;
  @ViewChild('editTitleModalClose', { static: false }) editTitleModalClose: ElementRef;
  @ViewChild('previewModal', { static: true }) previewModal: ElementRef;

  authUser: Auth;
  user = {
    ID: 1,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  categoryList = [
    {
      id: 0,
      slug: 'new',
      name: 'New Templates',
      icon: 'assets/img/new-templates-icon.svg'
    },
    {
      id: 0,
      slug: 'popular',
      name: 'Popular Templates',
      icon: 'assets/img/popular-templates-icon.svg'
    },
    {
      id: 1,
      slug: 'enewsletter',
      name: 'Email',
      icon: 'assets/img/temp1.svg'
    },
    {
      id: 2,
      slug: 'buyer-match',
      name: 'Buyer Match',
      icon: 'assets/img/temp6.svg'
    },
    {
      id: 3,
      slug: 'quick-enquiry',
      name: 'Quick Enquiry',
      icon: 'assets/img/quick-enquiry.svg'
    },
    {
      id: 4,
      slug: 'brochure',
      name: 'Brochure',
      icon: 'assets/img/temp2.svg'
    },
    {
      id: 5,
      slug: 'property-guide',
      name: 'Property Guide',
      icon: 'assets/img/temp7.svg'
    },
    {
      id: 6,
      slug: 'social-media',
      name: 'Social Media',
      icon: 'assets/img/social-media.svg'
    }
  ];

  supportLink = '';
  shareListUsers = [];

  isList = false;

  isFetchedAlready = false;

  constructor(private frontService: FrontService,
              private templateService: TemplateService) { }

  ngOnInit() {
    this.supportLink = this.frontService.supportLink;
    this.authUser = this.frontService.authService.auth;
    this.shareListUsers = this.authUser.client.users;
    this.user = {
      ID: this.authUser.user.agent_id,
      name: this.authUser.user.firstname + ' ' + this.authUser.user.firstname,
      photo: this.authUser.user.photo,
      email: this.authUser.user.email,
      mobile: this.authUser.user.mobile,
      telephone: this.authUser.user.telephone,
      fax: this.authUser.user.fax
    };

    if ( this.frontService.authService.getApp() === 'Designly' ) {
      this.isList = true;
    }

    this.loading = true;
    this.frontService.loadMenu(this.menus);

    const templateQuery = {
      user_agent_id: `${this.authUser.user.ID},${this.authUser.user.agent_id}`,
      client_id: this.authUser.client.ID,
      group_id: this.authUser.client.group_id,
      is_active: '0'
    };

    if ( this.authUser.is_admin ) {
      delete templateQuery.user_agent_id;
    }

    this.templateService.httpGetTemplates(
      templateQuery,
      '',
      ''
    );

    this.subscriptionTemplateDeletion = this.templateService.templateDeletion.subscribe(
      (templates: Template) => {
        if ( typeof templates.ID !== 'undefined' ) {

          this.templateService.httpGetTemplates(
            templateQuery,
            '',
            ''
          );
        }
      }
    );

    this.templatesSubscription = this.templateService.templateGetAll.subscribe(
      (templates: any) => {
        if ( typeof templates !== 'undefined' && templates.status === 'success' ) {
          this.loading = false;
          this.templates = templates.data;

          this.template_count = templates.data.length;
          this.templateFilter = 'all';
          this.dateFilter = 'recent';
        }
      }
    );

    this.subscriptionResponse = this.templateService.templateOperation.subscribe(
      (template: Template) => {
        if ( typeof template.ID !== 'undefined' ) {
          this.loading = true;

          this.templateService.httpGetTemplates(
            templateQuery,
            '',
            ''
          );
        }
      }
    );

    this.TEMPLATE_THUMB_S3_URL = this.frontService.appConfig.S3_URL + 'templates/thumbnails/';

    this.initForm();
  }

  private initForm() {
    this.templateAddForm = new FormGroup({
      'title': new FormControl('', Validators.required)
    });

    this.templateUpdateForm = new FormGroup({
      'ID': new FormControl('', Validators.required),
      'title': new FormControl('', Validators.required)
    });
    
  }

  showAll() {
    let panes = this.categoryContent.nativeElement.querySelectorAll('.tab-pane');

    for ( let pane of panes ) {
      pane.className = 'tab-pane active';
    }

    this.pane_open = true;
  }

  hideAll() {
    let panes = this.categoryContent.nativeElement.querySelectorAll('.tab-pane');

    for ( let pane of panes ) {
      pane.className = 'tab-pane';
    }

    this.pane_open = false;
  }

  onModal(item) {
    this.master = item;
    this.editTitle.nativeElement.querySelector('#editTitleLabel').innerHTML = 'Add ' + item.title;
  }

  onSubmitUpdate() {
    if (this.templateUpdateForm.valid) {
      this.loading = true;

      let templateAddValues = this.templateUpdateForm.value;

      this.templateService.httpPutTemplate(templateAddValues.ID, templateAddValues);

      setTimeout(
        () => {
          this.templateUpdateForm.reset();
          this.editTitleModalClose.nativeElement.click();
        }, 500
      );

    }
  }

  onDuplicate(item) {
    this.loading = true;

    delete item.ID;
    delete item.category;
    delete item.created;
    delete item.department;
    delete item.master;
    delete item.media_attribute;
    delete item.modified;

    item.is_shared = 0;
    const templateValues = item;
    this.templateService.httpPostTemplate(templateValues);
  }

  onShare(item: Template) {
    this.loading = true;

    let templateValues: Object = {};
    templateValues['is_shared'] = !item.is_shared;
    this.templateService.httpPutTemplate(item.ID, <Template>templateValues);
  }

  onArchive(id: number) {
    this.loading = true;

    let templateValues: Object = {};
    templateValues['is_active'] = 0;
    this.templateService.httpPutTemplate(id, <Template>templateValues);
  }

  onActivate(id: number) {
    this.loading = true;

    let templateValues: Object = {};
    templateValues['is_active'] = 1;
    this.templateService.httpPutTemplate(id, <Template>templateValues);
  }

  onDelete(id: number) {
    let $this = this;
    swal({
      title: 'Are you sure you want to delete this template?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(function () {
      swal(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      );

      $this.loading = true;
      $this.templateService.httpDeleteTemplate(id);
    });

  }

  onPreview(template: any) {
    let img_src = '';
    if ( template.thumbnail === '' ) {
      img_src = template.ID + '.jpg';
    } else {
      img_src = template.thumbnail;
    }

    this.previewModal.nativeElement.querySelector('.modal-body img').src
      = this.frontService.appConfig.S3_URL + 'templates/thumbnails/' + img_src;

    $('#previewTemplate').modal('show');
  }

  onEditTitle($id: number, $name: string) {
    this.edit_title_id = $id;
    this.edit_title_name = $name;
  }

  getAgentData(id, type) {
    const agentID = this.shareListUsers.findIndex(a => a.id === id);
    if ( typeof this.shareListUsers[ agentID ] !== 'undefined') {
      return this.shareListUsers[ agentID ][ type ];
    }

    return ' ';
  }

  convertDate(date) {
    return moment(date).format('DD/MM/YY');
  }

  onFeedback(){
    swal({
    title: 'We would love to hear from you about the new MyDesign',
    imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
    imageWidth: 300,
    imageHeight: 100,
    showConfirmButton: false,
    html: '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
    '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' + this.supportLink+ '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>'
    } );

    jQuery('.btn-bh-feedback').click(function() {
      swal.close();
      _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
    });
  }

  onSearchKeyUp() {
    if ( !this.isFetchedAlready ) {
      this.isFetchedAlready = true;

      setTimeout(() => {
        this.isFetchedAlready = false;

        const templateQuery = <any> {
          user_agent_id: `${this.authUser.user.ID},${this.authUser.user.agent_id}`,
          client_id: this.authUser.client.ID,
          group_id: this.authUser.client.group_id,
          is_active: '0',
          keyword: this.term
        };

        if ( this.authUser.is_admin || this.authUser.client.group_id === 12 ) {
          delete templateQuery.user_agent_id;
        }

        this.templateService.httpGetTemplates(
          templateQuery,
          'dashboard'
        );
      }, 2000);
    }
  }

  ngOnDestroy() {
    this.frontService.authService.cancelRequest();
    this.templatesSubscription.unsubscribe();
    this.subscriptionResponse.unsubscribe();
    this.subscriptionTemplateDeletion.unsubscribe();
  }

}
