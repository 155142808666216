import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FolderService } from '../../../services/folder.service';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-template-front-folders',
  templateUrl: './template-front-folders.component.html',
  styleUrls: ['./template-front-folders.component.scss']
})
export class TemplateFrontFoldersComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  loading = false;

  folders = [];

  folderForm: FormGroup;
  isFolderEditMode = false;
  selectedFolder: any;
  isShare = false;

  constructor(
    private folderService: FolderService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.folderService.httpGetAll();
    localStorage.removeItem('customFolders');
    localStorage.setItem('isTemplatePage', '0');
    this.folderService.onFolderGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined') {
          this.loading = false;
          if (response.status === 'success') {
            this.folders = response.data;
          }
          if (response.status === 'error') {
            Swal('There is a problem getting your Folders', 'Contact Designly', 'error');
          }
        }
      }
    );

    this.folderService.onFolderAdd
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined') {
          this.loading = false;
          if (response.status === 'success') {
            this.folderService.httpGetAll();

            Swal('Folder created successfully', '', 'success');
            $('#newFolder').modal('hide');
            this.folderForm.reset();
            this.loading = true;
          }
          if (response.status === 'error') {
            Swal('There is a problem creating your Folder', 'Contact Designly', 'error');
          }
        }
      }
    );

    this.folderService.onFolderUpdate
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined') {
          this.loading = false;
          if (response.status === 'success') {
            this.folderService.httpGetAll();

            if (this.isShare) {
              Swal('Folder shared across your office', '', 'success');
              this.isShare = false;
            } else {
              Swal('Folder updated successfully', '', 'success');
              $('#newFolder').modal('hide');
              this.folderForm.reset();
            }
            this.loading = true;
          }
          if (response.status === 'error') {
            Swal('There is a problem updating your Folder', 'Contact Designly', 'error');
          }
        }
      }
    );

    this.folderService.onFolderDelete
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined') {
          this.loading = false;
          if (response.status === 'success') {
            this.folderService.httpGetAll();

            Swal('Folder deleted successfully', '', 'success');
            this.loading = true;
          }
          if (response.status === 'error') {
            Swal('There is a problem deleting your Folder', 'Contact Designly', 'error');
          }
        }
      }
    );

    this.folderForm = new FormGroup({
      'name': new FormControl('', Validators.required)
    });
  }

  createFolder() {
    this.folderForm.reset();
    this.isFolderEditMode = false;
  }

  editFolder(folder: any) {
    this.selectedFolder = folder;
    this.isFolderEditMode = true;

    this.folderForm.patchValue({
      name: folder.name
    });
  }

  onCreateFolder() {
    const folderFormValues = this.folderForm.value;

    if (this.folderForm.valid) {
      if (this.isFolderEditMode) {
        this.folderService.httpUpdate(this.selectedFolder.UID, folderFormValues);
      } else {
        this.folderService.httpAdd(folderFormValues);
      }
    }

    this.loading = true;
  }

  deleteFolder(UID: string) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.folderService.httpDelete(UID);
        $this.loading = true;
      }
    }).catch(Swal.noop);
  }

  shareFolder(folder, isShared = 1) {
    this.folderService.httpUpdate(folder.UID, {
      is_shared: isShared
    });
    this.loading = true;
    this.isShare = isShared ? true : false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
