<div class="child-content-wrapper">
  <div class="heading text-left">
    <h2>Support Portal</h2>
    <!-- <div class="pull-right"><a class="btn btn-round btn-bordered btn-primary btn-3" (click)="startTour()" data-intro="Take a Tour - Help Portal. Provides a step-by-step guide to the Help Portal.">TAKE A TOUR <i class="icon-circle-question margin-left-10"></i></a></div> -->
  </div>
  <div class="page-content">
    <div class="panel-center link-panel">
      <div class="row text-center">
        <div class="col-md-3">
          <a routerLink="/help/training-videos">
            <div class="panel" data-intro="Training Videos - contains various useful videos that illustrate some of the  common processes in MyDesign." data-step="2">
              <div class="panel-body container-fluid">
                <span class="icon feather icon-play-circle"></span>
                <h2 class="margin-top-30 margin-bottom-20">Training Videos</h2>
                <p class="description">View our wide range of easy to follow training videos.</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-3">
          <a routerLink="/help/faqs">
            <div class="panel" data-intro="FAQ - contains some common questions and answers related to MyDesign." data-step="3">
              <div class="panel-body container-fluid">
                <span class="icon feather icon-help-circle"></span>
                <h2 class="margin-top-30 margin-bottom-20">FAQ</h2>
                <p class="description">Have questions? We have the answers right here for you.</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-3" *ngIf="frontService.appConfig.SKIN === 'md3'">
          <a href="https://www.designly.com.au/custom-templates-mydesign" target="_blank">
            <div class="panel">
              <div class="panel-body container-fluid">
                <span class="icon feather icon-file-text"></span>
                <h2 class="margin-top-30 margin-bottom-20">Custom Templates</h2>
                <p class="description">Looking for more personalised templates? Click here to find out.</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-3" *ngIf="frontService.appConfig.SKIN !== 'md3' && frontService.appConfig.SKIN !== 'professionals'">
          <a href="https://www.designly.com.au/custom-templates" target="_blank">
            <div class="panel">
              <div class="panel-body container-fluid">
                <span class="icon feather icon-file-text"></span>
                <h2 class="margin-top-30 margin-bottom-20">Custom Templates</h2>
                <p class="description">Looking for more personalised templates? Click here to find out.</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-3">
          <a routerLink="/help/support">
            <div class="panel" data-intro="Support Request - an online form to log a support request if you are unable to locate the information you require in the Help Portal, or are experiencing an issue." data-step="4">
              <div class="panel-body container-fluid">
                <span class="icon feather icon-message-square"></span>
                <h2 class="margin-top-30 margin-bottom-20">Support Request</h2>
                <p class="description">If you have a support question, please log your support request here.</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4"></div>

        <div class="col-md-4">
          <button (click)="gotoChangeLog()" class="btn btn-primary btn-1 margin-top-30">View Change Log</button>
        </div>

        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
