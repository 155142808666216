import { Component, OnInit, OnDestroy } from '@angular/core';
import { Style } from '../../../models/style.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { DepartmentService } from '../../../services/department.service';
import { GroupService } from '../../../services/group.service';
import { MediaAttributeService } from '../../../services/media-attribute.service';
import { StyleService } from '../../../services/style.service';

import { Group } from '../../../models/group.model';
import { MediaAttribute } from '../../../models/media-attribute.model';
import { Department } from '../../../models/department.model';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { Select2OptionData } from 'ng2-select2';
import { Client } from '../../../models/client.model';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'app-style-edit',
  templateUrl: './style-edit.component.html',
  styleUrls: ['./style-edit.component.scss']
})
export class StyleEditComponent implements OnInit, OnDestroy {
  loading = false;
  id: number;
  editMode = false;
  backUrl: string;
  category: string;
  category_id: number;
  duplicateMode = false;
  showCkeditorFont = false;

  subscription: Subscription;
  subscriptionGroup: Subscription;
  subscriptionMediaAttribute: Subscription;
  subscriptionDepartment: Subscription;
  subscriptionClient: Subscription;
  subscriptionResponse: Subscription;

  categories = [
    {
      id: 1,
      name: 'Email'
    },
    {
      id: 2,
      name: 'Buyer Match'
    },
    {
      id: 3,
      name: 'Quick Enquiry'
    },
    {
      id: 4,
      name: 'Brochure'
    },
    {
      id: 5,
      name: 'Property Guide'
    },
    {
      id: 6,
      name: 'Social Media'
    },
    {
      id: 7,
      name: 'Email Signature'
    },
    {
      id: 8,
      name: 'DL Card'
    },
    {
      id: 9,
      name: 'Signboard'
    },
    {
      id: 10,
      name: 'PDF Flyers'
    },
    {
      id: 11,
      name: 'Match Properties'
    },
    {
      id: 13,
      name: 'Property Alerts'
    },
    {
      id: 14,
      name: 'Pricefinder'
    },
    {
      id: 15,
      name: 'WebBooks'
    },
    {
      id: 16,
      name: 'Owner Reports'
    },
    {
      id: 17,
      name: 'Proposals'
    }
  ];

  style: Style;
  styleForm: FormGroup;

  groups: Group[];
  mediaAttributes: MediaAttribute[];
  departments: Department[];
  clients: Client[];

  s2ClientValue = 0;
  s2ClientData: Array<Select2OptionData>;
  s2ClientOptions: Select2Options;

  constructor(private styleService: StyleService,
    private groupService: GroupService,
    private mediaAttributeService: MediaAttributeService,
    private departmentService: DepartmentService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;
        this.duplicateMode = params['dupe'];
        this.editMode = params['id'] != null;
        if (this.duplicateMode) {
          this.backUrl = '../../../';
        } else {
          this.backUrl = this.editMode ? '../../' : '../';
        }
        this.category = params['type'];
        this.category_id = this.categories[this.category];

      }
    );

      this.groupService.httpGetGroups();
      this.mediaAttributeService.httpGetMediaAttributes();
      this.departmentService.httpGetDepartments();
      this.clientService.httpGetClients({}, 'list');

    this.subscriptionGroup = this.groupService.groupsChanged.subscribe(
      (groups: Group[]) => {
        this.groups = groups;
      }
    );

    this.subscriptionMediaAttribute = this.mediaAttributeService.mediaAttributesChanged.subscribe(
      (mediaAttributes: MediaAttribute[]) => {
        this.mediaAttributes = mediaAttributes;
      }
    );

    this.subscriptionDepartment = this.departmentService.departmentsChanged.subscribe(
      (departments: Department[]) => {
        this.departments = departments;
      }
    );

    this.subscriptionClient = this.clientService.clientsChanged.subscribe(
      (clients: Client[]) => {
        if (clients.length > 0) {
          this.s2ClientData =  <any>clients.map(
            (obj) => {
              return {id: obj.ID, text: (obj.name ? obj.name : obj.company_name) + ' (' + obj.office_id + ')'};
            }
          );

          this.s2ClientData.unshift({id: '0', text: 'All'});
        }

        if (this.editMode) {
          this.loading = true;
          this.styleService.httpGetStyle(this.id);
        }
      }
    );

    this.s2ClientOptions = {
      width: '100%',
      placeholder: 'Select a Client'
    };

    this.subscription = this.styleService.styleChanged.subscribe(
      (style: Style) => {
        this.style = style;

        if ( this.editMode && typeof this.style.ID !== 'undefined' ) {
          this.loading = false;
          if (this.style.category_id === 4 || this.style.category_id === 5 || this.style.category_id === 8 ||
              this.style.category_id === 10 || this.style.category_id === 1 || this.style.category_id === 7 ||
              this.style.category_id === 11 || this.style.category_id === 2 || this.style.category_id === 13) {
              this.showCkeditorFont = true;
          } else {
            this.showCkeditorFont = false;
          }
          this.initFormEdit();
        }
      }
    );

    this.subscriptionResponse = this.styleService.styleOperation.subscribe(
      (style: Style) => {
        if ( typeof style.ID !== 'undefined' ) {
          this.loading = true;
          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.styleForm = new FormGroup({
      'category_id': new FormControl('', Validators.required),
      'group_id': new FormControl(1, Validators.required),
      'media_attribute_id': new FormControl(1, Validators.required),
      'department_id': new FormControl(1, Validators.required),
      'client_id': new FormControl(0, Validators.required),
      'style': new FormControl(''),
      'script': new FormControl(''),
      'external_css': new FormControl(''),
      'external_js': new FormControl(''),
      'external_font': new FormControl(''),
      'ckeditor_font_name': new FormControl(''),
      'ckeditor_font_family': new FormControl('')
    });
  }

  private initFormEdit() {
    this.s2ClientValue = this.style.client_id;
    this.styleForm.patchValue({
      'category_id': this.style.category_id,
      'group_id': this.style.group_id,
      'media_attribute_id': this.style.media_attribute_id,
      'department_id': this.style.department_id,
      'client_id': this.style.client_id,
      'style': this.style.style,
      'script': this.style.script,
      'external_css': this.style.external_css,
      'external_js': this.style.external_js,
      'external_font': this.style.external_font,
      'ckeditor_font_name': this.style.ckeditor_font_name,
      'ckeditor_font_family': this.style.ckeditor_font_family
    });
  }

  patchForm(field, value) {
    if (field === 'client_id') {
      this.styleForm.patchValue({'client_id': value});
      this.s2ClientValue = value;
    }
  }

  onSubmit() {
    let styleValues = this.styleForm.value;
    this.loading = true;

    if ( this.editMode && this.duplicateMode == undefined ) {
      this.styleService.httpPutStyle(this.id, styleValues);
    } else {
      this.styleService.httpPostStyle(styleValues);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionGroup.unsubscribe();
    this.subscriptionMediaAttribute.unsubscribe();
    this.subscriptionDepartment.unsubscribe();
    this.subscriptionClient.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }

}
