import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from '../../app.config';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { VaultService } from '../../services/vault.service';
import { AuthService } from '../../services/auth.service';

import swal from 'sweetalert2';
@Component({
  selector: 'app-vaultre-login',
  templateUrl: './vaultre-login.component.html',
  styleUrls: ['./vaultre-login.component.scss']
})
export class VaultreLoginComponent implements OnInit {
  loading = false;
  login_link = '';
  code: '';

  vaultLoginSuccess = false;
  vaultLoginMessage = '';

  selectedLogo = '';
  redirectUrl = '';
  corporateRedirectUrl = '';

  isUk = false;
  isCorporate = false;

  isLoginChecked = false;

  isRh = false;
  previousAuths = [];

  constructor(
    @Inject(APP_CONFIG) public appConfig,
    private route: ActivatedRoute,
    private router: Router,
    private vaultService: VaultService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    if (this.authService.checkAuthToken()) {
      window.location.href = '/templates';
      return true;
    }

    this.isLoginChecked = true;
    this.previousAuths = this.authService.getPreviousAuth();

    if (this.appConfig.IS_UK && this.router.url === '/login') {
      this.router.navigateByUrl('/vaultea/login');
    }

    if ( String(this.router.url).includes('/vaultea/login') ) {
      this.redirectUrl = this.corporateRedirectUrl = `${this.appConfig.HTML_ENDPOINT}/vaultea/login`;
      // tslint:disable-next-line:max-line-length
      this.login_link = 'https://login.vaultea.co.uk/cgi-bin/clientvault/oauth-authorize-user.cgi?client_id=' + this.appConfig.VAULT_UK_CLIENTID + '&redirect_uri=' + this.redirectUrl + '&response_type=code';
      this.isUk = true;
    } else {
      this.redirectUrl = this.corporateRedirectUrl = `${this.appConfig.HTML_ENDPOINT}/login`;
      // tslint:disable-next-line:max-line-length
      this.login_link = 'https://login.vaultre.com.au/cgi-bin/clientvault/oauth-authorize-user.cgi?client_id=' + this.appConfig.VAULT_CLIENTID + '&redirect_uri=' + this.redirectUrl + '&response_type=code';
      this.isUk = false;
    }

    this.route.queryParams.subscribe(
      (params: Params) => {

        if ( params['RH'] === '1' ) {
          this.selectedLogo = 'designlyplus-logo.svg';

          this.redirectUrl += '?RH=1';
          this.corporateRedirectUrl += '?RH=1';
          // tslint:disable-next-line:max-line-length
          this.login_link = 'https://login.vaultre.com.au/cgi-bin/clientvault/oauth-authorize-user.cgi?client_id=' + this.appConfig.VAULT_CLIENTID + '&redirect_uri=' + this.redirectUrl + '&response_type=code';
          this.isRh = true;
        } else if (this.appConfig.SKIN === 'prd') {
          this.selectedLogo = 'PRD.REALESTATE_Logo_Red-Grey_RGB_SVG.svg';
        } else if (this.appConfig.SKIN === 'raywhite') {
          this.selectedLogo = 'rw-logo.svg';
        } else if (this.appConfig.SKIN === 'professionals') {
          this.selectedLogo = 'professionals_asset_library.svg';
        } else {
          this.selectedLogo = 'designly-logo-login.svg';
        }

        if ( params['cv'] === '1' ) {
          if ( params['RH'] === '1' ) {
            this.corporateRedirectUrl += '&cv=1';
          } else {
            this.corporateRedirectUrl += '?cv=1';
          }
          // tslint:disable-next-line:max-line-length
          this.login_link = 'https://login.clientvault.com/cgi-bin/clientvault/oauth-authorize-user.cgi?client_id=' + this.appConfig.VAULT_CLIENTID + '&redirect_uri=' + encodeURIComponent(this.corporateRedirectUrl) + '&response_type=code';
          this.isCorporate = true;

          localStorage.setItem('is_corporate', 'yes');
        }

        this.vaultLoginMessage = params['reason'];
        if ( typeof this.vaultLoginMessage !== 'undefined' && this.vaultLoginMessage === 'success' ) {
          this.loading = true;
          this.code = params['code'];
          this.vaultLoginSuccess = true;

          this.vaultService.httpLogin({
            client_id: this.isUk ? this.appConfig.VAULT_UK_CLIENTID : this.appConfig.VAULT_CLIENTID,
            code: this.code,
            corporate: this.isCorporate
          });
        }
      }
    );

    this.vaultService.vaultAuthLogin.subscribe(
      (auth: any) => {
        if ( typeof auth !== 'undefined' && auth.status === 'success' ) {
          this.loading = false;
          this.authService.updatePreviousData(auth);
          this.authService.setAuthStorage(auth, false, false, false, this.isCorporate);
          const $this = this;
          swal({
            title: 'Preparing this device...',
            timer: 3000,
            onOpen: function () {
              swal.showLoading ();
            }
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === 'timer') {
                $this.authService.getAuthCookie();

                if (auth && typeof auth.is_first !== 'undefined' && auth.is_first) {
                  return window.location.href = '/settings?setup=true';
                }

                const redirectUrl = $this.authService.cookieService.get('redirect');
                if (redirectUrl !== '' && redirectUrl !== ' ' && redirectUrl !== '/') {
                  $this.authService.cookieService.delete('redirect', '/');
                  return window.location.href = redirectUrl;
                }

                if ($this.authService.auth.first === '1') {
                  return window.location.href = '/settings?setup=true';
                }

                if ($this.authService.auth.brandid === '6') {
                  return window.location.href = '/templates';
                } else {
                  return window.location.href = '/templates';
                }
              }
            }
          );
        }
        if (typeof auth !== 'undefined' && auth.status === 'error') {
          this.loading = false;
          swal({
            title: 'VaultAPI Issue',
            html: 'A login error has occurred, please try again. If the issue persists please contact <a href="https://websites.formstack.com/forms/designly_support_form">support</a>.',
            type: 'error',
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'Try again'
          }).then((result) => {
            if (result) {
              return window.location.href = this.redirectUrl;
            }
          }).catch(swal.noop);
        }
        if (typeof auth !== 'undefined' && auth.status === 'scopeissue') {
          this.loading = false;
          swal({
            title: 'VaultAPI Issue',
            text: 'In order to utilise Designly, permissions to your Properties and Users are required',
            type: 'error',
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'Try again'
          }).then((result) => {
            if (result) {
              return window.location.href = this.redirectUrl;
            }
          }).catch(swal.noop);
        }
        if ( typeof auth !== 'undefined' && auth.status === 'billingissue' ) {
          this.loading = false;
          swal(
            'Whoops, it looks like your subscription has not been paid.',
            'Please contact 1300 650 520 to arrange payment and get back to designing.',
            'error'
          );
        }
        if ( typeof auth !== 'undefined' && auth.status === 'blocked' ) {
          this.loading = false;

          swal({
            title: '',
            type: 'error',
            html:
              // tslint:disable-next-line:max-line-length
              '<p><strong>Doesn’t look like you have an account, please click <a target="_blank" href="https://websites.formstack.com/forms/designlyregistration">here</a> to enquire</strong></p>'
          });
        }
      }
    );
  }

  onSelectAuth(auth) {
    const $this = this;
    swal({
      title: 'Are you sure you want to login to this account?',
      text: '',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result) {
        $this.authService.updatePreviousData(auth);
        $this.authService.setAuthStorage(auth);
        $this.authService.getAuthCookie();

        const redirectUrl = $this.authService.cookieService.get('redirect');
        if (redirectUrl !== '' && redirectUrl !== ' ' && redirectUrl !== '/') {
          $this.authService.cookieService.delete('redirect', '/');
          return window.location.href = redirectUrl;
        }

        if ($this.authService.auth.brandid === '6') {
          window.location.href = '/templates';
        } else {
          window.location.href = '/templates';
        }
      }
    }).catch(swal.noop);
  }

}
