<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Block': 'Block - ' + block_title }}</h2>

    <button [routerLink]="backUrl" type="button" class="btn-admin">
        <i class="icon feather icon-arrow-left"></i><span>Back</span>
      </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="blockForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="group_id" class="col-sm-2">Group</label>
            <div class="col-sm-5">
              <ng-select formControlName="group_id" [clearable]="false" [searchable]="true" [multiple]="false" id="group_blocks">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option value="0">All</ng-option>
                <ng-option *ngFor="let group of groups" [value]="group?.ID">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-5">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="sort" class="col-sm-2">Sort #</label>
            <div class="col-sm-3">
              <input formControlName="sort" type="number" class="form-control" id="sort" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!blockForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
