import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EnvironmentService {
  onGet = new Subject();

  constructor(
    private httpClient: HttpClient
  ) { }

  httpGet(url: string) {
    return this.httpClient.get(url)
      .subscribe(
        (response: any) => {
          this.onGet.next(response);
        },
        (response: any) => {
          this.onGet.next(response.error);
        }
    );
  }
}
