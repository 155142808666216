import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminService } from '../../../admin.service';
import { DesignsTypeService } from '../../../../services/designs-type.service';
import { DesignsType } from '../../../../models/designs-type.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-pros-builder-types-edit',
  templateUrl: './admin-pros-builder-types-edit.component.html',
  styleUrls: ['./admin-pros-builder-types-edit.component.scss']
})
export class AdminProsBuilderTypesEditComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;

  designsType: DesignsType;
  designsTypeForm: FormGroup;

  constructor(
    private adminService: AdminService,
    private designsTypeService: DesignsTypeService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const designsTypeId = this.route.snapshot.paramMap.get('id');

    if (designsTypeId) {
      this.designsTypeService.httpGet(designsTypeId);
      this.editMode = true;
      this.loading = true;
    }

    this.designsTypeService.onGet
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.data !== 'undefined' && response.status === 'success') {
            this.designsType = response.data;

            this.initEdit();
          } else {
            swal({
              title: 'Failed to get type',
              text: 'Contact Dev Team',
              type: 'error'
            });
          }
        }
      }
    );

    this.designsTypeService.onStore
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            swal({
              title: 'Type created',
              type: 'success'
            });
            this.router.navigateByUrl('/admin/pros/builder/types');
          } else {
            swal({
              title: 'Failed to create type',
              text: 'Contact Dev Team',
              type: 'error'
            });
          }
        }
      }
    );

    this.designsTypeService.onUpdate
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            swal({
              title: 'Type updated',
              type: 'success'
            });
            this.router.navigateByUrl('/admin/pros/builder/types');
          } else {
            swal({
              title: 'Failed to update type',
              text: 'Contact Dev Team',
              type: 'error'
            });
          }
        }
      }
    );

    this.initForm();
  }

  initForm(): void {
    this.designsTypeForm = new FormGroup({
      reference: new FormControl('printables', Validators.required),
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      dpi: new FormControl(300, Validators.required),
      width: new FormControl(0, Validators.required),
      height: new FormControl(0, Validators.required)
    });
  }

  initEdit(): void {
    this.designsTypeForm.patchValue({
      reference: this.designsType.reference,
      name: this.designsType.name,
      description: this.designsType.description,
      dpi: this.designsType.dpi,
      width: this.designsType.width,
      height: this.designsType.height
    });
  }

  onSubmit(): void {
    const designsTypeFormValues = this.designsTypeForm.value;

    if (this.designsTypeForm.valid) {
      if (this.editMode) {
        this.designsTypeService.httpUpdate(this.designsType.uuid, designsTypeFormValues);
      } else {
        this.designsTypeService.httpStore(designsTypeFormValues);
      }
      this.loading = true;
    }
  }

}
