<div class="row">
    <div class="col-md-12 heading">
        <h2 *ngIf="!editMode" class="pull-left">Add Type</h2>
        <h2 *ngIf="editMode" class="pull-left">Edit Type</h2>

        <button routerLink="/admin/pros/builder/types" type="button" class="btn-admin">
            <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
</div>
<div class="edit-box">
    <div class="row">
        <div class="col-md-12">
            <div class="edit-box-content">
                <h4>Details</h4>
                <form [formGroup]="designsTypeForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="inputName" class="col-sm-2">Reference</label>
                        <div class="col-sm-6">
                            <select class="form-select" formControlName="reference">
                                <option value="printables">Printables</option>
                                <option value="socialmedia">Social Media</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputName" class="col-sm-2">Name</label>
                        <div class="col-sm-6">
                            <input formControlName="name" type="text" class="form-control" id="inputName"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputDescription" class="col-sm-2">Description</label>
                        <div class="col-sm-6">
                            <textarea formControlName="description" class="form-control"
                                id="inputDescription"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputDpi" class="col-sm-2">DPI</label>
                        <div class="col-sm-6">
                            <input formControlName="dpi" type="number" class="form-control" id="inputDpi"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputWidth" class="col-sm-2">Width</label>
                        <div class="col-sm-6">
                            <input formControlName="width" type="number" class="form-control" id="inputWidth"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputHeight" class="col-sm-2">Height</label>
                        <div class="col-sm-6">
                            <input formControlName="height" type="number" class="form-control" id="inputHeight"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2">&nbsp;</label>
                        <div class="col-sm-10">
                            <button [disabled]="!designsTypeForm.valid"
                                class="btn-admin btn-admin-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>