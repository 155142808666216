import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { BlacklistService } from '../../../services/blacklist.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-blacklists-edit',
  templateUrl: './blacklists-edit.component.html',
  styleUrls: ['./blacklists-edit.component.scss']
})
export class BlacklistsEditComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject;
  loading = false;
  menus: Menu[] = [];

  blacklistForm: FormGroup;

  constructor(
    private adminService: AdminService,
    private blacklistService: BlacklistService
  ) { }

  ngOnInit() {
    this.adminService.loadMenu(this.menus);

    this.blacklistService.blacklistPost
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.blacklistForm.reset();

          Swal(
            'Success',
            'Office/Franchise blacklisted successfully',
            'success'
          );
        }
      }
    );

    this.blacklistForm = new FormGroup({
      'provider': new FormControl('', Validators.required),
      'type': new FormControl('', Validators.required),
      'external_id': new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    const blacklistFormValues = this.blacklistForm.value;

    this.blacklistService.httpPostBlacklist(blacklistFormValues);
    this.loading = true;
  }

}
