<div class="row">
    <div class="col-md-12 heading">
        <h2 *ngIf="!editMode" class="pull-left">Add {{product | titlecase}} Element</h2>
        <h2 *ngIf="editMode" class="pull-left">Edit {{product | titlecase}} Element</h2>

        <button routerLink="/admin/pros/themes/{{product}}" type="button" class="btn-admin">
            <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
</div>
<div class="edit-box">
    <div class="edit-box-content">
        <form [formGroup]="proThemeForm" (ngSubmit)="onSubmit()">
            <div class="d-flex">
                <div class="w-25 p-2">
                    <h4>Details</h4>
                    <div class="form-group">
                        <label for="selectGroupId" class="col-sm-2">Groups</label>
                        <div class="col-sm-12">
                            <ng-select formControlName="group_id" [clearable]="false" [searchable]="false"
                                [multiple]="true">
                                <ng-option value="" disabled>Select a Group</ng-option>
                                <ng-option value="all">All</ng-option>
                                <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="selectGroupId" class="col-sm-12">Excluded Groups</label>
                        <div class="col-sm-12">
                            <ng-select formControlName="excluded_group_id" [clearable]="false" [searchable]="false"
                                [multiple]="true">
                                <ng-option value="" disabled>Select a Group to exclude</ng-option>
                                <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="selectGroupId" class="col-sm-2">Clients</label>
                        <div class="col-sm-12">
                            <ng-select formControlName="client_id" [clearable]="false" [searchable]="true"
                                [multiple]="true">
                                <ng-option value="" disabled>Select a Client to exclude</ng-option>
                                <ng-option value="all">All</ng-option>
                                <ng-option *ngFor="let client of clients" [value]="client?.ID">{{client?.name}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="title" class="col-sm-2">Title</label>
                        <div class="col-sm-12">
                            <input formControlName="title" type="text" class="form-control" id="title"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputDescription" class="col-sm-2">Description</label>
                        <div class="col-sm-12">
                            <textarea formControlName="description" class="form-control"
                                id="inputDescription"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="title" class="col-sm-4">Sort Order</label>
                        <div class="col-sm-12">
                            <input formControlName="sort" type="number" class="form-control" id="sort"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputThumbnail" class="col-sm-2">Thumbnail</label>
                        <div class="col-sm-12">
                            <input type="file" class="form-control" (change)="onUpload($event.target.files)"
                                accept="image/*">
                        </div>
                        <img *ngIf="proThemeForm?.get('thumbnail')?.value" class="img-fluid"
                            src="{{adminService?.appConfig?.S3_URL}}{{proThemeForm?.get('thumbnail')?.value}}">
                    </div>
                    <h4>External</h4>
                    <div class="form-group">
                        <label for="inputExternalCss" class="col-sm-12">External CSS</label>
                        <div class="col-sm-12">
                            <textarea formControlName="external_css" class="form-control"
                                id="inputExternalCss"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputExternalJs" class="col-sm-12">External JS</label>
                        <div class="col-sm-12">
                            <textarea formControlName="external_js" class="form-control"
                                id="inputExternalJs"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputExternalFonts" class="col-sm-12">External Fonts</label>
                        <div class="col-sm-12">
                            <textarea formControlName="external_fonts" class="form-control"
                                id="inputExternalFonts"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="selectGroupId" class="col-sm-2">Is Active?</label>
                        <div class="col-sm-12">
                            <select formControlName="is_active" class="form-control">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2">&nbsp;</label>
                        <div class="col-sm-10">
                            <button [disabled]="!proThemeForm.valid" class="btn-admin btn-admin-primary">Save</button>
                        </div>
                    </div>
                </div>
                <div class="w-75 p-2">
                    <h4>HTML</h4>
                    <ngx-monaco-editor formControlName="html" style="height: 100%; min-height: 900px;" [options]="editorOptions"></ngx-monaco-editor>
                </div>
            </div>
        </form>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>