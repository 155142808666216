import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Faq } from '../models/faq.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class FaqService {
  faqsChanged = new Subject<Faq[]>();
  faqChanged = new Subject<Faq>();
  faqOperation = new Subject<Faq>();

  private faqs: Faq[] = [];

  // New Subscriptions
  onGetFaqs = new Subject();
  onGetFaq = new Subject();
  onPostFaq = new Subject();
  onPutFaq = new Subject();
  onDeleteFaq = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) {}

  httpGetFaqs(params: any = {}, columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    if (order) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/faqs`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetFaqs.next(response);
      },
      (response: any) => {
        this.onGetFaqs.next(response.error);
      }
    );
  }

  httpGetFaq(UID: string) {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/faqs/${UID}`)
    .subscribe(
      (response: any) => {
        this.onGetFaq.next(response);
      },
      (response: any) => {
        this.onGetFaq.next(response.error);
      }
    );
  }

  httpPostFaq(data: Faq) {
    this.httpClient.post(`${this.appConfig.API_ENDPOINT}/faqs`, data)
    .subscribe(
      (response: any) => {
        this.onPostFaq.next(response);
      },
      (response: any) => {
        this.onPostFaq.next(response.error);
      }
    );
  }

  httpPutFaq(UID: string, data: Faq) {
    this.httpClient.put(`${this.appConfig.API_ENDPOINT}/faqs/${UID}`, data)
    .subscribe(
      (response: any) => {
        this.onPutFaq.next(response);
      },
      (response: any) => {
        this.onPutFaq.next(response.error);
      }
    );
  }

  httpDeleteFaq(UID: string) {
    this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/faqs/${UID}`)
    .subscribe(
      (response: any) => {
        this.onDeleteFaq.next(response);
      },
      (response: any) => {
        this.onDeleteFaq.next(response.error);
      }
    );
  }
}
