<div class="panel panel-bordered panel-box-shadow">
  <div class="panel-heading">
    <h3 class="panel-title mt-0">Latest Activity</h3>
  </div>
  <div class="panel-body">
    <ul class="list-unstyled lists-item">
      <li class="margin-bottom-15">
        <small class="gray4-color">Market Report</small>
        <p class="margin-0 darkblue-color">August Market Update</p>
        <small class="gray4-color">Updated by john Doe • 12 August 2017</small>
      </li>

      <li class="margin-bottom-15">
        <small class="gray4-color">Window Card</small>
        <p class="margin-0 darkblue-color">A4 Portrait - For Sale</p>
        <small class="gray4-color">Updated by john Doe • 12 August 2017</small>
      </li>

      <li class="margin-bottom-15">
        <small class="gray4-color text-uppercase">Property Guide</small>
        <p class="margin-0 darkblue-color">Open Times Property Guide</p>
        <small class="gray4-color">Updated by john Doe • 12 August 2017</small>
      </li>

      <li class="margin-bottom-15">
        <small class="gray4-color">Ecard</small>
        <p class="margin-0 darkblue-color">August Market Update</p>
        <small class="gray4-color">Updated by john Doe • 12 August 2017</small>
      </li>
    </ul>
  </div>
</div>
