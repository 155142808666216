<div class="row">
    <div class="col-md-12 heading">
        <h2 *ngIf="!editMode" class="pull-left">Add Merge Field</h2>
        <h2 *ngIf="editMode" class="pull-left">Edit Merge Field</h2>

        <button routerLink="/admin/pros/builder/mergefields" type="button" class="btn-admin">
            <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
</div>
<div class="edit-box">
    <div class="row">
        <div class="col-md-12">
            <div class="edit-box-content">
                <h4>Details</h4>
                <form [formGroup]="mergeFieldForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="selectCategory" class="col-sm-2">Category</label>
                        <div class="col-sm-6">
                            <ng-select formControlName="merge_fields_category_id" [clearable]="false" [searchable]="true">
                                <ng-option value="" disabled>Select a category</ng-option>
                                <ng-option *ngFor="let mergeFieldsCategory of mergeFieldsCategories" [value]="mergeFieldsCategory?.uuid">{{mergeFieldsCategory?.name}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputReference" class="col-sm-2">Reference</label>
                        <div class="col-sm-6">
                            <ng-select formControlName="reference" [clearable]="false" [searchable]="true">
                                <ng-option value="" disabled>Select a field reference</ng-option>
                                <ng-option *ngFor="let field of fields" [value]="field">{{field}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputName" class="col-sm-2">Name</label>
                        <div class="col-sm-6">
                            <input formControlName="name" type="text" class="form-control" id="inputName"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputMergeField" class="col-sm-2">Merge Field</label>
                        <div class="col-sm-6">
                            <input formControlName="merge_field" type="text" class="form-control" id="inputMergeField"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputMergeFieldOutput" class="col-sm-2">Merge Field Output</label>
                        <div class="col-sm-6">
                            <textarea formControlName="merge_field_output" class="form-control"
                                id="inputMergeFieldOutput"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputPlaceholder" class="col-sm-2">Placeholder</label>
                        <div class="col-sm-6">
                            <textarea formControlName="placeholder" class="form-control"
                                id="inputPlaceholder"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2">&nbsp;</label>
                        <div class="col-sm-10">
                            <button [disabled]="!mergeFieldForm.valid" class="btn-admin btn-admin-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>