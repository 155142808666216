import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementService } from '../../../../services/element.service';
import { Element } from '../../../../models/element.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { GroupService } from '../../../../services/group.service';
import { MediaAttributeService } from '../../../../services/media-attribute.service';
import { DepartmentService } from '../../../../services/department.service';
import { ClientService } from '../../../../services/client.service';
import { Group } from '../../../../models/group.model';
import { MediaAttribute } from '../../../../models/media-attribute.model';
import { Department } from '../../../../models/department.model';
import { Client } from '../../../../models/client.model';

import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { BlockService } from '../../../../services/block.service';

@Component({
  selector: 'app-element-edit',
  templateUrl: './element-edit.component.html',
  styleUrls: ['./element-edit.component.scss']
})
export class ElementEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  id: number;
  editMode = false;
  backUrl = '../../';
  category: string;
  category_id: number;
  block: string;
  block_id: string;
  duplicateMode = false;
  htmlHolder: any;
  blockHolder: any;
  categoryHolder: any;

  categories = {
    'enewsletter': 1,
    'buyer-match': 2,
    'quick-enquiry': 3,
    'brochure': 4,
    'property-guide': 5,
    'social-media': 6,
    'email-signature': 7,
    'dl-card': 8,
    'sign-board': 9,
    'property-match': 10,
    'match-listings': 11,
    'buyer-match-vault': 12,
    'property-alerts': 13,
    'pricefinder': 14,
    'webbooks': 15,
    'owner-reports': 16,
    'proposals': 17
  };

  elementForm: FormGroup;
  element: Element;
  elements: Element[];

  groups: Group[];
  mediaAttributes: MediaAttribute[];
  departments: Department[];
  clients: Client[];

  element_title = '';
  element_icon = '';
  element_thumbnail = '';

  s3_resource_path = '';

  aceEditor: any;
  scriptEditor: any;

  @ViewChild('fileIcon', { static: true }) fileIcon: ElementRef;
  @ViewChild('fileThumbnail', { static: true }) fileThumbnail: ElementRef;

  constructor(
    private elementService: ElementService,
    private groupService: GroupService,
    private mediaAttributeService: MediaAttributeService,
    private departmentService: DepartmentService,
    private clientService: ClientService,
    private blockService: BlockService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.category = this.route.snapshot.paramMap.get('type');
    this.category_id = this.categories[this.category];
    this.block_id = this.route.snapshot.paramMap.get('block');

    if (!this.category || !this.category_id) {
      this.router.navigateByUrl('/admin/templates/elements');
    }

    this.loading = true;
    const elementId = this.route.snapshot.paramMap.get('id');
    const duplicateMode = this.route.snapshot.paramMap.get('dupe');

    if (elementId) {
      // tslint:disable-next-line: radix
      this.id = parseInt(elementId);
      this.editMode = true;
    }

    if (duplicateMode) {
      this.duplicateMode = true;
      this.backUrl = '../../../';
    }

    this.groupService.httpGetGroupsNew();

    this.groupService.onGetGroups
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.groups = response.data;

              this.clientService.httpGetClientsNew({}, 'list');
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting groups', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.clientService.onGetClients
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.clients = response.data;

              this.mediaAttributeService.httpGetMediaAttributesNew();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting clients', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.mediaAttributeService.onGetMediaAttributes
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.mediaAttributes = response.data;

              this.departmentService.httpGetDepartmentsNew();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting media attributes', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.departmentService.onGetDepartments
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.departments = response.data;

              if (this.editMode) {
                this.elementService.httpGetElementNew(elementId);
              }

              if (this.block_id) {
                this.elementService.httpGetElementsNew({
                  parent_id: 0,
                  category_id: this.category_id,
                  block_id: this.block_id
                });
              }
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting media attributes', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.elementService.onGetElement
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.element = response.data;

              this.elementService.httpGetElementsNew({
                parent_id: 0,
                category_id: this.element.category_id,
                block_id: this.element.block_id
              });

              if (this.duplicateMode) {
                this.category_id = this.element.category_id;
              }

              this.block_id = this.element.block_id.toString();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting element', 'Contact Dev Team', 'error');
              this.router.navigateByUrl('/admin/templates/elements');
            }
          }
        }
      }
    );

    this.elementService.onGetElements
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.elements = response.data;

              if (this.blockService.group_id) {
                this.elementForm.patchValue({
                  group_id: this.blockService.group_id
                });
              }

              if (this.editMode) {
                this.initFormEdit();
              }
            }

            if (response.status === 'error') {
              swal('Error getting elements', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.elementService
    .elementOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (element: Element) => {
        if ( typeof element.ID !== 'undefined' ) {
          this.loading = false;
          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.elementForm = new FormGroup({
      'title': new FormControl('', Validators.required),
      'description': new FormControl(''),
      'provider': new FormControl(['all'], Validators.required),
      'group_id': new FormControl('', Validators.required),
      'media_attribute_id': new FormControl('', Validators.required),
      'department_id': new FormControl(1, Validators.required),
      'client_id': new FormControl('', Validators.required),
      'parent_id': new FormControl('0'),
      'is_active': new FormControl(1, Validators.required),
      'icon': new FormControl(''),
      'thumbnail': new FormControl(''),
      'type': new FormControl('default', Validators.required),
      'listing_category': new FormControl(''),
      'sort': new FormControl('0'),
    });
  }

  private initFormEdit() {
    this.element_title = this.element.title;
    this.elementForm.patchValue({
      'title': this.element.title,
      'description': this.element.description,
      'group_id': this.element.group_id.toString(),
      'provider': this.element.provider,
      'media_attribute_id': this.element.media_attribute_id.toString(),
      'department_id': this.element.department_id.toString(),
      'client_id': this.element.client_id.toString(),
      'parent_id': this.element.parent_id.toString(),
      'is_active': (this.duplicateMode) ? 0 : this.element.is_active,
      'icon': this.element.icon,
      'thumbnail': this.element.thumbnail,
      'type': this.element.type,
      'listing_category': this.element.listing_category,
      'sort': +('' + this.element.sort).substr(-5),
    });

    this.loading = false;
  }

  onSubmit() {
    const elementValues = this.elementForm.value;

    const icon = this.fileIcon.nativeElement;
    const thumbnail = this.fileThumbnail.nativeElement;
    this.loading = true;

    if (icon.files.length > 0) {
      elementValues['iconFile'] = icon.files[0];
    }
    if (thumbnail.files.length > 0) {
      elementValues['thumbnailFile'] = thumbnail.files[0];
    }

    if (elementValues['type'] === 'default') {
      elementValues['parent_id'] = 0;
    }

    if (this.editMode && !this.duplicateMode) {
      this.elementService.httpPutElement(this.id, elementValues);
    } else if (this.duplicateMode) {
      elementValues['element_duplicate_id'] = this.id;
      elementValues['category_id'] = this.category_id;
      elementValues['block_id'] = this.block_id;

      this.elementService.httpPostElement(elementValues);
    } else {
      elementValues['category_id'] = this.category_id;
      elementValues['block_id'] = this.block_id;

      this.elementService.httpPostElement(elementValues);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
