<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">{{product | titlecase}} Themes</h2>

        <button routerLink="new" type="button" class="btn-admin">
            <i class="icon feather icon-plus"></i><span>Add New {{product | titlecase}} Theme</span>
        </button>
    </div>
</div>
<div id="box" class="row">
    <div class="col-md-12">
        <div class="table-box">
            <app-admin-table-component [rowData]="proThemes">
                <thead>
                    <tr>
                        <th>Groups</th>
                        <th>Excluded Groups</th>
                        <th>Clients</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Sort</th>
                        <th>Status</th>
                        <th>Last Updated</th>
                        <th class="text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let proTheme of proThemes">
                        <td>
                            <span *ngIf="proTheme?.whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="proTheme?.whitelist === 'selected'">
                                <ng-container *ngFor="let group of proTheme?.groups">
                                <span class="badge badge-primary">{{group?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="!proTheme?.excluded_groups?.length">None</span>
                            <ng-container *ngIf="proTheme?.excluded_groups?.length">
                                <ng-container *ngFor="let group of proTheme?.excluded_groups">
                                <span class="badge badge-primary">{{group?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="proTheme?.client_whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="proTheme?.client_whitelist === 'selected'">
                                <ng-container *ngFor="let client of proTheme?.clients">
                                <span class="badge badge-primary">{{client?.name}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>{{proTheme?.title}}</td>
                        <td>{{proTheme?.description}}</td>
                        <td>{{proTheme?.sort}}</td>
                        <td>
                            <span *ngIf="proTheme?.is_active" class="badge badge-success">Active</span>
                            <span *ngIf="!proTheme?.is_active" class="badge badge-danger">Inactive</span>
                        </td>
                        <td>{{proTheme?.modified | date:'medium'}}</td>
                        <td class="text-end">
                            <a routerLink="/admin/pros/themes/{{product}}/{{proTheme?.UID}}/edit" class="btn btn-primary btn-sm">Edit</a>
                        </td>
                    </tr>
                </tbody>
            </app-admin-table-component>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>