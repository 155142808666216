import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

declare const $: any;

@Component({
  selector: 'app-admin-table-component',
  templateUrl: './admin-table-component.component.html',
  styleUrls: ['./admin-table-component.component.scss']
})
export class AdminTableComponentComponent implements OnInit, OnChanges, OnDestroy {
  dtTrigger: Subject<boolean> = new Subject<boolean>();
  dtOptions: DataTables.Settings = {};

  @ViewChild(DataTableDirective, {static: true})
  dtElements: QueryList<DataTableDirective>;
  constructor() { }

  @Input() rowData: [{}];
  ngOnInit(): void { }

  ngOnChanges(): void {
    this.destroyActiveDataTables();

    if (typeof this.rowData !== 'undefined') {
      this.dtTrigger.next();
    }

    this.dtOptions = {
      order: [],
      pageLength: 25
    };
  }

  destroyActiveDataTables(): void {
    const activeDataTables = $.fn.dataTable.tables();
    if (activeDataTables.length) {
      activeDataTables.forEach(element => {
        $(element).DataTable().destroy();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyActiveDataTables();
    this.dtTrigger.unsubscribe();
  }
}
