import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { GroupService } from '../../../services/group.service';
import { ActivatedRoute, Params } from '@angular/router';
import { BlockService } from '../../../services/block.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Group } from '../../../models/group.model';
import { isArray } from 'util';
import { AdminService } from '../../admin.service';

declare const $: any;

@Component({
  selector: 'app-template-blocks',
  templateUrl: './template-blocks.component.html',
  styleUrls: ['./template-blocks.component.scss']
})
export class TemplateBlocksComponent implements OnInit, OnDestroy {
  loading = false;
  category: string;
  category_id: number;
  category_name = '';

  group_id = '';

  dataTableHtml: any;

  categories = {
    'enewsletter': {
      id: 1,
      name: 'Email'
    },
    'buyer-match': {
      id: 2,
      name: 'Buyer Match'
    },
    'quick-enquiry': {
      id: 3,
      name: 'Quick Enquiry'
    },
    'brochure': {
      id: 4,
      name: 'Brochure'
    },
    'property-guide': {
      id: 5,
      name: 'Property Guide'
    },
    'social-media': {
      id: 6,
      name: 'Social Media'
    },
    'email-signature': {
      id: 7,
      name: 'Email Signature'
    },
    'dl-card': {
      id: 8,
      name: 'DL Card'
    },
    'sign-board': {
      id: 9,
      name: 'Signboard'
    },
    'property-match': {
      id: 10,
      name: 'PDF Flyers'
    },
    'match-listings': {
      id: 11,
      name: 'Match Properties'
    },
    'buyer-match-vault': {
      id: 12,
      name: 'Buyer Match Vault'
    },
    'property-alerts': {
      id: 13,
      name: 'Property Alerts'
    },
    'pricefinder': {
      id: 14,
      name: 'Pricefinder'
    },
    'webbooks': {
      id: 15,
      name: 'WebBooks',
    },
    'owner-reports': {
      id: 16,
      name: 'Owner Reports',
    },
    'proposals': {
      id: 17,
      name: 'Proposals',
    }
  };

  selectGroupForm: FormGroup;
  groups: Group[];
  $destroy: Subject<boolean> = new Subject<boolean>();

  blocks: any;

  constructor(
    private adminService: AdminService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private blockService: BlockService
  ) { }

  ngOnInit() {
    this.groupService.httpGetGroups();

    this.route.params.subscribe(
      (params: Params) => {
        this.category = params['type'];
        this.category_id = this.categories[this.category].id;
        this.category_name = this.categories[this.category].name;

        if ( this.blockService.group_id !== 0 ) {
          this.loading = true;
          this.blockService.httpGetBlocks(
            {
              category_id: this.category_id,
              group_id: `0,${this.blockService.group_id}`
            }
          );
        }
      }
    );

    this.blockService.blocksChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && isArray(response)) {
          this.loading = false;
          this.blocks = response;

          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    );

    this.groupService.groupsChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (groups: any) => {
        this.groups = [];
        if ( groups.length > 0 ) {
          this.groups = groups;
        }
      }
    );

    this.initSelectGroupForm();
  }

  initSelectGroupForm() {
    this.selectGroupForm = new FormGroup({
      'group_id': new FormControl('')
    });

    if ( this.blockService.group_id !== 0 ) {
      this.selectGroupForm.patchValue({
        'group_id': this.blockService.group_id
      });
    }
  }

  onChangeGroup(id) {
    this.blockService.group_id = id;

    if (typeof this.blockService.group_id === 'undefined') {
      this.blockService.group_id = -1;
    }

    this.loading = true;
    if ( id === '0' ) {
      this.blockService.httpGetBlocks({
        category_id: this.category_id
      });
    } else {
      this.blockService.httpGetBlocks(
        {
          category_id: this.category_id,
          group_id: `0,${this.blockService.group_id}`
        }
      );
    }
  }

  initDT() {
    setTimeout(() => {
      this.dataTableHtml = $('#datatable-masters').DataTable({
        'pagingType': 'full_numbers',
        'lengthMenu': [
          [50, 100, 200, -1],
          [50, 100, 200, 'All']
        ],
        'pageLength': 50,
        // responsive: true,
        stateSave: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
          'paginate': {
            'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
          }
        },
        'fnDrawCallback': function (oSettings) {
          $('.dataTables_wrapper').find('input[type=search]').addClass('btn-search');
          $('.dataTables_wrapper').find('select').addClass('select_datatable');
        }
      });

    }, 500);
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
    if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
      this.dataTableHtml.destroy();
    }
  }

}
