import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrontService } from '../../front.service';
import { Auth } from '../../../models/auth.model';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { User } from '../../../models/user.model';
import { Client } from '../../../models/client.model';
import { ChargebeeService } from '../../../services/chargebee.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

declare const Chargebee: any;
@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  authUser: Auth;
  user: User;
  client: Client;
  nextDueDate = '';

  agentSubscriptionPrice = 0;
  agencySubscriptionPrice = 0;

  requestDemo = false;

  cbInstance: any;
  chargebeePlans = {
    user: {
      planId: '',
      addons: []
    },
    client: {
      planId: '',
      addons: []
    },
  };

  customerPlans = {
    user: {
      subscriptionId: null,
      status: null,
      addons: []
    },
    client: {
      subscriptionId: null,
      status: null,
      addons: []
    },
  };

  selectedPlans = {
    user: [],
    client: []
  };

  userSubscriptionChanges = false;
  clientSubscriptionChanges = false;

  skipCancelPlanNotification = false;

  constructor(
    public frontService: FrontService,
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService
  ) {
    if (!document.head.querySelector('#chargebee-script')) {
      const chargebeeScript = document.createElement('script');
      chargebeeScript.id = 'chargebee-script';
      chargebeeScript.src = 'https://js.chargebee.com/v2/chargebee.js';
      document.head.append(chargebeeScript);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.authUser = this.frontService.authService.auth;
    this.nextDueDate = moment().add(1, 'M').startOf('month').format('DD/MM/YYYY');

    setTimeout(() => {
      window['Chargebee'].init({
        site: this.frontService.appConfig.CHARGEBEE_SITE_ID,
      });

      this.cbInstance = Chargebee.getInstance();
    }, 1000);

    this.chargebeeService.httpGetPlans();
    this.chargebeeService.httpGetCustomerPlans();

    this.chargebeeService.onGetPlans
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            if (typeof response.data['Pro-Agency'] !== 'undefined') {
              this.chargebeePlans['client'] = response.data['Pro-Agency'];

              if (this.chargebeePlans.client.addons.length) {
                this.chargebeePlans.client.addons = this.chargebeePlans.client.addons.filter(addon => this.authUser.client.show_pro_builder ? true : !addon.id.includes('Pro-Builder'));
              }
            }

            if (typeof response.data['Pro-Agent'] !== 'undefined') {
              this.chargebeePlans['user'] = response.data['Pro-Agent'];

              if (this.chargebeePlans.user.addons.length) {
                this.chargebeePlans.user.addons = this.chargebeePlans.user.addons.filter(addon => this.authUser.client.show_pro_builder ? true : !addon.id.includes('Pro-Builder'));
              }
            }
          }
        }
      }
    );

    this.chargebeeService.onGetCustomerPlans
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            if (typeof response.data.user !== 'undefined' && response.data.user) {
              this.customerPlans['user'] = response.data.user;

              this.agentSubscriptionPrice = 0;
              if (this.customerPlans.user.status === 'active') {
                this.customerPlans.user.addons.forEach((plan: any) => {
                  this.selectedPlans.user.push(plan.itemPriceId);
                  this.agentSubscriptionPrice = this.agentSubscriptionPrice + (plan.itemPrice / 100);
                });
              }
            }

            if (typeof response.data.client !== 'undefined' && response.data.client) {
              this.customerPlans['client'] = response.data.client;

              this.agencySubscriptionPrice = 0;
              if (this.customerPlans.client.status === 'active') {
                this.customerPlans.client.addons.forEach((plan: any) => {
                  this.selectedPlans.client.push(plan.itemPriceId);
                  this.agencySubscriptionPrice = this.agencySubscriptionPrice + (plan.itemPrice / 100);
                });
              }
            }

            if (this.customerPlans.client.addons.length) {
              localStorage.setItem('isClientPro', 'true');
            } else {
              localStorage.removeItem('isClientPro');
            }

            if (this.customerPlans.user.addons.length) {
              localStorage.setItem('isUserPro', 'true');
            } else {
              localStorage.removeItem('isUserPro');
            }
          }
        }
      }
    );

    this.chargebeeService.onCancelPlans
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.chargebeeService.httpGetCustomerPlans();

            if (!this.skipCancelPlanNotification) {
              this.toastrService.success('Plan cancelled successfully.', 'Success');
            }

            this.skipCancelPlanNotification = false;
          }
        }
      }
    );

    this.chargebeeService.onCancelSubscription
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            swal({
              title: 'Subscription cancelled successfully.',
              text: 'Your access will be removed at the end of the billing term',
              type: 'success',
              allowEscapeKey: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEnterKey: false
            });

            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            swal({
              title: 'Internal Server Error',
              text: 'Contact Dev Team',
              type: 'error',
            });
          }
        }
      }
    );

    const $this = this;
    $('#requestDemoModal').on('show.bs.modal', function (e) {
        if (!$this.requestDemo) {
          $this.requestDemo = true;

          const hubspotScript = document.createElement('script');
          // tslint:disable-next-line: max-line-length
          const inlineScript = document.createTextNode(`hbspt.forms.create({region: "na1", portalId: "20238220",formId: "80996989-5419-4096-a2ed-42e752b2045b", target: "#hubspotForm"});`);
          hubspotScript.appendChild(inlineScript);
          $('#hubspotForm').append(`<div class="form-loader d-flex justify-content-center align-items-center">
          <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>`);

          setTimeout(() => {
            $('#hubspotForm').find('.form-loader').remove();
            $('head').append(hubspotScript);
          }, 1500);
        }
    });
  }

  onAgentCheckChange(event: any): void {
    this.userSubscriptionChanges = true;
    const planId = event.target.value as string;
    if (event.target.checked) {
      if (planId.includes('Bundle')) {
        this.selectedPlans.user = [planId];
      } else {
        this.selectedPlans.user.forEach((addonId: string, index: number) => {
          if (addonId.includes('Bundle')) {
            this.selectedPlans.user.splice(index, 1);
          }
        });
        this.selectedPlans.user.push(planId);
      }
    } else {
      this.selectedPlans.user.forEach((addonId: string, index: number) => {
        if (addonId === planId) {
          this.selectedPlans.user.splice(index, 1);
        }
      });
    }
  }

  onUserSubscribe(checkoutType: 'create' | 'update'): void {
    const planAddons = this.selectedPlans.user.map((addonId: string) => {
      return {
        itemPriceId: addonId
      };
    });

    if (checkoutType === 'create') {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          return this.chargebeeService.httpHostedPage({
            planId: this.chargebeePlans.user.planId,
            addons: planAddons,
            customerType: 'user',
            checkoutType,
          }).toPromise();
        },
        success: () => {
          this.cbInstance.closeAll();

          swal({
            title: 'Subscription successful',
            text: 'Please wait while we refresh your page.',
            type: 'success',
            allowEscapeKey: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEnterKey: false
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    }

    if (checkoutType === 'update') {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          return this.chargebeeService.httpHostedPage({
            planId: this.chargebeePlans.user.planId,
            addons: planAddons,
            customerType: 'user',
            checkoutType,
            subscription: {
              id: this.customerPlans.user.subscriptionId
            }
          }).toPromise();
        },
        success: () => {
          this.cbInstance.closeAll();

          swal({
            title: 'Subscription updated successfully',
            text: 'Please wait while we refresh your page.',
            type: 'success',
            allowEscapeKey: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEnterKey: false
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    }
  }

  onClientCheckChange(event: any): void {
    this.clientSubscriptionChanges = true;
    const planId = event.target.value as string;
    if (event.target.checked) {
      if (planId.includes('Bundle')) {
        this.selectedPlans.client = [planId];
      } else {
        this.selectedPlans.client.forEach((addonId: string, index: number) => {
          if (addonId.includes('Bundle')) {
            this.selectedPlans.client.splice(index, 1);
          }
        });
        this.selectedPlans.client.push(planId);
      }
    } else {
      this.selectedPlans.client.forEach((addonId: string, index: number) => {
        if (addonId === planId) {
          this.selectedPlans.client.splice(index, 1);
        }
      });
    }
  }

  onClientSubscribe(checkoutType: 'create' | 'update'): void {
    const planAddons = this.selectedPlans.client.map((addonId: string) => {
      return {
        itemPriceId: addonId
      };
    });

    if (checkoutType === 'create') {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          return this.chargebeeService.httpHostedPage({
            planId: this.chargebeePlans.client.planId,
            addons: planAddons,
            customerType: 'client',
            checkoutType,
          }).toPromise();
        },
        success: () => {
          this.cbInstance.closeAll();

          swal({
            title: 'Subscription successful',
            text: 'Please wait while we refresh your page.',
            type: 'success',
            allowEscapeKey: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEnterKey: false
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    }

    if (checkoutType === 'update') {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          return this.chargebeeService.httpHostedPage({
            planId: this.chargebeePlans.client.planId,
            addons: planAddons,
            customerType: 'client',
            checkoutType,
            subscription: {
              id: this.customerPlans.client.subscriptionId
            }
          }).toPromise();
        },
        success: () => {
          this.cbInstance.closeAll();

          swal({
            title: 'Subscription updated successfully',
            text: 'Please wait while we refresh your page.',
            type: 'success',
            allowEscapeKey: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEnterKey: false
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    }
  }

  initTooltip() {
    setTimeout(() => {
      let timeout;
      $('[data-tooltip]').on({
        'mouseenter': function () {
          timeout = setTimeout(() => {
              $('[data-tooltip]').removeClass('hovered');
              $(this).addClass('hovered');
            }, 350);
        },
        'mouseleave' : function () {
          clearTimeout(timeout);
          $(this).removeClass('hovered');
        }
      });
    }, 300);
  }

  toolTipData(name) {
    let tooltip_value = '';
    switch (name) {
      case 'Live Reports':
        tooltip_value = 'Unlock online Owner Reports';
        break;
      case 'Web Books':
        tooltip_value = 'Unlock online property Webbooks';
        break;
      case 'Proposals':
        tooltip_value = 'Simply beautiful proposals for your clients.';
        break;
      case 'Bundle':
        tooltip_value = 'Save on Designly Pro Bundle subscription';
        break;
      case '':
        break;
    }

    return tooltip_value;
  }

  checkUserPlans(itemPriceId: string): boolean {
    return this.selectedPlans.user.includes(itemPriceId);
  }

  checkClientPlans(itemPriceId: string): boolean {
    return this.selectedPlans.client.includes(itemPriceId);
  }

  formatPlanName(plan: string): string {
    if (plan.includes('Bundle')) {
      return 'Bundle';
    }

    if (plan.includes('Live-Reports')) {
      return 'Live Reports';
    }

    if (plan.includes('WebBooks')) {
      return 'WebBooks';
    }

    if (plan.includes('Proposals')) {
      return 'Proposals';
    }

    if (plan.includes('Pro-Builder')) {
      return 'Pro Builder';
    }

    return plan;
  }

  formatCurrency(currenyCode: string): string {
    if (currenyCode === 'GBP') {
      return '£';
    }

    return '$';
  }

  refreshPage(): void {
    window.location.reload();
  }

  cancelSubscription(customerType: 'user' | 'client'): void {
    swal({
      title: 'Are you sure you want to cancel your subscription?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
      if (result) {
        this.loading = true;
        this.chargebeeService.httpCancelSubscription(customerType);
      }
    }).catch(swal.noop);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
