import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DomainService } from '../../services/domain.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var swal: any;

@Component({
  selector: 'app-domain-login',
  templateUrl: './domain-login.component.html',
  styleUrls: ['./domain-login.component.scss']
})
export class DomainLoginComponent implements OnInit {
  loading = false;
  domain_login_link = '';

  selectedLogo = 'signify_design_login_logo.svg';

  isSignifi = true;
  isEmptyUser = false;
  domainToken = '';
  multiAgency = false;

  agentLoginForm: FormGroup;
  agencyLoginForm: FormGroup;

  agents = [];
  agencies = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private domainService: DomainService
  ) { }

  ngOnInit() {
    let loginLink = `${environment.htmlEndpoint}/login`;

    if (this.authService.isSignifi() === 'no' && this.authService.isRaywhite() === 'no') {
      loginLink = `${environment.htmlEndpoint}/domain/login`;
      this.selectedLogo = 'designly-logo-login.svg';
      this.isSignifi = false;
    }

    if (this.authService.isRaywhite() === 'yes') {
      this.selectedLogo = 'rw-logo.svg';
      this.isSignifi = false;
    }

    // tslint:disable-next-line:max-line-length
    this.domain_login_link = `https://auth.domain.com.au/v1/connect/authorize?client_id=${environment.domainClientId}&redirect_uri=${loginLink}&response_type=code&scope=openid%20profile%20api_listings_read%20api_agencies_read%20public-basic-profile`;

    this.route.queryParams.subscribe(
      (params: Params) => {
        if ( params['code'] !== undefined ) {
          this.domainService.httpLogin({
            grant_type: 'authorization_code',
            redirect_uri: loginLink,
            code: params['code']
          });
          this.loading = true;
        }
      }
    );

    this.domainService.domainLogin.subscribe(
      (auth: any) => {
        if ( typeof auth !== 'undefined' && auth.status === 'success' ) {
          this.loading = false;
          this.authService.setAuthStorage(auth);
          const $this = this;

          swal({
            title: 'Preparing this device...',
            onOpen: function () {
              swal.showLoading ();
            }
          });

          setTimeout(() => {
            window.location.href = '/campaigns';
          }, 1500);
        }
        if ( typeof auth !== 'undefined' && auth.status === 'emptyagent' ) {
          this.loading = false;
          this.isEmptyUser = true;

          this.agents = auth.data;
          this.domainToken = auth.token;
        }
        if ( typeof auth !== 'undefined' && auth.status === 'multi' ) {
          this.loading = false;
          this.multiAgency = true;

          this.agencies = auth.data;
          this.domainToken = auth.token;
        }
        if ( typeof auth !== 'undefined' && auth.status === 'billingissue' ) {
          this.loading = false;
          swal(
            'Whoops, it looks like your subscription has not been paid.',
            'Please contact 1300 650 520 to arrange payment and get back to designing.',
            'error'
          );
        }
        if ( typeof auth !== 'undefined' && auth.status === 'blocked' ) {
          this.loading = false;

          swal({
            title: '',
            type: 'error',
            html:
              // tslint:disable-next-line:max-line-length
              '<p><strong>Doesn’t look like you have an account, please click <a target="_blank" href="https://websites.formstack.com/forms/designlyregistration">here</a> to enquire</strong></p>'
          });
        }
        if ( typeof auth !== 'undefined' && auth.status === 'error' ) {
          this.loading = false;
          swal(
            'Account Issue',
            'Contact Support',
            'error'
          );
        }
      }
    );

    this.domainService.onGetAgents
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          this.agents = response.data;
        }
      }
    );

    this.agentLoginForm = new FormGroup({
      'agent_id': new FormControl('', Validators.required)
    });

    this.agencyLoginForm = new FormGroup({
      'agency_id': new FormControl('', Validators.required),
      'agent_id': new FormControl('', Validators.required)
    });
  }

  onLoginAgent() {
    if (this.domainToken === '') {
      swal(
        'Error',
        'Try again later',
        'error'
      );
    }

    const agentLoginValues = this.agentLoginForm.value;
    agentLoginValues['select_agent_token'] = this.domainToken;

    this.domainService.httpLogin(agentLoginValues);
    this.loading = true;
  }

  onLoginAgency() {
    if (this.domainToken === '') {
      swal(
        'Error',
        'Try again later',
        'error'
      );
    }

    const agencyLoginValues = this.agencyLoginForm.value;
    agencyLoginValues['select_agent_token'] = this.domainToken;
    agencyLoginValues['multi_login'] = this.domainToken;

    this.domainService.httpLogin(agencyLoginValues);
    this.loading = true;
  }

  onChangeAgency(agencyId: number) {
    this.domainService.httpGetAgents({
      agency_id: agencyId,
      token: this.domainToken
    });
    this.loading = true;
  }

}
