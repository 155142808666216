import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { MasterService } from '../../../services/master.service';
import { Master } from '../../../models/master.model';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemplateService } from '../../../services/template.service';
import { Template } from '../../../models/template.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from '../../../models/auth.model';

declare var $: any;

@Component({
  selector: 'app-template-front-shared',
  templateUrl: './template-front-shared.component.html',
  styleUrls: ['./template-front-shared.component.scss']
})
export class TemplateFrontSharedComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  menus: Menu[] = [];

  template: Template;
  templates: Template[];
  template_count = 0;
  templateFilter;
  dateFilter;
  
  term;
  
  subscription: Subscription;

  navigationType = 'link';
  navigationDefault = 'franchise';

  path = '';
  base_path = '';

  templateAddForm: FormGroup;
  templateUpdateForm: FormGroup;

  TEMPLATE_THUMB_S3_URL = '';

  @ViewChild('newTemplate', { static: false }) newTemplate: ElementRef;
  @ViewChild('newTemplateModalClose', { static: false }) newTemplateModalClose: ElementRef;
  @ViewChild('previewModal', { static: false }) previewModal: ElementRef;

  authUser: Auth;
  user = {
    ID: 21,
    client_id: 5,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  constructor(private frontService: FrontService,
              private masterService: MasterService,
              private templateService: TemplateService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.loading = true;

    this.authUser = this.frontService.authService.auth;

    this.user = {
      ID: this.authUser.user.id,
      client_id: this.authUser.client.ID,
      name: this.authUser.user.firstname + ' ' + this.authUser.user.firstname,
      photo: this.authUser.user.imageurl,
      email: this.authUser.user.email,
      mobile: this.authUser.user.mobile,
      telephone: this.authUser.user.telephone,
      fax: this.authUser.user.fax
    };

    this.menus = [
      new Menu('Shared Templates', '', [
        // new Menu('Franchise Templates', '/shared-templates/franchise'),
        new Menu('Group Templates', '/shared-templates/groups'),
        new Menu('Office Templates', '/shared-templates/office')
      ])
    ];

    this.frontService.loadMenu(this.menus);

    this.frontService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

    this.TEMPLATE_THUMB_S3_URL = this.frontService.appConfig.S3_URL + 'templates/thumbnails/';

    this.initForm();
  }

  private initForm() {
    this.templateAddForm = new FormGroup({
      'title': new FormControl('', Validators.required)
    });

    this.templateUpdateForm = new FormGroup({
      'ID': new FormControl('', Validators.required),
      'title': new FormControl('', Validators.required)
    });
  }

  onModal(item) {
    this.template = item;
    this.newTemplate.nativeElement.querySelector('#newTemplateLabel').innerHTML = 'Duplicate ' + item.title;
  }

  onSubmit() {
    if (this.templateAddForm.valid) {
      let templateAddValues = this.templateAddForm.value;

      let item = this.template;
      this.loading = true;

      delete item.ID;
      delete item.category;
      delete item.created;
      delete item.department;
      delete item.master;
      delete item.media_attribute;
      delete item.modified;

      item.user_id = this.user.ID;
      item.is_shared = 0;
      item.title = templateAddValues['title'];

      this.templateService.httpPostTemplate(item);

      setTimeout(
        () => {
          this.templateAddForm.reset();
          this.newTemplateModalClose.nativeElement.click();
        }, 500
      );
    }
  }

  onPreview($id: number) {
    this.previewModal.nativeElement.querySelector('.modal-body img').src
      = this.frontService.appConfig.S3_URL + 'templates/thumbnails/' + $id + '.jpg';
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    $('#content-wrapper').css('margin-left', '100px');
  }

  ngAfterViewInit() {
    $('#content-wrapper').css('margin-left', '290px');
  }

}
