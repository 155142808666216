<div class="page-content container-fluid">
  <div class="row">
    <div class="col-lg-12">
        <form [formGroup]="requestForm" (ngSubmit)="onSubmit()" class="form-horizontal support-form">
          <h2>Custom Request</h2>
          <div class="panel-footer">
            <div class="row col-sm-12 margin-top-30">
              <h3>Looking for more personalised templates?</h3>
              <p>To request for custom elements or templates simply fill out the form below with as much detail as possible on what type of elements or templates you are wanting and how they should work, you can also upload files to your request for example a PDF or screenshot.</p>
              <br>
              <div class="form-group row">
                <div class="col-md-6">
                  <label class="control-label" for="agencyname">Office Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="agencyname" autocomplete="off"
                    formControlName="agencyname">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  <label class="control-label" for="firstname">First Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="firstname" autocomplete="off" formControlName="firstname">
                </div>

                <div class="col-sm-3">
                  <label class="control-label" for="lastname">Last Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="lastname" autocomplete="off" formControlName="lastname">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  <label class="control-label" for="email">Contact Email<i class="text-danger">*</i></label>
                  <input type="email" class="form-control" id="email" autocomplete="off" formControlName="email">
                </div>

                <div class="col-sm-3">
                  <label class="control-label" for="phone">Contact Phone</label>
                  <input type="phone" class="form-control" id="phone" autocomplete="off" formControlName="phone">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  <label class="control-label" for="email">Element Category<i class="text-danger">*</i></label><br>
                  <input type="checkbox" formControlName="elementemail" value="Email"> Email &emsp;
                  <input type="checkbox" formControlName="elementprintable" value="Printable"> Printable &emsp;
                  <input type="checkbox" formControlName="elementsocialmedia" value="Social Media"> Social Media &emsp;
                  <input type="checkbox" formControlName="elementother" value="Other"> Other &emsp;
                </div>

                <div class="col-sm-3">
                  <label class="control-label">
                    Attachment
                  </label>
                  <input #fileAttached class="form-control" type="file" accept="image/*" id="fileAttached"
                    formControlName="fileAttached" multiple>
                  <small class="gray4-color">If you have an example of how you would like element to appear?</small>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label" for="message">Tell us what you would like your new element or template to do and how you would like it to work<i class="text-danger">*</i></label><br>
                  <textarea formControlName="message" id="message" cols="30" rows="10" class="form-control"></textarea>
                </div>
              </div>

            </div>
          </div>

          <div class="panel-footer">
              <button class="btn btn-3" [disabled]="!requestForm.valid">Submit Request</button>
          </div>

        </form>

    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>