<div class="image-library" [class.is_overflow_auto.p-5]="is_admin || is_builder">

    <div class="heading-text">
        <h2 class="text-capitalize mt-0 mb-3">
            {{ libraryType }}s
            <ng-container *ngIf="!isStockPage">
                <button type="button" (click)="addFolder()" class="btn btn-link pull-right ms-2"><i class="icon feather icon-plus"></i> <span>Create folder</span></button>
                <button type="button" (click)="addFile('image')" class="btn-admin btn-admin-primary btn-upload-font pull-right"><i class="icon feather icon-upload"></i> <span>Upload</span></button>
            </ng-container>
            <ng-container *ngIf="(selectedImage | json) != '{}'">
                <button *ngIf="isStockPage" type="button" class="btn-admin btn-admin-primary btn-upload-font pull-right me-2" (click)="onUnsplashInsert(selectedImage?.links)"><i class="icon feather icon-download"></i> <span>Insert</span></button>
                <button *ngIf="!isStockPage" type="button" class="btn-admin btn-admin-primary btn-upload-font pull-right me-2" (click)="onInsert(selectedImage)"><i class="icon feather icon-download"></i> <span>Insert</span></button>
            </ng-container>
        </h2>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <nav aria-label="breadcrumb">
                <ol class="main-breadcrumb breadcrumb">
                    <ng-container *ngFor="let breadcrumb of breadcrumbs; let first = first; let last = last;">
                        <ng-container *ngIf="last && !root">
                            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb.name }}</li>
                        </ng-container>
                        <ng-container *ngIf="!last">
                            <ng-container *ngIf="breadcrumbs.length > 2; else elseBreadcrumb">
                                <li class="breadcrumb-item">
                                    <ng-container *ngIf="first">
                                        <a href="javascript:void(0)" (click)="openFolder('user', breadcrumb?.folder)">{{ breadcrumb.name }}</a>
                                    </ng-container>
                                    <ng-container *ngIf="!first">
                                        <a href="javascript:void(0)" (click)="openFolder(breadcrumb.type, breadcrumb?.folder)">{{ breadcrumb.name }}</a>
                                    </ng-container>
                                </li>
                            </ng-container>
                            <ng-template #elseBreadcrumb>
                                <li class="breadcrumb-item">
                                    <a href="javascript:void(0)" (click)="openFolder('user', breadcrumb?.folder)">{{ breadcrumb.name }}</a>
                                </li>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </ol>
            </nav>
        </div>

        <div class="col-md-12">
            <div class="search">
                <div class="input-group">
                    <ng-container *ngIf="isStockPage">
                        <input type="text" [formControl]="stockSearchText" class="form-control" placeholder="Search..." id="stockSearchText" />
                    </ng-container>
                    <ng-container *ngIf="!isStockPage">
                        <input type="text" class="form-control" placeholder="Search" [(ngModel)]="term">
                    </ng-container>
                    <span class="input-group-text"><i class="icon feather icon-search"></i></span>
                </div>
            </div>
        </div>

        <div *ngIf="!root" class="col-md-12 mt-3">
            <button type="button" class="btn btn-back" (click)="onGoBack()">
                <span class="icon feather icon-arrow-left"></span>
                <span class="title">Go Back</span>
            </button>    
        </div>
    </div>
    
    <div class="image-library-content-wrapper" [class.stock-images]="isStockPage">
        <div *ngIf="!isStockPage" class="folder-list clearfix d-flex flex-wrap row">
            <ng-container *ngIf="!root">
                <div class="col-md-3 custom-folder" *ngFor="let folder of folders">
                    <div class="card">
                        <a class="link" href="javascript:void(0)" (click)="openFolder(folderType, folder)"></a>
                        <div class="card-body container-fluid">
                            <span class="icon feather icon-folder"></span>
                            <span class="title">{{ folder?.name }}</span>
                            <div class="btn-group folder-action">
                                <button aria-expanded="false" class="btn btn-sm" data-bs-toggle="dropdown" type="button">
                                    <i class="icon feather icon-more-horizontal"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="renameFolder(folder)">Rename</a>
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="onRemoveFolder(folder)">Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="root">
                <div class="col-md-3">
                    <a href="javascript:void(0)" (click)="openFolder('group')">
                        <div class="card">
                            <div class="card-body container-fluid">
                                <span class="icon feather icon-globe"></span>
                                <span class="title text-capitalize">Group {{libraryType}}s</span>
                            </div>
                        </div>
                    </a>
                </div>
                
                <div class="col-md-3">
                    <a href="javascript:void(0)" (click)="openFolder('client')">
                        <div class="card">
                            <div class="card-body container-fluid">
                                <span class="icon feather icon-home"></span>
                                <span class="title text-capitalize">Office {{libraryType}}s</span>
                            </div>
                        </div>
                    </a>
                </div>
        
                <div *ngIf="authUser?.is_admin" class="col-md-3">
                    <a href="javascript:void(0)" (click)="openFolder('admin')">
                        <div class="card">
                            <div class="card-body container-fluid">
                                <span class="icon feather icon-lock"></span>
                                <span class="title text-capitalize">Admin {{libraryType}}s</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-md-3">
                    <a href="javascript:void(0)" (click)="openFolder('stock')">
                        <div class="card">
                            <div class="card-body container-fluid">
                                <span class="icon feather icon-camera"></span>
                                <span class="title">Stock Images</span>
                            </div>
                        </div>
                    </a>
                </div>
                
                <div class="col-md-3 custom-folder" *ngFor="let folder of folders">
                    <div class="card">
                        <a class="link" href="javascript:void(0)" (click)="openFolder('user', folder)"></a>
                        <div class="card-body container-fluid">
                            <span class="icon feather icon-folder"></span>
                            <span class="title">{{ folder?.name }}</span>
                            <div class="btn-group folder-action">
                                <button aria-expanded="false" class="btn btn-sm" data-bs-toggle="dropdown" type="button">
                                    <i class="icon feather icon-more-horizontal"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="renameFolder(folder)">Rename</a>
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="onRemoveFolder(folder)">Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    
        <ng-container *ngIf="isStockPage">
            <div class="unsplash-list" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" [infiniteScrollContainer]="scrollContainer" [fromRoot]="scrollFromRoot" (scrolled)="onScroll()">
                <div class="image-list row">
                    <ng-container *ngFor="let unsplashImage of unsplashImages">
                        <div class="col-md-3">
                            <div class="card border-white">
                                <div class="card-body">
                                    <div class="card-image" (click)="onImageClick($event, unsplashImage, 'unsplash')" (dblclick)="onUnsplashInsert(unsplashImage?.links)" [ngClass]="is_builder ? 'photo-wrapper-custom' : ''">
                                        <div class="btn-group btn-action">
                                            <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                                            <div class="dropdown-menu">
                                              <a *ngIf="is_builder" class="dropdown-item" href="javascript:void(0)" (click)="onUnsplashInsert(unsplashImage?.links)">Insert</a>
                                              <a class="dropdown-item" href="javascript:void(0)" (click)="viewUnsplash(unsplashImage?.links?.html)">View</a>
                                            </div>
                                          </div>
                                        <img src="{{ unsplashImage?.urls?.small }}" class="img-fluid" />
                                    </div>
                                    <div class="card-title mb-0">
                                        <strong>Photo by <a href="{{ unsplashImage?.user?.links?.html }}?utm_source=Designly&utm_medium=referral" target="_blank">{{ unsplashImage?.user?.name }}</a> on <a href="https://unsplash.com/?utm_source=Designly&utm_medium=referral" target="_blank">Unsplash</a></strong>
                                    </div>
                                </div>
                              </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="unsplashImages.length === 0">
                        <div class="col-md-12">
                            <p>No stock images found</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isStockPage">
            <div class="images-list" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" [infiniteScrollContainer]="scrollContainer" [fromRoot]="scrollFromRoot" (scrolled)="onScrollImage()">
                <div class="image-list row">
                    <div class="col-md-3" *ngFor="let image of images | library: term; let i = index">
                        <div class="card border-white" [ngClass]="{'is-selected': moveFile?.uuid === image?.uuid}">
                            <div class="card-body">
                                <div class="card-image" (click)="onImageClick($event, image, 'image')" (dblclick)="onInsert(image)" [ngClass]="is_builder ? 'photo-wrapper-custom' : ''">
                                    <div class="btn-group btn-action">
                                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                                        <div class="dropdown-menu">
                                          <a *ngIf="is_builder" class="dropdown-item" href="javascript:void(0)" (click)="onInsert(image)">Insert</a>
                                          <a class="dropdown-item" href="javascript:void(0)" (click)="showFile(image)">View</a>
                                          <a class="dropdown-item" href="javascript:void(0)" (click)="downloadImage(image)">Download</a>
                                          <a class="dropdown-item" href="javascript:void(0)" (click)="copyLink(image)">Copy link to clipboard</a>
                                          <a *ngIf="folderType !== 'stock'" class="dropdown-item" href="javascript:void(0)" (click)="onMoveFile(image)" data-bs-target="#moveFile" data-bs-toggle="modal">{{ checkImageFileType(image.mimetype) ? 'Move image' : 'Move File' }}</a>
                                          <a class="dropdown-item text-danger" href="javascript:void(0)" (click)="onDelete(i, image)">Delete</a>
                                        </div>
                                      </div>
                                    <ng-container  *ngIf="checkImageFileType(image.mimetype)">
                                        <img src="{{ getLink(image) }}" class="img-fluid" />
                                    </ng-container>
                                    <ng-container  *ngIf="!checkImageFileType(image.mimetype)">
                                        <span class="file-placeholder">{{ getFileExtension(image?.name) }}</span>
                                    </ng-container>
                                </div>
                                <div class="card-title mb-0">
                                    {{ image?.name }}
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div #displayUpload id="upload" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="uploadLabel">
        <div class="modal-dialog modal-center" role="document">
          <div class="modal-content">
              <div class="modal-body text-center p-0">
                <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
                    <button type="button" class="close close-outside" (click)="closeModal('image')"><span aria-hidden="true">&times;</span></button>
                    <h3>Upload Files Here</h3>
                    <div class="form-horizontal">
                        <ng-container *ngIf="folderType === 'admin' || is_admin; else notAdmin">
                            <div class="upload-container">
                                <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" multiple (change)="fileBrowseHandler($event)" style="display: none" /></label>
                            </div>
                            <p class="mb-4">JPG, PNG, GIF, SVG, PDF, DOC, TXT, HTML, CSS, FONT</p>
                        </ng-container>
                        <ng-template #notAdmin>
                            <ng-container *ngIf="libraryType !== 'file'">
                                <div class="upload-container">
                                    <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" multiple (change)="fileBrowseHandler($event)" style="display: none" accept="image/*" /></label>
                                </div>
                                <p class="mb-4">JPG, PNG, GIF, SVG</p>
                            </ng-container>
                            <ng-container *ngIf="libraryType === 'file'">
                                <div class="upload-container">
                                    <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" multiple (change)="fileBrowseHandler($event)" style="display: none" /></label>
                                </div>
                                <p class="mb-4">JPG, PNG, GIF, SVG, PDF, DOC, TXT, HTML, CSS, FONT</p>
                            </ng-container>
                        </ng-template>
                        <p class="mb-4">Please note SVG formatted only supported in Designly Pro.</p>
                    </div>
                </div>
    
                <div *ngIf="files.length > 0" class="file-lists">
                    <p class="title">Uploading {{ files.length }} Files:</p>
                </div>
                <div class="filesContents">
                    <div *ngIf="files.length > 0" class="file-lists">
                        <div class="d-flex gap-1 flex-wrap">
                            <div *ngFor="let file of files; let i = index" class="file-item file-item w-100">
                                <div class="d-flex gap-2 text-left justify-content-between">
                                    <i class="icon feather" [ngClass]="{ 'icon-image': checkImageFileType(file?.type), 'icon-file' : !checkImageFileType(file?.type)  }"></i> 
                                    <div class="flex-fill">
                                        <div class="d-flex justify-content-between">
                                            <div class="file-info">
                                                {{ file?.name }}
                                                <span class="file-size">{{ formatBytes(file?.size) }}</span>
                                            </div>
                                            <div class="file-action">
                                                <button *ngIf="file?.error && checkImageFileType(file?.type) && folderType !== 'file'" class="btn btn-link btn-sm btn-overwrite" type="button" (click)="onOverwriteFile(i)" >overwrite</button>
                                                <button *ngIf="file?.completed" type="button" class="btn btn-link btn-sm pe-none btn-complete"><i class="icon feather icon-check"></i></button>
                                                <button *ngIf="file?.error" type="button" class="btn btn-link btn-sm btn-error" (click)="onDeleteFile(i)"><i class="icon feather icon-x"></i></button>
                                            </div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" [ngClass]="{ 'progress-bar-striped active': !file?.completed && !file?.error }" [class.bg-success]="file?.completed" [class.bg-danger]="file?.error" ></div>
                                        </div>    
                                        <div *ngIf="file?.error" class="text-danger">{{ getErrorMessage(file?.error) }}</div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </div>

    <div #newFolder id="newFolder" class="modal fade edit-box" role="dialog" aria-labelledby="newFolderLabel">
        <div class="modal-dialog modal-center" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close close-outside" (click)="closeModal('folder')" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title" id="newFolderLabel">{{ renameFolderId ? 'Rename folder' : 'Create new folder' }}</h4>
            </div>
            <form (ngSubmit)="onCreateFolder()" class="form-horizontal">
              <div class="modal-body">
                <div class="form-group d-flex">
                  <label for="newFolderName" class="col-sm-2">Name</label>
                  <div class="col-sm-8">
                    <input #itemFolder type="text" class="form-control" id="newFolderName" autocomplete="off" autofocus />
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-start">
                <button type="submit" class="btn-admin btn-admin-primary me-2">Save</button>
                <button type="button" class="btn-admin" (click)="closeModal('folder')">Cancel</button>
              </div>
            </form>
          </div>
        </div>
    </div>

    <div #moveFileModal id="moveFile" class="modal fade edit-box" role="dialog" aria-labelledby="moveFileLabel">
        <div class="modal-dialog modal-center" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close close-outside" (click)="onCancelMoveFile()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title" id="newFolderLabel">Move File</h4>
            </div>
            <form (ngSubmit)="onMoveFileSubmit()" class="form-horizontal">
              <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <ng-container *ngIf="moveFileFolderRoot">
                                    <li class="breadcrumb-item active" aria-current="page">My Images</li>
                                    <li class="breadcrumb-item" aria-current="page"></li>
                                </ng-container>
                                <ng-container *ngFor="let breadcrumb of folderBreadcrumbs; let first = first; let last = last;">
                                    <ng-container *ngIf="last && !moveFileFolderRoot">
                                        <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb.name }}</li>
                                    </ng-container>
                                    <ng-container *ngIf="!last">
                                        <ng-container *ngIf="folderBreadcrumbs.length > 2; else elseBreadcrumb">
                                            <li class="breadcrumb-item">
                                                <ng-container *ngIf="first">
                                                    <a href="javascript:void(0)" (click)="openMoveFileFolder('user', breadcrumb?.folder)">{{ breadcrumb.name }}</a>
                                                </ng-container>
                                                <ng-container *ngIf="!first">
                                                    <a href="javascript:void(0)" (click)="openMoveFileFolder(breadcrumb.type, breadcrumb?.folder)">{{ breadcrumb.name }}</a>
                                                </ng-container>
                                            </li>
                                        </ng-container>
                                        <ng-template #elseBreadcrumb>
                                            <li class="breadcrumb-item">
                                                <a href="javascript:void(0)" (click)="openMoveFileFolder('user', breadcrumb?.folder)">{{ breadcrumb.name }}</a>
                                            </li>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ol>
                        </nav>
                    </div>
                    <div class="folderList gx-0">
                        <div *ngIf="!moveFileFolderRoot" class="col-md-12 mt-2 mb-3">
                            <button type="button" class="btn btn-back" (click)="onGoBackMoveFileFolder()">
                                <span class="icon feather icon-arrow-left"></span>
                                <span class="title">Go Back</span>
                            </button>    
                        </div>    
                        <ng-container *ngIf="!moveFileFolderRoot">
                            <div class="col-md-12 custom-folder folder-item" *ngFor="let folder of moveFileFolders">
                                <div class="card">
                                    <a class="link" href="javascript:void(0)" (click)="openMoveFileFolder(moveFileFolderType, folder)"></a>
                                    <div class="card-body container-fluid">
                                        <span class="icon feather icon-folder"></span>
                                        <span class="title">{{ folder?.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="moveFileFolderRoot">
                            <div *ngIf="authUser?.is_master" class="col-md-12 custom-folder folder-item">
                                <div class="card">
                                    <a class="link" href="javascript:void(0)" (click)="openMoveFileFolder('group')"></a>
                                    <div class="card-body container-fluid">
                                        <span class="icon feather icon-globe"></span>
                                        <span class="title">Group Images</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 custom-folder folder-item">
                                <div class="card">
                                    <a class="link" href="javascript:void(0)" (click)="openMoveFileFolder('client')"></a>
                                    <div class="card-body container-fluid">
                                        <span class="icon feather icon-home"></span>
                                        <span class="title">Office Images</span>
                                    </div>
                                </div>
                            </div>
                    
                            <div *ngIf="authUser?.is_admin" class="col-md-12 custom-folder folder-item">
                                <div class="card">
                                    <a class="link" href="javascript:void(0)" (click)="openMoveFileFolder('admin')"></a>
                                    <div class="card-body container-fluid">
                                        <span class="icon feather icon-lock"></span>
                                        <span class="title">Admin Images</span>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="moveFileFolderRoot">
                                <div class="col-md-12 custom-folder folder-item" *ngFor="let folder of moveFileFolders">
                                    <div class="card">
                                        <a class="link" href="javascript:void(0)" (click)="openMoveFileFolder('user', folder)"></a>
                                        <div class="card-body container-fluid">
                                            <span class="icon feather icon-folder"></span>
                                            <span class="title">{{ folder?.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
              </div>
              <div class="modal-footer justify-content-start">
                <button type="submit" class="btn-admin btn-admin-primary me-2">Move Here</button>
                <button type="button" class="btn-admin" (click)="onCancelMoveFile()">Cancel</button>
              </div>
            </form>
          </div>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>