import {HttpParams,  HttpClient,   HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';

@Injectable()
export class DomainService {
  domainLogin = new Subject<any>();

  onGetDomainListings = new Subject();
  onGetDomainListing = new Subject();
  onLoginAs = new Subject();
  onRefresh = new Subject();
  onGetAgencies = new Subject();
  onGetAgents = new Subject();
  onGetPAgents = new Subject();

  constructor(
      @Inject(APP_CONFIG) private appConfig,
      private httpClient: HttpClient,
      private authService: AuthService
  ) {}

  httpLogin(data: any) {
    const req = new HttpRequest(
        'POST',
        this.appConfig.API_ENDPOINT + '/domain/login',
        data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
        if (response.body) {
            if ( typeof response.body.status !== 'undefined') {
                return response.body;
            }
        }

        return [];
        }
    )
    .subscribe(
        (response: any) => {
            this.domainLogin.next(response);
        },
        (response: any) => {
            this.domainLogin.next(response.error);
        }
    );
  }

  httpGetListings(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/domain/listings',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetDomainListings.next(response);
      },
      (response: any) => {
        this.onGetDomainListings.next(response.error);
      }
    );
  }

  httpGetListing(id: number, params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/domain/listing/' + id,
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetDomainListing.next(response);
      },
      (response: any) => {
        this.onGetDomainListing.next(response.error);
      }
    );
  }

  httpLoginAs(data: any) {
    const req = new HttpRequest(
        'POST',
        `${this.appConfig.API_ENDPOINT}/domain/login-as`,
        data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
        if (response.body) {
            if ( typeof response.body.status !== 'undefined') {
                return response.body;
            }
        }

        return [];
        }
    )
    .subscribe(
      (response: any) => {
          this.onLoginAs.next(response);
      },
      (response: any) => {
          this.onLoginAs.next(response.error);
      }
    );
  }

  httpRefreshClient(id: number) {
    const req = new HttpRequest(
      'GET',
      `${this.appConfig.API_ENDPOINT}/domain/refresh/${id}/ghost/webadmin`
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onRefresh.next(response);
      },
      (response: any) => {
        this.onRefresh.next(response.error);
      }
    );
  }

  httpGetAgencies() {
    const req = new HttpRequest(
      'GET',
      `${this.appConfig.API_ENDPOINT}/domain/agencies`
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetAgencies.next(response);
      },
      (response: any) => {
        this.onGetAgencies.next(response.error);
      }
    );
  }

  httpGetAgents(data: any) {
    const req = new HttpRequest(
        'POST',
        this.appConfig.API_ENDPOINT + '/domain/agents',
        data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
        if (response.body) {
            if ( typeof response.body.status !== 'undefined') {
                return response.body;
            }
        }

        return [];
        }
    )
    .subscribe(
        (response: any) => {
            this.onGetAgents.next(response);
        },
        (response: any) => {
            this.onGetAgents.next(response.error);
        }
    );
  }

  httpGetPAgents(data: any) {
    const req = new HttpRequest(
        'POST',
        this.appConfig.API_ENDPOINT + '/domain/pagents',
        data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
        if (response.body) {
            if ( typeof response.body.status !== 'undefined') {
                return response.body;
            }
        }

        return [];
        }
    )
    .subscribe(
        (response: any) => {
            this.onGetPAgents.next(response);
        },
        (response: any) => {
            this.onGetPAgents.next(response.error);
        }
    );
  }
}
