import { Component, OnInit } from '@angular/core';
import { Group } from '../../models/group.model';
import { GroupService } from '../../services/group.service';
import { Menu } from '../../models/menu.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  menus: Menu[] = [];

  constructor(private adminService: AdminService,
              private groupService: GroupService
  ) { }

  ngOnInit() {
    this.adminService.loadMenu(this.menus);
  }

}
