import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FaqCategory } from '../../../models/faqcategory.model';
import { FaqCategoryService } from '../../../services/faqcategory.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-faq-category-edit',
  templateUrl: './faq-category-edit.component.html',
  styleUrls: ['./faq-category-edit.component.scss']
})
export class FaqCategoryEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  UID: string;
  loading = false;
  editMode = false;
  backUrl = '../';

  subscription: Subscription;
  subscriptionResponse: Subscription;

  faqCategory: FaqCategory;
  faqCategoryForm: FormGroup;

  constructor(
    private faqCategoryService: FaqCategoryService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const faqsCategoryId = this.route.snapshot.paramMap.get('id');

    if (faqsCategoryId) {
      this.UID = faqsCategoryId;
      this.editMode = true;
      this.backUrl = '../../';

      this.faqCategoryService.httpGetFaqsCategory(faqsCategoryId);
    }

    this.faqCategoryService.onGetFaqsCategory
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.faqCategory = response.data;
              this.initFormEdit();
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqCategoryService.onPostFaqsCategory
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.router.navigate([this.backUrl], {relativeTo: this.route});
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqCategoryService.onPutFaqsCategory
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              swal('FAQ Category Updated', '', 'success');
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.faqCategoryForm = new FormGroup({
      'title': new FormControl('', Validators.required),
    });
  }

  private initFormEdit() {
    this.faqCategoryForm.patchValue({
    'title': this.faqCategory.title
    });
  }

  onSubmit() {
    const faqCategoryFormValues = this.faqCategoryForm.value;
    this.loading = true;

    if (this.editMode) {
      this.faqCategoryService.httpPutFaqsCategory(this.UID, faqCategoryFormValues);
    } else {
      this.faqCategoryService.httpPostFaqsCategory(faqCategoryFormValues);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
