import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

import swal from 'sweetalert2';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})
export class EmailLoginComponent implements OnInit {
  loading = true;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');
    const expiry = this.route.snapshot.queryParamMap.get('expiry');
    const templateId = this.route.snapshot.queryParamMap.get('template_id');

    const auth = {
      token: token,
      expires: expiry
    };

    if (token) {
      this.authService.setAuthStorage(auth, false, false, true);

      swal({
        title: 'Preparing this device...',
        timer: 3000,
        onOpen: function () {
          swal.showLoading ();
        }
      }).then(
        function () {},
        // handling the promise rejection
        function (dismiss) {
          if (dismiss === 'timer') {
            if (templateId) {
              window.location.href = `/templates/${templateId}/build?internal=create`;
            } else {
              window.location.href = '/templates';
            }
          }
        }
      );
    }
  }

}
