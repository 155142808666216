<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Blocks - {{ category_name }}</h2>

    <button routerLink="new" type="button" class="btn-admin"><i class="icon feather icon-plus"></i><span>Add Block</span></button>
  </div>
</div>
<div id="box" class="row">
  <div class="groupBox col-md-4">
    <form [formGroup]="selectGroupForm">
      <label for="sgf_group_id">Filter by Group</label>
      <ng-select id="sgf_group_id" formControlName="group_id" [(ngModel)]="group_id" (change)="onChangeGroup($event)">
        <ng-option value="" disabled>Select group</ng-option>
        <ng-option value="0">All</ng-option>
        <ng-option *ngFor="let group of groups" [value]="group.ID">{{ group.title }}</ng-option>
      </ng-select>
    </form>
  </div>

  <div class="col-md-12">
    <div class="table-box">
      <table id="datatable-blocks" class="datatable-elements table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width: 100%">
        <thead>
          <tr>
            <th>Title</th>
            <th>Group</th>
            <th>Sort</th>
            <th>Date Added</th>
            <th>Date Modified</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let block of blocks">
            <td>{{ block?.title }}</td>
            <td>{{ block?.group ? block?.group.title : 'All' }}</td>
            <td>{{ block?.sort }}</td>
            <td>{{ block?.created | date: 'dd.MM.yy' }}</td>
            <td>{{ block?.modified | date: 'dd.MM.yy' }}</td>
            <td style="text-align: right">
              <button routerLink="{{ block?.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
