<div class="row">
    <div class="col-md-12 heading">
      <h2 class="pull-left">{{ !editMode ? 'Add Icon': 'Icon' }}</h2>
  
      <button [routerLink]="backUrl" type="button" class="btn-admin">
          <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
  </div>
  <div class="edit-box">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Details</h4>
          <form class="form-horizontal">
              <div class="form-group">
                <label for="category_id" class="col-sm-2">Category</label>
                <div class="col-sm-5">
                  <select class="form-control" id="category_id" >
                    <option value="" disabled>Select a Category</option>
                    <option *ngIf="icon?.category_type == 'Primary'" selected>Primary</option>
                    <option *ngIf="icon?.category_type !== 'Primary'">Primary</option>
                    <option *ngIf="icon?.category_type == 'Secondary'" selected>Secondary</option>
                    <option *ngIf="icon?.category_type !== 'Secondary'">Secondary</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="type_id" class="col-sm-2">Type</label>
                <div class="col-sm-5">
                  <select class="form-control" id="type_id" >
                    <option value="" disabled>Select a Type</option>
                    <option *ngIf="icon?.icon_type == 'Bed'" selected>Bed</option>
                    <option *ngIf="icon?.icon_type !== 'Bed'">Bed</option>
                    <option *ngIf="icon?.icon_type == 'Bath'" selected>Bath</option>
                    <option *ngIf="icon?.icon_type !== 'Bath'">Bath</option>
                    <option *ngIf="icon?.icon_type == 'Car'" selected>Car</option>
                    <option *ngIf="icon?.icon_type !== 'Car'">Car</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="type_id" class="col-sm-2">Sort Order</label>
                <div class="col-sm-5">
                  <input type="text" class="form-control" id="sort_order" value="{{ icon?.sort_order }}">
                </div>
              </div>
              <div class="form-group">
                <label for="upload_id" class="col-sm-2">Preview</label>
                <div class="col-sm-5">
                    <div *ngIf="icon?.category_type == 'Primary'" class="icon-holder">
                      <img class="icon-img" src="{{ icon?.icon_url }}">
                    </div>
                    <div *ngIf="icon?.category_type == 'Secondary'" class="icon-holder-dark">
                      <img class="icon-img" src="{{ icon?.icon_url }}">
                    </div>
                    <div *ngIf="!icon?.ID">
                      No preview available
                    </div>
                </div>
              </div>
              <div class="form-group">
                <label for="upload_id" class="col-sm-2">Upload Icon</label>
                <div class="col-sm-5">
                    <input #itemFile class="form-control" type="file" accept="image/*" >
                </div>
              </div>
              <div class="form-group">
                <label for="upload_id" class="col-sm-2"></label>
                <div class="col-sm-5">
                    <button class="btn btn-success" (click)="onUpload()">Save</button>
                    <!-- <p style="color: blue">Form automatically saves after selecting image</p> -->
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>