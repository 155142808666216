import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { Auth } from '../../models/auth.model';
import { FrontService } from '../front.service';

@Component({
  selector: 'app-front-content-marketplace',
  templateUrl: './front-content-marketplace.component.html',
  styleUrls: ['./front-content-marketplace.component.scss']
})
export class FrontContentMarketplaceComponent implements OnInit {
  loading = false;
  authUser: Auth;

  menus: Menu[] = [];
  constructor(
    private frontService: FrontService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.frontService.fullPageContent = true;
    this.frontService.loadMenu(this.menus);
  }

}