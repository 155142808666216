import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { SigninComponent } from './signin/signin.component';
import { LoginComponent } from './login/login.component';
import { AuthRouteModule } from './auth.route.module';
import { VersionComponent } from './version/version.component';

import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceStatusComponent } from './maintenance/maintenance-status/maintenance-status.component';
import { VaultreLoginComponent } from './vaultre-login/vaultre-login.component';
import { DomainLoginComponent } from './domain-login/domain-login.component';
import { CentralLoginComponent } from './central-login/central-login.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { IdashboardLoginComponent } from './idashboard-login/idashboard-login.component';

@NgModule({
  declarations: [
    SigninComponent,
    LoginComponent,
    VersionComponent,
    AutoLoginComponent,
    MaintenanceComponent,
    MaintenanceStatusComponent,
    VaultreLoginComponent,
    DomainLoginComponent,
    CentralLoginComponent,
    DomainLoginComponent,
    EmailLoginComponent,
    IdashboardLoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRouteModule,
    LoadingModule.forRoot({
      // animationType: ANIMATION_TYPES.rectangleBounce,
      animationType: ANIMATION_TYPES.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      backdropBorderRadius: '3px',
      primaryColour: 'transparent',
      secondaryColour: '#fff',
      // tertiaryColour: '#FFF'
    }),
  ]
})
export class AuthModule { }
