import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from '../../app.config';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  selectedLogo = '';

  constructor(@Inject(APP_CONFIG) private appConfig,
              public authService: AuthService,
              private router: Router) { }

  ngOnInit() {
    if ( this.authService.getApp() === 'MyDesign3') {
      this.selectedLogo = 'mydesign-logo.svg';
    } else {
      this.selectedLogo = 'designly-logo-inverted.svg';
    }
  }

}
