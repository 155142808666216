import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Faq } from '../../../models/faq.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FaqService } from '../../../services/faq.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FaqCategory } from '../../../models/faqcategory.model';
import { FaqCategoryService } from '../../../services/faqcategory.service';
import swal from 'sweetalert2';
import { GroupService } from '../../../services/group.service';
import { Group } from '../../../models/group.model';

declare var CKEDITOR: any;

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss']
})
export class FaqEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  UID: string;
  loading = false;
  editMode = false;
  backUrl = '../';

  faq: Faq;
  faqsCategories: FaqCategory[] = [];
  groups: Group[] = [];

  faqForm: FormGroup;

  constructor(
    private faqService: FaqService,
    private route: ActivatedRoute,
    private router: Router,
    private faqCategoryService: FaqCategoryService,
    private groupService: GroupService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.groupService.httpGetGroupsNew();

    const faqId = this.route.snapshot.paramMap.get('id');

    if (faqId) {
      this.UID = faqId;
      this.editMode = true;
      this.backUrl = '../../';
    }

    this.groupService.onGetGroups
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.groups = response.data;
              this.faqCategoryService.httpGetFaqsCategories();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqCategoryService.onGetFaqsCategories
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.faqsCategories = response.data;

              if (faqId) {
                this.faqService.httpGetFaq(faqId);
                this.loading = true;
              }
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqService.onGetFaq
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.faq = response.data;
              this.initFormEdit();
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqService.onPostFaq
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.router.navigate([this.backUrl], {relativeTo: this.route});
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqService.onPutFaq
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              swal('FAQ Updated', '', 'success');
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.faqForm = new FormGroup({
      group_id: new FormControl([], Validators.required),
      exclude_group_id: new FormControl([], Validators.required),
      faqs_category_id: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      provider: new FormControl('', Validators.required),
      description: new FormControl('')
    });

    if (!this.editMode) {
      this.enableCkEditor();
    }
  }

  private initFormEdit() {
    this.faqForm.patchValue({
      faqs_category_id: this.faq.category.UID,
      title: this.faq.title,
      provider: this.faq.provider,
      category: this.faq.category,
      description: this.faq.description
    });

    if (this.faq.group_whitelist === 'all') {
      this.faqForm.patchValue({
        group_id: ['all']
      });
    } else {
      const groupUids = this.faq.groups.map((group: any) => group.group.UID);

      this.faqForm.patchValue({
        group_id: groupUids
      });
    }

    if (this.faq.excluded_groups) {
      const groupUids = this.faq.excluded_groups.map((group: any) => group.group.UID);

      this.faqForm.patchValue({
        exclude_group_id: groupUids
      });
    }

    this.enableCkEditor();
  }

  onSubmit() {
    const faqFormValues = this.faqForm.value;
    faqFormValues['description'] = CKEDITOR.instances.textarea.getData();

    if (this.editMode) {
      this.faqService.httpPutFaq(this.UID, faqFormValues);
    } else {
      this.faqService.httpPostFaq(faqFormValues);
    }

    this.loading = true;
  }

  enableCkEditor() {
    const toolbar = [
      { name: 'clipboard', items: [ 'Undo', 'Redo', '-' ] },
      { name: 'basicstyles', groups: [ 'basicstyles' ], items: [ 'Bold', 'Italic', 'Underline', '-' ] },
      // tslint:disable-next-line:max-line-length
      { name: 'paragraph', groups: [ 'list', 'align', 'justify' ], items: [ 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
      { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'links', items : [ 'Link', 'Unlink'] },
      { name: 'insert', items: [ 'Image', 'Table' ] }
    ];
    CKEDITOR.replace('textarea', {
      toolbar: toolbar
    });
  }

  ngOnDestroy() {
    if (CKEDITOR && typeof CKEDITOR.instances !== 'undefined') {
      for (const name in CKEDITOR.instances) {
        if (name) {
          CKEDITOR.instances[name].destroy(true);
        }
      }
    }

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
