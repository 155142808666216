<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Changelog': 'Changelog' }}</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="changelogForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="headline" class="col-sm-2">Changelog message</label>
            <div class="col-sm-6">
              <input formControlName="headline" type="text" class="form-control" id="headline" autocomplete="off" value="A recent update has been made to Designly">
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-6">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Content</label>
            <div class="col-sm-6">
              <textarea formControlName="content" class="form-control" id="content" autocomplete="off"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-6">
              <select formControlName="provider" class="form-control" id="provider">
                <option value="" disabled>Select a Provider</option>
                <option value="all">All Providers</option>
                <option value="mydesktop">MyDesktop</option>
                <option value="vaultre">VaultRE</option>
                <option value="idashboard">iDashboard</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="group_id" class="col-sm-2">Group Name</label>
            <div class="col-sm-5">
              <select formControlName="group_id" class="form-control" id="group_id" >
                <option value="" disabled>Select a Group</option>
                <option value="0">All</option>
                <option *ngFor="let mValue of groups" value="{{ mValue.ID }}">{{ mValue.code }} {{ mValue.title }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="is_hidden" class="col-sm-2">Visibility</label>
            <div class="col-sm-5">
              <select formControlName="is_hidden" class="form-control" id="is_hidden" >
                <option value="" disabled>Select Visibility</option>
                <option value="0">Visible</option>
                <option value="1">Hidden</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="is_button_hidden" class="col-sm-2">View Details Button Visibility</label>
            <div class="col-sm-5">
              <select formControlName="is_button_hidden" class="form-control" id="is_button_hidden">
                <option value="" disabled>Select View Details Button Visibility</option>
                <option value="0">Visible</option>
                <option value="1">Hidden</option>
              </select>
            </div>
          </div>
          <div *ngIf="editMode" class="form-group">
            <label for="reset_to_users" class="col-sm-2">Reset Notification to Users</label>
            <div class="col-sm-5">
              <select formControlName="reset_to_users" class="form-control" id="reset_to_users">
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!changelogForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>