import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from '../app.config';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  onGetAll = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetAll(params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/products`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetAll.next(response);
      },
      (response: any) => {
        this.onGetAll.next(response.error);
      }
    );
  }
}
