<div class="container-login" [class.hidden]="!isLoginChecked">
  <div class="login-wrap">
    <div class="_login">
      <div class="logo-holder">
        <img *ngIf="appConfig.SKIN !== 'raywhite'" src="/assets/img/{{ selectedLogo }}" class="logo" [class.designly-logo]="!isRh && appConfig.SKIN === 'designly'">
        <img *ngIf="appConfig.SKIN === 'raywhite'" src="/assets/img/{{ selectedLogo }}" class="rw-logo">
        <!-- <img *ngIf="appConfig.SKIN === 'raywhite'" src="/assets/img/designly-logo-login.svg"> -->
      </div>
      <h4>
        <ng-container *ngIf="appConfig.SKIN === 'prd'">Sign into your PRD Designly Account</ng-container>
        <ng-container *ngIf="appConfig.SKIN === 'professionals'">Sign into your Professionals Marketing Account</ng-container>
        <ng-container *ngIf="appConfig.SKIN !== 'prd' && appConfig.SKIN !== 'professionals'">
          <ng-container *ngIf="isRh">
            Sign into your DesignPlus Account
          </ng-container>
          <ng-container *ngIf="!isRh">
            Sign into your Designly Account
          </ng-container>
        </ng-container>
      </h4>
      <p *ngIf="isRh" class="sub-heading">In order to utilise Designplus we need to connect your Compassplus account</p>
      <p *ngIf="!isRh && appConfig.SKIN === 'designly'" class="sub-heading">In order to utilise Designly we need to connect your Vault account</p>
      <p *ngIf="appConfig.SKIN === 'prd'" class="sub-heading">In order to utilise Designly we need to connect your Vault account</p>
      <p *ngIf="appConfig.SKIN === 'professionals'" class="sub-heading">In order to utilise Professionals Marketing we need to connect your Vault account</p>
      <p *ngIf="appConfig.SKIN === 'raywhite'" class="sub-heading">In order to utilise Designly we need to connect your Vault account</p>
      <a *ngIf="!vaultLoginSuccess && vaultLoginMessage !== ''" href="{{ login_link }}" class="btn btn-primary theme-btn-default signin-btn">Sign In</a>
      <p *ngIf="!vaultLoginSuccess && vaultLoginMessage === ''" class="text-danger"><strong>Login failed.</strong> Please try again later.</p>
      <p class="sub-heading problem-link-holder"><a class="login-link" target="_blank" href="https://websites.formstack.com/forms/designly_support_form">Problems signing in?</a></p>
      <hr/>
      <ng-container *ngIf="previousAuths.length > 0">
        <div class="previously-section">
          <div class="previously">
            <p class="title">Previously logged in accounts. Click to sign-in</p>
            <ng-container *ngFor="let previousAuth of previousAuths">
              <a href="javascript:void(0)" (click)="onSelectAuth(previousAuth)">
                <div class="agent">
                  <img [src]="previousAuth?.photo" onerror="this.src='assets/img/img_avatar.png'" alt="Avatar" class="avatar">
                  <div class="agent-info">
                    <p class="name">{{previousAuth?.name}}</p>
                    <p><small>{{previousAuth?.office}}</small></p>
                  </div>
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="login-bg" style="background-image: url('/assets/img/designly-login-background.jpg');">
    <div class="login-bg-text">Designly is the all-in-one design platform for creating emarketing emails, window cards, property guides and more</div>
  </div>
</div>
<!-- <div class="height-100" id="sign-form-box" [class.hidden]="!isLoginChecked">
  <div class="login-section">
    <div class="logo-holder">
      <img *ngIf="appConfig.SKIN !== 'raywhite'" src="/assets/img/{{ selectedLogo }}" class="logo" [class.designly-logo]="!isRh && appConfig.SKIN === 'designly'">
      <img *ngIf="appConfig.SKIN === 'raywhite'" src="/assets/img/{{ selectedLogo }}">
    </div>
    <p *ngIf="isRh" class="sub-heading">In order to utilise Designplus we need to connect your Compassplus account</p>
    <p *ngIf="!isRh && appConfig.SKIN === 'designly'" class="sub-heading">In order to utilise Marketing Vault we need to connect your Vault account</p>
    <p *ngIf="appConfig.SKIN === 'prd'" class="sub-heading">In order to utilise PRD Smarter Marketing we need to connect your Vault account</p>
    <a *ngIf="!vaultLoginSuccess && vaultLoginMessage !== ''" href="{{ login_link }}" class="btn btn-primary form-control theme-btn-default signin-btn">Sign In</a>
    <p *ngIf="!vaultLoginSuccess && vaultLoginMessage === ''" class="text-danger"><strong>Login failed.</strong> Please try again later.</p>
    <p class="sub-heading problem-link-holder"><a class="login-link" target="_blank" href="https://websites.formstack.com/forms/designly_support_form">Problems logging in?</a></p>
  </div>
  <ng-container *ngIf="previousAuths.length > 0">
    <div class="vl"></div>
    <div class="previously-section">
      <div class="previously">
        <p class="title">Previously logged in accounts. Click to sign-in</p>
        <ng-container *ngFor="let previousAuth of previousAuths">
          <a href="javascript:void(0)" (click)="onSelectAuth(previousAuth)">
            <div class="agent">
              <img [src]="previousAuth?.photo" onerror="this.src='assets/img/img_avatar.png'" alt="Avatar" class="avatar">
              <div class="agent-info">
                <p class="name">{{previousAuth?.name}}</p>
                <p><small>{{previousAuth?.office}}</small></p>
              </div>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div> -->
<ngx-loading [show]="loading"></ngx-loading>