import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProElement } from '../../../../models/pro-element.model';
import { ProElementService } from '../../../../services/pro-element.service';
import swal from 'sweetalert2';
import { ProBlockService } from '../../../../services/pro-block.service';
import { ProBlock } from '../../../../models/pro-block.model';

@Component({
  selector: 'app-admin-pros-elements-dashboard',
  templateUrl: './admin-pros-elements-dashboard.component.html',
  styleUrls: ['./admin-pros-elements-dashboard.component.scss']
})
export class AdminProsElementsDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  proBlocks: ProBlock[] = [];
  proElements: ProElement[] = [];
  product: string;

  constructor(
    private proElementService: ProElementService,
    private proBlockService: ProBlockService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.paramMap
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (params: ParamMap) => {
        this.product = params.get('product');

        if (this.product) {
          this.proElementService.httpGetAll({
            product: this.product
          });
        }
      }
    );

    if (this.product) {
      this.proBlockService.httpGetAll({
        product: this.product
      });
    }

    this.proBlockService.onGetAll
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.proBlocks = response.data;
            }
          }
        }
      }
    );

    this.proElementService.onGetAll
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.proElements = response.data;
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Blocks',
                type: 'error'
              });
            }
          }
        }
      }
    );
  }

  onChangeBlockFilter(blockId: string) {
    this.proElementService.httpGetAll({
      product: this.product,
      pro_block_id: blockId
    });
    this.loading = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
