<div class="col-md-12 heading text-end">
    <h2 class="pull-left mt-0">Subscriptions</h2>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="panel bg-transparent">
            <div class="panel-body container-fluid p-0">
                <div class="subscriptions-wrapper">
                    <div class="d-flex subscription-details">
                        <div *ngIf="authUser?.isUserPro">
                            <p class="heading-text"><strong>My Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Pro</span></p>
                            <p>Valid Until: <span>{{nextDueDate}}</span></p>
                        </div>
                        <div *ngIf="!authUser?.isUserPro">
                            <p class="heading-text"><strong>My Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Lite</span></p>
                            <p>Valid Until: <span>Forever</span></p>
                        </div>
                        <div *ngIf="authUser?.isClientPro">
                            <p class="heading-text"><strong>Office Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Pro</span></p>
                            <p>Valid Until: <span>{{nextDueDate}}</span></p>
                        </div>
                        <div *ngIf="!authUser?.isClientPro">
                            <p class="heading-text"><strong>Office Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Lite</span></p>
                            <p>Valid Until: <span>Forever</span></p>
                        </div>
                    </div>
                    <div class="subscriptions-package">
                        <div class="d-flex flex-wrap subscriptions-package-list">
                            <div class="agent-subscription package-item card">
                                <div class="card-body">
                                    <div class="d-flex flex-column h-100">
                                        <p class="package-name pro text-center"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="18.09" height="19.61" viewBox="0 0 18.09 19.61">
                                                <g id="Group_2517" data-name="Group 2517"
                                                    transform="translate(-13.226 -18.625)">
                                                    <rect id="Rectangle_1209" data-name="Rectangle 1209" width="15.389"
                                                        height="3.065" rx="0.5" transform="translate(14.067 35.17)"
                                                        fill="#fac656" />
                                                    <path id="Path_1028" data-name="Path 1028"
                                                        d="M26.12,24.3a.668.668,0,0,1-.984-.247l-2.171-4.468L21.709,17l-1.256,2.585-2.171,4.468a.668.668,0,0,1-.984.247l-3.584-2.529a.661.661,0,0,0-1.043.617l1.421,9.57a.656.656,0,0,0,.655.553H28.67a.656.656,0,0,0,.655-.553l1.421-9.57a.661.661,0,0,0-1.043-.617Z"
                                                        transform="translate(0.562 1.623)" fill="#fac656" />
                                                </g>
                                            </svg> pro</p>
                                        <p class="package-amount"><span
                                                class="price-text">${{agentSubscriptionPrice}}*</span>month<span class="d-block fw-normal small">per user</span></p>
                                        <div class="divider"></div>
                                        <div class="package-details">
                                            <ng-container *ngFor="let plan of chargebeePlans?.user?.addons">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <strong>{{formatPlanName(plan?.id)}} <span data-tooltip-pos="top"><i class="icon feather icon-help-circle"></i></span></strong>
                                                    </div>
                                                    <div>{{formatCurrency(plan?.currency_code)}}{{ (plan?.price / 100) }}/month</div>
                                                    <div>
                                                        <label class="switch">
                                                            <input class="agent-checkbox"
                                                                [value]="plan?.id" type="checkbox"
                                                                (click)="onAgentCheckChange($event)"
                                                                [checked]="checkUserPlans(plan?.id)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <p class="text-center mt-2 mb-4">Create <strong>UNLIMITED</strong> Webbooks, Proposals and Owner Reports</p>
                                        </div>
                                        <div class="mt-auto">
                                            <button type="button" *ngIf="selectedPlans?.user?.length && !customerPlans?.user?.subscriptionId" (click)="onUserSubscribe('create')"
                                                class="btn btn-primary btn-lg w-100 mt-3">Subscribe</button>
                                                <button type="button" *ngIf="customerPlans?.user?.subscriptionId && userSubscriptionChanges" (click)="onUserSubscribe('update')"
                                                class="btn btn-primary btn-lg w-100 mt-3">Update Subscription</button>
                                                <button type="button" *ngIf="customerPlans?.user?.subscriptionId && userSubscriptionChanges && !selectedPlans?.user?.length" (click)="cancelSubscription('user')"
                                                class="btn btn-danger btn-lg w-100 mt-3">Cancel Subscription</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="package-item card">
                                <div class="card-body">
                                    <div class="d-flex flex-column h-100">
                                        <p class="package-name office-pro text-center"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="18.09" height="19.61" viewBox="0 0 18.09 19.61">
                                                <g id="Group_2517" data-name="Group 2517"
                                                    transform="translate(-13.226 -18.625)">
                                                    <rect id="Rectangle_1209" data-name="Rectangle 1209" width="15.389"
                                                        height="3.065" rx="0.5" transform="translate(14.067 35.17)"
                                                        fill="#fac656" />
                                                    <path id="Path_1028" data-name="Path 1028"
                                                        d="M26.12,24.3a.668.668,0,0,1-.984-.247l-2.171-4.468L21.709,17l-1.256,2.585-2.171,4.468a.668.668,0,0,1-.984.247l-3.584-2.529a.661.661,0,0,0-1.043.617l1.421,9.57a.656.656,0,0,0,.655.553H28.67a.656.656,0,0,0,.655-.553l1.421-9.57a.661.661,0,0,0-1.043-.617Z"
                                                        transform="translate(0.562 1.623)" fill="#fac656" />
                                                </g>
                                            </svg> office pro</p>
                                        <p class="package-amount"><span
                                                class="price-text">${{agencySubscriptionPrice}}*</span>month<span class="d-block fw-normal small">Unlimited users</span></p>
                                        <div class="divider"></div>
                                        <div class="package-details">
                                            <ng-container *ngFor="let plan of chargebeePlans?.client?.addons">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <strong>{{formatPlanName(plan?.id)}} <span data-tooltip-pos="top"><i class="icon feather icon-help-circle"></i></span></strong>
                                                    </div>
                                                    <div>{{formatCurrency(plan?.currency_code)}}{{ (plan?.price / 100) }}/month</div>
                                                    <div>
                                                        <label class="switch">
                                                            <input class="agent-checkbox"
                                                                [value]="plan?.id" type="checkbox"
                                                                (click)="onClientCheckChange($event)"
                                                                [checked]="checkClientPlans(plan?.id)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <p class="text-center mt-2 mb-4">Take your
                                                office to the next level with
                                                office
                                                wide Access to <strong>Designly Pro</strong></p>
                                        </div>
                                        <div class="mt-auto">
                                            <button type="button" *ngIf="selectedPlans?.client?.length && !customerPlans?.client?.subscriptionId" (click)="onClientSubscribe('create')"
                                                class="btn btn-dark btn-lg w-100 mt-3">Subscribe</button>
                                                <button type="button" *ngIf="customerPlans?.client?.subscriptionId && clientSubscriptionChanges && selectedPlans?.client?.length" (click)="onClientSubscribe('update')"
                                                class="btn btn-dark btn-lg w-100 mt-3">Update Subscription</button>
                                                <button type="button" *ngIf="customerPlans?.client?.subscriptionId && clientSubscriptionChanges && !selectedPlans?.client?.length" (click)="cancelSubscription('client')"
                                                class="btn btn-danger btn-lg w-100 mt-3">Cancel Subscription</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>* Prices exclusive of GST</p>
                </div>
                <div *ngIf="!authUser?.isUserPro && !authUser?.isClientPro" class="request-demo-panel">
                    <div class="request-demo">
                        <div class="d-flex justify-content-between">
                            <div>
                                <p class="mb-0">
                                    <strong>Request a demo of Designly Pro</strong>
                                    <span class="d-block small">Connect with us to learn more about how Designly Pro can help you grow your business.</span>
                                </p>
                            </div>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#requestDemoModal">Request a Demo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="requestDemoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
        <div class="modal-body">
            <div id="hubspotForm"></div>
        </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>