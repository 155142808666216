<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ this. title }}</h2>

    <button *ngIf="path !== base_path" (click)="openRoot()" data-bs-toggle="modal" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
    <button *ngIf="path === base_path" data-bs-target="#newFolder" data-bs-toggle="modal" type="button" class="btn-admin">
      <i class="icon feather icon-plus"></i><span>New Folder</span>
    </button>
    <button data-bs-target="#uploadItem" data-bs-toggle="modal" type="button" class="btn-admin">
      <i class="icon feather icon-upload"></i><span>Upload</span>
    </button>
  </div>
</div>
<h4>Directory: {{ path === base_path ? 'TOP FOLDER' : path.replace(base_path, 'TOP FOLDER') }}</h4>
<!-- <div id="box" class="row">
  <a *ngFor="let file of files; let i = index;" (dblclick)="openFolder(file)" class="col-md-2 s3-items {{ 's3-item-' + file.type }}">
    <i class="icon feather icon-{{ file.type === 'dir' ? 'folder' : 'file' }}"></i>
    <span>{{ file. basename }}</span>
    <div *ngIf="file.type === 'file'" class="file-controls">
      <button class="btn-table" type="button" (click)="onDelete(file)" title="Delete">
        <i class="icon feather icon-trash"></i>
      </button>
      <button class="btn-table" type="button" (click)="showLink(file)" title="URL">
        <i class="icon feather icon-link"></i>
      </button>
    </div>
  </a>
</div> -->
<div id="box" class="row">
    <a *ngFor="let file of files; let i = index;" (dblclick)="openFolder(file)" class="col-md-2 s3-items {{ 's3-item-' + file.type }}">
      <i *ngIf="file.type === 'dir'" class="icon feather icon-{{ file.type === 'dir' ? 'folder' : 'file' }}"></i>
      <img *ngIf="file.type === 'file'" src="{{getLink(file)}}" class="file-thumb" />
      <span>{{ file. basename }}</span>
      <div *ngIf="file.type === 'file'" class="file-controls">
        <button class="btn-table" type="button" (click)="onDelete(file)" title="Delete">
          <i class="icon feather icon-trash"></i>
        </button>
        <button class="btn-table" type="button" (click)="showLink(file)" title="URL">
          <i class="icon feather icon-link"></i>
        </button>
      </div>
      <div *ngIf="file.type === 'dir'" class="file-controls">
        <button class="btn-table" type="button" (click)="onDelete(file)" title="Delete">
          <i class="icon feather icon-trash"></i>
        </button>
      </div>
    </a>
  </div>
  
<div id="uploadItem" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="uploadItemLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" #uploadItemModalClose data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="uploadItemLabel">Upload File</h4>
      </div>
      <form (ngSubmit)="onUpload()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="medium" class="col-sm-2">File</label>
            <div class="col-sm-8">
              <input #itemFile class="form-control" type="file" accept="image/*" multiple>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Upload</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="newFolder" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="newFolderLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" #newFolderModalClose data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="newFolderLabel">New Folder</h4>
      </div>
      <form (ngSubmit)="onSubmit()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="medium" class="col-sm-2">Name</label>
            <div class="col-sm-8">
              <input #itemFolder class="form-control" type="text" value="" >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>