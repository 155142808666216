import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MergeField } from '../../../../models/merge-field.model';
import swal from 'sweetalert2';
import { MergefieldService } from '../../../../services/mergefield.service';
import { MergeFieldsCategory } from '../../../../models/merge-fields-category.model';

@Component({
  selector: 'app-admin-pros-builder-merge-fields-edit',
  templateUrl: './admin-pros-builder-merge-fields-edit.component.html',
  styleUrls: ['./admin-pros-builder-merge-fields-edit.component.scss']
})
export class AdminProsBuilderMergeFieldsEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;

  fields = [];

  mergeFieldForm: FormGroup;
  mergeField: MergeField;
  mergeFieldsCategories: MergeFieldsCategory[] = [];

  constructor(
    private mergeFieldService: MergefieldService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    const mergeFieldId = this.route.snapshot.paramMap.get('id');

    this.mergeFieldService.httpGetCategories();
    this.mergeFieldService.onGetCategories
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.mergeFieldsCategories = response.data;
            this.mergeFieldService.httpGetFields();
          }
        }
      }
    );

    this.mergeFieldService.onGetFields
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.fields = response.data;

            if (mergeFieldId) {
              this.editMode = true;
              this.mergeFieldService.httpGet(mergeFieldId);
              this.loading = true;
            }
          }
        }
      }
    );

    this.mergeFieldService.onStore
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              swal({
                title: 'Merge Field created successfully',
                type: 'success',
              }).catch(swal.noop);

              this.router.navigateByUrl('/admin/pros/builder/mergefields');
            } else if (response.status === 'VALIDATION_ERROR') {
              swal({
                title: 'Validation Error',
                text: response.message,
                type: 'error',
              }).catch(swal.noop);
            } else {
              swal({
                title: 'Internal Server Error',
                text: 'Contact Dev Team',
                type: 'error'
              }).catch(swal.noop);
            }
          } else {
            swal({
              title: 'Internal Server Error',
              text: 'Contact Dev Team',
              type: 'error'
            }).catch(swal.noop);
          }
        }
      }
    );

    this.mergeFieldService.onGet
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.mergeField = response.data;

            this.mergeFieldForm.patchValue({
              merge_fields_category_id: this.mergeField.merge_fields_category.uuid,
              reference: this.mergeField.reference,
              name: this.mergeField.name,
              merge_field: this.mergeField.merge_field,
              merge_field_output: this.mergeField.merge_field_output,
              placeholder: this.mergeField.placeholder
            });
          } else {
            swal({
              title: 'Internal Server Error',
              text: 'Contact Dev Team',
              type: 'error'
            }).catch(swal.noop);
          }
        }
      }
    );

    this.mergeFieldService.onUpdate
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              swal({
                title: 'Merge Field updated successfully',
                type: 'success',
              }).catch(swal.noop);

              this.router.navigateByUrl('/admin/pros/builder/mergefields');
            } else if (response.status === 'VALIDATION_ERROR') {
              swal({
                title: 'Validation Error',
                text: response.message,
                type: 'error',
              }).catch(swal.noop);
            } else {
              swal({
                title: 'Internal Server Error',
                text: 'Contact Dev Team',
                type: 'error'
              }).catch(swal.noop);
            }
          } else {
            swal({
              title: 'Internal Server Error',
              text: 'Contact Dev Team',
              type: 'error'
            }).catch(swal.noop);
          }
        }
      }
    );

    this.initForms();
  }

  initForms(): void {
    this.mergeFieldForm = new FormGroup({
      merge_fields_category_id: new FormControl('', Validators.required),
      reference: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      merge_field: new FormControl('', Validators.required),
      merge_field_output: new FormControl('', Validators.required),
      placeholder: new FormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    const mergeFieldFormValues = this.mergeFieldForm.value;

    if (this.mergeFieldForm.valid) {
      if (!this.editMode) {
        this.mergeFieldService.httpStore(mergeFieldFormValues);
      } else {
        this.mergeFieldService.httpUpdate(this.mergeField.uuid, mergeFieldFormValues);
      }

      this.loading = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
