<div class="child-content-wrapper changelog">
  <div class="heading heading-height">
    <h2 class="pull-left">{{ frontService.authService.getApp() }} Change Log</h2>
  </div>
  <div id="box" class="row">
    <div class="col-md-12">
      <div class="table-box">
        <div class="container">
          <ng-container *ngFor="let changelog of changelogs">
            <h3>{{ formatTime(changelog.title) }}</h3>
              <div [innerHTML]="changelog.content | safeHtml">
              </div>
            <hr>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>