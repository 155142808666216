<div class="page-content container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div [class.panel-box-shadow]="page !== 'builder'" [class.panel]="page !== 'builder'">
        <form [formGroup]="supportForm" (ngSubmit)="onSubmit()" class="form-horizontal support-form">
          <h2 [class.h2-pad]="page !== 'builder'">Hi {{ authUser?.user?.firstname }} {{ authUser?.user?.lastname }}</h2>
          <div class="panel-body">
            <p>Need assistance? We’re here to help! Submit a detailed request below to our support team and we will get back to you as soon as possible.</p>

            <div class="margin-top-30">
              <div class="form-group hidden">
                <div class="col-md-3">
                  <label class="control-label" for="firstname">Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="firstname" placeholder="First Name" autocomplete="off" formControlName="firstname" />
                </div>
                <div class="col-md-3">
                  <label class="control-label" for="lastname">&nbsp;</label>
                  <input type="text" class="form-control" id="lastname" placeholder="Last Name" autocomplete="off" formControlName="lastname" />
                </div>

                <div class="col-md-6">
                  <label class="control-label" for="office">Office Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="office" autocomplete="off" formControlName="office" />
                </div>
              </div>
              <div class="form-group hidden">
                <div class="col-sm-3">
                  <label class="control-label" for="phone">Phone<i class="text-danger">*</i></label>
                  <input type="phone" class="form-control" id="phone" autocomplete="off" formControlName="phone" />
                </div>

                <div class="col-sm-4">
                  <label class="control-label" for="email">Email<i class="text-danger">*</i></label>
                  <input type="email" class="form-control" id="email" autocomplete="off" formControlName="email" />
                </div>
              </div>
              <p>
                <strong>To help us investigate an issue please provide:</strong>
              </p>
              <div class="form-group">
                <ol>
                  <li>Steps to reproduce the issue/s</li>
                  <li>The expected result</li>
                  <li>Actual result</li>
                </ol>
                <div class="col-sm-12">
                  <textarea formControlName="information" class="form-control col-sm-12" rows="4" placeholder="Please provide as much information as possible regarding your inquiry"></textarea>
                </div>
              </div>

              <div class="form-group">
                <div *ngIf="page === 'builder'" class="col-md-12">
                  <p>To better troubleshoot issues or request please try uploading a screenshot or try using our screenshot tool.</p>
                  <input #fileAttached class="support-file-upload" type="file" accept="image/*" id="fileAttached" formControlName="fileAttached" multiple /><button type="button" (click)="takeScreenshot()" class="screenshot-btn btn btn-3">Take Screenshot</button> <span class="screenshot-count text-success" *ngIf="builder_screenshots.length > 0">{{ builder_screenshots.length }} screenshot uploaded</span>
                  <p><small class="gray4-color">*You can select multiple files</small></p>
                </div>
                <div *ngIf="page !== 'builder'" class="col-sm-6">
                  <div class="col-sm-6">
                    <small class="gray4-color">if you have a screenshot or example you would like to include, please feel free to upload it.</small><br />
                    <label class="control-label"> Screenshot or Example </label>
                    <input #fileAttached class="form-control" type="file" accept="image/*" id="fileAttached" formControlName="fileAttached" multiple />

                    <small class="gray4-color">*You can select multiple files</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-footer">
            <div class="text-right">
              <button [disabled]="!supportForm.valid" class="btn btn-3">Submit Request</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div #previewTour id="previewTour" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-lg modal-video">
    <div class="modal-content">
      <button type="submit" #previewTourModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>Test</button>
      <div class="modal-body">Tour Modal</div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
