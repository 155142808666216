import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'settingsDefaultVaultTemplates'
})
export class SettingsDefaultVaultTemplatesPipe implements PipeTransform {

  transform(templates: any, term: string): any {
    if (term === undefined) {
        return templates;
    }

    return templates.filter(template => {
        const templateSearch = `${template.title}`;

        return templateSearch.toLowerCase().includes(term.toLowerCase());
    });
  }

}
