<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 heading">
          <h4 class="pull-left">White/Black Listings</h4>
        </div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="20%">Type</th>
            <th>IDs</th>
            <th width="10%" style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Whitelist - Office ID</td>
            <td>{{ setting.whitelist_officeid }}</td>
            <td style="text-align: right">
              <button data-bs-target="#settingWhiteListOfficeID" data-bs-toggle="modal" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
            </td>
          </tr>
          <tr>
            <td>Blacklist - Brand ID</td>
            <td>{{ setting.blacklist_brandid }}</td>
            <td style="text-align: right">
              <button data-bs-target="#settingBlackListBrandID" data-bs-toggle="modal" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="settingWhiteListOfficeID" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="settingWhiteListOfficeIDLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #settingWhiteListOfficeIDModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="settingWhiteListOfficeIDLabel">Add Whitelisting - Office ID</h4>
      </div>
      <form (ngSubmit)="onSubmit('whitelist_officeid')" [formGroup]="settingWhiteListOfficeIDForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="title" class="col-sm-2">Office ID</label>
            <div class="col-sm-10">
              <textarea rows="6" formControlName="whitelist_officeid" class="form-control" id="whitelist_officeid" ></textarea>
              <small>(,) comma separated e.g. 12345, 56789</small>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!settingWhiteListOfficeIDForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div id="settingBlackListBrandID" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="settingBlackListBrandIDLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #settingBlackListBrandIDModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="settingBlackListBrandIDLabel">Add Blacklisting - Brand ID</h4>
      </div>
      <form (ngSubmit)="onSubmit('blacklist_brandid')" [formGroup]="settingBlackListBrandIDForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="title" class="col-sm-2">Brand ID</label>
            <div class="col-sm-10">
              <textarea rows="6" formControlName="blacklist_brandid" class="form-control" id="blacklist_brandid" ></textarea>
              <small>(,) comma separated e.g. 12345, 56789</small>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!settingBlackListBrandIDForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>