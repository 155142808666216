import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WebsiteService } from '../../../services/website.service';
import { Website } from '../../../models/website.model';
import { Subscription } from 'rxjs/Subscription';

declare var swal: any;
@Component({
  selector: 'app-website-dashboard',
  templateUrl: './website-dashboard.component.html',
  styleUrls: ['./website-dashboard.component.scss']
})
export class WebsiteDashboardComponent implements OnInit {
  loading = false;
  type: String;

  websites: Website[];

  websitesSubscription: Subscription;
  websiteDeleteSubscription: Subscription;

  dtOptions: any;

  selectedRow = 0;
  selectedRowDataId = [];

  constructor(
    private websiteService: WebsiteService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.type = params['type'];

        if ( this.type !== 'undefined' ) {
          this.websiteService.httpGetWebsites({
            type: this.type
          });
          this.loading = true;
        }

      }
    );

    this.websitesSubscription = this.websiteService.websiteGetAll.subscribe(
      (websites: any) => {
        if ( typeof websites !== 'undefined' && websites.status === 'success' ) {
          this.loading = false;
          this.websites = websites.data;

          this.initDT();
        }
      }
    );

    this.websiteDeleteSubscription = this.websiteService.websiteDelete.subscribe(
      (website: any) => {
        if ( typeof website !== 'undefined' && website.status === 'success' ) {
          this.websiteService.httpGetWebsites({
            type: this.type
          });
        }
      }
    );
  }

  initDT() {
    this.dtOptions = {
      order: [],
      pagingType: 'full_numbers',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
        paginate: {
          first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
          previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
          next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
          last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
        }
      },
      pageLength: 50,
      retrieve: true,
      select: {
        'style': 'multiple',
      },
    };

    setTimeout(
      () => {
        this.loading = false;
        $('table').DataTable(this.dtOptions);

        jQuery('table').DataTable().column(0).visible(false);

        const $this = this;

        $('table:not(.static)').on('select.dt', function ( e, dt, type, indexes ) {
          $this.selectedRow = $this.selectedRow + 1;
          const data = dt.rows('.selected' ).data();

          for (let i = 0; i < $this.selectedRow; i++) {
            if ($this.selectedRowDataId.includes(data[i][0])) {
              continue;
            } else {
              $this.selectedRowDataId.push(data[i][0]);
            }
          }
        });

        $('table:not(.static)').on('deselect.dt', function ( e, dt, type, indexes ) {
          $this.selectedRow = $this.selectedRow - 1;
          const data = dt.rows(indexes).data();
          const groupID = data[0][0];

          $this.selectedRowDataId.forEach((value, index) => {
              if (value === groupID) {
                $this.selectedRowDataId.splice(index, 1);
              }
          });
        });

        $('input[type=search]').addClass('btn-search');
        $('input[type=search]').attr('placeholder','Search');
        $('select').addClass('select_datatable');
        $('select').append('<option selected value="-1">Filter</option>');
      }, 1000
    );
  }

  formatTitle(type) {
    if ( type === 'agency-website' ) {
      return 'Agency Website';
    }

    if ( type === 'agent-only-website' ) {
      return 'Agent Only Website';
    }

    if ( type === 'lead-generating-website' ) {
      return 'Lead Generating Website';
    }

    return '';
  }

  onDelete(id: number) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.websiteService.httpDeleteWebsite(id);
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this data selected?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.websiteService.httpDeleteWebsite(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }

}
