import { Params, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from './admin.service';
import { Menu } from '../models/menu.model';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  menus: Menu[];

  @ViewChild('sidebarWrapper', { static: true }) sidebar: ElementRef;
  @ViewChild('contentWrapper', { static: true }) content: ElementRef;

  constructor(private adminService: AdminService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.subscription = this.adminService.menuChanged.subscribe((menus: Menu[]) => {
      if (this.adminService.fullPageContent) {
        this.sidebar.nativeElement.className = 'hidden height-100';
        this.content.nativeElement.className = 'col-12 height-100';
        this.adminService.fullPageContent = false;
      } else {
        if (!this.adminService.hasMenu()) {
          this.sidebar.nativeElement.className = 'col-1 height-100';
          this.content.nativeElement.className = 'col-11 height-100';
        } else {
          this.sidebar.nativeElement.className = 'col-2 height-100 open-submenu';
          this.content.nativeElement.className = 'col-10 height-100 open-submenu';
        }
      }
    });

    this.menus = [];
    this.adminService.loadMenu(this.menus);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
