import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable, Inject } from '@angular/core';

import { APP_CONFIG } from '../app.config';
import { Subscription } from 'rxjs';
import { OptionService } from './option.service';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  isMaintenance: Subscription;

  constructor(@Inject(APP_CONFIG) private appConfig,
              private authService: AuthService,
              private router: Router,
              private optionService: OptionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.optionService.httpGetMaintenance();

    this.isMaintenance = this.optionService.maintenanceChanged.subscribe(
      (maintenance: any) => {
        if (typeof maintenance !== 'undefined') {
          if (maintenance.value === '1') {
            this.authService.deleteAuthCookie();
            this.router.navigate(['/maintenance']);
            return false;
          }
        }
      }
    );


    return true;
  }
}
