<div class="row">
    <div class="col-md-12 heading">
      <h2 class="pull-left">Icons</h2>
      <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
        <i class="icon feather icon-trash"></i><span>Delete Selected</span>
      </button>
      <button routerLink="new" type="button" class="btn-admin">
          <i class="icon feather icon-plus"></i><span>Add Icons</span>
        </button>
    </div>
  </div>
  <div id="box" class="row">
    <div class="col-md-12">
      <div class="table-box">
        <h1>Primary Icons</h1>
          <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Icon</th>
                  <th style="text-align: right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let icon of icons">
                  <tr *ngIf="icon?.category_type == 'Primary'">
                    <td>{{ icon?.ID }}</td>
                    <td>
                      <div class="icon-holder">
                        <img src="{{ icon?.icon_url }}" class="icon-img">
                      </div>
                    </td>
                    <td style="text-align: right"> 
                      <button routerLink="{{ icon?.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                      <button (click)="onDelete(icon?.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
        
            <h1>Secondary Icons</h1>
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Icon</th>
                  <th style="text-align: right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let icon of icons">
                  <tr *ngIf="icon?.category_type == 'Secondary'">
                    <td>{{ icon?.ID }}</td>
                    <td>
                      <div class="icon-holder-dark">
                        <img src="{{ icon?.icon_url }}" class="icon-img">
                      </div>
                    </td>
                    <td style="text-align: right"> 
                      <button routerLink="{{ icon?.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                      <button (click)="onDelete(icon?.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>