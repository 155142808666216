import { HttpParams, HttpClient, HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Element } from '../models/element.model';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';

@Injectable()
export class ElementService {
  elementsChanged = new Subject<Element[]>();
  elementChanged = new Subject<Element>();
  elementOperation = new Subject<Element>();
  elementListingHtml = new Subject<any[]>();
  elementDelete = new Subject<any>();

  private elements: Element[] = [];

  // New Subscription
  onGetElements = new Subject();
  onGetElement = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig,
              private httpClient: HttpClient,
              private authService: AuthService) {}

  setElements(elements: Element[]) {
    this.elements = elements;
    this.elementsChanged.next(this.elements.slice());
  }

  getElements() {
    return this.elements.slice();
  }

  getIdIndex(id: number) {
    return this.elements.findIndex(e => e.ID === id);
  }

  httpGetElementsNew(params: any = {}, columns: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/elements`, {
        params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetElements.next(response);
      },
      (response: any) => {
        this.onGetElements.next(response.error);
      }
    );
  }

  httpGetElements(params: any = [], columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/elements',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Element[]) => {
        this.setElements(response);
      }
    );
  }

  httpGetElementListingHtml(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/elements/listing-html/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
          return response.body;
        }

        return [];
      }
    )
    .subscribe(
      (response: any[]) => {
        this.elementListingHtml.next(response);
      }
    );
  }

  httpGetElementNew(id: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/elements/${id}`)
    .subscribe(
      (response: any) => {
        this.onGetElement.next(response);
      },
      (response: any) => {
        this.onGetElement.next(response.error);
      }
    );
  }

  httpGetElement(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/elements/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Element) => {
        this.elementChanged.next(response);
      }
    );
  }

  httpPostElement(data: Element) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/elements',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            const responseData = response.body.data;
            const id = responseData.ID;

            if ( typeof data['iconFile'] !== 'undefined' ||
              typeof data['thumbnailFile'] !== 'undefined' ) {

              const icon = data['iconFile'];
              const thumbnail = data['thumbnailFile'];
              const formData = new FormData();

              if ( typeof icon !== 'undefined' ) {
                formData.append('icon', icon, icon.name);
              }

              if ( typeof thumbnail !== 'undefined' ) {
                formData.append('thumbnail', thumbnail, thumbnail.name);
              }

              const uploadReq = new HttpRequest(
                'POST',
                this.appConfig.API_ENDPOINT + '/elements/upload/' + id,
                formData
              );

              this.httpClient.request<any>(uploadReq).toPromise();
            }

            return responseData;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.elementOperation.next(response);
      }
    );
  }

  httpPutElement(id: number, data: Element) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/elements/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            if ( typeof data['iconFile'] !== 'undefined' ||
                  typeof data['thumbnailFile'] !== 'undefined' ) {

              const icon = data['iconFile'];
              const thumbnail = data['thumbnailFile'];
              const formData = new FormData();

              if ( typeof icon !== 'undefined' ) {
                formData.append('icon', icon, icon.name);
              }

              if ( typeof thumbnail !== 'undefined' ) {
                formData.append('thumbnail', thumbnail, thumbnail.name);
              }

              const uploadReq = new HttpRequest(
                'POST',
                this.appConfig.API_ENDPOINT + '/elements/upload/' + id,
                formData
              );

              this.httpClient.request<any>(uploadReq).toPromise();
            }

            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.elementOperation.next(response);
      }
    );
  }

  httpDeleteElement(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/elements/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.elementDelete.next(response);
      },
      (response: any) => {
        this.elementDelete.next(response.error);
      }
    );
  }

  httpReplicateElement(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/elements/replicate',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.elementOperation.next(response);
      }
    );
  }

}
