import {HttpParams,  HttpClient,   HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Client } from '../models/client.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';
import { Auth } from '../models/auth.model';

@Injectable()
export class ClientService {
  maintenanceChanged = new Subject<any>();
  clientsChanged = new Subject<Client[]>();
  ClientsOfGroupID = new Subject<Client[]>();
  ClientsOfSubGroupID = new Subject<Client[]>();
  clientChanged = new Subject<Client>();
  clientOperation = new Subject<Client>();
  clientLink = new Subject<Client>();
  clientLogo = new Subject<Client>();
  clientColor = new Subject<Client>();
  clientIcon = new Subject<Client>();
  clientDelete = new Subject<any>();
  agentSettings = new Subject<any>();

  clientLockFields = new Subject();

  private clients: Client[] = [];
  public client: Client;
  public clientId: number;

  // New Subscriptions
  onGetClients = new Subject();
  onGetClient = new Subject();
  onUpdateIntegrations = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setClients(clients: Client[]) {
    this.clients = clients;
    this.clientsChanged.next(this.clients.slice());
  }

  getClients() {
    return this.clients.slice();
  }

  getIdIndex(id: number) {
    return this.clients.findIndex(c => c.ID === id);
  }

  httpGetClientsNew(params: any = {}, columns: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/clients`, {
        params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetClients.next(response);
      },
      (response: any) => {
        this.onGetClients.next(response.error);
      }
    );
  }

  httpGetClients(params: any = {}, columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/clients',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return undefined;
      }
    )
    .subscribe(
      (response: Client[]) => {
        this.clientsChanged.next(response);
      }
    );
  }

  httpGetClientsOfGroupID(params: any = {}, columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/clients',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Client[]) => {
        this.ClientsOfGroupID.next(response);
      }
    );
  }

  httpGetClientsOfSubGroupID(params: any = {}, columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/clients',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Client[]) => {
        this.ClientsOfSubGroupID.next(response);
      }
    );
  }

  httpGetClient(id: number) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/clients/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Client) => {
        this.clientChanged.next(response);
      }
    );
  }

  httpGetClientNew(id: number) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/clients/${id}`)
    .subscribe(
      (response: any) => {
        this.onGetClient.next(response);
      },
      (response: any) => {
        this.onGetClient.next(response.error);
      }
    );
  }

  httpPostClient(data: Client) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/clients',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.clientOperation.next(response);
      }
    );
  }

  httpPutClient(id: number, data: Client) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/clients/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.clientOperation.next(response);
      }
    );
  }

  httpPutLinks(id: number, data: Client) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/clients/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.clientLink.next(response);
      }
    );
  }

  httpDeleteClient(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/clients/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.clientDelete.next(response);
      },
      (response: any) => {
        this.clientDelete.next(response.error);
      }
    );
  }

  httpaddColor(id: number, value: string) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/clients/addcolor/' + id + '/' + value,
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetClient(id);
      }
    );
  }

  httpPutColor(id: number, data: Client) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/clients/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.clientColor.next(response);
      }
    );
  }

  httpPutLogo(id: number, type: string, img: File) {
    const formData: FormData = new FormData();
    formData.append(type + '_logo', img, img.name);
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/clients/upload/' + type + '/' + id,
      formData
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.clientLogo.next(response);
      }
    );
  }

  httpPutIcon(id: number, data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/clients/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
       this.clientIcon.next(response);
      }
    );
  }

  httpGetClientLockFields(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/clients/lockfields/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.clientLockFields.next(response);
      },
      (response: any) => {
        this.clientLockFields.next(response.error);
      }
    );
  }

  httpPutAgentSettings(id: number, data: Client) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/clients/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.agentSettings.next(response);
      }
    );
  }

  httpUpdateIntegrations(id: number, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/clients/${id}/integrations`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateIntegrations.next(response);
      },
      (response: any) => {
        this.onUpdateIntegrations.next(response.error);
      }
    );
  }

}
