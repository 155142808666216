<header>
  <div class="pull-left">
    <button *ngIf="page === 'home'" class="back-btn" (click)="onBackRefresh()"><i class="icon feather icon-chevron-left"></i></button>
    <button *ngIf="page === 'builder'" class="back-btn" (click)="closeBuilderModal()"><i class="icon feather icon-chevron-left"></i></button>
    <span class="title">The Real Estate Voice</span>
    <button (click)="particaHelp()" class="help-btn-tooltip help-btn tooltip-bottom position-relative">
      <i class="icon feather icon-help-circle"></i>
      <span class="help-tooltip">
        <div class="row">
          <div class="col-md-9">Free real estate articles dedicated to home owners, investors, landlords, tenants and anyone interested in learning about property.</div>
          <div class="col-md-3">
            <img class="tooltip-img pull-right" src="./assets/img/REV_Logo_Final-300x214-1.png" alt="" />
          </div>
        </div>
      </span>
    </button>
  </div>
</header>
<div class="row height-100 main-content articles" (click)="mainContentClick()">
  <div class="col-lg-12 height-100 content-wrapper">
    <ul class="nav nav-tabs">
      <li *ngIf="!raywhiteGroupIds.includes(authUser?.client?.group_id)"><a id="realEstateVoiceLibrary" data-bs-toggle="tab" href="#realestatevoice" (click)="onChangeTab('realestatevoice')" class="active">Articles</a></li>
      <li *ngIf="authUser?.user?.rss_feed || myVoiceEnabled"><a id="myVoiceLibrary" data-bs-toggle="tab" href="#realestatevoice" (click)="onChangeTab('myvoice')">My Voice</a></li>
      <li *ngFor="let rssFeed of rssFeeds">
        <a data-bs-toggle="tab" href="#realestatevoice" id="rssFeed-{{ rssFeed?.ID }}" (click)="loadFeed(rssFeed)">{{ rssFeed?.title }}</a>
      </li>
      <li class="add-rss-li">
        <button class="add-rss-btn" type="button" (click)="onOpenRssForm()"><i class="icon feather icon-plus"></i> <span>Add RSS Feed</span></button>
      </li>
    </ul>

    <div class="tab-content">
      <div id="realestatevoice" class="tab-pane fade in">
        <!-- <div class="row search-text">
          <div class="col-md-12">
            <div class="pull-right">
              <p>Total Results: <strong>{{ rssArticles?.length }}</strong></p>
            </div>
          </div>
        </div> -->
        <div class="row w-100">
          <div class="col-md-6 partica-search">
            <input type="text" class="form-control search-input" placeholder="Search articles" [formControl]="articleSearchInput" />
          </div>
          <div *ngIf="rssFeed" class="col-md-6 rss-settings">
            <div class="float-end">
              <button *ngIf="(rssFeed?.group_id !== null && authUser?.is_master) || rssFeed?.group_id === null" class="add-rss-btn" type="button" (click)="onOpenRssForm(false)"><i class="icon feather icon-settings"></i> <span>Settings</span></button>
            </div>
          </div>
        </div>
        <div class="row col-md-12">
          <ng-container *ngFor="let article of rssArticles">
            <div class="col-md-3 items">
              <div class="card">
                <div class="top" [ngStyle]="{ 'background-image': 'url(' + article?.thumbnail + ')' }"></div>
                <div class="bottom">
                  <p class="article-title">{{ cleanTitle(article.title) }}</p>
                  <p class="article-description">
                    {{ cutText(article.description) }}
                  </p>
                  <div class="article-buttons clearfix">
                    <div class="pull-left">
                      <button class="read-btn" type="button" (click)="readArticle(article)"><strong>Read full article</strong></button>
                    </div>
                    <div class="pull-right">
                      <button *ngIf="page === 'builder'" class="add-article read-btn" type="button" (click)="insertArticle(article, '', 'rss')"><i class="icon feather icon-share"></i></button>
                      <!-- <button data-tooltip="Add to Cart" class="add-article read-btn intro-10 tooltip-bottom {{ article?.articleId }}"
                        type="button" (click)="onAddArticleToCart(article)"><i
                          class="icon feather icon-shopping-cart"></i></button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="read-article-rss side-container height-100 hidden">
  <div class="pull-right">
    <i class="icon feather icon-x close-btn" (click)="closeArticle()"></i>
  </div>
  <div class="article-content">
    <div class="article-img">
      <img src="{{ rssArticle?.thumbnail }}" />
    </div>
    <hr />
    <h2>{{ rssArticle?.title }}</h2>
    <div [innerHtml]="htmlToPlaintext(rssArticle?.raw) | safeHtml"></div>
    <hr />
  </div>
</div>
<!-- Select Main Picture Modal -->
<div id="selectPictureModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
        <h4 class="modal-title">Select your preferred article image</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div *ngFor="let image of article?.images" class="col-md-3 article-select-image">
            <img class="selected-article__img" (click)="insertArticle(article, image.src)" height="100" width="100" src="{{ viewerUrl }}/{{ image.src }}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="updateRssFeed" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">RSS Feed URL</h4>
      </div>
      <div class="modal-body">
        <p>If you have your own Branded real estate voice subscription, paste in your blog RSS feed URL below. For more information <a target="_blank" href="https://www.therealestatevoice.com.au/subscribe-to-our-newsletter/">click here</a></p>
        <ng-container *ngIf="!editMode">
          <button *ngIf="authUser?.is_master" type="button" class="btn-admin btn-admin-primary me-2" (click)="onShowRssForm('group')">For my group</button>
          <button *ngIf="authUser?.is_admin" type="button" class="btn-admin btn-admin-primary me-2" (click)="onShowRssForm('office')">For my office</button>
          <button type="button" class="btn-admin btn-admin-primary" (click)="onShowRssForm('user')">For myself</button>
        </ng-container>
        <hr />
        <ng-container *ngIf="showOfficeForm || showUserForm || showGroupForm">
          <form [formGroup]="rssForm" (ngSubmit)="onSaveRss()">
            <div class="form-group">
              <input type="text" class="form-control" formControlName="title" id="title" placeholder="Title" />
              <input type="text" class="form-control" formControlName="url" id="url" placeholder="RSS Feed URL" />
              <i *ngIf="showOfficeForm" class="note">An office wide RSS feed</i>
              <i *ngIf="showUserForm" class="note">A personal RSS feed</i>
              <i *ngIf="showGroupForm" class="note">A group wide RSS feed</i>
            </div>
            <div class="form-group">
              <button [disabled]="!rssForm.valid" type="submit" class="btn-admin btn-admin-primary me-2">Save</button>
              <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-admin">Cancel</button>
              <button *ngIf="editMode" type="button" (click)="deleteFeed(rssFeed?.ID)" class="pull-right btn btn-danger">Delete RSS Feed</button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
