<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">Types</h2>

        <button routerLink="new" type="button" class="btn-admin">
            <i class="icon feather icon-plus"></i><span>Add New Type</span>
        </button>
    </div>
</div>
<div id="box" class="row">
    <div class="col-md-12">
        <div class="table-box">
            <app-admin-table-component [rowData]="designsTypes">
                <thead>
                    <tr>
                        <th>Reference</th>
                        <th>Name</th>
                        <th>DPI</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Last Updated</th>
                        <th class="text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let designsType of designsTypes">
                        <td>{{designsType?.reference}}</td>
                        <td>{{designsType?.name}}</td>
                        <td>{{designsType?.dpi}}</td>
                        <td>{{designsType?.width}}</td>
                        <td>{{designsType?.height}}</td>
                        <td>{{designsType?.updated_at | date:'medium'}}</td>
                        <td class="text-end">
                            <a routerLink="/admin/pros/builder/types/{{designsType?.uuid}}/edit" class="btn btn-primary btn-sm">Edit</a>
                        </td>
                    </tr>
                </tbody>
            </app-admin-table-component>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>