import { Injectable, Inject } from '@angular/core';
import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';
import { AuthService } from './auth.service';

import { keys } from '../../environments/keys';
import { Auth } from '../models/auth.model';
import { Template } from '../models/template.model';
import { ProposalClient } from '../models/proposal-client.model';
import { ProposalInvestment } from '../models/proposal-investment.model';
declare var $: any;

@Injectable()
export class GlobalService {
  resetSearch = new Subject<boolean>();
  insertListings = new Subject();
  onSelectListing = new Subject();

  public element_listing_limit = 0;
  public template: any;

  constructor(
    @Inject(APP_CONFIG) public appConfig,
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  httpGetAuthRefresh(provider = 'mydesktop'): Observable<any> {
    const groupid = this.authService.auth.groupid;
    const agentid = this.authService.auth.agentid;

    let url = 'auth';

    if (provider === 'vaultre') {
      url = 'vault';
    }

    if ( provider === 'domain' ) {
      url = 'domain';
    }

    if (provider === 'designly') {
      url = 'accounts';
    }

    if (provider === 'idashboard') {
      url = 'idashboard';
    }

    let isAdminLink = '/user';
    const isAdminData = localStorage.getItem('isAdmin');

    if ( isAdminData === 'yes' ) {
      isAdminLink = '/admin';
    }

    if ( provider === 'mydesktop') {
      isAdminLink = '';
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/' + url + '/refresh/' + groupid + '/' + agentid + isAdminLink
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          return response.body;
        }
      }
    );
  }

  cleanAgentName(value: string) {
    return value.replace(/â€™/g, '&#39;');
  }

  getEnvironmentKeys(key: string) {
    try {
      return keys[key];
    } catch (e) {
      console.error(e);
    }

    console.log('wahoo');
    return false;
  }

  fillProposalMergeClass(elementBlock: any, data: any) {
    if (typeof data.proposalClient !== 'undefined' && data.proposalClient) {
      const client = data.proposalClient;
      const allClientsName = data.clients.map((client: ProposalClient) => {
        return client.name
      });

      if (allClientsName) {
        elementBlock.find('.proposal-client-name').html(allClientsName.join(' & '));
      } else {
        elementBlock.find('.proposal-client-name').html('-');
      }

      if (typeof client.email !== 'undefined' && client.email) {
        elementBlock.find('.proposal-client-email').html(client.email);
      } else {
        elementBlock.find('.proposal-client-email').html('-');
      }

      if (typeof client.mobile !== 'undefined' && client.mobile) {
        elementBlock.find('.proposal-client-mobile').html(client.mobile);
      } else {
        elementBlock.find('.proposal-client-mobile').html('-');
      }
    }

    if (typeof data.property !== 'undefined' && data.property) {
      const property = data.property;

      if (typeof property.unit_number !== 'undefined' && property.unit_number) {
        elementBlock.find('.proposal-property-unit-number').html(property.unit_number);
      } else {
        elementBlock.find('.proposal-property-unit-number').html('');
      }

      if (typeof property.street_number !== 'undefined' && property.street_number) {
        elementBlock.find('.proposal-property-street-number').html(property.street_number);
      } else {
        elementBlock.find('.proposal-property-street-number').html('');
      }

      if (typeof property.street !== 'undefined' && property.street) {
        elementBlock.find('.proposal-property-street').html(property.street);
      } else {
        elementBlock.find('.proposal-property-street').html('');
      }

      if (typeof property.suburb !== 'undefined' && property.suburb) {
        elementBlock.find('.proposal-property-suburb').html(property.suburb);
      } else {
        elementBlock.find('.proposal-property-suburb').html('');
      }

      if (typeof property.postcode !== 'undefined' && property.postcode) {
        elementBlock.find('.proposal-property-postcode').html(property.postcode);
      } else {
        elementBlock.find('.proposal-property-postcode').html('');
      }

      if (typeof property.state !== 'undefined' && property.state) {
        elementBlock.find('.proposal-property-state').html(property.state);
      } else {
        elementBlock.find('.proposal-property-state').html('');
      }

      if (typeof property.beds !== 'undefined' && property.beds) {
        elementBlock.find('.proposal-property-beds').html(property.beds);
      } else {
        elementBlock.find('.proposal-property-beds').html('-');
      }

      if (typeof property.baths !== 'undefined' && property.baths) {
        elementBlock.find('.proposal-property-baths').html(property.baths);
      } else {
        elementBlock.find('.proposal-property-baths').html('-');
      }

      if (typeof property.cars !== 'undefined' && property.cars) {
        elementBlock.find('.proposal-property-cars').html(property.cars);
      } else {
        elementBlock.find('.proposal-property-cars').html('-');
      }

      if (typeof property.floor_area !== 'undefined' && property.floor_area) {
        elementBlock.find('.proposal-property-floor-area').html(`${property.floor_area} sqm`);
      } else {
        elementBlock.find('.proposal-property-floor-area').html('-');
      }

      if (typeof property.land_area !== 'undefined' && property.land_area) {
        elementBlock.find('.proposal-property-land-area').html(`${property.land_area} sqm`);
      } else {
        elementBlock.find('.proposal-property-land-area').html('-');
      }

      if (typeof property.method_of_sale !== 'undefined' && property.method_of_sale) {
        elementBlock.find('.proposal-property-methodofsale').html(property.method_of_sale);
      } else {
        elementBlock.find('.proposal-property-methodofsale').html('-');
      }

      if (typeof property.price !== 'undefined' && property.price) {
        elementBlock.find('.proposal-property-displayprice').html(property.price);
      } else {
        elementBlock.find('.proposal-property-displayprice').html('-');
      }

      if (typeof property.sold_price !== 'undefined' && property.sold_price) {
        elementBlock.find('.proposal-property-soldprice').html(property.sold_price);
        elementBlock.find('.proposal-property-saleprice').html(property.sold_price);
      } else {
        elementBlock.find('.proposal-property-soldprice').html('-');
        elementBlock.find('.proposal-property-saleprice').html('-');
      }

      if (typeof property.photo !== 'undefined' && property.photo) {
        elementBlock.find('.proposal-property-image').attr('src', property.photo);
      }

      if (typeof property.selling_price !== 'undefined' && property.selling_price) {
        elementBlock.find('.proposal-property-sellingprice').html(property.selling_price);
      } else {
        elementBlock.find('.proposal-property-sellingprice').html('-');
      }
    }

    if (typeof data.agent !== 'undefined' && data.agent) {
      const agent = data.agent;

      if (typeof agent.firstname !== 'undefined' && agent.firstname) {
        elementBlock.find('.proposal-agent-first-name').html(agent.firstname);
      } else {
        elementBlock.find('.proposal-agent-first-name').html('-');
      }

      if (typeof agent.lastname !== 'undefined' && agent.lastname) {
        elementBlock.find('.proposal-agent-last-name').html(agent.lastname);
      } else {
        elementBlock.find('.proposal-agent-last-name').html('-');
      }

      if (typeof agent.email !== 'undefined' && agent.email) {
        elementBlock.find('.proposal-agent-email').html(agent.email);
      } else {
        elementBlock.find('.proposal-agent-email').html('-');
      }

      if (typeof agent.mobile !== 'undefined' && agent.mobile) {
        elementBlock.find('.proposal-agent-mobile').html(agent.mobile);
      } else {
        elementBlock.find('.proposal-agent-mobile').html('-');
      }

      if (typeof agent.photo !== 'undefined' && agent.photo) {
        elementBlock.find('.proposal-agent-photo').attr('src', agent.photo);
      }

      if (typeof agent.position !== 'undefined' && agent.position) {
        elementBlock.find('.proposal-agent-position').html(agent.position);
      } else {
        elementBlock.find('.proposal-agent-position').html('-');
      }
    }

    if (typeof data.investments !== 'undefined' && data.investments && data.investments.length) {
      const strategyGrid = elementBlock.find('.dsgnly-marketing-schedule-grid');

      if (strategyGrid.length) {
        const strategyItem = strategyGrid.find('.dsgnly-schedule-item').first();

        if (strategyItem) {
          const strategyItemClone = strategyItem.clone();

          if (strategyItemClone) {
            strategyGrid.empty();

            let totalAmount = 0;
            data.investments.forEach((investment: ProposalInvestment) => {
              totalAmount += investment.amount;

              strategyItemClone.find('.dsgnly-schedule-item-title').html(investment.item);
              strategyItemClone.find('.dsgnly-schedule-item-price').html(`$${investment.amount.toLocaleString()}`);

              strategyGrid.append(strategyItemClone.prop('outerHTML'));
            });

            elementBlock.find('.dsgnly-schedule-total-price').html(`$${totalAmount.toLocaleString()}`);
          }
        }
      }
    }

    if (typeof data.advertisementTitle !== 'undefined' && data.advertisementTitle) {
      elementBlock.find('.dsgnly-schedule-title').html(data.advertisementTitle);
    }

    return elementBlock.html();
  }

  fillMergeClass(auth: Auth, elementBlock: any, listing: any, data: any) {
    try {
      const clientSetting = auth.client;
      const groupSetting = clientSetting.group.master;
      const template = typeof data.template !== 'undefined' && data.template ? data.template as Template : null;

      if (template) {
        if (template.category_id === 1) {
          elementBlock.find('table').first().attr('data-propid', listing.id);
        }

        if (listing.images !== null && listing.images.length > 0) {
          const imgEl = elementBlock.find('.md-prop-field-image').attr('src', listing.images[0].url);
          const imgElHighRes = elementBlock.find('.md-prop-field-hiresimage').attr('src', listing.images[0].hiresurl);

          if (template.category_id !== 7 &&
            template.category_id !== 1 &&
            template.category_id !== 2 && template.category_id !== 3) {
            if (typeof imgEl !== 'undefined') {
              imgEl.css({ top: 0, left: 0 });
            }

            if (typeof imgElHighRes !== 'undefined') {
              imgElHighRes.css({ top: 0, left: 0 });
            }
          }

          // tslint:disable-next-line: max-line-length
          const defaultImageUrl = 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/propphoto_spacer.png';
          const blockImages = elementBlock.find('[class*="md-prop-field-image"]');

          if (blockImages.length) {
            blockImages.each(function() {
              const classNames = $(this).attr('class');

              if (classNames) {
                const blockImageClass = classNames.match(/md-prop-field-image-\d+/g);

                if (blockImageClass && blockImageClass.length && blockImageClass.length === 1) {
                  // tslint:disable-next-line: radix
                  const imageIndex = parseInt(String(blockImageClass[0]).substring(20));

                  let listingImageUrl = defaultImageUrl;

                  if (typeof listing.images[imageIndex - 1] !== 'undefined') {
                    listingImageUrl = listing.images[imageIndex - 1].url;
                  }

                  const imgElSub = $(this).attr('src', listingImageUrl);

                  if (template.category_id !== 7 &&
                    template.category_id !== 1 &&
                    template.category_id !== 2 && template.category_id !== 3) {
                    if (typeof imgElSub !== 'undefined') {
                      imgElSub.css({ top: 0, left: 0 });
                    }
                  }
                }
              }
            });
          }

          const blockHiresImages = elementBlock.find('[class*="md-prop-field-hiresimage"]');

          blockHiresImages.each(function() {
            const classNames = $(this).attr('class');

            if (classNames) {
              const blockHiresImageClass = classNames.match(/md-prop-field-hiresimage-\d+/g);

              if (blockHiresImageClass && blockHiresImageClass.length && blockHiresImageClass.length === 1) {
                // tslint:disable-next-line: radix
                const imageIndex = parseInt(String(blockHiresImageClass[0]).substring(25));

                let listingImageUrl = defaultImageUrl;

                if (typeof listing.images[imageIndex - 1] !== 'undefined') {
                  listingImageUrl = listing.images[imageIndex - 1].hiresurl;
                }

                const imgElSub = $(this).attr('src', listingImageUrl);

                if (template.category_id !== 7 &&
                  template.category_id !== 1 &&
                  template.category_id !== 2 && template.category_id !== 3) {
                  if (typeof imgElSub !== 'undefined') {
                    imgElSub.css({ top: 0, left: 0 });
                  }
                }
              }
            }
          });
        }
      }

      if (auth.provider === 'vaultre' && typeof data.fillType === 'undefined') {
        if (listing.floorplans !== null && listing.floorplans.length > 0) {
          const largeFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-lrg');
          const smallFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-sml');

          largeFloorPlans.attr('src',  listing.floorplans[0].url).removeAttr('style');
          smallFloorPlans.attr('src',  listing.floorplans[0].url).removeAttr('style');

          for (let i = 0; i < listing.floorplans.length; i++) {
            const idx = i + 1;

            elementBlock.find('.md-prop-field-imagefloorplan-sml-' + idx).attr('src',  listing.floorplans[i].url).removeAttr('style');
            elementBlock.find('.md-prop-field-imagefloorplan-lrg-' + idx).attr('src',  listing.floorplans[i].hiresurl).removeAttr('style');
          }
        } else {
          elementBlock.find('.md-prop-field-imagefloorplan-sml').attr('src',  'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/floorplan_placeholder.svg').removeAttr('style');
          elementBlock.find('.md-prop-field-imagefloorplan-lrg').attr('src',  'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/floorplan_placeholder.svg').removeAttr('style');
        }
      }

      if (this.authService.getApp() === 'MyDesign3' || auth.provider === 'domain') {
        if (elementBlock.find('[class*="md-prop-field-bulletpoint-"]').length > 0) {
          for (let i = 0; i < elementBlock.find('[class*="md-prop-field-bulletpoint-"]').length; i++) {
            const bullet_number = i + 1;

            if (typeof listing.bulletpoints[i] !== 'undefined') {
              elementBlock.find('.md-prop-field-bulletpoint-' + bullet_number).html(listing.bulletpoints[i].item);
            } else {
              elementBlock.find('.md-prop-field-bulletpoint-' + bullet_number).html('');
            }
          }
        }
      }

      if (typeof listing.auctiondate !== 'undefined' && listing.auctiondate != null) {
        elementBlock.find('.md-prop-field-listing-type').html('For Auction');
        elementBlock.find('.md-prop-field-rh-status').html('Guide');
        elementBlock.find('.md-prop-field-gh-status').html('For Auction');

        if (listing.listing_status !== 'settled' || listing.listing_status !== 'unconditional'
          || listing.listing_status !== 'settled,unconditional') {
          const dateToday = Date.parse(new Date().toString());

          let dateAuction = 0;
  
          if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
            dateAuction = Date.parse(listing.auctiondateformat.toString());
          }
          
          if (dateAuction >= dateToday) {
            elementBlock.find('.md-prop-field-status').html('For Auction');
          } else {
            if (listing.portalstatus !== null && listing.portalstatus === 'conditional') {
              elementBlock.find('.md-prop-field-status').html('Under Offer');
            } else {
              elementBlock.find('.md-prop-field-status').html('For Sale');
            }
          }
        }

        let auctionDateFormat = '';

        if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat) {
          auctionDateFormat = moment(listing.auctiondateformat).format('dddd Do MMMM, h:mmA');

          elementBlock.find('.md-prop-field-auctiondate').html(listing.auctiondate);
          elementBlock.find('.md-prop-field-auctiondate-title').html('<b>Auction</b> ' + auctionDateFormat);
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('<b>Auction</b> ' + auctionDateFormat + '<br>');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('<b>Auction</b><br> ' + auctionDateFormat);
        } else {
          elementBlock.find('.md-prop-field-auctiondate').html('');
          elementBlock.find('.md-prop-field-auctiondate-title').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-auctiondate').html('');
        elementBlock.find('.md-prop-field-auctiondate-title').html('');
        elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        if (listing.listing_type === 'sale') {
          elementBlock.find('.md-prop-field-listing-type').html('For Sale');
          elementBlock.find('.md-prop-field-status').html('For Sale');
          elementBlock.find('.md-prop-field-rh-status').html('Guide');
          elementBlock.find('.md-prop-field-gh-status').html('For Sale');
        }
        if (listing.listing_type === 'sale' && listing.listing_category === 'commercial') {
          elementBlock.find('.md-prop-field-rh-status').html('Sale');
          elementBlock.find('.md-prop-field-gh-status').html('Sale');
        }
        if (listing.listing_type === 'rent') {
          if (auth.provider === 'vaultre') {
            elementBlock.find('.md-prop-field-listing-type').html('For Lease');
            if (this.authService.isUk()) {
              elementBlock.find('.md-prop-field-status').html('For Rent');
            } else {
              elementBlock.find('.md-prop-field-status').html('For Lease');
            }
            elementBlock.find('.md-prop-field-gh-status').html('For Lease');
          } else {
            elementBlock.find('.md-prop-field-listing-type').html('For Rent');
            elementBlock.find('.md-prop-field-status').html('For Rent');
            elementBlock.find('.md-prop-field-gh-status').html('For Rent');
          }
          elementBlock.find('.md-prop-field-rh-status').html('Lease');
        }
        if (listing.listing_type === 'rent' && listing.listing_category === 'commercial') {
          elementBlock.find('.md-prop-field-listing-type').html('For Lease');
          if (this.authService.isUk()) {
            elementBlock.find('.md-prop-field-status').html('For Rent');
          } else {
            elementBlock.find('.md-prop-field-status').html('For Lease');
          }
          elementBlock.find('.md-prop-field-rh-status').html('Lease');
          elementBlock.find('.md-prop-field-gh-status').html('For Lease');
        }
        if (listing.listing_type === 'rent' && (listing.listing_status === 'settled' || listing.listing_status === 'leased')) {
          elementBlock.find('.md-prop-field-status').html('Leased');
          elementBlock.find('.md-prop-field-rh-status').html('Leased');
          elementBlock.find('.md-prop-field-gh-status').html('Leased');
        }
        if (listing.listing_status === 'leased' && listing.listing_category === 'commercial') {
          elementBlock.find('.md-prop-field-rh-status').html('Leased');
          elementBlock.find('.md-prop-field-gh-status').html('Leased');
        }
        if (listing.listing_type === 'sale' && (listing.listing_status === 'settled' || listing.listing_type === 'sold')) {
          elementBlock.find('.md-prop-field-status').html('Sold');
          elementBlock.find('.md-prop-field-rh-status').html('Sold');
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }
        if (listing.listing_category === 'holidayRental') {
          elementBlock.find('.md-prop-field-status').html('Holiday Rental');
          elementBlock.find('.md-prop-field-gh-status').html('Holiday Rental');
        }

        if (listing.listing_status === 'unconditional') {
          elementBlock.find('.md-prop-field-status').html('Sold');
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }

        if (listing.listing_status === 'conditional') {
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }

        if (listing.listing_status === 'leased') {
          elementBlock.find('.md-prop-field-status').html('Leased');
          elementBlock.find('.md-prop-field-gh-status').html('Leased');
        }

        if (listing.listing_status === 'settled,unconditional') {
          elementBlock.find('.md-prop-field-status').html('Sold');
          elementBlock.find('.md-prop-field-gh-status').html('Sold');
        }

        if (listing.portalstatus === 'conditional') {
          elementBlock.find('.md-prop-field-status').html('Under Offer');
        }
      }

      if (auth.provider === 'idashboard') {
        let statusTitle = this.capitalizeFirstLetter(listing.listing_status);

        if (listing.listing_status === 'appraisal') {
          statusTitle = 'Appraisal';
        }

        if (listing.listing_status === 'listing' && listing.listing_type !== 'lease') {
          statusTitle = 'For Sale';
        }

        if (listing.listing_type === 'lease' && listing.listing_status !== 'leased') {
          statusTitle = 'For Lease';
        }

        if (listing.listing_status === 'application') {
          statusTitle = 'For Lease';
        }

        elementBlock.find('.md-prop-field-status').html(statusTitle);
      }

      if (typeof listing.saleLifeId !== 'undefined' &&
      listing.saleLifeId !== null &&
      listing.listing_type === 'sale' &&
      listing.listing_status !== 'unconditional' &&
      listing.listing_status !== 'settled' && listing.listing_status !== 'settled,unconditional') {
        elementBlock.find('.md-prop-field-lifeid').html(listing.saleLifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('L' + listing.saleLifeId);
      } else if (typeof listing.leaseLifeId !== 'undefined' &&
      listing.leaseLifeId !== null && listing.listing_type === 'rent' &&
      listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled' &&
      listing.listing_status !== 'settled,unconditional') {
        elementBlock.find('.md-prop-field-lifeid').html(listing.leaseLifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('R' + listing.leaseLifeId);
      } else if (typeof listing.lifeId !== 'undefined' && listing.lifeId !== null &&
      (listing.listing_status === 'unconditional' || listing.listing_status === 'settled' ||
      listing.listing_status === 'settled,unconditional')) {
        elementBlock.find('.md-prop-field-lifeid').html(listing.lifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('L' + listing.lifeId);
      } else if (typeof listing.leasedlifeId !== 'undefined' && listing.leasedlifeId !== null &&
      listing.listing_status === 'leased') {
        elementBlock.find('.md-prop-field-lifeid').html(listing.leasedlifeId);
        elementBlock.find('.md-prop-field-lifeid-alt').html('R' + listing.leasedlifeId);
      } else {
        elementBlock.find('.md-prop-field-lifeid').html('');
        elementBlock.find('.md-prop-field-lifeid-alt').html('');
      }

      if (typeof listing.tenureortitletype !== 'undefined' && listing.tenureortitletype !== null) {
        elementBlock.find('.md-prop-field-titletype').html(listing.tenureortitletype);
        elementBlock.find('.md-prop-field-titletype-title').html('<b>Title</b>&nbsp' + listing.tenureortitletype);
        elementBlock.find('.md-prop-field-titletype-title-br').html('<b>Title</b>&nbsp' + listing.tenureortitletype + '<br>');
      } else {
        elementBlock.find('.md-prop-field-titletype').html('');
        elementBlock.find('.md-prop-field-titletype-title').html('');
        elementBlock.find('.md-prop-field-titletype-title-br').html('');
      }

      if (typeof listing.lotnumber !== 'undefined' && listing.lotnumber !== null && listing.lotnumber !== '') {
        elementBlock.find('.md-prop-field-lotnumber').html(listing.lotnumber);
        elementBlock.find('.md-prop-field-lotnumber-title').html('<b>Lot</b>&nbsp' + listing.lotnumber);
        elementBlock.find('.md-prop-field-lotnumber-title-br').html('<b>Lot</b>&nbsp' + listing.lotnumber + '<br>');
      } else {
        elementBlock.find('.md-prop-field-lotnumber').html('');
        elementBlock.find('.md-prop-field-lotnumber-title').html('');
        elementBlock.find('.md-prop-field-lotnumber-title-br').html('');
      }

      if (typeof listing.certificateoftitle !== 'undefined' && listing.certificateoftitle !== null && listing.certificateoftitle !== '') {
        elementBlock.find('.md-prop-field-ct').html(listing.certificateoftitle);
        elementBlock.find('.md-prop-field-ct-title').html('<b>CT</b>&nbsp' + listing.certificateoftitle);
        elementBlock.find('.md-prop-field-ct-title-br').html('<b>CT</b>&nbsp' + listing.certificateoftitle + '<br>');
      } else {
        elementBlock.find('.md-prop-field-ct').html('');
        elementBlock.find('.md-prop-field-ct-title').html('');
        elementBlock.find('.md-prop-field-ct-title-br').html('');
      }

      if (typeof listing.setSaleDate !== 'undefined' && listing.setSaleDate !== null && listing.setSaleDate !== '') {
        elementBlock.find('.md-prop-field-setsaledate').html(listing.setSaleDate);
      } else {
        elementBlock.find('.md-prop-field-setsaledate').html('');
      }

      const listingHeading = listing.heading !== null ? listing.heading : '';
      elementBlock.find('.md-prop-field-heading').html(listingHeading);

      if (typeof listing.beds !== 'undefined' && listing.beds !== null && listing.beds !== '' && listing.beds !== 0) {
        elementBlock.find('.md-prop-field-beds').html(listing.beds);

        const bedsHarcourts = '<span class="md-prop-field-beds" style="display: inline-block; vertical-align: middle">'+listing.beds+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_bed.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" />';

        elementBlock.find('.md-prop-field-beds-harcourts').html(bedsHarcourts);

      } else {
        elementBlock.find('.md-prop-field-beds').html('-');
        elementBlock.find('.md-prop-field-beds-harcourts').html('');
      }

      if (typeof listing.baths !== 'undefined' && listing.baths !== null && listing.baths !== '' && listing.baths !== 0) {
        elementBlock.find('.md-prop-field-baths').html(listing.baths);

        const bathsHarcourts = '<span class="md-prop-field-baths" style="display: inline-block; vertical-align: middle">'+listing.baths+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_bath.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-baths-harcourts').html(bathsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-baths').html('-');
        elementBlock.find('.md-prop-field-baths-harcourts').html('');
      }

      if (typeof listing.toilets !== 'undefined' && listing.toilets !== null && listing.toilets !== '' && listing.toilets !== 0) {
        elementBlock.find('.md-prop-field-toilets').html(listing.toilets);

        const toiletsHarcourts = '<span class="md-prop-field-toilets" style="display: inline-block; vertical-align: middle">'+listing.toilets+'</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_toilet.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-toilets-harcourts').html(toiletsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-toilets').html('-');
        elementBlock.find('.md-prop-field-toilets-harcourts').html('');
      }

      if (typeof listing.cars !== 'undefined' && listing.cars !== null && listing.cars !== '' && listing.cars !== 0) {
        elementBlock.find('.md-prop-field-cars').html(listing.cars);
        elementBlock.find('.md-prop-field-cars-title').html('<b>Parking</b>&nbsp;' + listing.cars);
        elementBlock.find('.md-prop-field-cars-title-br').html('<b>Parking</b>&nbsp;' + listing.cars + '<br>');

        const carsHarcourts = '<span class="md-prop-field-cars" style="display: inline-block; vertical-align: middle">'+listing.cars+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_car.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-cars-harcourts').html(carsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-cars').html('-');
        elementBlock.find('.md-prop-field-cars-title').html('-');
        elementBlock.find('.md-prop-field-cars-title-br').html('-');
        elementBlock.find('.md-prop-field-cars-harcourts').html('');
      }

      if (typeof listing.carports !== 'undefined' && listing.carports !== null && listing.carports !== '' && listing.carports !== 0) {
        elementBlock.find('.md-prop-field-carports').html(listing.carports);

        const carsportsHarcourts = '<span class="md-prop-field-carports" style="display: inline-block; vertical-align: middle">'+listing.carports+'</span><span>&nbsp;&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_car_port.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-carports-harcourts').html(carsportsHarcourts);
      } else {
        elementBlock.find('.md-prop-field-carports').html('-');
        elementBlock.find('.md-prop-field-carports-harcourts').html('');
      }

      if (typeof listing.garages !== 'undefined' && listing.garages !== null && listing.garages !== '' && listing.garages !== 0) {
        elementBlock.find('.md-prop-field-garages').html(listing.garages);

        const garagesHarcourts = '<span class="md-prop-field-garages" style="display: inline-block; vertical-align: middle">'+listing.garages+'</span><span>&nbsp;</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_car.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';

        elementBlock.find('.md-prop-field-garages-harcourts').html(garagesHarcourts);
      } else {
        elementBlock.find('.md-prop-field-garages').html('-');
        elementBlock.find('.md-prop-field-garages-harcourts').html('');
      }

      if (typeof listing.openparkingspaces !== 'undefined' && listing.openparkingspaces !== null &&
      listing.openparkingspaces !== '' && listing.openparkingspaces !== 0) {
        elementBlock.find('.md-prop-field-open-parking').html(listing.openparkingspaces);
      } else {
        elementBlock.find('.md-prop-field-open-parking').html('');
      }

      elementBlock.find('.md-prop-field-price').html(listing.price);
      elementBlock.find('.md-prop-field-price-alt').html(listing.price);

      if (typeof listing.priceMain !== 'undefined' && listing.priceMain) {
        if (listing.listing_status === 'sold' && typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
          if (typeof listing.showsaleprice !== 'undefined' && listing.showsaleprice !== null) {
            if (listing.showsaleprice === true) {
              elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
            } else {
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          } else {
            elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
          }
        } else {
          elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
        }
      } else {
        elementBlock.find('.md-prop-field-pricemain').html('');
      }

      if (typeof listing.displayprice !== 'undefined' && listing.displayprice) {
        elementBlock.find('.md-prop-field-displayprice').html(listing.displayprice);
      } else {
        elementBlock.find('.md-prop-field-displayprice').html('');
      }

      if (typeof listing.searchprice !== 'undefined' && listing.searchprice) {
        elementBlock.find('.md-prop-field-searchprice').html(listing.searchprice);
      } else {
        elementBlock.find('.md-prop-field-searchprice').html('');
      }

      if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
        if (typeof listing.showsaleprice !== 'undefined' && listing.showsaleprice !== null) {
          if (listing.showsaleprice === true) {
            elementBlock.find('.md-prop-field-saleprice').html(listing.salepricecurrent);
          } else {
            elementBlock.find('.md-prop-field-saleprice').html('');
          }
        } else {
          elementBlock.find('.md-prop-field-saleprice').html(listing.salepricecurrent);
        }
      } else {
        elementBlock.find('.md-prop-field-saleprice').html('');
      }

      if (typeof listing.gstRate !== 'undefined' && listing.gstRate) {
        const gstrateString = `${listing.gstRate}%`;
        elementBlock.find('.md-prop-field-gstrate').html(gstrateString);
      } else {
        elementBlock.find('.md-prop-field-gstrate').html('');
      }

      if (typeof listing.saleprice !== 'undefined') {
        if (listing.saleprice === null) {
          elementBlock.find('.md-prop-field-price').html('Contact Agent');
          elementBlock.find('.md-prop-field-price-alt').html('Contact Agent');
        } else {
          elementBlock.find('.md-prop-field-price').html(listing.saleprice);
          elementBlock.find('.md-prop-field-price-alt').html(listing.saleprice);
        }
      }

      if (listing.listing_category === 'commercial') {
        elementBlock.find('.md-prop-field-cars').html('');
        elementBlock.find('.md-prop-field-cars-title').html('');

        if (listing.cars !== null && listing.cars !== 0) {
          elementBlock.find('.md-prop-field-cars').html(listing.cars);
          elementBlock.find('.md-prop-field-cars-title').html('<b>Parking</b>&nbsp;' + listing.cars);
          elementBlock.find('.md-prop-field-cars-title-br').html('<b>Parking</b>&nbsp;' + listing.cars + '<br>');
        }
      }

      if (typeof listing.rpdp !== 'undefined' && listing.rpdp !== null && listing.rpdp !== '') {
        elementBlock.find('.md-prop-field-rpdp').html(listing.rpdp);
        elementBlock.find('.md-prop-field-rpdp-title').html('<b>RPD</b>&nbsp' + listing.rpdp);
        elementBlock.find('.md-prop-field-rpdp-title-br').html('<b>RPD</b>&nbsp' + listing.rpdp + '<br>');
      } else {
        elementBlock.find('.md-prop-field-rpdp').html('');
        elementBlock.find('.md-prop-field-rpdp-title').html('');
        elementBlock.find('.md-prop-field-rpdp-title-br').html('');
      }

      if (this.authService.getApp() === 'Designly') {
        const dateToday = Date.parse(new Date().toString());

        let dateAuction = 0;

        if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
          dateAuction = Date.parse(listing.auctiondateformat.toString());
        }

        if (typeof listing.auctiondate !== 'undefined' && listing.auctiondate !== null &&
        (typeof data.s2StatusValue !== 'undefined' && data.s2StatusValue && data.s2StatusValue) !== 'sold' && dateAuction >= dateToday) {
          elementBlock.find('.md-prop-field-price').html('');
          elementBlock.find('.md-prop-field-price-alt').html('');
          elementBlock.find('.md-prop-field-pricemain').html('');
        } else {
          if (data.s2StatusValue === 'sold') {
            if (typeof listing.showsaleprice !== 'undefined' && listing.showsaleprice !== null) {
              if (listing.showsaleprice === true) {
                if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
                  elementBlock.find('.md-prop-field-price').html(listing.salepricecurrent);
                  elementBlock.find('.md-prop-field-price-alt').html(listing.salepricecurrent);
                  elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
                }
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-price-alt').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            } else {
              if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
                elementBlock.find('.md-prop-field-price').html(listing.salepricecurrent);
                elementBlock.find('.md-prop-field-price-alt').html(listing.salepricecurrent);
                elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
              }
            }
          } else {
            if (typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
              elementBlock.find('.md-prop-field-price').html(listing.pricetext);
              elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
              if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            } else {
              if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                elementBlock.find('.md-prop-field-price-alt').html(listing.priceMain);
                elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-price-alt').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            }
          }
          elementBlock.find('.md-prop-field-auctiondate').html('');
          elementBlock.find('.md-prop-field-auctiondate-title').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        }

        if (listing.methodofsale === 'Set Sale Date' && data.s2StatusValue !== 'sold') {
          if (typeof listing.setSaleDate !== 'undefined' && listing.setSaleDate !== null) {
            let dateSetSaleDate = 0;

            dateSetSaleDate = Date.parse(listing.setSaleDateFormat.toString());
            if (dateSetSaleDate > dateToday) {
              elementBlock.find('.md-prop-field-price').html('');
              elementBlock.find('.md-prop-field-price-alt').html('');
              elementBlock.find('.md-prop-field-pricemain').html('');
            } else {
              if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
                elementBlock.find('.md-prop-field-price').html(listing.pricetext);
                elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
                if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                  elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                } else {
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              } else {
                if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                  elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                  elementBlock.find('.md-prop-field-price-alt').html(listing.priceMain);
                  elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                } else {
                  elementBlock.find('.md-prop-field-price').html('');
                  elementBlock.find('.md-prop-field-price-alt').html('');
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              }
            }
          }
        }

        if (typeof listing.methodofsale !== 'undefined' && data.s2StatusValue !== 'sold') {
          if (typeof listing.tenderdateformat !== 'undefined' && listing.tenderdateformat !== null) {
            elementBlock.find('.md-prop-field-status').html('For Sale by Tender');

            if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
              elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
            } else {
              elementBlock.find('.md-prop-field-price-alt').html('Sale by tender');
            }

            if (listing.portalstatus === 'conditional') {
              elementBlock.find('.md-prop-field-status').html('Under Offer');
            }

            let dateTenderDateFormat = 0;

            dateTenderDateFormat = Date.parse(listing.tenderdateformat.toString());

            if (dateTenderDateFormat <= dateToday) {
              elementBlock.find('.md-prop-field-price').html('');
              elementBlock.find('.md-prop-field-pricemain').html('');
            } else {
              if ( typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext ) {
                elementBlock.find('.md-prop-field-price').html(listing.pricetext);
                if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                  elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                } else {
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              } else {
                if ( typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain ) {
                  elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                  elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
                } else {
                  elementBlock.find('.md-prop-field-price').html('');
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              }
            }
          }
        }

        if ( auth.client.group_id === 12 ) {
          elementBlock.find('[href*="rh.com.au"]').each(function() {
              let propertyUrl = 'https://www.raineandhorne.com.au/';

            if (listing.saleLifeId !== null && listing.listing_type === 'sale') {
              propertyUrl = `${propertyUrl}L${listing.saleLifeId}`;
            }

            if (listing.leaseLifeId !== null && listing.listing_type === 'rent') {
              propertyUrl = `${propertyUrl}R${listing.leaseLifeId}`;
            }

            $(this).attr('href', propertyUrl);
          });
        }

        if (auth.client.group_id === 19) {
          elementBlock.find('[href*="rhcommercial.com.au"]').each(function () {
            let propertyUrl = 'https://rhcommercial.com.au/';

            if (listing.saleLifeId !== null && (listing.listing_type === 'sale' || listing.listing_category === 'commercial')) {
              propertyUrl = `${propertyUrl}L${listing.saleLifeId}`;
            }

            if (listing.leaseLifeId !== null && listing.listing_type === 'rent') {
              propertyUrl = `${propertyUrl}R${listing.leaseLifeId}`;
            }

            $(this).attr('href', propertyUrl);
          });
        }
      }

      if (this.authService.isUk()) {
        if (typeof listing.fulladdress !== 'undefined' && listing.fulladdress !== null && listing.fulladdress !== '') {
          elementBlock.find('.md-prop-field-fulladdress').html(listing.fulladdress);
        } else {
          elementBlock.find('.md-prop-field-fulladdress').html('');
        }

        if (typeof listing.fulladdress_br !== 'undefined' && listing.fulladdress_br !== null && listing.fulladdress_br !== '') {
          elementBlock.find('.md-prop-field-fulladdress-br').html(listing.fulladdress_br);
        } else {
          elementBlock.find('.md-prop-field-fulladdress-br').html('');
        }
      }

      let address_unitnum = '';
      let webbook_street = '';
      let webbook_suburb = '';
      if (listing.address_unitnum !== null) {
        address_unitnum = listing.address_unitnum + '/';
        elementBlock.find('.md-prop-field-unitnum').html(listing.address_unitnum);
        webbook_street = listing.address_unitnum;
      }

      if (clientSetting.country === 'New Zealand') {
        const addressNz = address_unitnum + ' ' + listing.address_streetnum + ' ' + listing.address_street + ', ' + listing.address_suburb;

        elementBlock.find('.md-prop-field-address').html(addressNz);
      } else {
        elementBlock.find('.md-prop-field-address').html(listing.displayaddress);
      }

      if (listing.address_streetnum !== null) {
        elementBlock.find('.md-prop-field-streetnum').html(address_unitnum + listing.address_streetnum);
        webbook_street = `${webbook_street} ${listing.address_streetnum}`;
      }
      if (listing.address_street !== null) {
        elementBlock.find('.md-prop-field-street').html(listing.address_street);
        webbook_street = `${webbook_street} ${listing.address_street}`;
      }
      if (listing.address_suburb !== null) {
        elementBlock.find('.md-prop-field-suburb').html(listing.address_suburb);
        webbook_suburb = listing.address_suburb;
      }
      if (listing.address_postcode !== null) {
        elementBlock.find('.md-prop-field-postcode').html(listing.address_postcode);
      } else {
        elementBlock.find('.md-prop-field-postcode').html('');
      }
      if (listing.address_state !== null) {
        elementBlock.find('.md-prop-field-state').html(listing.address_state);
      }

      elementBlock.find('.md-prop-field-landarea').html(listing.landarea);
      if (typeof listing.landarea !== 'undefined' && listing.landarea !== '0' && listing.landarea !== null) {
        elementBlock.find('.md-prop-field-landareatype').html(listing.landareatype);
        elementBlock.find('.md-prop-field-landarea-title').html('<b>Land size</b>&nbsp' + listing.landarea + listing.landareatype);
        elementBlock.find('.md-prop-field-landarea-title-br').html('<b>Land size</b>&nbsp' + listing.landarea + listing.landareatype + '<br>');
        elementBlock.find('.md-prop-field-landareafull').html(listing.landarea + listing.landareatype);
        elementBlock.find('.md-prop-field-landareafull-title-br').html('<b>Land size</b>&nbsp' + listing.landarea + listing.landareatype + '<br>');

        const landareaHarcourts = '<span class="md-prop-field-landarea" style="display: inline-block; vertical-align: middle">'+listing.landarea+'</span><img src="https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/4349/Images/custom_icon_land.png" alt="Icon" height="26" style="display: inline-block; vertical-align: middle" /><span>&nbsp;&nbsp;</span>';
        elementBlock.find('.md-prop-field-landarea-harcourts').html(landareaHarcourts);
      } else {
        elementBlock.find('.md-prop-field-landareatype').html('');
        elementBlock.find('.md-prop-field-landarea-title').html('');
        elementBlock.find('.md-prop-field-landarea-title-br').html('');
        elementBlock.find('.md-prop-field-landareafull').html('');
        elementBlock.find('.md-prop-field-landareafull-title').html('');
        elementBlock.find('.md-prop-field-landareafull-title-br').html('');
        elementBlock.find('.md-prop-field-landarea-harcourts').html('');
      }

      if (typeof listing.buildingarea !== 'undefined' && listing.buildingarea !== '0' && listing.buildingarea !== null) {
        // tslint:disable-next-line: max-line-length
        elementBlock.find('.md-prop-field-buildingarea-title').html('<b>Building size</b>&nbsp' + listing.buildingarea + listing.buildingareatype);
        elementBlock.find('.md-prop-field-buildingarea-title-br').html('<b>Building size</b>&nbsp' + listing.buildingarea + listing.buildingareatype + '<br>');
        elementBlock.find('.md-prop-field-buildingareafull').html(listing.buildingarea + listing.buildingareatype);
        elementBlock.find('.md-prop-field-buildingareafull-title').html('<b>Building size</b>&nbsp' + listing.buildingarea + listing.buildingareatype);
        elementBlock.find('.md-prop-field-buildingareafull-title-br').html('<b>Building size</b>&nbsp' + listing.buildingarea + listing.buildingareatype + '<br>');
      } else {
        elementBlock.find('.md-prop-field-buildingarea-title').html('');
        elementBlock.find('.md-prop-field-buildingarea-title-br').html('');
        elementBlock.find('.md-prop-field-buildingareafull').html('');
        elementBlock.find('.md-prop-field-buildingareafull-title').html('');
        elementBlock.find('.md-prop-field-buildingareafull-title-br').html('');
      }

      const listingDescription = String(listing.description).replace(/â€¢/g, '•');

      elementBlock.find('.md-prop-field-description').html(listingDescription);

      let trimmedString50 = listingDescription.substring(0, 50);
      let trimmedString100 = listingDescription.substring(0, 100);
      let trimmedString200 = listingDescription.substring(0, 200);
      let trimmedString300 = listingDescription.substring(0, 300);
      let trimmedString400 = listingDescription.substring(0, 400);
      let trimmedString500 = listingDescription.substring(0, 500);
      let trimmedString600 = listingDescription.substring(0, 600);

      if (listingDescription.length <= 50) {
        trimmedString50 = listingDescription;
      } else {
        trimmedString50 = trimmedString50.substr(0, Math.min(trimmedString50.length, trimmedString50.lastIndexOf(' ')));
        trimmedString50 = trimmedString50 + '...';
      }
      elementBlock.find('.md-prop-field-description-50').html(trimmedString50);

      if (listingDescription.length <= 100) {
        trimmedString100 = listingDescription;
      } else {
        trimmedString100 = trimmedString100.substr(0, Math.min(trimmedString100.length, trimmedString100.lastIndexOf(' ')));
        trimmedString100 = trimmedString100 + '...';
      }
      elementBlock.find('.md-prop-field-description-100').html(trimmedString100);

      if (listingDescription.length <= 200) {
        trimmedString200 = listingDescription;
      } else {
        trimmedString200 = trimmedString200.substr(0, Math.min(trimmedString200.length, trimmedString200.lastIndexOf(' ')));
        trimmedString200 = trimmedString200 + '...';
      }
      elementBlock.find('.md-prop-field-description-200').html(trimmedString200);

      if (listingDescription.length <= 300) {
        trimmedString300 = listingDescription;
      } else {
        trimmedString300 = trimmedString300.substr(0, Math.min(trimmedString300.length, trimmedString300.lastIndexOf(' ')));
        trimmedString300 = trimmedString300 + '...';
      }
      elementBlock.find('.md-prop-field-description-300').html(trimmedString300);

      if (listingDescription.length <= 400) {
        trimmedString400 = listingDescription;
      } else {
        trimmedString400 = trimmedString400.substr(0, Math.min(trimmedString400.length, trimmedString400.lastIndexOf(' ')));
        trimmedString400 = trimmedString400 + '...';
      }
      elementBlock.find('.md-prop-field-description-400').html(trimmedString400);

      if (listingDescription.length <= 500) {
        trimmedString500 = listingDescription;
      } else {
        trimmedString500 = trimmedString500.substr(0, Math.min(trimmedString500.length, trimmedString500.lastIndexOf(' ')));
        trimmedString500 = trimmedString500 + '...';
      }
      elementBlock.find('.md-prop-field-description-500').html(trimmedString500);

      if (listingDescription.length <= 600) {
        trimmedString600 = listingDescription;
      } else {
        trimmedString600 = trimmedString600.substr(0, Math.min(trimmedString600.length, trimmedString600.lastIndexOf(' ')));
        trimmedString600 = trimmedString600 + '...';
      }
      elementBlock.find('.md-prop-field-description-600').html(trimmedString600);

      if (typeof listing.mainDescription !== 'undefined' && listing.mainDescription !== '' && listing.mainDescription !== null) {
        elementBlock.find('.md-prop-main-description').html(listing.mainDescription);
      } else {
        elementBlock.find('.md-prop-main-description').html('');
      }

      if (typeof listing.brochureDescription !== 'undefined' && listing.brochureDescription !== '' && listing.brochureDescription !== null) {
        elementBlock.find('.md-prop-brochure-description').html(listing.brochureDescription);
      } else {
        elementBlock.find('.md-prop-brochure-description').html('');
      }

      const listing_id = listing.id ? listing.id : ' ';
      elementBlock.find('.md-prop-field-id').html(listing_id);

      const listing_refid = listing.refid ? listing.refid : ' ';
      elementBlock.find('.md-prop-field-refid').html(listing_refid);

      const listing_propertytype = listing.propertytype ? listing.propertytype : ' ';
      elementBlock.find('.md-prop-field-proptype').html(listing_propertytype);

      if (listing.listing_category !== 'land') {
        if (typeof listing.buildingarea !== 'undefined' && listing.buildingarea !== '0' && listing.buildingarea !== null) {
          elementBlock.find('.md-prop-field-buildingarea').html(listing.buildingarea);
        } else {
          elementBlock.find('.md-prop-field-buildingarea').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-buildingarea').html('');
      }

      const listing_landarea = listing.landarea ? listing.landarea : ' ';
      elementBlock.find('.md-prop-field-landarea').html(listing_landarea);

      const listing_tenderdate = listing.tenderdate ? listing.tenderdate : ' ';
      elementBlock.find('.md-prop-field-tenderenddate').html(listing_tenderdate);

      let listing_agent1_name = ' ';
      let listing_agent1_imageurl = '';
      let listing_agent1_email = ' ';
      let listing_agent1_position = ' ';

      let listing_agent2_name = ' ';
      let listing_agent2_imageurl = '';
      let listing_agent2_email = ' ';
      let listing_agent2_position = ' ';

      if (listing.agent2 !== null) {
        listing_agent2_position = typeof listing.agent2.position !== 'undefined' && listing.agent2.position ? listing.agent2.position : ' ';
        elementBlock.find('.md-prop-field-lposition2').html(listing_agent2_position);
      } else {
        elementBlock.find('.md-prop-field-lposition2').html('');
        if (elementBlock.find('.element-listing-prop-two-agents')) {
          elementBlock.find('.md-prop-field-lprofile2').html('');
          elementBlock.find('.md-prop-field-profile2').html('');
          elementBlock.find('.md-prop-field-limage2').html('');
          elementBlock.find('.md-prop-field-limage2').css('display', 'none');
          elementBlock.find('.md-prop-field-lname2').html('');
          elementBlock.find('.md-prop-field-lmobile2').html('');
          elementBlock.find('.md-prop-field-lphone2').html('');
          elementBlock.find('.agent-listing-2').css('display', 'none');
        }
        if (elementBlock.find('.element-agent-single-prop-delete')) {
          elementBlock.find('.element-agent-single-prop-delete').remove();
        }
      }
      if (this.authService.getApp() === 'MyDesign3') {
        if (typeof listing.rates.water.amount !== 'undefined' && listing.rates.water.amount !== null) {
          elementBlock.find('.md-prop-field-waterrates').html(listing.rates.water.amount);
          elementBlock.find('.md-prop-field-waterrates-title').html('<b>Water Rates</b> ' + listing.rates.water.amount);
          elementBlock.find('.md-prop-field-waterrates-title-br').html('<b>Water Rates</b> ' + listing.rates.water.amount+ '<br>');
        } else {
          elementBlock.find('.md-prop-field-waterrates').html('');
          elementBlock.find('.md-prop-field-waterrates-title').html('');
          elementBlock.find('.md-prop-field-waterrates-title-br').html('');
        }

        if (typeof listing.rates.strata.amount !== 'undefined' && listing.rates.strata.amount !== null) {
          elementBlock.find('.md-prop-field-stratarates').html(listing.rates.strata.amount);
          elementBlock.find('.md-prop-field-stratarates-title').html('<b>Strata Rates</b> ' + listing.rates.strata.amount);
          elementBlock.find('.md-prop-field-stratarates-title-br').html('<b>Strata Rates</b> ' + listing.rates.strata.amount + '<br>');
        } else {
          elementBlock.find('.md-prop-field-stratarates').html('');
          elementBlock.find('.md-prop-field-stratarates-title').html('');
          elementBlock.find('.md-prop-field-stratarates-title-br').html('');
        }

        if (typeof listing.rates.council.amount !== 'undefined' && listing.rates.council.amount !== null) {
          elementBlock.find('.md-prop-field-councilrate').html(listing.rates.council.amount);
          elementBlock.find('.md-prop-field-councilrate-title').html('<b>Council Rates</b> ' + listing.rates.council.amount);
          elementBlock.find('.md-prop-field-councilrate-title-br').html('<b>Council Rates</b> ' + listing.rates.council.amount + '<br>');
        } else {
          elementBlock.find('.md-prop-field-councilrate').html('');
          elementBlock.find('.md-prop-field-councilrate-title').html('');
          elementBlock.find('.md-prop-field-councilrate-title-br').html('');
        }

        if (typeof listing.agent1 !== 'undefined' && listing.agent1) {
          // tslint:disable-next-line: max-line-length
          listing_agent1_position = typeof listing.agent1.position !== 'undefined' && listing.agent1.position ? listing.agent1.position : ' ';
          elementBlock.find('.md-prop-field-lposition').html(listing_agent1_position);

          if (listing.agent1.firstname && listing.agent1.lastname) {
            listing_agent1_name = listing.agent1.firstname + ' ' + listing.agent1.lastname;
          }
          elementBlock.find('.md-prop-field-lname').html(listing_agent1_name);

          let listing_agent1_mobiledisplay = listing.agent1.mobiledisplay ? listing.agent1.mobiledisplay : ' ';
          listing_agent1_mobiledisplay = listing_agent1_mobiledisplay.replace('+61', '').replace('+64', '').trim();

          if (listing_agent1_mobiledisplay) {
            listing_agent1_mobiledisplay = String(listing_agent1_mobiledisplay);
            if (!listing_agent1_mobiledisplay.startsWith('1800') && listing_agent1_mobiledisplay.charAt(0) !== '0') {
              listing_agent1_mobiledisplay = '0' + listing_agent1_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile').html(listing_agent1_mobiledisplay);

          listing_agent1_email = listing.agent1.email ? listing.agent1.email : ' ';
          elementBlock.find('.md-prop-field-lemail').html(listing_agent1_email);

          listing_agent1_imageurl = listing.agent1.imageurl ? listing.agent1.imageurl : '';
          elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
          if (!listing_agent1_imageurl) {
            elementBlock.find('.md-prop-field-limage').css('display', 'none');
          }

          const listing_agent1_telephone = listing.agent1.telephone ? listing.agent1.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone').html(listing_agent1_telephone);
        }

        if (listing.agent2.firstname && listing.agent2.lastname) {
          listing_agent2_name = listing.agent2.firstname + ' ' + listing.agent2.lastname;
        }
        elementBlock.find('.md-prop-field-lname2').html(listing_agent2_name);

        let listing_agent2_mobiledisplay = listing.agent2.mobiledisplay ? listing.agent2.mobiledisplay : ' ';
        listing_agent2_mobiledisplay = listing_agent2_mobiledisplay.replace('+61', '').replace('+64', '').trim();
        if (listing_agent2_mobiledisplay) {
          listing_agent2_mobiledisplay = String(listing_agent2_mobiledisplay);
          if (!listing_agent2_mobiledisplay.startsWith('1800') && listing_agent2_mobiledisplay.charAt(0) !== '0') {
            listing_agent2_mobiledisplay = '0' + listing_agent2_mobiledisplay;
          }
        }
        elementBlock.find('.md-prop-field-lmobile2').html(listing_agent2_mobiledisplay);

        listing_agent2_email = listing.agent2.email ? listing.agent2.email : ' ';
        elementBlock.find('.md-prop-field-lemail2').html(listing_agent2_email);

        listing_agent2_imageurl = listing.agent2.imageurl ? listing.agent2.imageurl : '';
        elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
        if (!listing_agent2_imageurl) {
          elementBlock.find('.md-prop-field-limage2').css('display', 'none');
        }

        const listing_agent2_telephone = listing.agent2.telephone ? listing.agent2.telephone : ' ';
        elementBlock.find('.md-prop-field-lphone2').html(listing_agent2_telephone);

        if (listing.listing_status === 'settled' || listing.listing_status === 'unconditional' || listing.listing_status === 'notcurrentlylisted') {
          elementBlock.find('.md-prop-field-status').html('Sold');
        }

        if (listing.listing_status === 'conditional') {
          elementBlock.find('.md-prop-field-status').html('For Sale');
        }

        if (listing.listing_status === 'listing' && listing.listing_type === 'sale') {
          const dateToday = Date.parse(new Date().toString());

          let dateAuction = 0;

          if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
            dateAuction = Date.parse(listing.auctiondateformat.toString());
          }

          if (dateAuction >= dateToday) {
            elementBlock.find('.md-prop-field-status').html('For Auction');
          } else {
            elementBlock.find('.md-prop-field-status').html('For Sale');
          }
        }
      }

      if (this.authService.getApp() !== 'MyDesign3') {
        if (listing.agent1 !== null) {
          if (listing.agent1.firstname && listing.agent1.lastname) {
            listing_agent1_name = listing.agent1.firstname + ' ' + listing.agent1.lastname;
          }
          elementBlock.find('.md-prop-field-lname').html(listing_agent1_name);

          let listing_agent1_mobiledisplay = listing.agent1.mobiledisplay ? listing.agent1.mobiledisplay : ' ';
          listing_agent1_mobiledisplay = listing_agent1_mobiledisplay.replace('+61', '').replace('+64', '').trim();

          if (listing_agent1_mobiledisplay) {
            listing_agent1_mobiledisplay = String(listing_agent1_mobiledisplay);
            if (!listing_agent1_mobiledisplay.startsWith('1800') && listing_agent1_mobiledisplay.charAt(0) !== '0') {
              listing_agent1_mobiledisplay = '0' + listing_agent1_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile').html(listing_agent1_mobiledisplay);

          listing_agent1_email = listing.agent1.email ? listing.agent1.email : ' ';
          elementBlock.find('.md-prop-field-lemail').html(listing_agent1_email);

          listing_agent1_position = listing.agent1.position ? listing.agent1.position : ' ';
          elementBlock.find('.md-prop-field-lposition').html(listing_agent1_position);

          listing_agent1_imageurl = listing.agent1.imageurl ? listing.agent1.imageurl : '';
          elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
          if (!listing_agent1_imageurl) {
            elementBlock.find('.md-prop-field-limage').css('display', 'none');
          }

          const listing_agent1_telephone = listing.agent1.telephone ? listing.agent1.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone').html(listing_agent1_telephone);
        }

        if (listing.agent2 !== null) {
          if (listing.agent2.firstname && listing.agent2.lastname) {
            listing_agent2_name = listing.agent2.firstname + ' ' + listing.agent2.lastname;
          }
          let listing_agent2_mobiledisplay = listing.agent2.mobiledisplay ? listing.agent2.mobiledisplay : ' ';
          listing_agent2_mobiledisplay = listing_agent2_mobiledisplay.replace('+61', '').replace('+64', '').trim();
          if (listing_agent2_mobiledisplay) {
            listing_agent2_mobiledisplay = String(listing_agent2_mobiledisplay);
            if (!listing_agent2_mobiledisplay.startsWith('1800') && listing_agent2_mobiledisplay.charAt(0) !== '0') {
              listing_agent2_mobiledisplay = '0' + listing_agent2_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile2').html(listing_agent2_mobiledisplay);

          listing_agent2_position = listing.agent2.position ? listing.agent2.position : ' ';
          elementBlock.find('.md-prop-field-lposition2').html(listing_agent2_position);

          listing_agent2_email = listing.agent2.email ? listing.agent2.email : ' ';
          elementBlock.find('.md-prop-field-lemail2').html(listing_agent2_email);

          listing_agent2_imageurl = listing.agent2.imageurl ? listing.agent2.imageurl : '';
          elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
          if (!listing_agent2_imageurl) {
            elementBlock.find('.md-prop-field-limage2').css('display', 'none');
          }

          listing_agent2_position = listing.agent2.position ? listing.agent2.position : ' ';
          elementBlock.find('.md-prop-field-lposition2').html(listing_agent2_position);

          const listing_agent2_telephone = listing.agent2.telephone ? listing.agent2.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone2').html(listing_agent2_telephone);
        } else {
          elementBlock.find('.md-prop-field-lmobile2').html('');
          elementBlock.find('.md-prop-field-lphone2').html('');
          elementBlock.find('.md-prop-field-limage2').html('');
          elementBlock.find('.md-prop-field-limage2').css('display', 'none');
          elementBlock.find('.md-prop-field-lname2').html('');
          elementBlock.find('.md-prop-field-lemail2').html('');
          elementBlock.find('.md-prop-field-lposition2').html('');
        }

        elementBlock.find('.md-prop-field-lname2').html(listing_agent2_name);
      }

      if (this.authService.getApp() === 'Designly' && auth.provider === 'vaultre') {
        if (typeof listing.rates.water.amount !== 'undefined' && listing.rates.water.amount !== null) {
          elementBlock.find('.md-prop-field-waterrates').html(listing.rates.water.amount);
          elementBlock.find('.md-prop-field-waterrates-title').html('<b>Water Rates</b> ' + listing.rates.water.amount);
          elementBlock.find('.md-prop-field-waterrates-title-br').html('<b>Water Rates</b> ' + listing.rates.water.amount+ '<br>');
        } else {
          elementBlock.find('.md-prop-field-waterrates').html('');
          elementBlock.find('.md-prop-field-waterrates-title').html('');
          elementBlock.find('.md-prop-field-waterrates-title-br').html('');
        }

        if (typeof listing.rates.strata.amount !== 'undefined' && listing.rates.strata.amount !== null) {
          elementBlock.find('.md-prop-field-stratarates').html(listing.rates.strata.amount);
          elementBlock.find('.md-prop-field-stratarates-title').html('<b>Strata Rates</b> ' + listing.rates.strata.amount);
          elementBlock.find('.md-prop-field-stratarates-title-br').html('<b>Strata Rates</b> ' + listing.rates.strata.amount + '<br>');
        } else {
          elementBlock.find('.md-prop-field-stratarates').html('');
          elementBlock.find('.md-prop-field-stratarates-title').html('');
          elementBlock.find('.md-prop-field-stratarates-title-br').html('');
        }

        if (typeof listing.rates.council.amount !== 'undefined' && listing.rates.council.amount !== null) {
          elementBlock.find('.md-prop-field-councilrate').html(listing.rates.council.amount);
          elementBlock.find('.md-prop-field-councilrate-title').html('<b>Council Rates</b> ' + listing.rates.council.amount);
          elementBlock.find('.md-prop-field-councilrate-title-br').html('<b>Council Rates</b> ' + listing.rates.council.amount + '<br>');
        } else {
          elementBlock.find('.md-prop-field-councilrate').html('');
          elementBlock.find('.md-prop-field-councilrate-title').html('');
          elementBlock.find('.md-prop-field-councilrate-title-br').html('');
        }

        elementBlock.find('.md-prop-field-mezzaninearea').html('');
        elementBlock.find('.md-prop-field-mezzaninearea-title').html('');
          elementBlock.find('.md-prop-field-mezzaninearea-title-br').html('');
          elementBlock.find('.md-prop-field-mezzanineareatype').html('');

        if (listing.mezzaninearea !== null && listing.mezzaninearea !== 0) {
          elementBlock.find('.md-prop-field-mezzaninearea').html(listing.mezzaninearea);
          // tslint:disable-next-line: max-line-length
          elementBlock.find('.md-prop-field-mezzaninearea-title').html('<b>Mezzanine</b> ' + listing.mezzaninearea + listing.mezzanineareatype);
          elementBlock.find('.md-prop-field-mezzaninearea-title-br').html('<b>Mezzanine</b> ' + listing.mezzaninearea + listing.mezzanineareatype + '<br>');
        }
        if (listing.mezzanineareatype !== null && listing.mezzaninearea !== 0) {
          elementBlock.find('.md-prop-field-mezzanineareatype').html(listing.mezzanineareatype);
        }

        if (listing.warehousearea !== null && listing.warehousearea !== 0) {
          elementBlock.find('.md-prop-field-warehousearea').html(listing.warehousearea);
          // tslint:disable-next-line: max-line-length
          elementBlock.find('.md-prop-field-warehousearea-title').html('<b>Warehouse Area</b> ' + listing.warehousearea + listing.warehouseareatype);
          elementBlock.find('.md-prop-field-warehousearea-title-br').html('<b>Warehouse Area</b> ' + listing.warehousearea + listing.warehouseareatype + '<br>');
        } else {
          elementBlock.find('.md-prop-field-warehousearea').html('');
          elementBlock.find('.md-prop-field-warehousearea-title').html('');
          elementBlock.find('.md-prop-field-warehousearea-title-br').html('');
        }

        if (listing.warehouseareatype !== null && listing.warehousearea !== 0) {
          elementBlock.find('.md-prop-field-warehouseareatype').html(listing.warehouseareatype);
        } else {
          elementBlock.find('.md-prop-field-warehouseareatype').html('');
        }

        if (listing.officearea !== null && listing.officearea !== 0) {
          elementBlock.find('.md-prop-field-officearea').html(listing.officearea);
          elementBlock.find('.md-prop-field-officearea-title').html('<b>Office Area</b> ' + listing.officearea + listing.officeareatype);
          elementBlock.find('.md-prop-field-officearea-title-br').html('<b>Office Area</b> ' + listing.officearea + listing.officeareatype + '<br>');
        } else {
          elementBlock.find('.md-prop-field-officearea').html('');
          elementBlock.find('.md-prop-field-officearea-title').html('');
          elementBlock.find('.md-prop-field-officearea-title-br').html('');
        }

        if (listing.officeareatype !== null && listing.officearea !== 0) {
          elementBlock.find('.md-prop-field-officeareatype').html(listing.officeareatype);
        } else {
          elementBlock.find('.md-prop-field-officeareatype').html('');
        }

        if (listing.retailarea !== null && listing.retailarea !== 0 && listing.retailarea) {
          elementBlock.find('.md-prop-field-retailarea').html(listing.retailarea);
          elementBlock.find('.md-prop-field-retailarea-title').html('<b>Retail Area</b> ' + listing.retailarea + listing.retailareatype);
          elementBlock.find('.md-prop-field-retailarea-title-br').html('<b>Retail Area</b> ' + listing.retailarea + listing.retailareatype + '<br>');
        } else {
          elementBlock.find('.md-prop-field-retailarea').html('');
          elementBlock.find('.md-prop-field-retailarea-title').html('');
          elementBlock.find('.md-prop-field-retailarea-title-br').html('');
        }

        if (listing.retailareatype !== null && listing.retailareatype !== 0 &&
          listing.retailareatype && listing.retailarea !== null && listing.retailarea !== 0) {
          elementBlock.find('.md-prop-field-retailareatype').html(listing.retailareatype);
        } else {
          elementBlock.find('.md-prop-field-retailareatype').html('');
        }
      }

      if (typeof listing.counciltaxband !== 'undefined' && listing.counciltaxband !== null) {
        elementBlock.find('.md-prop-field-counciltaxband').html(listing.counciltaxband);
      } else {
        elementBlock.find('.md-prop-field-counciltaxband').html('');
      }

      if (typeof listing.buildingareatype !== 'undefined' && listing.listing_category !== 'land' &&
      listing.buildingarea !== '0' && listing.buildingarea !== null) {
        elementBlock.find('.md-prop-field-buildingareatype').html(listing.buildingareatype);
      } else {
        elementBlock.find('.md-prop-field-buildingareatype').html('');
      }

      if (auth.provider === 'mydesktop') {
        elementBlock.find('.md-prop-field-buildingareatype').html('sqm');

        if (listing.sales) {
          if (typeof listing.sales.saledate !== 'undefined' && listing.sales.saledate) {
            elementBlock.find('.md-prop-field-saledate').html(moment(listing.sales.saledate).format('Do, MMM YYYY'));
          } else {
            elementBlock.find('.md-prop-field-saledate').html('');
          }

          if (typeof listing.sales.saleprice !== 'undefined' && listing.sales.saleprice) {
            elementBlock.find('.md-prop-field-price').html(listing.sales.saleprice);
          }
        }
      }

      if (typeof listing.priceonapplication !== 'undefined' && listing.priceonapplication) {
          if (typeof listing.priceonapplication_auction !== 'undefined' && listing.priceonapplication_auction) {
            if (typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
              elementBlock.find('.md-prop-field-price').html(listing.pricetext);
              elementBlock.find('.md-prop-field-price-alt').html(listing.pricetext);
            } else if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) { 
              elementBlock.find('.md-prop-field-price').html(listing.priceMain);
              elementBlock.find('.md-prop-field-price-alt').html(listing.priceMain);
            } else {
              elementBlock.find('.md-prop-field-price').html(listing.price);
              elementBlock.find('.md-prop-field-price-alt').html(listing.price);
            }
          } else {
            elementBlock.find('.md-prop-field-price').html('Price on Application');
            elementBlock.find('.md-prop-field-price-alt').html('Price on Application');
          }
      }

      if (auth.provider === 'vaultre') {
        if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale) {
          elementBlock.find('.md-prop-field-methodofsale').html(listing.methodofsale);
        } else {
          elementBlock.find('.md-prop-field-methodofsale').html('');
        }

        if (typeof listing.tenderdetails !== 'undefined' && listing.tenderdetails && listing.tenderdetails !== null) {
          elementBlock.find('.md-prop-field-tenderdate').html(listing.tenderdetails);
        } else {
          elementBlock.find('.md-prop-field-tenderdate').html('');
        }

        if (typeof listing.yearbuilt !== 'undefined' && listing.yearbuilt) {
          elementBlock.find('.md-prop-field-yearbuilt').html(listing.yearbuilt);
        } else {
          elementBlock.find('.md-prop-field-yearbuilt').html('');
        }

        if (typeof listing.yearbuiltage !== 'undefined' && listing.yearbuiltage) {
          elementBlock.find('.md-prop-field-ageofproperty').html(listing.yearbuiltage);
        } else {
          elementBlock.find('.md-prop-field-ageofproperty').html('');
        }

        if (typeof listing.rateablevalue !== 'undefined' && listing.rateablevalue) {
          elementBlock.find('.md-prop-field-rateablevalue').html(listing.rateablevalue);
        } else {
          elementBlock.find('.md-prop-field-rateablevalue').html('');
        }

        let currency = '$';
        if (this.authService.isUk()) {
          currency = '£';
        }

        if (typeof listing.landvalue !== 'undefined' && listing.landvalue) {
          elementBlock.find('.md-prop-field-landvalue').html(currency + listing.landvalue);
        } else {
          elementBlock.find('.md-prop-field-landvalue').html('');
        }

        if (typeof listing.improvementvalue !== 'undefined' && listing.improvementvalue) {
          elementBlock.find('.md-prop-field-improvementvalue').html(listing.improvementvalue);
        } else {
          elementBlock.find('.md-prop-field-improvementvalue').html('');
        }

        if (typeof listing.lotnumber !== 'undefined' && listing.lotnumber) {
          elementBlock.find('.md-prop-field-lotnumber').html(listing.lotnumber);
        } else {
          elementBlock.find('.md-prop-field-lotnumber').html('');
        }

        if (typeof listing.openspaces !== 'undefined' && listing.openspaces) {
          elementBlock.find('.md-prop-field-openspaces').html(listing.openspaces);
        } else {
          elementBlock.find('.md-prop-field-openspaces').html('');
        }

        if (typeof listing.legaldescription !== 'undefined' && listing.legaldescription) {
          elementBlock.find('.md-prop-field-legaldescription').html(listing.legaldescription);
        }

        if (typeof listing.unconditional_date !== 'undefined' && listing.unconditional_date) {
          elementBlock.find('.md-prop-field-unconditionaldate').html(moment(listing.unconditional_date).format('Do, MMM YYYY'));
        } else {
          elementBlock.find('.md-prop-field-unconditionaldate').html('');
        }

        if (listing.saledate) {
          if (typeof listing.saledate !== 'undefined' && listing.saledate) {
            elementBlock.find('.md-prop-field-saledate').html(moment(listing.saledate).format('Do, MMM YYYY'));
          } else {
            elementBlock.find('.md-prop-field-saledate').html('');
          }

          let sale_price = listing.pricefrom;
          if (typeof listing.saleprice !== 'undefined' && listing.saleprice) {
            sale_price = listing.saleprice;
          }
          const ratePerM2 = parseInt(sale_price, 0) / parseInt(listing.buildingarea, 0);

          elementBlock.find('.md-prop-field-rate').html(ratePerM2);
        } else {
          elementBlock.find('.md-prop-field-saledate').html('');
          elementBlock.find('.md-prop-field-rate').html('');
        }

        if (typeof listing.agentpriceopinion !== 'undefined' && listing.agentpriceopinion !== null) {
          elementBlock.find('.md-prop-field-agentpriceopinion').html(listing.agentpriceopinion);
        } else {
          elementBlock.find('.md-prop-field-agentpriceopinion').html('');
        }
      }

      if (auth.provider === 'vaultre') {
        if (typeof listing.pricequalifier !== 'undefined' && listing.pricequalifier) {
          if (listing.pricequalifier === 'Negotiation') {
            elementBlock.find('.md-prop-field-pricequalifier').html('Price by negotiation');
            elementBlock.find('.md-prop-field-searchprice').html('');
            elementBlock.find('.md-prop-field-price-alt').html('<b>Price by negotiation</b>');
          } else {
            elementBlock.find('.md-prop-field-pricequalifier').html(listing.pricequalifier);
          }
        } else {
          elementBlock.find('.md-prop-field-pricequalifier').html('');
        }
      }

      if (typeof listing.dateavailable !== 'undefined' && listing.dateavailable !== null && listing.dateavailable) {
        elementBlock.find('.md-prop-field-dateavailable').html(listing.dateavailable);
        elementBlock.find('.md-prop-field-dateavailable-title').html('<b>Date Available</b> ' + listing.dateavailable);
        elementBlock.find('.md-prop-field-dateavailable-title-br').html('<b>Date Available</b> ' + listing.dateavailable + '<br>');
      } else {
        elementBlock.find('.md-prop-field-dateavailable').html('');
        elementBlock.find('.md-prop-field-dateavailable-title').html('');
        elementBlock.find('.md-prop-field-dateavailable-title-br').html('');
      }

      const listing_tenancydetails = listing.tenancydetails ? listing.tenancydetails : ' ';
      elementBlock.find('.md-prop-field-tenancydetails').html(listing_tenancydetails);

      // const listing_legal_description = ( listing.legal_description ) ? listing.legal_description : ' ';
      // elementBlock.find('.md-prop-field-legaldescription').html(listing_legal_description);

      // const listing_legal_landvalue = ( listing.legal_landvalue ) ? listing.legal_landvalue : ' ';
      // elementBlock.find('.md-prop-field-landvalue').html(listing_legal_landvalue);

      const listing_legal_localauthority = listing.legal_localauthority ? listing.legal_localauthority : ' ';
      elementBlock.find('.md-prop-field-localauthority').html(listing_legal_localauthority);

      const listing_legal_lot = listing.legal_lot ? listing.legal_lot : ' ';
      elementBlock.find('.md-prop-field-lot').html(listing_legal_lot);

      const listing_legal_rpdp = listing.legal_rpdp ? listing.legal_rpdp : ' ';
      elementBlock.find('.md-prop-field-legal-rpdp').html(listing_legal_rpdp);

      const listing_legal_gvyear = listing.legal_gvyear ? listing.legal_gvyear : ' ';
      elementBlock.find('.md-prop-field-gvyear').html(listing_legal_gvyear);

      const listing_legal_governmentvalue = listing.legal_governmentvalue ? listing.legal_governmentvalue : ' ';
      elementBlock.find('.md-prop-field-govvalue').html(listing_legal_governmentvalue);

      if (typeof listing.legal_zoning !== 'undefined' && listing.legal_zoning && listing.legal_zoning !== null) {
        elementBlock.find('.md-prop-com-zoning').html(listing.legal_zoning);
        elementBlock.find('.md-prop-com-zoning-title').html('<b>Zoning</b>&nbsp' + listing.legal_zoning);
        elementBlock.find('.md-prop-com-zoning-title-br').html('<b>Zoning</b>&nbsp' + listing.legal_zoning + '<br>');
      } else {
        elementBlock.find('.md-prop-com-zoning').html('');
        elementBlock.find('.md-prop-com-zoning-title').html('');
        elementBlock.find('.md-prop-com-zoning-title-br').html('');
      }

      if (listing.listing_status == 'leased' && listing.listing_category == 'commercial') {
        elementBlock.find('.md-prop-com-leasesale').html('Leased');
      } else if (listing.listing_status == 'sold' && listing.listing_category == 'commercial') {
        elementBlock.find('.md-prop-com-leasesale').html('Sold');
      } else {
        elementBlock.find('.md-prop-com-leasesale').html(listing.leasesale);
      }

      if (typeof listing.commercialtypes !== 'undefined' && listing.commercialtypes && listing.commercialtypes !== null) {
        elementBlock.find('.md-prop-com-type').html(listing.commercialtypes);
        elementBlock.find('.md-prop-com-type-title').html('<b>Type</b> ' + listing.commercialtypes);
        elementBlock.find('.md-prop-com-type-title-br').html('<b>Type</b> ' + listing.commercialtypes + '<br>');
      } else {
        elementBlock.find('.md-prop-com-type').html('');
        elementBlock.find('.md-prop-com-type-title').html('');
        elementBlock.find('.md-prop-com-type-title-br').html('');
      }


      if (typeof listing.listingdate !== 'undefined' && listing.listingdate !== null && listing.listingdate) {
        elementBlock.find('.md-prop-field-listingdate').html(listing.listingdate);
      } else {
        elementBlock.find('.md-prop-field-listingdate').html('');
      }

      if (typeof listing.listingdatealt !== 'undefined' && listing.listingdatealt !== null && listing.listingdatealt) {
        elementBlock.find('.md-prop-field-listingdate-alt').html(listing.listingdatealt);
      } else {
        elementBlock.find('.md-prop-field-listingdate-alt').html('');
      }

      const listing_auctiondate = listing.auctiondate ? listing.auctiondate : '';

      const listing_auctionvenue = listing.auctionvenue ? listing.auctionvenue : '';
      elementBlock.find('.md-prop-field-auctionvenue').html(listing_auctionvenue);

      if (clientSetting.branch_name) {
        elementBlock.find('.md-office-field-branch').html(clientSetting.branch_name);
      } else {
        if (groupSetting !== null && groupSetting !== undefined) {
          elementBlock.find('.md-office-field-branch').html(groupSetting.branch_name);
        }
      }

      let listing_auction_details = '';
      if (listing_auctiondate !== ' ') {
        listing_auction_details = listing_auctiondate;
      }

      if (listing_auctionvenue !== ' ') {
        listing_auction_details = `${listing_auction_details} ${listing_auctionvenue}`;
      }

      elementBlock.find('.md-prop-field-auction').html(listing_auction_details);

      if (this.authService.isUk() && listing.energy_rating !== null) {
        elementBlock.find('.md-prop-field-energyrating').attr('src', listing.energy_rating);
      }

      if (auth.provider === 'vaultre' && !this.authService.isUk() && listing.energy_rating !== null) {
        elementBlock.find('.md-prop-field-energyrating').html(listing.energy_rating);
        elementBlock.find('.md-prop-field-energyrating-title').html('<b>NABERS</b>&nbsp;' + listing.energy_rating);
        elementBlock.find('.md-prop-field-energyrating-title-br').html('<b>NABERS</b>&nbsp;' + listing.energy_rating + '<br>');
      } else {
        elementBlock.find('.md-prop-field-energyrating').html('');
        elementBlock.find('.md-prop-field-energyrating-title').html('');
        elementBlock.find('.md-prop-field-energyrating-title-br').html('');
      }

      // Custom Fields
      // tslint:disable-next-line: quotemark
      const customFields = elementBlock.find("[class*='md-prop-field-custom-']");

      if (customFields.length > 0) {
        customFields.each(function () {
          const customFieldClass = $(this).attr('class');
          const customFieldClasses = customFieldClass.split(' ');

          if (customFieldClasses.length > 0) {
            customFieldClasses.forEach((element) => {
              if (String(element).includes('md-prop-field-custom-')) {
                const fieldName = element.split('md-prop-field-custom-')[1];

                if (typeof listing.customfields !== 'undefined') {
                  if (typeof listing.customfields[fieldName] !== 'undefined' && listing.customfields[fieldName]) {
                    if (fieldName === 'receptionrooms') {
                      // tslint:disable-next-line: radix
                      listing.customfields[fieldName] = parseInt(listing.customfields[fieldName]);
                    }
                    elementBlock.find(`.md-prop-field-custom-${fieldName}`).html(listing.customfields[fieldName]);
                  } else {
                    elementBlock.find(`.md-prop-field-custom-${fieldName}`).html('');
                  }
                }
              }
            });
          }
        });
      }

      // Custom Features
      // tslint:disable-next-line: quotemark
      const customFeatures = elementBlock.find("[class*='md-prop-field-feature-']");
      if (customFeatures.length > 0) {
        customFeatures.each(function() {
          const customFeatureClass = $(this).attr('class');
          const customFeatureClasses = customFeatureClass.split(' ');

          if (customFeatureClasses.length > 0) {
            customFeatureClasses.forEach(element => {
              if (String(element).includes('md-prop-field-feature-')) {
                const fieldName = element.split('md-prop-field-feature-')[1];

                if (typeof listing.customfeatures !== 'undefined') {
                  if (typeof listing.customfeatures[fieldName] !== 'undefined' && listing.customfeatures[fieldName]) {
                    elementBlock.find(`.md-prop-field-feature-${fieldName}`).html(listing.customfeatures[fieldName].join(', '));
                  } else {
                    elementBlock.find(`.md-prop-field-feature-${fieldName}`).html('');
                  }
                }
              }
            });
          }
        });
      }

      if (typeof listing.customfeatures !== 'undefined' && listing.customfeatures) {
        if (listing.customfeatures.general.includes('Study')) {
          elementBlock.find('.md-prop-field-feature-study').html('Study');
          elementBlock.find('.md-prop-field-feature-study-number').html('1');
        } else {
          elementBlock.find('.md-prop-field-feature-study').html('');
          elementBlock.find('.md-prop-field-feature-study-number').html('');
        }

        if (listing.customfeatures.exterior.includes('Tennis Court')) {
          elementBlock.find('.md-prop-field-feature-tennis').html('Tennis Court');
          elementBlock.find('.md-prop-field-feature-tennis-number').html('1');
        } else {
          elementBlock.find('.md-prop-field-feature-tennis').html('');
          elementBlock.find('.md-prop-field-feature-tennis-number').html('');
        }

        let poolNumber = 0;
        if (listing.customfeatures.exterior.includes('Pool Above Ground')) {
          poolNumber = poolNumber + 1;
        }

        if (listing.customfeatures.exterior.includes('Pool Inground')) {
          poolNumber = poolNumber + 1;
        }

        if (listing.customfeatures.exterior.includes('Pool Above Ground') || listing.customfeatures.exterior.includes('Pool Inground')) {
          elementBlock.find('.md-prop-field-feature-pool').html('Pool');
          elementBlock.find('.md-prop-field-feature-pool-number').html(poolNumber);
        } else {
          elementBlock.find('.md-prop-field-feature-pool').html('');
          elementBlock.find('.md-prop-field-feature-pool-number').html('');
        }

      }

      elementBlock.find('.md-prop-field-owners-name').html('');
      elementBlock.find('.md-prop-field-owners-company').html('');
      elementBlock.find('.md-prop-field-owners-address').html('');

      if (typeof listing.owners !== 'undefined' && listing.owners) {
        if (typeof listing.owners.name !== 'undefined' && listing.owners.name) {
          elementBlock.find('.md-prop-field-owners-name').html(listing.owners.name);
        } else {
          elementBlock.find('.md-prop-field-owners-name').html('');
        }

        if (typeof listing.owners.company !== 'undefined' && listing.owners.company) {
          elementBlock.find('.md-prop-field-owners-company').html(listing.owners.company);
        } else {
          elementBlock.find('.md-prop-field-owners-company').html('');
        }

        if (typeof listing.owners.address !== 'undefined' && listing.owners.address) {
          elementBlock.find('.md-prop-field-owners-address').html(listing.owners.address);
        } else {
          elementBlock.find('.md-prop-field-owners-address').html('');
        }
      }

      if (typeof listing.portalstatus !== 'undefined' && listing.portalstatus !== null && listing.portalstatus) {
        elementBlock.find('.md-prop-field-status-alt').html(listing.portalstatus);
      } else {
        elementBlock.find('.md-prop-field-status-alt').html('');
      }

      elementBlock.find('.md-prop-tommys-tender').html('');

      if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale === 'Tender') {
        if (typeof listing.tenderdate_nz !== 'undefined' && listing.tenderdate_nz) {
          const officeStreet = typeof clientSetting.street !== 'undefined' ? clientSetting.street : '';
          const officeSuburb = typeof clientSetting.suburb !== 'undefined' ? clientSetting.suburb : '';
          // tslint:disable-next-line: max-line-length
          elementBlock.find('.md-prop-tommys-tender').html(`Tender closing ${listing.tenderdate_nz} at Tommy’s Real Estate Ltd, ${officeStreet}, ${officeSuburb}`);
        }
      }

      if (typeof listing.methodofsale !== 'undefined' && listing.methodofsale === 'Auction') {
        elementBlock.find('.md-prop-field-pricequalifier').html('To be auctioned');
        elementBlock.find('.md-prop-field-searchprice').html('');

        if (typeof listing.displayprice !== 'undefined' && listing.displayprice) {
          elementBlock.find('.md-prop-field-price-alt').html(listing.displayprice);
        } else {
          elementBlock.find('.md-prop-field-price-alt').html('<b>To be auctioned</b>');
        }
      }

      let roomsHtml = '';
      if (typeof listing.rooms !== 'undefined' && listing.rooms) {
        listing.rooms.forEach((room) => {
          let roomSize = '';

          if (room.width !== 0 && room.length !== 0) {
            if (room.units === 'metres') {
              const roomWidth = room.width * 3.281;
              const roomLength = room.length * 3.281;

              const roomWidthArray = roomWidth.toString().split('.');
              const roomWidthFeet = `${roomWidthArray[0]}'`;

              let roomWidthInch = `${roomWidthArray[1][0]}"`;

              if (roomWidthArray[1][0] === '0') {
                roomWidthInch = '';
              }

              const roomLengthArray = roomLength.toString().split('.');
              const roomLengthFeet = `${roomLengthArray[0]}'`;
              let roomLengthInch = `${roomLengthArray[1][0]}"`;

              if (roomLengthArray[1][0] === '0') {
                roomLengthInch = '';
              }

              roomSize = `${roomWidthFeet}${roomWidthInch} x ${roomLengthFeet}${roomLengthInch} (${room.width}m x ${room.length}m)`;
            } else {
              const roomWidth = `${room.width}`;
              const roomWidthFeet = roomWidth.split('.');
              const widthFeetStr = `${roomWidthFeet[0]}'`;
              const widthFeet = `${roomWidthFeet[0]}`;

              let widthInch = '';
              let widthInchStr = '';
              if (typeof roomWidthFeet[1] !== 'undefined') {

                let roomWidthFeetInt = parseInt(roomWidthFeet[1]);

                if (roomWidthFeetInt < 10) {
                  roomWidthFeetInt = roomWidthFeetInt * 10;
                }

                const widthInches = Math.round((roomWidthFeetInt / 100) * 12);

                if (widthInches !== 0) {
                  widthInch = `${widthInches}`;
                  widthInchStr = `${widthInches}"`;
                }
              }

              const roomLength = `${room.length}`;
              const roomLengthFeet = roomLength.split('.');
              const lengthFeet = `${roomLengthFeet[0]}`;
              const lengthFeetStr = `${roomLengthFeet[0]}'`;

              let lengthInch = '';
              let lengthInchStr = '';
              if (typeof roomLengthFeet[1] !== 'undefined') {

                let roomLengthFeetInt = parseInt(roomLengthFeet[1]);

                if (roomLengthFeetInt < 10) {
                  roomLengthFeetInt = roomLengthFeetInt * 10;
                }

                const LengthInches = Math.round((roomLengthFeetInt / 100) * 12);

                if (LengthInches !== 0) {
                  lengthInch = `${LengthInches}`;
                  lengthInchStr = `${LengthInches}"`;
                }
              }

              // tslint:disable-next-line: radix
              let widthMeter = parseFloat(`${widthFeet}.${widthInch}`);
              // tslint:disable-next-line: radix
              let lengthMeter = parseFloat(`${lengthFeet}.${lengthInch}`);

              widthMeter = widthMeter / 3.281;
              lengthMeter = lengthMeter / 3.281;

              // tslint:disable-next-line: max-line-length
              roomSize = `${widthFeetStr}${widthInchStr} x ${lengthFeetStr}${lengthInchStr} (${widthMeter.toFixed(2)}m x ${lengthMeter.toFixed(2)}m)<br>`;

            }
          }

          // tslint:disable-next-line: max-line-length
          roomsHtml += `<span class="room"><b>${room.name}</b><br>${room.description}<br>${roomSize}<br><code class="room-end"></code></span>`;
        });

        elementBlock.find('.md-prop-field-rooms').html(roomsHtml);

        const lenghtRoomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]').length;
        let lastroomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]')[lenghtRoomNumber - 1];

        if (lastroomNumber) {
          lastroomNumber = lastroomNumber.attributes[0].value;
          const getClassRoom = lastroomNumber.split(' ');

          const totalRoomLength = getClassRoom[0].replace('md-prop-field-rooms-', '');
          for (let i = 0; i < totalRoomLength; i++) {
            let roomHtml = '';
            const roomIndex = i + 1;

            let roomSize = '';

            if (listing.rooms[i].width !== 0 && listing.rooms[i].length !== 0) {
              if (listing.rooms[i].units === 'metres') {
                const roomWidth = listing.rooms[i].width * 3.281;
                const roomLength = listing.rooms[i].length * 3.281;

                const roomWidthArray = roomWidth.toString().split('.');
                const roomWidthFeet = `${roomWidthArray[0]}'`;

                let roomWidthInch = `${roomWidthArray[1][0]}"`;

                if (roomWidthArray[1][0] === '0') {
                  roomWidthInch = '';
                }

                const roomLengthArray = roomLength.toString().split('.');
                const roomLengthFeet = `${roomLengthArray[0]}'`;
                let roomLengthInch = `${roomLengthArray[1][0]}"`;

                if (roomLengthArray[1][0] === '0') {
                  roomLengthInch = '';
                }

                // tslint:disable-next-line: max-line-length
                roomSize = `${roomWidthFeet}${roomWidthInch} x ${roomLengthFeet}${roomLengthInch} (${listing.rooms[i].width}m x ${listing.rooms[i].length}m)`;

              } else {
                const roomWidth = `${listing.rooms[i].width}`;
              const roomWidthFeet = roomWidth.split('.');
              const widthFeetStr = `${roomWidthFeet[0]}'`;
              const widthFeet = `${roomWidthFeet[0]}`;

              let widthInch = '';
              let widthInchStr = '';
              if (typeof roomWidthFeet[1] !== 'undefined') {

                let roomWidthFeetInt = parseInt(roomWidthFeet[1]);

                if (roomWidthFeetInt < 10) {
                  roomWidthFeetInt = roomWidthFeetInt * 10;
                }

                const widthInches = Math.round((roomWidthFeetInt / 100) * 12);

                if (widthInches !== 0) {
                  widthInch = `${widthInches}`;
                  widthInchStr = `${widthInches}"`;
                }
              }

              const roomLength = `${listing.rooms[i].length}`;
              const roomLengthFeet = roomLength.split('.');
              const lengthFeet = `${roomLengthFeet[0]}`;
              const lengthFeetStr = `${roomLengthFeet[0]}'`;

              let lengthInch = '';
              let lengthInchStr = '';
              if (typeof roomLengthFeet[1] !== 'undefined') {

                let roomLengthFeetInt = parseInt(roomLengthFeet[1]);

                if (roomLengthFeetInt < 10) {
                  roomLengthFeetInt = roomLengthFeetInt * 10;
                }

                const LengthInches = Math.round((roomLengthFeetInt / 100) * 12);

                if (LengthInches !== 0) {
                  lengthInch = `${LengthInches}`;
                  lengthInchStr = `${LengthInches}"`;
                }
              }

              // tslint:disable-next-line: radix
              let widthMeter = parseFloat(`${widthFeet}.${widthInch}`);
              // tslint:disable-next-line: radix
              let lengthMeter = parseFloat(`${lengthFeet}.${lengthInch}`);

              widthMeter = widthMeter / 3.281;
              lengthMeter = lengthMeter / 3.281;

              // tslint:disable-next-line: max-line-length
              roomSize = `${widthFeetStr}${widthInchStr} x ${lengthFeetStr}${lengthInchStr} (${widthMeter.toFixed(2)}m x ${lengthMeter.toFixed(2)}m)<br>`;

              }
            }

            if (typeof listing.rooms[i] !== 'undefined' ) {
              roomHtml += `<b>${listing.rooms[i].name}</b><br>${listing.rooms[i].description}<br>${roomSize}`;
            }

            elementBlock.find('.md-prop-field-rooms-' + roomIndex).html(roomHtml);
          }
        }
      } else {
        elementBlock.find('.md-prop-field-rooms').html('');
        const lenghtRoomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]').length;
        let lastroomNumber = elementBlock.find('[class*="md-prop-field-rooms-"]')[lenghtRoomNumber - 1];

        if (lastroomNumber && lastroomNumber.length) {
          lastroomNumber = lastroomNumber.attributes[0].value;

          const totalRoomLength = lastroomNumber.replace('md-prop-field-rooms-', '');

          for (let i = 0; i < totalRoomLength; i++) {
            const roomIndex = i + 1;
            elementBlock.find('.md-prop-field-rooms-' + roomIndex).html('');
          }
        }
      }

      if (typeof listing.highlights !== 'undefined' && listing.highlights) {
        elementBlock.find('.md-prop-field-highlights').html(listing.highlights.join(', '));

        // tslint:disable-next-line: max-line-length
        const formattedHighlights = listing.highlights.map((highlight: string) => `<span class="bullet">&bull;</span><span class='highlight'>${highlight}</span>`);
        elementBlock.find('.md-prop-field-highlights-list').html(formattedHighlights.join('<br>'));

        const formattedHighlightsAlt = listing.highlights.map((highlight: string) => `<span class='highlight'>${highlight}</span>`);
        elementBlock.find('.md-prop-field-highlights-list-alt').html(formattedHighlightsAlt.join('<br>'));

        for (let i = 0; i < 20; i++) {
          const highlightIndex = i + 1;
          if (typeof listing.highlights[i] !== 'undefined') {
            elementBlock.find('.md-prop-field-highlights-' + highlightIndex).html(listing.highlights[i]);
          } else {
            elementBlock.find('.md-prop-field-highlights-' + highlightIndex).html('');
          }
        }
      }

      elementBlock.find('.md-prop-field-map-image').attr('src', listing.map_image);

      // Reset Class Merge Field if required
      elementBlock.find("img[data-src*='{$']").each(function () {
        const imgValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (imgValue.includes('{%REFID%}')) {
          $(this).attr('src', imgValue);
        }
      });

      elementBlock.find("a[data-url*='{$']").each(function () {
        const hrefValue = $(this).attr('data-url').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (hrefValue.includes('{%REFID%}') || hrefValue.includes('{%VIEWLINKURL%}') || hrefValue.includes('{%ETABLEURL%}')) {
          $(this).attr('href', hrefValue);
          $(this).html(hrefValue);
        }

        if (hrefValue.includes('{%OFFERLINKURL%}')) {
          $(this).attr('href', hrefValue);
        }
      });

      elementBlock.find("[data-text*='{$']").each(function () {
        const textValue = $(this).attr('data-text').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (textValue.includes('{%REFID%}')) {
          $(this).html(textValue);
        }
      });

      // Non Class Property - Merge Field
      let elementBlockHtml = elementBlock.html();

      let listing_url = '#';

      if (listing.url && listing.url !== '') {
        listing_url = listing.url;
      } else {
        if (clientSetting.link_website !== '') {
          listing_url = clientSetting.link_website;
        } else {
          if (groupSetting !== null && groupSetting !== undefined && groupSetting.link_website !== '') {
            listing_url = groupSetting.link_website;
          }
        }
      }

      let listing_etableurl = '#';

      if (listing.etableurl && listing.etableurl !== '' && listing.etableurl !== null) {
        listing_etableurl = listing.etableurl;
      } else {
        listing_etableurl = listing_url;
      }

      let listing_offerurl = '#';
      if (listing.offers_url && listing.offers_url !== '' && listing.offers_url !== null) {
        listing_offerurl = listing.offers_url;
      }

      elementBlockHtml = elementBlockHtml.replace(/\{%VIEWLINKURL%\}/gi, listing_url);
      elementBlockHtml = elementBlockHtml.replace(/\{%OFFERLINKURL%\}/gi, listing_offerurl);
      elementBlockHtml = elementBlockHtml.replace(/\{%ETABLEURL%\}/gi, listing_etableurl);

      const listing_videourl = listing.videourl ? listing.videourl : '';
      elementBlockHtml = elementBlockHtml.replace(/\{%VIDEOURL%\}/gi, listing_videourl);

      const listing_virtualtour = listing.virtualtour ? listing.virtualtour : '';
      elementBlockHtml = elementBlockHtml.replace(/\{%VIRTUALTOURURL%\}/gi, listing_virtualtour);
      elementBlockHtml = elementBlockHtml.replace(/\{%LPHOTO%\}/gi, listing_agent1_imageurl);
      elementBlockHtml = elementBlockHtml.replace(/\{%LPHOTO2%\}/gi, listing_agent2_imageurl);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL%\}/gi, listing_agent1_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL2%\}/gi, listing_agent2_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%ID%\}/gi, listing_id);
      elementBlockHtml = elementBlockHtml.replace(/\{%OFFICEID%\}/gi, this.authService.auth.groupid);
      elementBlockHtml = elementBlockHtml.replace(/\{%REFID%\}/gi, listing_refid);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL%\}/gi, listing_agent1_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL2%\}/gi, listing_agent2_email);
      elementBlockHtml = elementBlockHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, webbook_street);
      elementBlockHtml = elementBlockHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, webbook_suburb);

      elementBlock.html(elementBlockHtml);

      elementBlock.find('.md-prop-field-opentime').html('By appointment');
      elementBlock.find('.md-prop-field-opentimes-title').html('');
      elementBlock.find('.md-prop-field-opentimes-title-br').html('');

      elementBlock.find('.md-prop-field-multiple-opentimes').html('By appointment');
      elementBlock.find('.md-prop-field-multiple-opentimes-title').html('');
      elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('');

      let listing_opentimes = '';
      let listing_multipleopentimes = '';
      let listing_multipleopentimes_br = '';

      if (listing.inspections && listing.inspections.length > 0) {
        if (typeof listing.inspections[0].opendate !== 'undefined' && listing.inspections[0].opendate) {
          listing_opentimes = moment(listing.inspections[0].opendate).format('dddd Do MMMM, h:mmA');
          if (typeof listing.inspections[0].closedate !== 'undefined' && listing.inspections[0].closedate) {
            listing_opentimes += ' - ' + moment(listing.inspections[0].closedate).format('h:mmA');
          }

          listing_multipleopentimes = listing_opentimes;
          listing_multipleopentimes_br = listing_opentimes;
          elementBlock.find('.md-prop-field-opentime').html(listing_opentimes);
        }

        if (typeof listing.inspections[1] !== 'undefined') {
          if (typeof listing.inspections[1].opendate !== 'undefined' && listing.inspections[1].opendate) {
            let listing_opentimes2 = moment(listing.inspections[1].opendate).format('dddd Do MMMM, h:mmA');
            if (typeof listing.inspections[1].closedate !== 'undefined' && listing.inspections[1].closedate) {
              listing_opentimes2 += ' - ' + moment(listing.inspections[1].closedate).format('h:mmA');
            }

            listing_multipleopentimes = `${listing_multipleopentimes},<br> ${listing_opentimes2}`;
            listing_multipleopentimes_br = `${listing_multipleopentimes_br}, ${listing_opentimes2}`;
          }
        }
      }

      if (typeof listing.bondprice !== 'undefined' && listing.bondprice !== null && listing.bondprice !== '' && listing.bondprice !== 0) {
        elementBlock.find('.md-prop-field-bond').html(listing.bondprice);
      } else {
        elementBlock.find('.md-prop-field-bond').html('');
      }

      if (listing_opentimes !== '') {
        elementBlock.find('.md-prop-field-opentimes-title').html('<b>View</b>&nbsp;' + listing_opentimes);
        elementBlock.find('.md-prop-field-opentimes-title-br').html('<b>View</b>&nbsp;' + listing_opentimes + '<br>');
        elementBlock.find('.md-prop-field-opentimes-title-alt').html('<b>View</b><br> ' + listing_opentimes);
      } else {
        if (listing.openhomebyappointment === 1) {
          elementBlock.find('.md-prop-field-opentimes-title').html('<b>View</b>&nbsp;By Appointment');
          elementBlock.find('.md-prop-field-opentimes-title-br').html('<b>View</b>&nbsp;By Appointment<br>');
          elementBlock.find('.md-prop-field-opentimes-title-alt').html('<b>View</b><br>By Appointment');
        } else {
          elementBlock.find('.md-prop-field-opentimes-title').html('');
          elementBlock.find('.md-prop-field-opentimes-title-alt').html('');
          elementBlock.find('.md-prop-field-opentimes-title-br').html('');
        }
      }

      if (listing_multipleopentimes !== '') {
        elementBlock.find('.md-prop-field-multiple-opentimes').html(listing_multipleopentimes);
        elementBlock.find('.md-prop-field-multiple-opentimes-title').html('<b>View</b>&nbsp;' + listing_multipleopentimes);
        elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('<b>View</b>&nbsp;' + listing_multipleopentimes_br + '<br>');
        elementBlock.find('.md-prop-field-multiple-opentimes-title-alt').html('<b>View</b><br> ' + listing_multipleopentimes);
      } else {
        if (listing.openhomebyappointment === 1) {
          elementBlock.find('.md-prop-field-multiple-opentimes').html('By Appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title').html('<b>View</b>&nbsp;By Appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('<b>View</b>&nbsp;By Appointment<br>');
          elementBlock.find('.md-prop-field-multiple-opentimes-title-alt').html('<b>View</b><br>By Appointment');
        } else {
          elementBlock.find('.md-prop-field-multiple-opentimes').html('By appointment');
          elementBlock.find('.md-prop-field-multiple-opentimes-title').html('');
        elementBlock.find('.md-prop-field-multiple-opentimes-title-br').html('');
        elementBlock.find('.md-prop-field-multiple-opentimes-title-alt').html('');
        }
      }

      if (typeof listing.volumenumber !== 'undefined' && listing.volumenumber && listing.volumenumber !== null) {
        elementBlock.find('.md-prop-field-volume').html(listing.volumenumber);
      } else {
        elementBlock.find('.md-prop-field-volume').html('');
      }

      if (typeof listing.folionumber !== 'undefined' && listing.folionumber && listing.folionumber !== null) {
        elementBlock.find('.md-prop-field-folio').html(listing.folionumber);
      } else {
        elementBlock.find('.md-prop-field-folio').html('');
      }

      if (typeof listing.authoritytype !== 'undefined' && listing.authoritytype && listing.authoritytype !== null) {
        elementBlock.find('.md-prop-field-authoritytype').html(listing.authoritytype);
      } else {
        elementBlock.find('.md-prop-field-authoritytype').html('');
      }

      elementBlock.find('.md-prop-search').each(function () {
        $(this).attr('data-listing-id', listing.id);
      });

      if (typeof listing.tenanted !== 'undefined' && listing.tenanted !== null) {
        if (listing.tenanted === true) {
          elementBlock.find('.md-prop-field-tenanted').html('Tenanted');
          elementBlock.find('.md-prop-field-tenanted-title').html('<b>Tenanted</b> Yes');
          elementBlock.find('.md-prop-field-tenanted-title-br').html('<b>Tenanted</b> Yes <br>');
        } else {
          elementBlock.find('.md-prop-field-tenanted').html('Vacant');
          elementBlock.find('.md-prop-field-tenanted-title').html('<b>Tenanted</b> No');
          elementBlock.find('.md-prop-field-tenanted-title-br').html('<b>Tenanted</b> No <br>');
        }
      } else {
        elementBlock.find('.md-prop-field-tenanted').html('');
        elementBlock.find('.md-prop-field-tenanted-title').html('');
        elementBlock.find('.md-prop-field-tenanted-title-br').html('');
      }

      if (typeof listing.tenancystop !== 'undefined' && listing.tenancystop !== null) {
        const tenancystop = moment(listing.tenancystop).format('dddd, MMMM DD');

        elementBlock.find('.md-prop-field-tenancystop').html(tenancystop);
        elementBlock.find('.md-prop-field-tenancystop-title').html('<b>Tenancy ending</b> ' + tenancystop);
        elementBlock.find('.md-prop-field-tenancystop-title-br').html('<b>Tenancy ending</b> ' + tenancystop + '<br>');
      } else {
        elementBlock.find('.md-prop-field-tenancystop').html('');
        elementBlock.find('.md-prop-field-tenancystop-title').html('');
        elementBlock.find('.md-prop-field-tenancystop-title-br').html('');
      }

      if (typeof listing.vpa !== 'undefined' && listing.vpa) {
        elementBlock.find('.md-prop-field-vpa').html(listing.vpa);
      } else {
        elementBlock.find('.md-prop-field-vpa').html('');
      }

      if (typeof listing.releasedate !== 'undefined' && listing.releasedate) {
        const releaseDate = moment(listing.releasedate).format('dddd, MMMM DD');
        elementBlock.find('.md-prop-field-release-date').html(listing.releasedate);
      } else {
        elementBlock.find('.md-prop-field-release-date').html('');
      }

      if (typeof listing.offers !== 'undefined' && listing.offers && typeof listing.offers.offerPrice !== 'undefined' && listing.offers.offerPrice) {
        elementBlock.find('.md-prop-field-offer-amount').html(`$${listing.offers.offerPrice.toLocaleString()}`);
      } else {
        elementBlock.find('.md-prop-field-offer-amount').html('');
      }

      if (typeof listing.offers !== 'undefined' && listing.offers && typeof listing.offers.offerDate !== 'undefined' && listing.offers.offerDate) {
        const offerDate = moment(listing.offers.offerDate).format('dddd, MMMM DD');
        elementBlock.find('.md-prop-field-offer-date').html(offerDate);
      } else {
        elementBlock.find('.md-prop-field-offer-date').html('');
      }

      if (typeof listing.gross_commission !== 'undefined' && listing.gross_commission) {
        elementBlock.find('.md-prop-field-gross-commissions').html(listing.gross_commission);
      } else {
        elementBlock.find('.md-prop-field-gross-commissions').html('');
      }

      if (typeof listing.gross_commission_gst !== 'undefined' && listing.gross_commission_gst) {
        elementBlock.find('.md-prop-field-gross-commissions-gst').html(listing.gross_commission_gst);
      } else {
        elementBlock.find('.md-prop-field-gross-commissions-gst').html('');
      }

      if (typeof listing.advertising !== 'undefined' && listing.advertising) {
        let totalUnpaid = 0;
        listing.advertising.forEach((advertising) => {
          if (advertising.amount !== null) {
            totalUnpaid = totalUnpaid + advertising.amount;
          }
        });
        elementBlock.find('.md-prop-field-marketing-unpaid').html(`$${Math.abs(totalUnpaid).toLocaleString()}`);

      } else {
        elementBlock.find('.md-prop-field-marketing-unpaid').html('');
      }

      if (typeof listing.solicitor !== 'undefined' && listing.solicitor) {
        if (listing.solicitor.vendor !== null) {
          elementBlock.find('.md-prop-field-vendor-solicitor-title').html(listing.solicitor.vendor.title);
          elementBlock.find('.md-prop-field-vendor-solicitor-fname').html(listing.solicitor.vendor.firstName);
          elementBlock.find('.md-prop-field-vendor-solicitor-lname').html(listing.solicitor.vendor.lastName);
          elementBlock.find('.md-prop-field-vendor-solicitor-company').html(listing.solicitor.vendor.company);

          const phoneNumbers = listing.solicitor.vendor.phoneNumbers;
          let vendorPhone = '';
          let vendorMobile = '';

          phoneNumbers.forEach((phone) => {
            if (phone.type === 'Mobile') {
              if (vendorMobile === '') {
                vendorMobile = phone.number;
              }
            }

            if (phone.type !== 'Mobile') {
              if (vendorPhone === '') {
                vendorPhone = phone.number;
              }
            }
          });

          elementBlock.find('.md-prop-field-vendor-solicitor-phone').html(vendorPhone);
          elementBlock.find('.md-prop-field-vendor-solicitor-mobile').html(vendorMobile);

          elementBlock.find('.md-prop-field-vendor-solicitor-email').html(listing.solicitor.vendor.emails[0]);


          let vendorPostalAddress = '';
          let vendorUnit = listing.solicitor.vendor.postalAddress.unitNumber;
          const vendorStreetNumber = listing.solicitor.vendor.postalAddress.streetNumber;
          const vendorStreet = listing.solicitor.vendor.postalAddress.street;
          const vendorSuburb = listing.solicitor.vendor.postalAddress.suburb !== null ? listing.solicitor.vendor.postalAddress.suburb.name : '';
          const vendorState = listing.solicitor.vendor.postalAddress.state !== null ? listing.solicitor.vendor.postalAddress.state.abbreviation : '';
          const vendorZip = listing.solicitor.vendor.postalAddress.suburb !== null ? listing.solicitor.vendor.postalAddress.suburb.postcode : '';

          if (vendorUnit !== '') {
            vendorUnit = vendorUnit + '/';
          }

          // tslint:disable-next-line: max-line-length
          vendorPostalAddress = vendorUnit + '' + vendorStreetNumber + ' ' + vendorStreet + ', ' + vendorSuburb + ' ' + vendorState + ' ' + vendorZip;

          elementBlock.find('.md-prop-field-vendor-solicitor-postal-address').html(vendorPostalAddress);
        } else {
          elementBlock.find('.md-prop-field-vendor-solicitor-title').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-fname').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-lname').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-company').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-phone').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-mobile').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-email').html('');
          elementBlock.find('.md-prop-field-vendor-solicitor-postal-address').html('');
        }

        if (listing.solicitor.purchaser !== null) {
          elementBlock.find('.md-prop-field-purchaser-solicitor-title').html(listing.solicitor.purchaser.title);
          elementBlock.find('.md-prop-field-purchaser-solicitor-fname').html(listing.solicitor.purchaser.firstName);
          elementBlock.find('.md-prop-field-purchaser-solicitor-lname').html(listing.solicitor.purchaser.lastName);
          elementBlock.find('.md-prop-field-purchaser-solicitor-company').html(listing.solicitor.purchaser.company);

          const phoneNumbers = listing.solicitor.purchaser.phoneNumbers;
          let purchaserPhone = '';
          let purchaserMobile = '';

          phoneNumbers.forEach((phone) => {
            if (phone.type === 'Mobile') {
              if (purchaserMobile === '') {
                purchaserMobile = phone.number;
              }
            }

            if (phone.type !== 'Mobile') {
              if (purchaserPhone === '') {
                purchaserPhone = phone.number;
              }
            }
          });

          elementBlock.find('.md-prop-field-purchaser-solicitor-phone').html(purchaserPhone);
          elementBlock.find('.md-prop-field-purchaser-solicitor-mobile').html(purchaserMobile);

          elementBlock.find('.md-prop-field-purchaser-solicitor-email').html(listing.solicitor.purchaser.emails[0]);


          let purchaserPostalAddress = '';
          let purchaserUnit = listing.solicitor.purchaser.postalAddress.unitNumber;
          const purchaserStreetNumber = listing.solicitor.purchaser.postalAddress.streetNumber;
          const purchaserStreet = listing.solicitor.purchaser.postalAddress.street;
          const purchaserSuburb = listing.solicitor.purchaser.postalAddress.suburb !== null ? listing.solicitor.purchaser.postalAddress.suburb.name : '';
          const purchaserState = listing.solicitor.purchaser.postalAddress.state !== null ? listing.solicitor.purchaser.postalAddress.state.abbreviation : '';
          const purchaserZip = listing.solicitor.purchaser.postalAddress.suburb !== null ? listing.solicitor.purchaser.postalAddress.suburb.postcode : '';


          if (purchaserUnit !== '') {
            purchaserUnit = purchaserUnit + '/';
          }

          // tslint:disable-next-line: max-line-length
          purchaserPostalAddress = purchaserUnit + '' + purchaserStreetNumber + ' ' + purchaserStreet + ', ' + purchaserSuburb + ' ' + purchaserState + ' ' + purchaserZip;

          elementBlock.find('.md-prop-field-purchaser-solicitor-postal-address').html(purchaserPostalAddress);
        } else {
          elementBlock.find('.md-prop-field-purchaser-solicitor-title').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-fname').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-lname').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-company').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-phone').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-mobile').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-email').html('');
          elementBlock.find('.md-prop-field-purchaser-solicitor-postal-address').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-vendor-solicitor-title').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-fname').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-lname').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-company').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-phone').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-mobile').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-email').html('');
        elementBlock.find('.md-prop-field-vendor-solicitor-postal-address').html('');

        elementBlock.find('.md-prop-field-purchaser-solicitor-title').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-fname').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-lname').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-company').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-phone').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-mobile').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-email').html('');
        elementBlock.find('.md-prop-field-purchaser-solicitor-postal-address').html('');
      }

      if (typeof listing.offer_conditions !== 'undefined' && listing.offer_conditions) {
        let offerConditions = '';

        listing.offer_conditions.forEach((conditions) => {
          const dueDate = moment(conditions.due).format('dddd, MMMM DD, YYYY');
          offerConditions = offerConditions + `${conditions.condition.name} - ${dueDate}<br>`;
        });

        elementBlock.find('.md-prop-field-offer-conditions').html(offerConditions);
      } else {
        elementBlock.find('.md-prop-field-offer-conditions').html('');
      }


      if (typeof data.fillType !== 'undefined' && data.fillType === 'webbooks') {
        elementBlock.find('.md-prop-box').each((index: number, el) => {
          const propBoxFieldImages = $(el).find('.md-prop-field-image');

          if (!propBoxFieldImages.length) {
            return;
          }

          if (propBoxFieldImages.length) {
            propBoxFieldImages.each((imgIndex: number, imgEl) => {
              if (typeof listing.images !== 'undefined' && typeof listing.images[imgIndex] !== 'undefined') {
                $(imgEl).attr('src', listing.images[imgIndex].url);
              }
            });

            if ($(el).hasClass('md-prop-image-grid')) {
              const propBoxImageHtml = $(propBoxFieldImages[0]).parent()[0].outerHTML;

              if (listing.images.length) {
                const imageContainer = $(el).find('.container');

                if (imageContainer.length) {
                  const rowContainer = imageContainer.find('.row');

                  if (rowContainer.length) {
                    const rowItems = rowContainer.find('.grid-item');

                    if (rowItems.length) {
                      const propImageHtml = rowItems[0].outerHTML;

                      if (propImageHtml) {
                        rowContainer.empty();

                        listing.images.forEach((image) => {
                          const newPropImageHtml = propImageHtml.replace(/src="(.*?)"/g, `src="${image.url}"`);
                          rowContainer.append(newPropImageHtml);
                        });
                      }
                    }
                  }
                }
              } else {
                $(el).remove();
              }
            }
          }
        });

        const fileCabinetWrapper = elementBlock.find('.filling-cabinet-holder');

        if (fileCabinetWrapper.length && typeof listing.files !== 'undefined' && listing.files.length) {
          const fileCabinet = $(fileCabinetWrapper.first().html());

          if (fileCabinet.length) {
            fileCabinetWrapper.empty();

            listing.files.forEach(file => {
              const fileCabinetLink = fileCabinet.find('a');

              if (fileCabinetLink.length) {
                fileCabinetLink.text(file.filename);
                fileCabinetLink.attr('href', file.url);

                fileCabinetWrapper.append(fileCabinet);
              }
            });
          }
        }

        const largeFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-lrg');
        const smallFloorPlans = elementBlock.find('.md-prop-field-imagefloorplan-sml');

        largeFloorPlans.each((index: number, floorplan) => {
          // tslint:disable-next-line: max-line-length
          $(floorplan).attr('src', 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/2354/WebBooks/Default-Theme/Images/floorplan.svg');

          if (typeof listing.floorplans !== 'undefined' && typeof listing.floorplans[index] !== 'undefined') {
            $(floorplan).attr('src', listing.floorplans[index].url);
          }

          if (typeof listing.floorplans !== 'undefined' && listing.floorplans.length && typeof listing.floorplans[index] === 'undefined') {
            $(floorplan).remove();
          }
        });

        smallFloorPlans.each((index: number, floorplan) => {
          // tslint:disable-next-line: max-line-length
          $(floorplan).attr('src', 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/2354/WebBooks/Default-Theme/Images/floorplan.svg');

          if (typeof listing.floorplans !== 'undefined' && typeof listing.floorplans[index] !== 'undefined') {
            $(floorplan).attr('src', listing.floorplans[index].url);
          }

          if (typeof listing.floorplans !== 'undefined' && listing.floorplans.length && typeof listing.floorplans[index] === 'undefined') {
            $(floorplan).remove();
          }
        });
      }


      if (typeof listing.geolocation !== 'undefined' && listing.geolocation) {
        if (typeof listing.geolocation.latitude !== 'undefined' && listing.geolocation.latitude) {
          elementBlock.find('.md-prop-field-latitude-address').html(listing.geolocation.latitude);
        } else {
          elementBlock.find('.md-prop-field-latitude-address').html('');
        }

        if (typeof listing.geolocation.longitude !== 'undefined' && listing.geolocation.longitude) {
          elementBlock.find('.md-prop-field-longitude-address').html(listing.geolocation.longitude);
        } else {
          elementBlock.find('.md-prop-field-longitude-address').html('');
        }
      } else {
        elementBlock.find('.md-prop-field-latitude-address').html('');
        elementBlock.find('.md-prop-field-longitude-address').html('');
      }

    } catch (err) {
      console.error(err);
    }

    return elementBlock;
  }

  fillMergeText(auth: Auth, html: string, type = 'replicate', content = 'element', data: any) {
    const clientSetting = auth.client;
    const groupSetting = clientSetting.group.master;
    const templateAgent = typeof data.user !== 'undefined' && data.user ? data.user : auth;
    const isMergedSaved = typeof data.isMergedSaved !== 'undefined' ? data.isMergedSaved : false;
    const fillType = data.fillType;

    let elementHtml = html;

    const elementObj = $('<div>' + elementHtml + '</div>');

    if (type === 'subtitute') {
      elementObj.find("a[data-url*='{$']").each(function () {
        const hrefValue = $(this).attr('data-url').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (fillType === 'template') {
          if (hrefValue.includes('{%REFID%}')) {
            return;
          }

          if (hrefValue === '{%VIEWLINKURL%}' || hrefValue === '{%OFFERLINKURL%}' || hrefValue === '{%PRICEFINDERURL%}' || hrefValue === '{%ETABLEURL%}') {
            return;
          }

          const skipMergeFields = [
            'mailto:{%USEREMAIL%}',
            'tel:{%USERMOBILE%}',
            '{%USEREMAIL%}',
            '{%OFFICEEMAIL%}',
            '{%USERPHONE%}',
            '{%USERMOBILE%}',
            '{%USERWEBSITEURL%}',
            '{%WEBSITEURL%}',
            '{%SHORTWEBSITEURL%}',
            '{%FACEBOOKURL%}',
            '{%TWITTERURL%}',
            '{%INSTAGRAMURL%}',
            '{%YOUTUBEURL%}',
            '{%LINKEDINURL%}',
            '{%PINTERESTURL%}',
            '{%GPLUSURL%}',
            '{%RATEMYAGENT%}',
            '{%GOOGLEREVIEWURL%}',
            '{%BUYURL%}',
            '{%SELLURL%}',
            '{%RENTURL%}',
            '{%HOMEURL%}',
            '{%ABOUTURL%}',
            '{%CAREERSURL%}',
            '{%CONTACTURL%}',
            '{%BLOGURL%}'
          ];

          if (content === 'template' && isMergedSaved && skipMergeFields.includes(hrefValue.trim())) {
            return;
          }
        }

        if (!$(this).data('updated')) {
          $(this).attr('href', hrefValue);
        }
      });

      elementObj.find("[data-alt*='{$']").each(function () {
        const altValue = $(this).attr('data-alt').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('alt', altValue);
      });

      elementObj.find("[data-style*='{$']").each(function () {
        const styleValue = $(this).attr('data-style').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('style', styleValue);
      });

      elementObj.find("[data-bgcolor*='{$']").each(function () {
        const bgcolorValue = $(this).attr('data-bgcolor').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('bgcolor', bgcolorValue);
      });

      elementObj.find("img[data-src*='{$']").each(function () {
        const imgValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (fillType === 'template') {
          if (imgValue.includes('{%REFID%}')) {
            return;
          }

          const skipMergeFields = ['{%PRIMARYLOGO%}', '{%SECONDARYLOGO%}', '{%USERPHOTO%}'];

          if (content === 'template' && isMergedSaved && skipMergeFields.includes(imgValue.trim())) {
            return;
          }
        }

        $(this).attr('src', imgValue);
      });

      elementObj.find("[data-text*='{$']").each(function () {
        const textValue = $(this).attr('data-text').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');

        if (fillType === 'template') {
          if (textValue.includes('{%REFID%}')) {
            return;
          }

          if (textValue === '{%VIEWLINKURL%}' || textValue === '{%OFFERLINKURL%}' || textValue === '{%PRICEFINDERURL%}' || textValue === '{%ETABLEURL%}') {
            return;
          }

          const skipMergeFields = [
            '{%USERFNAME%} {%USERLNAME%}',
            '{%USERID%}',
            '{%USEREMAIL%}',
            '{%USERFAX%}',
            '{%USERFNAME%}',
            '{%USERLNAME%}',
            '{%USERNAME%}',
            '{%USERPHOTO%}',
            '{%USERPHONE%}',
            '{%USERMOBILE%}',
            '{%USERPOSITION%}',
            '{%USERWEBSITEURL%}',
            '{%OFFICEID%}',
            '{%OFFICENAME%}',
            '{%OFFICEBUSINESS%}',
            '{%OFFICEBRANCH%}',
            '{%OFFICEPHONE%}',
            '{%OFFICEEMAIL%}',
            '{%OFFICEFAX%}',
            '{%OFFICESTREET%}',
            '{%OFFICESUBURB%}',
            '{%OFFICESTATE%}',
            '{%OFFICEPOSTCODE%}',
            '{%OFFICEABN%}',
            '{%WEBSITEURL%}',
            '{%SHORTWEBSITEURL%}',
            '{%FACEBOOKURL%}',
            '{%TWITTERURL%}',
            '{%INSTAGRAMURL%}',
            '{%YOUTUBEURL%}',
            '{%LINKEDINURL%}',
            '{%PINTERESTURL%}',
            '{%GPLUSURL%}',
            '{%RATEMYAGENT%}',
            '{%GOOGLEREVIEWURL%}',
            '{%BUYURL%}',
            '{%SELLURL%}',
            '{%RENTURL%}',
            '{%HOMEURL%}',
            '{%ABOUTURL%}',
            '{%CAREERSURL%}',
            '{%CONTACTURL%}',
            '{%BLOGURL%}',
          ];

          if (content === 'template' && isMergedSaved && skipMergeFields.includes(textValue.trim())) {
            return;
          }
        }

        $(this).html(textValue);
      });

      elementObj.find("iframe[data-src*='{$']").each(function () {
        const srcValue = $(this).attr('data-src').replace(/\{\$/gi, '{%').replace(/\$\}/gi, '%}');
        $(this).attr('src', srcValue);
      });
    } else {
      elementObj.find("a[href*='{%']").each(function () {
        const hrefValue = $(this).attr('href').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');

        $(this).attr('data-url', hrefValue);
      });

      elementObj.find("[style*='{%']").each(function () {
        const styleValue = $(this).attr('style').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-style', styleValue);
      });

      elementObj.find("[bgcolor*='{%']").each(function () {
        const bgcolorValue = $(this).attr('bgcolor').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-bgcolor', bgcolorValue);
      });

      elementObj.find("img[src*='{%']").each(function () {
        const imgValue = $(this).attr('src').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-src', imgValue);
      });

      elementObj.find("iframe[src*='{%']").each(function () {
        const srcValue = $(this).attr('src').replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-src', srcValue);
      });

      elementObj.find(":not(:has(*)):contains('{%')").each(function () {
        const textValue = $(this).html().replace(/\{%/gi, '{$').replace(/%\}/gi, '$}');
        $(this).attr('data-text', textValue);
        // return false;
      });
    }

    elementObj.find('[data-cke-saved-href]').each(function () {
      $(this).removeAttr('data-cke-saved-href');
    });

    elementHtml = elementObj.html();

    // Non-Class User - Merge Field
    const template_agent_id = templateAgent.id ? templateAgent.id : '';
    elementHtml = elementHtml.replace(/\{%USERID%\}/gi, template_agent_id);

    const template_agent_email = templateAgent.email ? templateAgent.email : '';
    elementHtml = elementHtml.replace(/\{%USEREMAIL%\}/gi, template_agent_email);

    const template_agent_fax = templateAgent.fax ? templateAgent.fax : '';
    elementHtml = elementHtml.replace(/\{%USERFAX%\}/gi, template_agent_fax);

    const template_agent_firstname = templateAgent.firstname ? templateAgent.firstname : '';
    elementHtml = elementHtml.replace(/\{%USERFNAME%\}/gi, template_agent_firstname);

    const template_agent_lastname = templateAgent.lastname ? templateAgent.lastname : '';
    elementHtml = elementHtml.replace(/\{%USERLNAME%\}/gi, template_agent_lastname);

    elementHtml = elementHtml.replace(/\{%USERNAME%\}/gi, template_agent_firstname + ' ' + template_agent_lastname);

    const template_agent_imageurl = templateAgent.imageurl ? templateAgent.imageurl : '';
    elementHtml = elementHtml.replace(/\{%USERPHOTO%\}/gi, template_agent_imageurl);

    const template_agent_telephone = templateAgent.telephone ? templateAgent.telephone : '';
    elementHtml = elementHtml.replace(/\{%USERPHONE%\}/gi, template_agent_telephone);

    const template_agent_position = templateAgent.position ? templateAgent.position : '';
    elementHtml = elementHtml.replace(/\{%USERPOSITION%\}/gi, template_agent_position);

    let template_agent_mobiledisplay = templateAgent.mobile ? templateAgent.mobile : '';
    template_agent_mobiledisplay = template_agent_mobiledisplay.replace('+61', '').replace('+64', '').trim();
    if (template_agent_mobiledisplay && template_agent_mobiledisplay.charAt(0) !== '0') {
      template_agent_mobiledisplay = '0' + template_agent_mobiledisplay;
    }
    elementHtml = elementHtml.replace(/\{%USERMOBILE%\}/gi, template_agent_mobiledisplay);

    const template_agent_url = templateAgent.website_url ? templateAgent.website_url : '';
    elementHtml = elementHtml.replace(/\{%USERWEBSITEURL%\}/gi, template_agent_url);

    // Non-Class Office Details - Merge Field
    elementHtml = elementHtml.replace(/\{%OFFICEID%\}/gi, auth.groupid);
    elementHtml = elementHtml.replace(/\{%DATE%\}/gi, moment().format('Do, MMM YYYY'));
    elementHtml = elementHtml.replace(/\{%DATEALT%\}/gi, moment().format('MMM YYYY'));

    if (clientSetting.name) {
      elementHtml = elementHtml.replace(/\{%OFFICENAME%\}/gi, clientSetting.name);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICENAME%\}/gi, '');
    }
    if (clientSetting.abn) {
      elementHtml = elementHtml.replace(/\{%OFFICEABN%\}/gi, clientSetting.abn);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICEABN%\}/gi, '');
    }
    if (clientSetting.company_name) {
      elementHtml = elementHtml.replace(/\{%OFFICEBUSINESS%\}/gi, clientSetting.company_name);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICEBUSINESS%\}/gi, '');
    }

    if (clientSetting.branch_name) {
      elementHtml = elementHtml.replace(/\{%OFFICEBRANCH%\}/gi, clientSetting.branch_name);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEBRANCH%\}/gi, groupSetting.branch_name);
      }
    }

    if (clientSetting.phone) {
      elementHtml = elementHtml.replace(/\{%OFFICEPHONE%\}/gi, clientSetting.phone);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICEPHONE%\}/gi, '');
    }
    if (clientSetting.email) {
      elementHtml = elementHtml.replace(/\{%OFFICEEMAIL%\}/gi, clientSetting.email);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEEMAIL%\}/gi, groupSetting.email);
      }
    }
    if (clientSetting.fax) {
      elementHtml = elementHtml.replace(/\{%OFFICEFAX%\}/gi, clientSetting.fax);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEFAX%\}/gi, groupSetting.fax);
      }
    }
    if (clientSetting.street) {
      elementHtml = elementHtml.replace(/\{%OFFICESTREET%\}/gi, clientSetting.street);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICESTREET%\}/gi, '');
    }
    if (clientSetting.suburb) {
      elementHtml = elementHtml.replace(/\{%OFFICESUBURB%\}/gi, clientSetting.suburb);
    } else {
      elementHtml = elementHtml.replace(/\{%OFFICESUBURB%\}/gi, '');
    }
    if (clientSetting.state_abbr) {
      elementHtml = elementHtml.replace(/\{%OFFICESTATE%\}/gi, clientSetting.state_abbr);
    } else {
      if (typeof groupSetting !== 'undefined' && groupSetting !== null) {
        elementHtml = elementHtml.replace(/\{%OFFICESTATE%\}/gi, groupSetting.state_abbr);
      } else {
        elementHtml = elementHtml.replace(/\{%OFFICESTATE%\}/gi, '');
      }
    }
    if (clientSetting.postcode) {
      elementHtml = elementHtml.replace(/\{%OFFICEPOSTCODE%\}/gi, clientSetting.postcode);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%OFFICEPOSTCODE%\}/gi, groupSetting.postcode);
      }
    }

    if (clientSetting.postal_postcode) {
      elementHtml = elementHtml.replace(/\{%POSTALPOSTCODE%\}/gi, clientSetting.postal_postcode);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALPOSTCODE%\}/gi, '');
    }

    if (clientSetting.postal_state) {
      elementHtml = elementHtml.replace(/\{%POSTALSTATE%\}/gi, clientSetting.postal_state);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALSTATE%\}/gi, '');
    }

    if (clientSetting.postal_street) {
      elementHtml = elementHtml.replace(/\{%POSTALSTREET%\}/gi, clientSetting.postal_street);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALSTREET%\}/gi, '');
    }

    if (clientSetting.postal_suburb) {
      elementHtml = elementHtml.replace(/\{%POSTALSUBURB%\}/gi, clientSetting.postal_suburb);
    } else {
      elementHtml = elementHtml.replace(/\{%POSTALSUBURB%\}/gi, '');
    }

    // Non-Class Setting - Merge Field
    if (clientSetting.color_1) {
      elementHtml = elementHtml.replace(/\{%COLOR1%\}/gi, clientSetting.color_1);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR1%\}/gi, groupSetting.color_1);
      }
    }
    if (clientSetting.color_2) {
      elementHtml = elementHtml.replace(/\{%COLOR2%\}/gi, clientSetting.color_2);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR2%\}/gi, groupSetting.color_2);
      }
    }
    if (clientSetting.color_3) {
      elementHtml = elementHtml.replace(/\{%COLOR3%\}/gi, clientSetting.color_3);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR3%\}/gi, groupSetting.color_3);
      }
    }
    if (clientSetting.color_4) {
      elementHtml = elementHtml.replace(/\{%COLOR4%\}/gi, clientSetting.color_4);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR4%\}/gi, groupSetting.color_4);
      }
    }
    if (clientSetting.color_5) {
      elementHtml = elementHtml.replace(/\{%COLOR5%\}/gi, clientSetting.color_5);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%COLOR5%\}/gi, groupSetting.color_5);
      }
    }
    if (clientSetting.color_6) {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR1%\}/gi, clientSetting.color_6);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR1%\}/gi, groupSetting.color_6);
      }
    }
    if (clientSetting.color_7) {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR2%\}/gi, clientSetting.color_7);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR2%\}/gi, groupSetting.color_7);
      }
    }
    if (clientSetting.color_8) {
      elementHtml = elementHtml.replace(/\{%ICONCOLOR1%\}/gi, clientSetting.color_8);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%ICONCOLOR1%\}/gi, groupSetting.color_8);
      }
    }

    if (clientSetting.color_9) {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR3%\}/gi, clientSetting.color_9);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR3%\}/gi, groupSetting.color_9);
      }
    }

    if (clientSetting.color_10) {
      elementHtml = elementHtml.replace(/\{%FONTCOLOR4%\}/gi, clientSetting.color_10);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTCOLOR4%\}/gi, groupSetting.color_10);
      }
    }
    if (clientSetting.font_family) {
      elementHtml = elementHtml.replace(/\{%FONTFAMILY%\}/gi, clientSetting.font_family);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FONTFAMILY%\}/gi, groupSetting.font_family);
      }
    }

    // low res logo
    if (clientSetting.primary_logo) {
      elementHtml = elementHtml.replace(/\{%PRIMARYLOGO%\}/gi, clientSetting.primary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYLOGO%\}/gi, groupSetting.primary_logo);
      }
    }
    if (clientSetting.secondary_logo) {
      elementHtml = elementHtml.replace(/\{%SECONDARYLOGO%\}/gi, clientSetting.secondary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYLOGO%\}/gi, groupSetting.secondary_logo);
      }
    }

    // high res logo
    if (clientSetting.hires_primary_logo) {
      elementHtml = elementHtml.replace(/\{%PRIMARYLOGOHIRES%\}/gi, clientSetting.hires_primary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYLOGOHIRES%\}/gi, groupSetting.hires_primary_logo);
      }
    }
    if (clientSetting.hires_secondary_logo) {
      elementHtml = elementHtml.replace(/\{%SECONDARYLOGOHIRES%\}/gi, clientSetting.hires_secondary_logo);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYLOGOHIRES%\}/gi, groupSetting.hires_secondary_logo);
      }
    }

    if (clientSetting.link_facebook) {
      elementHtml = elementHtml.replace(/\{%FACEBOOKURL%\}/gi, clientSetting.link_facebook);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%FACEBOOKURL%\}/gi, groupSetting.link_facebook);
      }
    }
    if (clientSetting.link_twitter) {
      elementHtml = elementHtml.replace(/\{%TWITTERURL%\}/gi, clientSetting.link_twitter);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%TWITTERURL%\}/gi, groupSetting.link_twitter);
      }
    }
    if (clientSetting.link_instagram) {
      elementHtml = elementHtml.replace(/\{%INSTAGRAMURL%\}/gi, clientSetting.link_instagram);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%INSTAGRAMURL%\}/gi, groupSetting.link_instagram);
      }
    }
    if (clientSetting.link_youtube) {
      elementHtml = elementHtml.replace(/\{%YOUTUBEURL%\}/gi, clientSetting.link_youtube);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%YOUTUBEURL%\}/gi, groupSetting.link_youtube);
      }
    }
    if (clientSetting.link_linkedin) {
      elementHtml = elementHtml.replace(/\{%LINKEDINURL%\}/gi, clientSetting.link_linkedin);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%LINKEDINURL%\}/gi, groupSetting.link_linkedin);
      }
    }
    if (clientSetting.link_gplus) {
      elementHtml = elementHtml.replace(/\{%GPLUSURL%\}/gi, clientSetting.link_gplus);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%GPLUSURL%\}/gi, groupSetting.link_gplus);
      }
    }
    if (clientSetting.link_pinterest) {
      elementHtml = elementHtml.replace(/\{%PINTERESTURL%\}/gi, clientSetting.link_pinterest);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PINTERESTURL%\}/gi, groupSetting.link_pinterest);
      }
    }

    if (clientSetting.link_ratemyagent) {
      elementHtml = elementHtml.replace(/\{%RATEMYAGENT%\}/gi, clientSetting.link_ratemyagent);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%RATEMYAGENT%\}/gi, groupSetting.link_ratemyagent);
      }
    }

    if (clientSetting.link_googlereviews) {
      elementHtml = elementHtml.replace(/\{%GOOGLEREVIEWURL%\}/gi, clientSetting.link_googlereviews);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%GOOGLEREVIEWURL%\}/gi, groupSetting.link_googlereviews);
      }
    }

    if (clientSetting.link_website) {
      elementHtml = elementHtml.replace(/\{%WEBSITEURL%\}/gi, clientSetting.link_website);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%WEBSITEURL%\}/gi, groupSetting.link_website);
      }
    }

    if (clientSetting.link_short_website) {
      elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/gi, clientSetting.link_short_website);
    } else {
      if ((groupSetting !== null && groupSetting !== undefined)) {
        if (groupSetting.link_short_website) {
          elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, groupSetting.link_short_website);
        } else {
          if (clientSetting.link_website) {
            elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, clientSetting.link_website);
          } else {
            elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, '');
          }
        }
      } else {
        if (clientSetting.link_website) {
          elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, clientSetting.link_website);
        } else {
          elementHtml = elementHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, '');
        }
      }
    }

    if (clientSetting.link_buy) {
      elementHtml = elementHtml.replace(/\{%BUYURL%\}/gi, clientSetting.link_buy);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%BUYURL%\}/gi, groupSetting.link_buy);
      }
    }
    if (clientSetting.link_sell) {
      elementHtml = elementHtml.replace(/\{%SELLURL%\}/gi, clientSetting.link_sell);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SELLURL%\}/gi, groupSetting.link_sell);
      }
    }
    if (clientSetting.link_rent) {
      elementHtml = elementHtml.replace(/\{%RENTURL%\}/gi, clientSetting.link_rent);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%RENTURL%\}/gi, groupSetting.link_rent);
      }
    }
    if (clientSetting.link_unsubscribe) {
      elementHtml = elementHtml.replace(/\{%UNSUBSCRIBEURL%\}/gi, clientSetting.link_unsubscribe);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%UNSUBSCRIBEURL%\}/gi, groupSetting.link_unsubscribe);
      }
    }
    if (clientSetting.link_home) {
      elementHtml = elementHtml.replace(/\{%HOMEURL%\}/gi, clientSetting.link_home);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%HOMEURL%\}/gi, groupSetting.link_home);
      }
    }
    if (clientSetting.link_about) {
      elementHtml = elementHtml.replace(/\{%ABOUTURL%\}/gi, clientSetting.link_about);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%ABOUTURL%\}/gi, groupSetting.link_about);
      }
    }
    if (clientSetting.link_careers) {
      elementHtml = elementHtml.replace(/\{%CAREERSURL%\}/gi, clientSetting.link_careers);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%CAREERSURL%\}/gi, groupSetting.link_careers);
      }
    }
    if (clientSetting.link_blog) {
      elementHtml = elementHtml.replace(/\{%BLOGURL%\}/gi, clientSetting.link_blog);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%BLOGURL%\}/gi, groupSetting.link_blog);
      }
    }
    if (clientSetting.link_contact) {
      elementHtml = elementHtml.replace(/\{%CONTACTURL%\}/gi, clientSetting.link_contact);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%CONTACTURL%\}/gi, groupSetting.link_contact);
      }
    }
    if (clientSetting.link_testimonials) {
      elementHtml = elementHtml.replace(/\{%TESTIMONIALSURL%\}/gi, clientSetting.link_testimonials);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%TESTIMONIALSURL%\}/gi, groupSetting.link_testimonials);
      }
    }
    if (clientSetting.primary_icon_bed) {
      elementHtml = elementHtml.replace(/\{%PRIMARYICONBED%\}/gi, clientSetting.primary_icon_bed);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYICONBED%\}/gi, groupSetting.primary_icon_bed);
      }
    }
    if (clientSetting.primary_icon_bath) {
      elementHtml = elementHtml.replace(/\{%PRIMARYICONBATH%\}/gi, clientSetting.primary_icon_bath);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYICONBATH%\}/gi, groupSetting.primary_icon_bath);
      }
    }
    if (clientSetting.primary_icon_car) {
      elementHtml = elementHtml.replace(/\{%PRIMARYICONCAR%\}/gi, clientSetting.primary_icon_car);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%PRIMARYICONCAR%\}/gi, groupSetting.primary_icon_car);
      }
    }
    if (clientSetting.secondary_icon_bed) {
      elementHtml = elementHtml.replace(/\{%SECONDARYICONBED%\}/gi, clientSetting.secondary_icon_bed);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYICONBED%\}/gi, groupSetting.secondary_icon_bed);
      }
    }
    if (clientSetting.secondary_icon_bath) {
      elementHtml = elementHtml.replace(/\{%SECONDARYICONBATH%\}/gi, clientSetting.secondary_icon_bath);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYICONBATH%\}/gi, groupSetting.secondary_icon_bath);
      }
    }
    if (clientSetting.secondary_icon_car) {
      elementHtml = elementHtml.replace(/\{%SECONDARYICONCAR%\}/gi, clientSetting.secondary_icon_car);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%SECONDARYICONCAR%\}/gi, groupSetting.secondary_icon_car);
      }
    }
    if (clientSetting.disclaimer_email) {
      elementHtml = elementHtml.replace(/\{%DISCLAIMEREMAIL%\}/gi, clientSetting.disclaimer_email);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%DISCLAIMEREMAIL%\}/gi, groupSetting.disclaimer_email);
      }
    }
    if (clientSetting.disclaimer_print) {
      elementHtml = elementHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, clientSetting.disclaimer_print);
    } else {
      if (groupSetting !== null && groupSetting !== undefined) {
        elementHtml = elementHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, groupSetting.disclaimer_print);
      } else {
        elementHtml = elementHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, '');
      }
    }
    let legal_1 = '';
    let legal_2 = '';
    let legal_3 = '';

    if (clientSetting.legal_1 !== null) {
      legal_1 = clientSetting.legal_1;
    } else {
      if (groupSetting !== null && groupSetting !== undefined && groupSetting.legal_1 !== null) {
        legal_1 = clientSetting.legal_1;
      }
    }
    elementHtml = elementHtml.replace(/\{%LEGAL1%\}/gi, legal_1);

    if (clientSetting.legal_2 !== null) {
      legal_2 = clientSetting.legal_2;
    } else {
      if (groupSetting !== null && groupSetting !== undefined && groupSetting.legal_2 !== null) {
        legal_2 = clientSetting.legal_2;
      }
    }
    elementHtml = elementHtml.replace(/\{%LEGAL2%\}/gi, legal_2);

    if (clientSetting.legal_3 !== null) {
      legal_3 = clientSetting.legal_3;
    } else {
      if (groupSetting !== null && groupSetting !== undefined && groupSetting.legal_3 !== null) {
        legal_3 = clientSetting.legal_3;
      }
    }
    elementHtml = elementHtml.replace(/\{%LEGAL3%\}/gi, legal_3);

    if (fillType === 'template') {
      if (auth.provider === 'vaultre' || auth.provider === 'designly') {
        elementHtml = elementHtml.replace(/%GREETING%/gi, '$$$greeting$$$').replace(/%UNSUBSCRIBE%/gi, '$$$unsubscribe$$$');
      }

      const pricefinderUrl = data.pricefinderUrl;

      if (typeof pricefinderUrl !== 'undefined' && pricefinderUrl) {
        elementHtml = elementHtml.replace(/\{%PRICEFINDERURL%\}/ig, pricefinderUrl);
      }
    }

    if (fillType === 'webbooks') {

      if (type === 'subtitute') {
        const unitnumber = elementObj.find('.dsgnly-property-map').attr('data-unitnumber');
        const streetnumber = elementObj.find('.dsgnly-property-map').attr('data-streetnumber');
        const street = elementObj.find('.dsgnly-property-map').attr('data-street');
        const suburb = elementObj.find('.dsgnly-property-map').attr('data-suburb');
        let webbookStreet = '';

        if (typeof unitnumber !== 'undefined' && unitnumber) {
          webbookStreet = unitnumber;
        }
        if (typeof streetnumber !== 'undefined' && streetnumber) {
          webbookStreet = `${webbookStreet} ${streetnumber}`;
        }
        if (typeof street !== 'undefined' && street) {
          webbookStreet = `${webbookStreet} ${street}`;
        }
        if (webbookStreet) {
          elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, webbookStreet);
        } else {
          elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, clientSetting.street);
        }
        if (typeof suburb !== 'undefined' && suburb) {
          elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, suburb);
        } else {
          elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, clientSetting.suburb);
        }

      } else {
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSTREET%\}/gi, clientSetting.street);
        elementHtml = elementHtml.replace(/\{%WEBBOOKPROPERTYSUBURB%\}/gi, clientSetting.suburb);
      }
    }

    if (fillType === 'proposals') {
      if (typeof data.proposalProperty !== 'undefined' && data.proposalProperty) {
        let proposalStreet = '';

        if (typeof data.proposalProperty.unit_number !== 'undefined' && data.proposalProperty.unit_number) {
          proposalStreet = data.proposalProperty.unit_number;
        }

        if (typeof data.proposalProperty.street_number !== 'undefined' && data.proposalProperty.street_number) {
          proposalStreet = `${proposalStreet} ${data.proposalProperty.street_number}`;
        }

        if (typeof data.proposalProperty.street !== 'undefined' && data.proposalProperty.street) {
          proposalStreet = `${proposalStreet} ${data.proposalProperty.street}`;
        }

        if (proposalStreet) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, proposalStreet);
        } else {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, '');
        }

        if (typeof data.proposalProperty.suburb !== 'undefined' && data.proposalProperty.suburb) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, data.proposalProperty.suburb);
        } else {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, '');
        }
      } else {
        if (clientSetting.street) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, clientSetting.street);
        } else {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSTREET%\}/gi, '');
        }

        if (clientSetting.suburb) {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, clientSetting.suburb);
        } else {
          elementHtml = elementHtml.replace(/\{%PROPOSALPROPERTYSUBURB%\}/gi, '');
        }
      }
    }

    return elementHtml;
  }

  capitalizeFirstLetter(string: string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  getLink(item: any) {
    if (item.type === 'file') {
      return this.appConfig.S3_URL + item.path;
    }
  }

  fillAgentMergeClass(elementBlock: any, data: any) {
    if (typeof data.agentName !== 'undefined' && data.agentName) {
      elementBlock.find('.dsgnly-agent-name').html(data.agentName);
    }

    if (typeof data.agentPosition !== 'undefined' && data.agentPosition) {
      elementBlock.find('.dsgnly-agent-position').html(data.agentPosition);
    }

    if (typeof data.agentPhone !== 'undefined' && data.agentPhone) {
      elementBlock.find('.dsgnly-agent-phone').html(data.agentPhone);
    }

    if (typeof data.agentMobile !== 'undefined' && data.agentMobile) {
      elementBlock.find('.dsgnly-agent-mobile').html(data.agentMobile);
    }

    if (typeof data.agentEmail !== 'undefined' && data.agentEmail) {
      elementBlock.find('.dsgnly-agent-email').html(data.agentEmail);
    }

    if (typeof data.agentPhoto !== 'undefined' && data.agentPhoto) {
      elementBlock.find('img.dsgnly-agent-photo').attr('src', data.agentPhoto);
    }

    if (typeof data.agentProfile !== 'undefined' && data.agentProfile) {
      elementBlock.find('.dsgnly-agent-profile').html(data.agentProfile);
    }

    if (typeof data.id !== 'undefined' && data.id && data.id === 'regenerate') {
      elementBlock.find('section').removeAttr('id');
    }

    return elementBlock.html();
  }
}
