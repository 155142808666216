import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable, Inject } from '@angular/core';

import { APP_CONFIG } from '../app.config';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(@Inject(APP_CONFIG) private appConfig,
              private authService: AuthService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.checkAuthCookie()) {
      this.authService.getAuthCookie();

      if (this.authService.auth._version === '0') {
        if ( state.url !== '/view/version') {
          this.router.navigate(['/view/version']);

          return false;
        }
      }

      if (this.authService.auth._active !== '1' ) {
        this.authService.deleteAuthCookie();
        this.router.navigate(['/login']);

        return false;
      }

      return true;
    }
    this.authService.deleteAuthCookie();
    this.authService.cookieService.delete('redirect');
    this.authService.cookieService.set('redirect', state.url, this.addMinutesToDate(new Date(), 20), '/');

    this.router.navigate(['/login']);

    return false;
  }

  addMinutesToDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }
}
