import { Component, OnInit } from '@angular/core';
import { FrontService } from '../front.service';
import { Menu } from '../../models/menu.model';
import { Router } from '@angular/router';

declare var introJs: any;

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  menus: Menu[] = [];

  supportLink = '';

  constructor(public frontService: FrontService, private router: Router) { }

  ngOnInit() {
    this.supportLink = this.frontService.supportLink;

    this.frontService.loadMenu(this.menus);
  }

  startTour() {
    introJs().start();
  }

  gotoChangeLog(){
    this.router.navigateByUrl('/help/changelogs/' + this.frontService.authService.auth.client.group_id);
  }

}


