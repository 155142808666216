import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { UserLog } from '../models/user-log.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class UserLogService {
  userlogsChanged = new Subject<UserLog[]>();
  userlogChanged = new Subject<UserLog>();
  userlogOperation = new Subject<UserLog>();

  private userlogs: UserLog[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setUserLogs(userlogs: UserLog[]) {
    this.userlogs = userlogs;
    this.userlogsChanged.next(this.userlogs.slice());
  }

  getUserLogs() {
    return this.userlogs.slice();
  }

  getIdIndex(id: number) {
    return this.userlogs.findIndex(c => c.ID === id);
  }

  httpGetUserLogs(params: any = [], columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/userlogs',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: UserLog[]) => {
        this.setUserLogs(response);
      }
    );
  }

  httpGetUserLog(id: number) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/userlogs/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: UserLog) => {
        this.userlogChanged.next(response);
      }
    );
  }

  httpPostUserLog(data: UserLog) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/userlogs',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.userlogOperation.next(response);
      }
    );
  }

  httpPutUserLog(id: number, data: UserLog) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/userlogs/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.userlogOperation.next(response);
      }
    );
  }

  httpDeleteUserLog(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/userlogs/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetUserLogs();
      }
    );
  }
}
