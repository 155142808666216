import { Component, OnInit, OnDestroy } from '@angular/core';
import { TemplateService } from '../../../services/template.service';
import { Subscription } from 'rxjs';
import { Template } from '../../../models/template.model';
import { FrontService } from '../../front.service';

@Component({
  selector: 'app-analytics-top-used-templates',
  templateUrl: './analytics-top-used-templates.component.html',
  styleUrls: ['./analytics-top-used-templates.component.scss']
})
export class AnalyticsTopUsedTemplatesComponent implements OnInit, OnDestroy {
  loading = false;

  template: Template;
  templates: Template[];

  templatesSubscription: Subscription;

  subscriptionMaster: Subscription;
  subscriptionResponse: Subscription;

  constructor(private frontService: FrontService, private templateService: TemplateService) { }

  ngOnInit() {


    this.templateService.httpGetTemplates(
      {
        client_id: this.frontService.authService.auth._ID,
        is_active: '1'
      },
      '',
      ''
    );

    this.templatesSubscription = this.templateService.templateGetAll.subscribe(
      (templates: any) => {
        if ( typeof templates !== 'undefined' && templates.status === 'success' ) {
          this.loading = false;
          this.templates = templates.data;
        }
      }
    );



  }

  ngOnDestroy() {
    this.templatesSubscription.unsubscribe();
  }

}
