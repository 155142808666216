import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MergefieldService } from '../../../../services/mergefield.service';
import { MergeField } from '../../../../models/merge-field.model';

@Component({
  selector: 'app-admin-pros-builder-merge-fields-dashboard',
  templateUrl: './admin-pros-builder-merge-fields-dashboard.component.html',
  styleUrls: ['./admin-pros-builder-merge-fields-dashboard.component.scss']
})
export class AdminProsBuilderMergeFieldsDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  mergeFields: MergeField[] = [];

  constructor(
    private mergeFieldService: MergefieldService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.mergeFieldService.httpGetAll();

    this.mergeFieldService.onGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.mergeFields = response.data;
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
