import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable, Inject } from '@angular/core';

import { APP_CONFIG } from '../app.config';
import { Subscription } from 'rxjs/Subscription';
import { SettingService } from './setting.service';

@Injectable()
export class AuthBlockGuard implements CanActivate {
  listingsSubscription: Subscription;

  constructor(@Inject(APP_CONFIG) private appConfig,
              private authService: AuthService,
              private router: Router,
              private settingService: SettingService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.settingService.httpGetSettingListings(1);

    this.listingsSubscription = this.settingService.settingListings.subscribe(
      (listings: any) => {
        if (typeof listings !== 'undefined' && typeof listings.whitelist_officeid !== 'undefined') {
          const whitelistOfficeID = listings.whitelist_officeid.split(',').map((item: string) => item.trim());
          const blacklistBrandID = listings.blacklist_brandid.split(',').map((item: string) => item.trim());

          if (this.authService.checkAuthCookie()) {
            this.authService.getAuthCookie();

            if ( whitelistOfficeID.indexOf(this.authService.auth.groupid) < 0
              && blacklistBrandID.indexOf(this.authService.auth.brandid) >= 0) {
              this.authService.deleteAuthCookie();

              window.location.replace('http://mydesign.websiteblue.com');

              return false;
            }

          }
        }
      }
    );


    return true;
  }
}
