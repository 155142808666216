<div class="height-100" id="sign-form-box">
  <div class="col-xs-12 col-sm-6 col-md-3 box">
    <img src="/assets/img/mydesign-logo-inverted-2020.svg" class="logo">
    <div class="heading">MyDesktop Login Credentials</div>
    <p class="sub-heading" *ngIf="!is_2fa">In order to utilise the new MyDesign we need to connect your MyDesktop account to MyDesign on this device.  Please enter your MyDesktop credentials below.</p>
    <p class="sub-heading" *ngIf="is_2fa">{{ auth_message }}</p>
    <form *ngIf="!is_2fa" [formGroup]="loginForm" (ngSubmit)="onLogin()">
      <div class="form-group">
        <input formControlName="username" type="text" class="form-control" id="username" placeholder="Username">
      </div>
      <div class="form-group">
        <input formControlName="password" type="password" class="form-control" id="password" placeholder="Password">
      </div>
      <button type="submit" class="btn btn-primary form-control theme-btn-default">Sign In</button>
    </form>
    <form *ngIf="is_2fa" [formGroup]="twoFaForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input formControlName="password" type="password" class="form-control" id="password" placeholder="2FA Code">
      </div>
      <button type="submit" class="btn btn-primary form-control theme-btn-default">Sign In</button>
    </form>
    <p class="sub-heading problem-link-holder"><a class="login-link" target="_blank" href="https://websites.formstack.com/forms/mydesign_support_form">Problems logging in?</a></p>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>