import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ChargebeeService {
  onGetPlans = new Subject();
  onGetCustomerPlans = new Subject();
  onCancelPlans = new Subject();
  onCheckPlan = new Subject();
  onCreateSession = new Subject();
  onCancelSubscription = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetPlans(params: any = {}) {
    this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/plans`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetPlans.next(response);
      },
      (response: any) => {
        this.onGetPlans.next(response.error);
      }
    );
  }

  httpGetCustomerPlans(params: any = {}) {
    this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/plans/customer`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetCustomerPlans.next(response);
      },
      (response: any) => {
        this.onGetCustomerPlans.next(response.error);
      }
    );
  }

  httpCancelPlans(data: any) {
    this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/plans/cancel`, data)
    .subscribe(
      (response: any) => {
        this.onCancelPlans.next(response);
      },
      (response: any) => {
        this.onCancelPlans.next(response.error);
      }
    );
  }

  httpCheckPlan(data: any) {
    this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/plan/check`, data)
    .subscribe(
      (response: any) => {
        this.onCheckPlan.next(response);
      },
      (response: any) => {
        this.onCheckPlan.next(response.error);
      }
    );
  }

  httpHostedPage(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/hostedpage`, data);
  }

  httpUpdateSubscriptionHostedPage(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/hostedpage/update`, data);
  }

  httpCreateSession(customerType: string) {
    this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/session/${customerType}`)
    .subscribe(
      (response: any) => {
        this.onCreateSession.next(response);
      },
      (response: any) => {
        this.onCreateSession.next(response.error);
      }
    );
  }

  httpCancelSubscription(customerType: 'client' | 'user') {
    this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/chargebee/cancel/${customerType}`, {
      cancel: true
    })
    .subscribe(
      (response: any) => {
        this.onCancelSubscription.next(response);
      },
      (response: any) => {
        this.onCancelSubscription.next(response.error);
      }
    );
  }

  formatPlanName(plan: string): string {
    if (plan.includes('Bundle')) {
      return 'Bundle';
    }

    if (plan.includes('Live-Reports')) {
      return 'Live Reports';
    }

    if (plan.includes('WebBooks')) {
      return 'WebBooks';
    }

    if (plan.includes('Proposals')) {
      return 'Proposals';
    }

    if (plan.includes('Pro-Builder')) {
      return 'Pro Builder';
    }

    return plan;
  }
}
