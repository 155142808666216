import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listingsearch'
})
export class ListingPipe implements PipeTransform {

  transform(value: any, term: string = '', type: string = ''): any {
    if (term === '') {
      return value;
    }

    return value.filter(function (val) {
      return val.displayaddress.toLowerCase().includes(term);
    });
  }
}
