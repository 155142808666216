import { Component, OnInit, Inject, AfterContentInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FrontService } from '../../../front.service';
import { MasterService } from '../../../../services/master.service';
import { TemplateService } from '../../../../services/template.service';
import { Template } from '../../../../models/template.model';
import { Subscription } from 'rxjs/Subscription';
import { Auth } from '../../../../models/auth.model';

import * as moment from 'moment';
import { APP_CONFIG } from '../../../../app.config';

declare const $: any;
declare const swal: any;
declare const _bugHerd: any;

@Component({
  selector: 'app-template-office',
  templateUrl: './template-office.component.html',
  styleUrls: ['./template-office.component.scss']
})
export class TemplateOfficeComponent implements OnInit, AfterContentInit, OnDestroy {
  loading = false;

  template: Template;
  templates: Template[];

  S3_RESOURCES_URL = '';

  term;
  template_count = 0;
  templateFilter;
  dateFilter;

  sharedOfficeTemplatesSubscription: Subscription;

  authUser: Auth;
  user = {
    ID: 1,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  supportLink = '';
  isFetchedAlready = false;

  shareListUsers = [];

  isList = false;

  @ViewChild('previewModal', { static: true }) previewModal: ElementRef;

  constructor(@Inject(APP_CONFIG) private appConfig, private frontService: FrontService,
              private masterService: MasterService,
              private templateService: TemplateService) { }

  ngOnInit() {
    this.supportLink = this.frontService.supportLink;

    if ( this.frontService.authService.getApp() === 'Designly' ) {
      this.isList = true;
    }

    this.S3_RESOURCES_URL = this.appConfig.S3_URL + 'templates/thumbnails/';
    this.authUser = this.frontService.authService.auth;
    this.user = {
      ID: this.authUser.user.id,
      name: this.authUser.user.firstname + ' ' + this.authUser.user.firstname,
      photo: this.authUser.user.imageurl,
      email: this.authUser.user.email,
      mobile: this.authUser.user.mobile,
      telephone: this.authUser.user.telephone,
      fax: this.authUser.user.fax
    };

    this.shareListUsers = this.authUser.client.users;

    this.loading = true;
    this.templateService.httpGetTemplates(
      {
        user_agent_id: this.authUser.user.agent_id,
        client_id: this.authUser.client.ID,
        is_active: '1',
        // is_shared: '2'
      },
      'share-to-users'
    );

    this.sharedOfficeTemplatesSubscription = this.templateService.templateGetAll.subscribe(
      (templates: any) => {
        if ( typeof templates !== 'undefined' && templates.status === 'success' ) {
          this.loading = false;
          this.templates = templates.data;

          this.templateFilter = 'all';
          this.dateFilter = 'recent';
        }
      }
    );

  }

  ngAfterContentInit() {
    // grid and list view
    jQuery(document).on('click', '.template-view', function(e){
      e.preventDefault();
      const view = jQuery(this).attr('data-view');

      jQuery('.template-view').removeClass('active');
      jQuery(this).addClass('active');

      if (view === 'list') {
            jQuery('#arrangement-grid').removeClass('active');
            jQuery('.media-list').removeClass('is-grid').addClass('is-list');
      } else {
            jQuery('#arrangement-list').removeClass('active');
            jQuery('.media-list').removeClass('is-list').addClass('is-grid');
      }
    });
  }

  onDuplicate(item) {
    const $this = this;
    // this.loading = true;

    swal({
      title: 'Enter Title',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: function (text) {
        return new Promise(function (resolve, reject) {
          setTimeout(function() {
              resolve();
          }, 2000);
        });
      },
      allowOutsideClick: false
    }).then(function (text) {
      swal({
        type: 'success',
        title: 'Your template has been created.'
      });
      setTimeout(function() {
        item.is_shared = 0;
        const templateValues = <any>{
          title: text,
          user_agent_id: $this.authUser.user.agent_id
        };
        $this.templateService.httpDuplicateTemplate(item.ID, templateValues);
      }, 500);
    }).catch(swal.noop);
  }

  onFeedback() {
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html: '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
      // tslint:disable-next-line:max-line-length
      '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' + this.supportLink + '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>'
      } );

      jQuery('.btn-bh-feedback').click(function() {
        swal.close();
        _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
      });

  }

  convertDate(date) {
    return moment(date).format('DD/MM/YY');
  }

  onPreview(template: any) {
    let img_src = '';
    if ( template.thumbnail === '' ) {
      img_src = template.ID + '.jpg';
    } else {
      img_src = template.thumbnail;
    }

    this.previewModal.nativeElement.querySelector('.modal-body img').src
      = this.frontService.appConfig.S3_URL + 'templates/thumbnails/' + img_src;

    $('#previewTemplate').modal('show');
  }

  onSearchKeyUp() {
    if ( !this.isFetchedAlready ) {
      this.isFetchedAlready = true;

      setTimeout(() => {
        this.isFetchedAlready = false;

        const templateQuery = <any> {
          user_agent_id: this.authUser.user.agent_id,
          client_id: this.authUser.client.ID,
          is_active: '1',
          keyword: this.term
        };

        if ( this.authUser.is_admin || this.authUser.client.group_id === 12 ) {
          delete templateQuery.user_agent_id;
        }

        this.templateService.httpGetTemplates(
          templateQuery,
          'share-to-users'
        );
      }, 2000);
    }
  }

  getAgentData(id, type) {
    const agentID = this.shareListUsers.findIndex(a => a.id === id);
    if ( typeof this.shareListUsers[ agentID ] !== 'undefined') {
      return this.shareListUsers[ agentID ][ type ];
    }

    return ' ';
  }

  ngOnDestroy() {
    this.frontService.authService.cancelRequest();
    this.sharedOfficeTemplatesSubscription.unsubscribe();
  }

}

