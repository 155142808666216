<div *ngIf="type === 'agency-website'" class="title-holder">
  <h1 class="site-title">Looking for a new agency website?</h1>
  <p class="site-description">At Website Blue we work with you to provide the most cost-effective means to get your
    Agency online. Our real
    estate websites include full MyDesktop integration and customised easy-to-use content management system (CMS). See
    below for a range of websites we have developed.<br /><br />If interested in working with Website Blue? Please
    contact us below and a sales representative will get back to you swiftly.</p>
</div>
<div *ngIf="type === 'agent-only-website'" class="title-holder">
  <h1 class="site-title">Looking for an agent only website?</h1>
  <p class="site-description">Our Agent-Only Websites allow individual agents to market their profile and branding. Each
    website has the ability
    to add Facebook feeds, link to properties and display the agent’s profile and imagery. See below for a range of
    agent websites we have developed.<br /><br />If interested in working with Website Blue? Please contact us below and
    a sales representative will get back to you swiftly.</p>
</div>
<div *ngIf="type === 'lead-generating-website'" class="title-holder">
  <h1 class="site-title">Looking for an lead generating website?</h1>
  <p class="site-description">If interested in
    working with Website Blue? Please contact us below and a sales representative will get back to you swiftly.</p>
</div>
<div class="page animsition">
  <div class="page-content padding-0">
    <ul class="blocks no-space blocks-100 blocks-xlg-3 blocks-md-2">
      <li *ngFor="let website of websites" class="widget">
        <div class="widget-header cover overlay overlay-hover">
          <img #widgetImage class="cover-image overlay-scale" src="{{ website.image }}"
            (click)="zoomImage(widgetImage)" alt="{{ website.name }}">
          <div
            class="overlay-panel overlay-fade overlay-background overlay-background-fixed text-center vertical-align">
            <div class="vertical-align-middle">
              <h3 class="widget-title margin-bottom-20">{{ website.name }}</h3>
              <button (click)="viewImage(website.image)" type="button"
                class="btn btn-round btn-outline btn-inverse">View</button> <button (click)="onEnquire()" type="button" 
                class="btn btn-round btn-outline btn-inverse">Enquire</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
