<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add User': 'Edit User - ' + account?.email }}</h2>

    <button routerLink="/admin/clients/{{ client?.ID }}/users" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-4">
      <div class="edit-box-content">
        <h4>Account Details</h4>
        <form (ngSubmit)="onSubmit()" [formGroup]="userForm" class="form-horizontal">
          <div class="form-group">
            <label for="email" class="col-sm-12">Email</label>
            <div class="col-sm-12">
              <input formControlName="email" required type="text" class="form-control" id="email" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="password" class="col-sm-12">Password</label>
            <div class="col-sm-12">
              <input formControlName="password" required type="password" class="form-control" id="password" autocomplete="off">
            </div>
          </div>
          <p *ngIf="editMode" class="text-danger">Leave blank if you don't want to edit the password.</p>
          <div class="form-group">
            <label for="is_active" class="col-sm-12">Is Active</label>
            <div class="col-sm-12">
              <select formControlName="is_active" class="form-control">
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
          </div>
          <h4>Personal Details</h4>
          <div class="form-group">
            <label for="firstname" class="col-sm-12">First Name</label>
            <div class="col-sm-12">
              <input formControlName="firstname" required type="text" class="form-control" id="firstname" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="lastname" class="col-sm-12">Last Name</label>
            <div class="col-sm-12">
              <input formControlName="lastname" required type="text" class="form-control" id="lastname" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="mobile" class="col-sm-12">Mobile</label>
            <div class="col-sm-12">
              <input formControlName="mobile" required type="text" class="form-control" id="mobile" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="telephone" class="col-sm-12">Telephone</label>
            <div class="col-sm-12">
              <input formControlName="telephone" type="text" class="form-control" id="telephone" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="fax" class="col-sm-12">Fax</label>
            <div class="col-sm-12">
              <input formControlName="fax" type="text" class="form-control" id="fax" autocomplete="off">
            </div>
          </div>
          <br>
          <div class="form-group">
            <div class="col-sm-12">
              <button [disabled]="!userForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>