<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Element': 'Element - ' + element_title }}</h2>

    <button (click)="onClose()" type="button" class="btn-admin">
      <i class="icon feather icon-times"></i><span>Close</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="elementForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="html" class="col-sm-2">Html</label>
            <div class="col-sm-10">
              <textarea formControlName="html" rows="10" class="form-control" id="html" style="display: none" #testing></textarea>
              <div id="html_editor"></div>
            </div>
          </div>
          <div class="form-group">
            <label for="script" class="col-sm-2">JS</label>
            <div class="col-sm-10">
              <textarea formControlName="script" rows="10" class="form-control" style="display: none" id="script"></textarea>
              <div id="script_editor"></div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!elementForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>