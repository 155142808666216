import { Component, OnInit, OnDestroy } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { FrontService } from '../../front.service';
import { ChangelogService } from '../../../services/changelogs.service';
import { Subscription } from 'rxjs';
import { Changelog } from '../../../models/changelog.model';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-changelogs-front',
  templateUrl: './changelogs-front.component.html',
  styleUrls: ['./changelogs-front.component.scss']
})
export class ChangelogsFrontComponent implements OnInit, OnDestroy {
  loading = true;
  menus: Menu[] = [];

  subscription: Subscription;

  changelogs: Changelog[];

  constructor(
      public frontService: FrontService,
      private changelogService: ChangelogService,
      private authService: AuthService
    ) { }

  ngOnInit() {
    this.frontService.loadMenu(this.menus);

    this.changelogService.httpGetChangelogs({
      group_id: this.frontService.authService.auth.client.group_id,
      is_hidden: 0,
      provider: ['all', this.authService.auth.provider]
    });

    this.subscription = this.changelogService.changelogsChanged.subscribe(
      (changelogs: Changelog[]) => {
        if (typeof changelogs !== 'undefined') {
          this.changelogs = changelogs;
          this.loading = false;
        }
      }
    );

  }

  formatTime(time) {
    return moment(time).format('MMMM D, YYYY');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
