import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accordion'
})
export class AccordionPipe implements PipeTransform {

  transform(value: any, term: any): any {
    // tslint:disable-next-line:curly
    if (term === undefined) return value;

    return value.filter(function(val) {
      return val.title.toLowerCase().includes(term.toLowerCase());
    });

  }

}
