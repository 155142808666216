<div id="sign-form-box">
  <div class="box">
    <img src="/assets/img/MyDesign-Logo.png" class="logo">
    <p class="title">Looks like you have templates in the previous version of <span class="highlight-blue">MyDesign</span></p>
    <div class="heading ">If you wish to continue to use your old templates, please select the previous version of <span class="highlight-blue">MyDesign</span> - Old <span class="highlight-blue">MyDesign</span>. If you wish to access the new version of <span class="highlight-blue">MyDesign</span> and create new templates as well as access the new <span class="highlight-blue">MyDesign</span> features, please select New <span class="highlight-blue">MyDesign</span>.</div>
    <br />
    <br />
    <div class="heading ">Take me to MyDesign</div>
    <div class="btn-holder">
      <button type="button" (click)="onVersion2()" class="btn btn-primary form-control btn-20 btn-version">Old MyDesign</button>  
      <button type="button" (click)="onVersion3()" class="btn btn-primary form-control btn-20 btn-version">New MyDesign</button>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>