import { Params, ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FrontService } from './front.service';
import { Menu } from '../models/menu.model';
import { Subscription } from 'rxjs/Subscription';
import { MyDesktopService } from '../services/mydesktop.service';
import { ClientService } from '../services/client.service';
import { Client } from '../models/client.model';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { PasteService } from '../services/paste.service';
import { ChangelogService } from '../services/changelogs.service';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Auth } from '../models/auth.model';
import { SupportService } from '../services/support.service';
import { Subject } from 'rxjs';
import { DomainService } from '../services/domain.service';
import { EnvironmentService } from '../services/environment.service';

declare const $: any;
declare const swal: any;

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss']
})
export class FrontComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy$: Subject<boolean> = new Subject();

  subscription: Subscription;
  subscriptionClient: Subscription;
  subscriptionUser: Subscription;
  subscriptionChangelogs: Subscription;
  subscriptionSwitchOffice: Subscription;

  menus: Menu[];

  public paste_alert = false;
  new_notification = false;
  headline = '';
  is_changelog_button_hidden = false;

  authUser: Auth;
  isMultipleOffice = false;
  multipleOffice = [];

  switchOfficeForm: FormGroup;

  currentSlide = 1;
  agencyLoginForm: FormGroup;
  instructions = {
    'step1': 'Drag over the new "Article" Elements',
    'step2': 'Click the main image and select the "Article Search" button',
    'step3': 'Search through thousands of articles',
    'step4': 'Once added to your article library, simply click the insert button to populate your element'
  };

  isPartica = false;

  agencies = [];
  agents = [];
  contactSales = false;
  
  @ViewChild('sidebarWrapper', { static: true }) sidebar: ElementRef;
  @ViewChild('contentWrapper', { static: true }) content: ElementRef;

  isUpgraded = false;

  constructor(
    public frontService: FrontService,
    private mydesktopService: MyDesktopService,
    private clientService: ClientService,
    private userService: UserService,
    private router: Router,
    private pasteService: PasteService,
    private changelogService: ChangelogService,
    private supportService: SupportService,
    private domainService: DomainService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentVersion') !== 'live.designlypro.v1') {
      localStorage.clear();
      this.frontService.authService.clearCookies();

      return window.location.href = '/login';
    }

    this.authUser = this.frontService.authService.auth;
    if ( this.authUser.multiple.length > 1 ) {
      this.isMultipleOffice = true;
      this.multipleOffice = this.authUser.multiple.map(
        (obj) => {
          return {id: obj.groupid, name: obj.office};
        }
      );
    }

    if (this.authUser.client.is_account_active !== 1 || this.authUser.client.group.is_account_active !== 1) {
      swal(
        'Account has been blocked.',
        'Kindly contact MyDesign Support Team.',
        'error'
      );

      this.frontService.authService.deleteAuthCookie();
      this.router.navigate(['/login']);
    }

    this.changelogService.httpGetLast({
      provider: ['all', this.authUser.provider],
      group_id: this.authUser.client.group_id
    });

    this.subscription = this.frontService.menuChanged.subscribe(
      (menus: Menu[]) => {
        if (this.frontService.fullPageContent) {
          this.sidebar.nativeElement.className = 'hidden height-100';
          this.content.nativeElement.className = 'full-page height-100';
          this.frontService.fullPageContent = false;
        } else {
            // tslint:disable-next-line: max-line-length
            const sidebarClass = this.authUser.provider === 'mydesktop' && String(this.router.url).includes('shared-templates') || this.frontService.hasMenu() ? 'height-100 open-submenu' : 'height-100';
            const contentClass = this.authUser.provider === 'mydesktop' && String(this.router.url).includes('shared-templates') || this.frontService.hasMenu() ? 'height-100 open-submenu' : 'height-100';

            this.sidebar.nativeElement.className = sidebarClass;
            this.content.nativeElement.className = contentClass;
        }
      }
    );

    this.changelogService.onGetLast
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' && response.code === 'NEW_NOTIFICATION') {
          this.new_notification = true;

          if (typeof response.data !== 'undefined' && typeof response.data.headline !== 'undefined') {
            this.headline = response.data.headline;

            if (response.data.is_changelog_button_hidden) {
              this.is_changelog_button_hidden = true;
            }
          } else {
            this.headline = `A recent update has been made to ${this.frontService.authService.getApp()}`;
          }
        }
      }
    );

    this.subscriptionSwitchOffice = this.mydesktopService.myDesktopAuthSwitch.subscribe(
      (authSwitch: any) => {
        if (typeof authSwitch !== 'undefined' && authSwitch
          && authSwitch.status === 'success'
          && typeof authSwitch.data.token !== 'undefined' ) {
          this.mydesktopService.authService.setAuthSwitch(authSwitch.data);

          location.reload();
        } else if (typeof authSwitch !== 'undefined' && authSwitch && authSwitch.status === 'blocked' ) {
          swal(
            'Account has been blocked.',
            'Kindly contact MyDesign Support Team.',
            'error'
          );
        }
      }
    );

    this.userService.userOperation.subscribe(
    (user: any) => {
        if ( typeof user !== 'undefined' && typeof user.ID !== 'undefined' ) {
          if ( this.isPartica ) {
            this.router.navigateByUrl('/content/marketplace');
          }
        }
      }
    );

    this.domainService.onGetAgencies
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.agencies = response.data;
        }
      }
    );

    this.domainService.onGetPAgents
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.agents = response.data;
        }
      }
    );

    this.domainService.domainLogin.subscribe(
      (auth: any) => {
        if ( typeof auth !== 'undefined' && auth.status === 'success' ) {
          this.frontService.authService.setAuthStorage(auth);
          window.location.reload();
        }
    });

    this.environmentService.onGet
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response && typeof response !== 'undefined' && typeof response.apiEndpoint !== 'undefined') {
          localStorage.setItem('designly_environment', JSON.stringify(response));

          window.location.reload();
        } else {
          swal('Error changing skin', '', 'error');
        }
      }
    );

    this.menus = [];
    this.frontService.loadMenu(this.menus);

    this.initForm();
  }

  private initForm() {
    this.switchOfficeForm = new FormGroup({
      'office_id': new FormControl('', Validators.required)
    });
    this.agencyLoginForm = new FormGroup({
      'agency_id': new FormControl('', Validators.required),
      'agent_id': new FormControl('', Validators.required)
    });
  }

  onViewNotification() {
    const user = {
      new_notification: 0
    };

    this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
    jQuery('.notification_area').css('display', 'none');

    window.open('/help/changelogs/' + this.frontService.authService.auth.client.group_id, '_blank');
  }

  onNotificationClose() {
    const user = {
      new_notification: 0
    };

    this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
    jQuery('.notification_area').css('display', 'none');
  }

  onSubmitSwitch () {
    if (this.switchOfficeForm.valid) {
      const switchValues = this.switchOfficeForm.value;

      const index = this.authUser.multiple.findIndex(c => c.groupid === +switchValues['office_id']);

      const token = this.authUser.multiple[ index ].token;

      delete switchValues['office_id'];

      switchValues['office'] = this.authUser.multiple[ index ].office;
      switchValues['pftoken'] = this.authUser.multiple[ index ].pftoken;
      switchValues['brandid'] = this.authUser.multiple[ index ].brandid;
      switchValues['agentid'] = this.authUser.multiple[ index ].agentid;
      switchValues['groupid'] = this.authUser.multiple[ index ].groupid;

      if (token && typeof token !== 'undefined') {
        swal({
          title: 'Switching office account to ' + switchValues['office'],
          onOpen: function () {
            swal.showLoading ();
          }
        });
        this.mydesktopService.httpPostAuthSwitch(switchValues, token);
      }
    }
  }

  onDisablePopup() {
    if ( this.checkIfDontShowChecked() ) {
      localStorage.setItem('tmp_partica_popup_disabled', 'yes');
      const user = {
        partica_popup: 1
      };
      this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
    }
  }

  onDisableActivePipe() {
    if (this.checkIfDontShowCheckedActivePipe()) {
      localStorage.setItem('tmp_activepipe_popup_disabled', 'yes');
      const user = {
        activepipe_popup: 1
      };
      this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
    }
  }

  onGoToMarketplace() {
    $('#articleModal').modal('hide');
    if ( this.checkIfDontShowChecked() ) {
      this.isPartica = true;
      this.onDisablePopup();
    } else {
      this.router.navigateByUrl('/content/marketplace');
    }
  }

  checkIfDontShowChecked() {
    return $('#showCheckBox').is(':checked');
  }

  checkIfDontShowCheckedActivePipe() {
    return $('#showCheckBoxActivePipe').is(':checked');
  }

  slider(type) {
    if ( type === 'prev' ) {
      if ( this.currentSlide === 1 ) {
        return false;
      } else {
        this.currentSlide -= 1;
      }
    } else {
      if ( this.currentSlide === 4 ) {
        return false;
      } else {
        this.currentSlide += 1;
      }
    }
  }

  onUpgrade() {
    this.isUpgraded = true;
    const upgradeValues = {
      'firstname': this.authUser.user.firstname,
      'lastname': this.authUser.user.lastname,
      'email': this.authUser.user.email,
      'phone': this.authUser.user.mobiledisplay,
      'office': this.authUser.client.name
    };

    this.supportService.httpPostActivePipe(upgradeValues);

    const user = {
      activepipe_popup: 1
    };
    this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
  }

  onLoginAgency() {
    const agencyLoginValues = this.agencyLoginForm.value;
    agencyLoginValues['select_agent_token'] = this.frontService.authService.getMDToken();
    agencyLoginValues['multi_login'] = true;
    agencyLoginValues['switch'] = true;

    this.domainService.httpLogin(agencyLoginValues);
  }

  onChangeAgency(agencyId: number) {
    this.domainService.httpGetPAgents({
      agency_id: agencyId
    });
  }

  changeSkin(skin: string) {
    const environmentUrl = `https://s3-ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/environments/${skin}.json`;

    this.environmentService.httpGet(environmentUrl);
  }

  requestDemo() {
    this.contactSales = true;

    const hubspotScript = document.createElement('script');
    // tslint:disable-next-line: max-line-length
    const inlineScript = document.createTextNode(`hbspt.forms.create({region: "na1", portalId: "20238220",formId: "80996989-5419-4096-a2ed-42e752b2045b", target: "#proVersion-content2"});`);
    hubspotScript.appendChild(inlineScript);

    $('#proVersion-content2').addClass('h-100').append(`<div class="form-loader d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
    </div>`);

    setTimeout(() => {
      $('#proVersion-content2').find('.form-loader').remove();
      $('head').append(hubspotScript);
    }, 1500);
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   $('#proVersionModal').modal('show');
    // }, 2100);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionClient.unsubscribe();
    this.subscriptionUser.unsubscribe();
    this.subscriptionSwitchOffice.unsubscribe();
  }
}
