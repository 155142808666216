import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class MergefieldService {
  onGetFields = new Subject();
  onGetAll = new Subject();
  onStore = new Subject();
  onGet = new Subject();
  onUpdate = new Subject();
  onGetCategories = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetAll(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/mergefields`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetAll.next(response);
      },
      (response: any) => {
        this.onGetAll.next(response.error);
      }
    );
  }

  httpGetFields(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/mergefields/flat/fields`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetFields.next(response);
      },
      (response: any) => {
        this.onGetFields.next(response.error);
      }
    );
  }

  httpGetCategories(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/mergefields/categories`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetCategories.next(response);
      },
      (response: any) => {
        this.onGetFields.next(response.error);
      }
    );
  }

  httpStore(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/mergefields`, data)
    .subscribe(
      (response: any) => {
        this.onStore.next(response);
      },
      (response: any) => {
        this.onStore.next(response.error);
      }
    );
  }

  httpGet(uuid: string) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/mergefields/${uuid}`)
    .subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  httpUpdate(uuid: string, data: any) {
    return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/mergefields/${uuid}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdate.next(response);
      },
      (response: any) => {
        this.onUpdate.next(response.error);
      }
    );
  }
}
