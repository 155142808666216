import { Directive, HostListener, HostBinding, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[fileDragDrop]'
})

export class FileDragNDropDirective {
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '';
  @HostBinding('style.border') private borderStyle = '';
  @HostBinding('style.border-color') private borderColor = '';
  @HostBinding('style.border-radius') private borderRadius = '';

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#d5d9db';
    this.borderColor = '';
    this.borderStyle = '';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    this.borderColor = '';
    this.borderStyle = '';
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    this.borderColor = '';
    this.borderStyle = '';
    const files = evt.dataTransfer.files;
    const valid_files: Array<File> = files;
    this.filesChangeEmiter.emit(valid_files);
  }
}
