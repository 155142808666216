<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Clients</h2>

    <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
      <i class="icon feather icon-trash"></i><span>Delete Selected</span>
    </button>
    <!-- <button type="button" class="btn btn-info table-btn-selected" *ngIf="selectedRow != 0">
      <i class="icon feather icon-copy"></i><span>Duplicate Selected</span>
    </button> -->

    <button type="button" class="btn-admin">
      <i class="icon feather icon-plus"></i><span>Add Client</span>
    </button>
  
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <!-- <label class="control">
          <input type="text" class="form-control admin-input" placeholder="Search..." [(ngModel)]="filter">
      </label> -->
      <table id="datatable-clients" class="datatable-clients table table-striped table-borderless table-hover" cellspacing="0" width="100%"
      style="width:100%">
        <thead>
          <tr>
            <th data-priority="1">ID</th>
            <th data-priority="3">Provider</th>
            <th data-priority="4">Office</th>
            <th>Brand ID</th>
            <th>Office ID</th>
            <th>Show Pro UI</th>
            <th>Franchise Master</th>
            <th>Master</th>
            <th data-priority="5">Status</th>
            <th style="text-align: right" data-priority="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngFor="let client of clients">
            <td>{{ client.name ? client.name : client?.company_name }}</td>
            <td>{{ client.franchise_code }}</td>
            <td>{{ client.office_id }}</td>
            <td>{{ client.is_franchise_master ? 'Yes' : 'No' }}</td>
            <td>{{ client.is_account_master ? 'Yes' : 'No' }}</td>
            <td><span class="label label-success">{{ client.is_account_active ? 'Active' : 'Inactive' }}</span></td>
            <td style="text-align: right">
              <button routerLink="{{ client.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button (click)="openGallery(client.ID, client.group_id, client)" type="button" class="btn-table" title="Gallery"><i class="icon feather icon-image"></i></button>
              <button *ngIf="client?.users.length > 0" (click)="loginAs(client)" type="button" class="btn-table" title="Login As"><i class="icon feather icon-log-in"></i></button>
              <button (click)="onDelete(client.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr> -->
        </tbody>
      </table>
      <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
    </div>
  </div>
</div>
<div #mdLibrarySearchModal id="mdLibrarySearch" class="modal fade edit-box preview app-media app-media-new" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-dialog-custom" role="document">
    <div class="modal-content modal-content-custom">
      <div class="modal-body modal-body-custom">
          <button (click)="closeGallery()" type="button" class="close close-outside close-outside-library" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <app-image-library-revamp page="admin" libraryType="image"></app-image-library-revamp>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>