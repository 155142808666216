<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ this.title }}</h2>

   
  </div>
</div>
<div class="page-content container-fluid">
    <!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
<div class="row">
  <div class="col-lg-3 col-sm-4">
    <!-- Panel -->
    <div class="panel panel-box-shadow">
      <div class="panel-body">
        <div class="list-group" data-plugin="nav-tabs" role="tablist">
          <a class="list-group-item active" data-bs-toggle="tab" href="#category-1" aria-controls="category-1" role="tab">Latest Activity</a>
          <a class="list-group-item" data-bs-toggle="tab" href="#category-2" aria-controls="category-2" role="tab">Top Used Templates</a>
          <a class="list-group-item" data-bs-toggle="tab" href="#category-3" aria-controls="category-3" role="tab">Template Report</a>
          <a class="list-group-item" data-bs-toggle="tab" href="#category-4" aria-controls="category-4" role="tab">Login Report</a>
        </div>
      </div>
    </div>
    <!-- End Panel -->
  </div>

  <div class="col-lg-9 col-sm-8">
    <div class="tab-content">
      <!-- Categroy 1 -->
      <div>
          
      </div>
      <app-analytics-latest-activity  class="tab-pane active" id="category-1" role="tabpanel"></app-analytics-latest-activity>
      <!-- End Categroy 1 -->

      <!-- Categroy 2 -->
    
      <app-analytics-top-used-templates class="tab-pane" id="category-2" role="tabpanel"></app-analytics-top-used-templates>
      <!-- End Categroy 2 -->


        <app-analytics-template-report  class="tab-pane" id="category-3" role="tabpanel"></app-analytics-template-report>
         
        <app-analytics-login-report class="tab-pane" id="category-4" role="tabpanel"></app-analytics-login-report>
      </div>
     
    </div>
  </div>
</div>