<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 heading">
          <h4 class="pull-left">Departments</h4>
          <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
            <i class="icon feather icon-trash"></i><span>Delete Selected</span>
          </button>
          <button data-bs-target="#newDepartment" data-bs-toggle="modal" type="button" class="btn-admin btn-admin-primary">
              <i class="icon feather icon-plus"></i><span>Add Department</span>
            </button>
        </div>
      </div>
      <table class="table table-hover" id="tbl_department">
        <thead>
          <tr>
            <th>ID</th>
            <th>Department</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let department of departments">
            <td class="hidden">{{ department.ID }}</td>
            <td>{{ department.title }}</td>
            <td style="text-align: right">
              <button (click)="onDelete(department.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="newDepartment" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="newDepartmentLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #newDepartmentModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="newDepartmentLabel">Add Department</h4>
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="departmentForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="title" class="col-sm-2">Name</label>
            <div class="col-sm-6">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!departmentForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>