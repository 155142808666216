<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Popup': 'Popup - ' + popup?.title }}</h2>

    <button [routerLink]="" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form (ngSubmit)="onSubmit()" [formGroup]="popupForm" class="form-horizontal">
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-5">
              <select #provider formControlName="provider" class="form-control" id="provider">
                <option value="all">All</option>
                <option value="mydesktop">MyDesktop</option>
                <option value="vaultre">VaultRE</option>
                <option value="domain">Domain</option>
                <option value="designly">Designly</option>
                <option value="idashboard">iDashboard</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="image" class="col-sm-2">Image</label>
            <div class="col-sm-6">
              <input #popupImage formControlName="image" type="file" class="form-control" id="image">
            </div>
          </div>
          <div class="form-group">
            <label for="findout_url" class="col-sm-2">Find out More URL</label>
            <div class="col-sm-6">
              <input formControlName="findout_url" required type="text" class="form-control" id="findout_url">
            </div>
          </div>
          <br>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!popupForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>