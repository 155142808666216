<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Master Templates - {{ category_name }}</h2>
    <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
      <i class="icon feather icon-trash"></i><span>Delete Selected</span>
    </button>
    <button routerLink="new" type="button" class="btn-admin"><i class="icon feather icon-plus"></i><span>Add Master Template</span></button>
  </div>
</div>
<div id="box" class="row">
  <div class="groupBox col-md-4">
    <form [formGroup]="selectGroupForm">
      <label for="sgf_group_id">Filter by Group</label>
      <ng-select id="sgf_group_id" formControlName="group_id" [(ngModel)]="group_id" (change)="onChangeGroup($event)">
        <ng-option value="" disabled>Select group</ng-option>
        <ng-option value="0">All</ng-option>
        <ng-option *ngFor="let group of groups" [value]="group.ID">{{ group.title }}</ng-option>
      </ng-select>
    </form>
  </div>

  <div class="col-md-12">
    <div class="table-box">
      <table id="datatable-masters" class="datatable-elements table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width: 100%">
        <thead>
          <tr>
            <th>ID</th>
            <th data-priority="1">Provider</th>
            <th data-priority="3">Title</th>
            <th data-priority="5">Group</th>
            <th data-priority="4">Media Attribute</th>
            <th>Department</th>
            <th data-priority="6">Client</th>
            <th>Date Added</th>
            <th>Date Modified</th>
            <th data-priority="7">Status</th>
            <th data-priority="2" style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let master of masters">
            <td>{{ master.ID }}</td>
            <td>{{ master.provider }}</td>
            <td>{{ master.title }}</td>
            <td>{{ master.group.title }}</td>
            <td *ngIf="master.media_attribute">{{ master.media_attribute.slug | uppercase }}</td>
            <td *ngIf="!master.media_attribute"></td>
            <td>{{ master.department?.title }}</td>
            <td>{{ master.client_id === 0 ? 'All' : master.client?.name }}</td>
            <td>{{ master.created | date: 'dd.MM.yy' }}</td>
            <td>{{ master.modified | date: 'dd.MM.yy' }}</td>
            <td>
              <span *ngIf="master.is_active === 1" class="label label-success">Active</span>
              <span *ngIf="master.is_active === 0" class="label label-danger">Deactivated</span>
              <span *ngIf="master.is_active === 2" class="label label-warning">Coming Soon</span>
              <span *ngIf="master.is_active === 3" class="label label-primary">Master Only</span>
            </td>
            <td style="text-align: right">
              <button routerLink="{{ master.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button routerLink="{{ master.ID }}/edit/duplicate" type="button" class="btn-table" title="Duplicate"><i class="icon feather icon-copy"></i></button>
              <button (click)="onDelete(master.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
