<app-vaultre-login *ngIf="frontService.authService.getApp() === 'Designly' && frontService.authService.isSignifi() === 'no' && frontService?.appConfig?.SKIN !== 'idashboard'"></app-vaultre-login>

<app-domain-login *ngIf="frontService.authService.getApp() === 'Designly' && frontService.authService.isSignifi() === 'yes'"></app-domain-login>

<app-login *ngIf="frontService.authService.getApp() === 'MyDesign3'"></app-login>

<app-idashboard-login *ngIf="frontService.authService.getApp() === 'Designly' && frontService.authService.isSignifi() === 'no' && frontService?.appConfig?.SKIN === 'idashboard'"></app-idashboard-login>

<!-- <div *ngIf="!frontService?.appConfig?.PRODUCTION && frontService?.appConfig?.APP === 'Designly'" class="change-template">
    <div class="row">
        <div class="col-md-12">
            <h3>Select Skin</h3>
            <select #skin class="form-control" (change)="changeSkin(skin.value)">
                <option value="">Select Skin</option>
                <option value="designly-staging">Designly</option>
                <option value="designly-prd">PRD</option>
                <option value="designly-professionals">Professionals</option>
                <option value="designly-raywhite">Ray White</option>
                <option value="designly-signifi">Signifi</option>
                <option value="designly-idashboard">iDashboard</option>
            </select>
        </div>
    </div>
</div> -->