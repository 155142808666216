
import { InjectionToken } from '@angular/core';

import { environment } from '../environments/environment';
export let APP_CONFIG = new InjectionToken('app.config');
let configuredEnvironment = environment;

if (!environment.production) {
    let stageEnvironment = <any>localStorage.getItem('designly_environment');
    try {
        if (stageEnvironment) {
            stageEnvironment = JSON.parse(stageEnvironment);

            if (typeof stageEnvironment.skin !== 'undefined') {
                configuredEnvironment = stageEnvironment;
            }
        }
    } catch (e) {
        console.log(e);
    }
}

if (environment.production) {
    localStorage.removeItem('designly_environment');
}
export interface IAppConfig {
    HTML_ENDPOINT: string;
    API_ENDPOINT: string;
    S3_URL: string;
    NEW_S3_URL: string;
    IS_MAINTENANCE: boolean;
    VAULT_CLIENTID: string;
    PARTICA_ENDPOINT: string;
    PARTICA_PARTNERID: string;
    PARTICA_VIEWER_URL: string;
    VAULT_UK_CLIENTID: string;
    IS_UK: boolean;
    SKIN: string;
    DESIGNLYMAIL_URL: string;
    CRM_ENDPOINT: string;
    APP: string;
    PRODUCTION: boolean;
    NEW_API_ENDPOINT: string;
    PRO_BUILDER_URL: string;
    CHARGEBEE_SITE_ID: string;
    SATISMETER_WRITE_KEY: string;
    CACHED_S3_BUCKET_URL: string;
}

export const AppConfig: IAppConfig = {
    HTML_ENDPOINT: configuredEnvironment.htmlEndpoint,
    API_ENDPOINT: configuredEnvironment.apiEndpoint,
    S3_URL: configuredEnvironment.s3BucketUrl,
    NEW_S3_URL: configuredEnvironment.newS3BucketUrl,
    IS_MAINTENANCE: false,
    VAULT_CLIENTID: configuredEnvironment.vaultClientId,
    PARTICA_ENDPOINT: configuredEnvironment.particaEndpoint,
    PARTICA_PARTNERID: configuredEnvironment.particaPartnerId,
    PARTICA_VIEWER_URL: configuredEnvironment.particaViewerEndpoint,
    VAULT_UK_CLIENTID: configuredEnvironment.vaultUkClientId,
    IS_UK: configuredEnvironment.isUk,
    SKIN: configuredEnvironment.skin,
    DESIGNLYMAIL_URL: configuredEnvironment.crmUrl,
    CRM_ENDPOINT: configuredEnvironment.crmEndpoint,
    APP: configuredEnvironment.app,
    PRODUCTION: configuredEnvironment.production,
    NEW_API_ENDPOINT: configuredEnvironment.newApiEndpoint,
    PRO_BUILDER_URL: configuredEnvironment.proBuilderUrl,
    CHARGEBEE_SITE_ID: configuredEnvironment.chargebeeSiteId,
    SATISMETER_WRITE_KEY: configuredEnvironment.satismeterWriteKey,
    CACHED_S3_BUCKET_URL: configuredEnvironment.cachedS3BucketUrl
};
