import {HttpClient,   HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class PopupService {
    onGetAll = new Subject();
    onGet = new Subject();
    onGetLatest = new Subject();
    onAdd  = new Subject();
    onDelete = new Subject();
    onPut = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    httpGetAll(_params: any = []) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/popups`)
        .subscribe(
            (response: any) => {
                this.onGetAll.next(response);
            },
            (response: any) => {
                this.onGetAll.next(response.error);
            }
        );
    }

    httpGet(ID: number) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/popups/${ID}`)
        .subscribe(
            (response: any) => {
                this.onGet.next(response);
            },
            (response: any) => {
                this.onGet.next(response.error);
            }
        );
    }

    httpGetLatest() {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/popups/latest`)
        .subscribe(
            (response: any) => {
                this.onGetLatest.next(response);
            },
            (response: any) => {
                this.onGetLatest.next(response.error);
            }
        );
    }

    httpAdd(provider: string, findout_url, files = []) {
        const formData = new FormData();

        formData.append('provider', provider);
        formData.append('findout_url', findout_url);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/popups`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onAdd.next(response);
            },
            (response: any) => {
                this.onAdd.next(response.error);
            }
        );
    }

    httpPut(id: number, provider: string, findout_url, files = []) {
        const formData = new FormData();

        formData.append('provider', provider);
        formData.append('findout_url', findout_url);

        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/popups/${id}`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onPut.next(response);
            },
            (response: any) => {
                this.onPut.next(response.error);
            }
        );
    }

    httpDelete(id: number) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/popups/${id}`)
        .subscribe(
            (response: any) => {
                this.onDelete.next(response);
            },
            (response: any) => {
                this.onDelete.next(response.error);
            }
        );
    }
}
