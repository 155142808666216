<div class="row">
    <div class="col-md-12 heading">
      <h2 class="pull-left">{{ !editMode ? 'Add FAQ Category': 'FAQ Category' }}</h2>
  
      <button [routerLink]="[backUrl]" type="button" class="btn-admin">
        <i class="icon feather icon-arrow-left"></i><span>Back</span>
      </button>
    </div>
  </div>
  <div class="edit-box">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Details</h4>
          <form [formGroup]="faqCategoryForm" (ngSubmit)="onSubmit()" class="form-horizontal">
            <div class="form-group">
              <label for="title" class="col-sm-2">Title</label>
              <div class="col-sm-6">
                <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2">&nbsp;</label>
              <div class="col-sm-10">
                <button [disabled]="!faqCategoryForm.valid" class="btn-admin btn-admin-primary">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>