import { Component, OnInit } from '@angular/core';
import { FrontService } from '../../../front.service';

declare var swal: any;
declare var _bugHerd: any;

@Component({
  selector: 'app-template-agent-shared',
  templateUrl: './template-agent-shared.component.html',
  styleUrls: ['./template-agent-shared.component.scss']
})
export class TemplateAgentSharedComponent implements OnInit {

  templateFilter;
  dateFilter;
  term;

  template_count = 0;
  supportLink = '';

  constructor(private frontService: FrontService) { }

  ngOnInit() {
    this.supportLink = this.frontService.supportLink;
  }

  onFeedback(){
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html: '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
      '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' + this.supportLink+ '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>'
      } );

      jQuery('.btn-bh-feedback').click(function() {
        swal.close();
        _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
      });

  }

}
