import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SubgroupService } from '../../../services/subgroup.service';
import { Menu } from '../../../models/menu.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Subgroup } from '../../../models/subgroup.model';

@Component({
  selector: 'app-subgroups-edit',
  templateUrl: './subgroups-edit.component.html',
  styleUrls: ['./subgroups-edit.component.scss']
})
export class SubgroupsEditComponent implements OnInit, OnDestroy {
  loading = false;
  id: number;
  editMode = false;
  menus: Menu[] = [];
  subgroup: Subgroup;
  backUrl = '/admin/subgroups';

  subgroupForm: FormGroup;

  subgroupSubscription: Subscription;
  subgroupPostSubscription: Subscription;
  subgroupPutSubscription: Subscription;

  constructor(private adminService: AdminService,
              private route: ActivatedRoute,
              private subgroupService: SubgroupService,
              private router: Router
            ) { }

  ngOnInit() {
    this.adminService.loadMenu(this.menus);

    this.loading = false;
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;

        if (this.editMode) {
          this.loading = true;
          this.subgroupService.httpGetSubgroup(this.id);
        }
      }
    );

    this.subgroupSubscription = this.subgroupService.subgroupGet.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.loading = false;
          this.subgroup = subgroup.data;

          this.initEditSubGroupForm();
        }
      }
    );

    this.subgroupPostSubscription = this.subgroupService.subgroupPost.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.loading = false;

          this.router.navigateByUrl('/admin/subgroups');
        }
      }
    );

    this.subgroupPutSubscription = this.subgroupService.subgroupPut.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.loading = false;

          this.router.navigateByUrl('/admin/subgroups');
        }
      }
    );

    this.initSubGroupForm();
  }

  initSubGroupForm() {
    this.subgroupForm = new FormGroup({
      'title': new FormControl('', Validators.required)
    });
  }

  initEditSubGroupForm() {
    this.subgroupForm.patchValue({
      'title': this.subgroup.title
    });
  }

  onSubmit() {
    const subgroupFormValues = this.subgroupForm.value;

    if ( this.editMode ) {
      this.subgroupService.httpPutSubgroup(this.id, subgroupFormValues);
      this.loading = true;
    } else {
      this.subgroupService.httpPostSubgroup(subgroupFormValues);
      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.subgroupPostSubscription.unsubscribe();
    this.subgroupSubscription.unsubscribe();
    this.subgroupPutSubscription.unsubscribe();
  }

}
