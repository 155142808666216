import { Injectable, Inject } from '@angular/core';
import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';

import { Website } from '../models/website.model';
import { APP_CONFIG } from '../app.config';
import { WebsiteLog } from '../models/websitelog.model';

@Injectable()
export class WebsiteService {
  websiteGetAll = new Subject<Website[]>();
  websiteGet = new Subject<Website>();
  websitePost = new Subject<Website>();
  websitePut = new Subject<Website>();
  websiteDelete = new Subject<Website>();

  websiteLogGetAll = new Subject<WebsiteLog[]>();
  websiteLogPost = new Subject<WebsiteLog>();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  httpGetWebsites(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/websites',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.websiteGetAll.next(response);
      },
      (response: any) => {
        this.websiteGetAll.next(response.error);
      }
    );
  }

  httpGetWebsiteLogs(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/websites/logs',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.websiteLogGetAll.next(response);
      },
      (response: any) => {
        this.websiteLogGetAll.next(response.error);
      }
    );
  }

  httpGetWebsite(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/websites/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.websiteGet.next(response);
      },
      (response: any) => {
        this.websiteGet.next(response.error);
      }
    );
  }

  httpPostWebsite(data: any) {
    const formData = new FormData();

    formData.append('file', data['image'], data['image'].name);
    formData.append('name', data['name']);
    formData.append('type', data['type']);
    formData.append('sort', data['sort']);

    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/websites',
      formData
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.websitePost.next(response);
      },
      (response: any) => {
        this.websitePost.next(response.error);
      }
    );
  }

  httpPostLog(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/websites/logs',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
            return response.body;
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.websiteLogPost.next(response);
      },
      (response: any) => {
        this.websiteLogPost.next(response.error);
      }
    );
  }

  httpPutWebsite(id: number, data: any) {
    const formData = new FormData();

    if ( data['image'] !== '' ) {
        formData.append('file', data['image'], data['image'].name);
    }
    formData.append('name', data['name']);
    formData.append('type', data['type']);
    formData.append('sort', data['sort']);

    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/websites/' + id,
      formData
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.websitePut.next(response);
      },
      (response: any) => {
        this.websitePut.next(response.error);
      }
    );
  }

  httpDeleteWebsite(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/websites/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
    (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
            return response.body;
        }

        return [];
    }
    )
    .subscribe(
      (response: any) => {
        this.websiteDelete.next(response);
      },
      (response: any) => {
        this.websiteDelete.next(response.error);
      }
    );
  }

}
