import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Department } from '../models/department.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class DepartmentService {
  departmentsChanged = new Subject<Department[]>();
  departmentChanged = new Subject<Department>();
  departmentOperation = new Subject<Department>();

  private departments: Department[] = [];

  // New Subscriptions
  onGetDepartments = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setDepartments(departments: Department[]) {
    this.departments = departments;
    this.departmentsChanged.next(this.departments.slice());
  }

  getDepartments() {
    return this.departments.slice();
  }

  getIdIndex(id: number) {
    return this.departments.findIndex(c => c.ID === id);
  }

  httpGetDepartmentsNew(params: any = {}, columns: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/departments`, {
        params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetDepartments.next(response);
      },
      (response: any) => {
        this.onGetDepartments.next(response.error);
      }
    );
  }

  httpGetDepartments(params: any = {}, columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/departments',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Department[]) => {
        this.setDepartments(response);
      }
    );
  }

  httpGetDepartment(id: number) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/departments/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Department) => {
        this.departmentChanged.next(response);
      }
    );
  }

  httpPostDepartment(data: Department) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/departments',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.departmentOperation.next(response);
      }
    );
  }

  httpPutDepartment(id: number, data: Department) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/departments/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.departmentOperation.next(response);
      }
    );
  }

  httpDeleteDepartment(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/departments/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetDepartments();
      }
    );
  }
}
