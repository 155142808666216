import { Injectable, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class PasteService {
  public paste_alert = false;

  constructor() {}


}
