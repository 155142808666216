import { Component, OnInit, OnDestroy } from '@angular/core';
import { Group } from '../../../models/group.model';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { GroupService } from '../../../services/group.service';
import { Subscription } from 'rxjs/Subscription';
import { ElementService } from '../../../services/element.service';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.scss']
})
export class GroupDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];
  groups: Group[];
  subscription: Subscription;

  subscriptionOperationElements: Subscription;

  deleteGroupSubscription: Subscription;

  dataTableHtml: any;

  group_name = '';
  group_id = '';
  filter;
  page = 1;

  selectedRow = 0;
  selectedRowDataId = [];

  constructor(private adminService: AdminService,
              private groupService: GroupService,
              private elementService: ElementService) {}

  ngOnInit() {
    this.adminService.loadMenu(this.menus);

    this.subscription = this.groupService.groupsChanged.subscribe(
      (groups: Group[]) => {
        if (typeof groups !== 'undefined') {
          this.loading = false;
          this.groups = groups;

          if (jQuery.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    );

    this.groupService.httpGetGroups();

  
    this.subscriptionOperationElements = this.elementService.elementOperation.subscribe(
      (element: any) => {
        if(element == 'All Elements have been replicated'){
          this.loading = false;

          this.subscriptionOperationElements.unsubscribe();

          swal({
            position: 'top-end',
            type: 'success',
            title: 'Duplication Success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
    );

    this.deleteGroupSubscription = this.groupService.groupDelete.subscribe(
      (group: any) => {
        if ( typeof group !== 'undefined' && group.status === 'success' ) {
          swal(
            'Deleted!',
            'Group has been deleted.',
            'success'
          );

          this.groupService.httpGetGroups();
          this.loading = false;

          if (jQuery.fn.DataTable.isDataTable('table')) {
            this.dataTableHtml.destroy();
          }
          this.initDT();

        }
      }
    );

    this.initDT();
  }

  initDT() {
    setTimeout(
      () => {
        if ( ! jQuery.fn.DataTable.isDataTable( 'table' ) ) {

          this.dataTableHtml = jQuery('table:not(.static)').DataTable({
              searching: true,
              // paging: false,
              info: false,
              // ordering: false,
              responsive: true,
              'pagingType': 'full_numbers',
              'processing': true,
              // 'columnDefs': [ {
              //   targets: [0],
              //   visible: false,
              // }],
              // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
              'pageLength': 50,
              select: {
                'style': 'multiple',
              },
            });

            // this.dataTableHtml.column(0).visible(false);

            const $this = this;

            $('table:not(.static)').on('select.dt', function ( e, dt, type, indexes ) {
              $this.selectedRow = $this.selectedRow + 1;
              const data = dt.rows('.selected' ).data();

              for (let i = 0; i < $this.selectedRow; i++) {
                if ($this.selectedRowDataId.includes(data[i][0])) {
                  continue;
                } else {
                  $this.selectedRowDataId.push(data[i][0]);
                }
              }
            });

            $('table:not(.static)').on('deselect.dt', function ( e, dt, type, indexes ) {
              $this.selectedRow = $this.selectedRow - 1;
              const data = dt.rows(indexes).data();
              const groupID = data[0][0];

              $this.selectedRowDataId.forEach((value, index) => {
                  if (value === groupID) {
                    $this.selectedRowDataId.splice(index, 1);
                  }
              });
            });
          }
      }, 1000
    );
  }

  onDelete(id: number) {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this group?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        this.groupService.httpDeleteGroup(id);

        $this.initDT();
      }
    }).catch(swal.noop);

  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this selected group/s?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.groupService.httpDeleteGroup(element);
        });

        $this.initDT();
      }
    }).catch(swal.noop);
  }

  duplicateElement(group){
    jQuery('#duplicateElementModal').modal('show');

    this.group_name = group.title;
    this.group_id = group.ID;
  }

  duplicate(){
    jQuery('#duplicateElementModal').modal('hide');
    let new_group_id = jQuery( '#duplicate_select' ).val();

    this.loading = true;

    let replicate_data = {
      group_id: this.group_id,
      new_group_id: new_group_id
    }

    this.elementService.httpReplicateElement(replicate_data);
  
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.subscription.unsubscribe();
    this.subscriptionOperationElements.unsubscribe();
    this.deleteGroupSubscription.unsubscribe();

    if (jQuery.fn.DataTable.isDataTable(this.dataTableHtml)) {
      this.dataTableHtml.destroy();
    }
  }

}
