import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { WebsiteService } from '../../../services/website.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

import swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Website } from '../../../models/website.model';

@Component({
  selector: 'app-website-edit',
  templateUrl: './website-edit.component.html',
  styleUrls: ['./website-edit.component.scss']
})
export class WebsiteEditComponent implements OnInit, OnDestroy {
  loading = false;
  websiteForm: FormGroup;
  type: String;
  editMode = false;
  ID = 0;

  backUrl: string;

  websiteSubscription: Subscription;
  websitePostSubscription: Subscription;
  websitePutSubscription: Subscription;

  website: Website;

  constructor(
    private websiteService: WebsiteService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @ViewChild('fileImage', { static: true }) fileImage: ElementRef;

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.type = params['type'];
        this.ID = +params['id'];

        if ( this.type !== undefined ) {
          this.backUrl = '/admin/websites/' + this.type + '/dashboard';
          this.editMode = false;
        }

        if ( params['id'] !== undefined ) {
          this.editMode = true;
          this.websiteService.httpGetWebsite(this.ID);

          this.loading = true;
        }
      }
    );

    this.websitePostSubscription = this.websiteService.websitePost.subscribe(
      (website: any) => {
        if ( typeof website !== 'undefined' && website.status === 'success' ) {
          this.loading = false;
          this.router.navigateByUrl('/admin/websites/' + this.type + '/dashboard');
        }
      }
    );

    this.websiteSubscription = this.websiteService.websiteGet.subscribe(
      (website: any) => {
        if ( typeof website !== 'undefined' && website.status === 'success' ) {
          this.loading = false;
          this.website = website.data;
          this.backUrl = '/admin/websites/' + this.website.type + '/dashboard';

          this.initEditWebsiteForm();
        }
      }
    );

    this.websitePutSubscription = this.websiteService.websitePut.subscribe(
      (website: any) => {
        if ( typeof website !== 'undefined' && website.status === 'success' ) {
          this.loading = false;
          this.router.navigateByUrl('/admin/websites/' + website.data.type + '/dashboard');
        }
      }
    );

    this.initWebsiteForm();
  }

  initWebsiteForm() {
    this.websiteForm = new FormGroup({
      'type': new FormControl(''),
      'name': new FormControl('', Validators.required),
      'image': new FormControl(''),
      'sort': new FormControl(0, Validators.required)
    });
  }

  initEditWebsiteForm() {
    this.websiteForm.patchValue({
      'type': this.website.type,
      'name': this.website.name,
      'image': this.website.image,
      'sort': this.website.sort
    });
  }

  onSubmit() {
    const websiteFormValues = this.websiteForm.value;

    const image = this.fileImage.nativeElement;

    if ( !this.editMode &&  image.files.length === 0 ) {
      swal(
        'Missing Image',
        'Please add an Image',
        'error'
      );

      return false;
    }

    if ( image.files.length > 0 ) {
      websiteFormValues['image'] = image.files[0];
    } else {
      websiteFormValues['image'] = '';
    }

    if ( this.editMode ) {
      this.websiteService.httpPutWebsite(this.ID, websiteFormValues);
    } else {
      websiteFormValues['type'] = this.type;
      this.websiteService.httpPostWebsite(websiteFormValues);
    }

    this.loading = true;
  }

  ngOnDestroy() {
    this.websitePostSubscription.unsubscribe();
    this.websitePutSubscription.unsubscribe();
    this.websiteSubscription.unsubscribe();
  }

}
