import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { Icon } from '../../../models/icon.model';
import { IconService } from '../../../services/icon.service';
import { Subscription } from 'rxjs/Subscription';

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-icons-dashboard',
  templateUrl: './icons-dashboard.component.html',
  styleUrls: ['./icons-dashboard.component.scss']
})
export class IconsDashboardComponent implements OnInit {
  menus: Menu[] = [];
  icons: Icon[] = [];

  loading = false;

  subscription: Subscription;

  selectedRow = 0;
  selectedRowDataId = [];
  
  constructor(private adminService: AdminService, private iconService: IconService) { }

  ngOnInit() {
      this.adminService.loadMenu(this.menus);

      this.iconService.httpGetIcons();

      this.subscription = this.iconService.iconsChanged.subscribe(
        (icons: Icon[]) => {
          
          this.icons = icons;
          setTimeout(
            () => {
              this.loading = false;
              jQuery('table').DataTable({
                'pagingType': 'full_numbers',
                // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
                language: {
                  search: '_INPUT_',
                  searchPlaceholder: 'Search',
                  'paginate': {
                    'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                    'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                    'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                    'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                  }
                },
                "pageLength": 3,
                "retrieve": true,
                select: {
                  'style': 'multiple',
                },
              });

              const $this = this;

              $('table:not(.static)').on('select.dt', function ( e, dt, type, indexes ) {
                $this.selectedRow = $this.selectedRow + 1;
                const data = dt.rows('.selected' ).data();

                for (let i = 0; i < $this.selectedRow; i++) {
                  if ($this.selectedRowDataId.includes(data[i][0])) {
                    continue;
                  } else {
                    $this.selectedRowDataId.push(data[i][0]);
                  }
                }
              });

              $('table:not(.static)').on('deselect.dt', function ( e, dt, type, indexes ) {
                $this.selectedRow = $this.selectedRow - 1;
                const data = dt.rows(indexes).data();
                const groupID = data[0][0];

                $this.selectedRowDataId.forEach((value, index) => {
                    if (value === groupID) {
                      $this.selectedRowDataId.splice(index, 1);
                    }
                });
              });
      
              jQuery('input[type=search]').addClass('btn-search');
              jQuery('input[type=search]').attr('placeholder','Search');
              jQuery('select').addClass('select_datatable');
              jQuery('select').append('<option selected value="-1">Filter</option>');
            }, 1000
          );
        }
      )
  }

  onDelete(id: number) {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.iconService.httpDeleteIcon(id);
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this selected icon/s?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.iconService.httpDeleteIcon(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }

}
