import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class VendorReportService {
    onGetAll = new Subject();
    onUpload = new Subject();
    onUpdate = new Subject();
    onDelete = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    httpGetAll(params: any = {}) {
        let httpParams = new HttpParams();

        for (const p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vendorreports`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetAll.next(response);
            },
            (response: any) => {
                this.onGetAll.next(response.error);
            }
        );
    }

    httpUpload(data: any, file: File) {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        formData.append('file', file, file.name);

        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/vendorreports`, formData)
        .subscribe(
            (response: any) => {
                this.onUpload.next(response);
            },
            (response: any) => {
                this.onUpload.next(response.error);
            }
        );
    }

    httpUpdate(UID: string, data: any, file: File) {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        formData.append('file', file, file.name);

        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/vendorreports/${UID}`, formData)
        .subscribe(
            (response: any) => {
                this.onUpdate.next(response);
            },
            (response: any) => {
                this.onUpdate.next(response.error);
            }
        );
    }

    httpDelete(UID: string) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/vendorreports/${UID}`)
        .subscribe(
            (response: any) => {
                this.onDelete.next(response);
            },
            (response: any) => {
                this.onDelete.next(response.error);
            }
        );
    }
}
