import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { VideoService } from '../../../../services/video.service';
import { Subscription } from 'rxjs/Subscription';
import { Video } from '../../../../models/video.model';
import { AdminService } from '../../../admin.service';
import { Menu } from '../../../../models/menu.model';
import swal from 'sweetalert2';
import { Subject } from 'rxjs';

declare var jQuery: any;

@Component({
  selector: 'app-training-videos-dashboard',
  templateUrl: './training-videos-dashboard.component.html',
  styleUrls: ['./training-videos-dashboard.component.scss']
})
export class TrainingVideosDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  loading = true;
  menus: Menu[] = [];
  navigationType = 'link';
  navigationDefault = 'training-videos';
  subscription: Subscription;
  videos: Video[] = [];

  selectedRow = 0;
  selectedRowDataId = [];

  dataTableHtml: any;

  constructor(
    private videoService: VideoService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.menus = [
      new Menu('Training', '', [
        new Menu('Training Videos', 'help/training-videos'),
      ]),
      new Menu('Frequently asked questions', '', [
        new Menu('FAQ', 'help/faqs'),
        new Menu('FAQ Categories', 'help/faqscategory'),
      ]),
      new Menu('Changelogs', '', [
        new Menu('Changelogs', 'help/changelogs')
      ])
    ];

    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

    this.videoService.httpGetVideos();

    this.videoService.onGetVideos
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.videos = response.data;

              setTimeout(
                () => {
                  this.loading = false;
                  jQuery('table').DataTable({
                    'responsive': true,
                    'pagingType': 'full_numbers',
                    // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
                    language: {
                      search: '_INPUT_',
                      searchPlaceholder: 'Search',
                      'paginate': {
                        'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                        'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                        'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                        'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                      }
                    },
                    'pageLength': 50,
                    'retrieve': true,
                    'order': [],
                    select: {
                      'style': 'multiple',
                    },
                  });
                  jQuery('table').DataTable().column(0).visible(false);

                  const $this = this;

                  $('table').on('select.dt', function ( e, dt, type, indexes ) {
                    $this.selectedRow = $this.selectedRow + 1;
                    const data = dt.rows('.selected' ).data();

                    for (let i = 0; i < $this.selectedRow; i++) {
                      if ($this.selectedRowDataId.includes(data[i][0])) {
                        continue;
                      } else {
                        $this.selectedRowDataId.push(data[i][0]);
                      }
                    }
                  });

                  $('table').on('deselect.dt', function ( e, dt, type, indexes ) {
                    $this.selectedRow = $this.selectedRow - 1;
                    const data = dt.rows(indexes).data();
                    const groupID = data[0][0];

                    $this.selectedRowDataId.forEach((value, index) => {
                        if (value === groupID) {
                          $this.selectedRowDataId.splice(index, 1);
                        }
                    });
                  });

                  jQuery('input[type=search]').addClass('btn-search');
                  jQuery('input[type=search]').attr('placeholder', 'Search');
                  jQuery('select').addClass('select_datatable');
                  jQuery('select').append('<option selected value="-1">Filter</option>');
                }, 1000
              );
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.videoService.onDeleteVideo
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.videoService.httpGetVideos();
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );
  }

  onDelete(UID: string) {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.videoService.httpDeleteVideo(UID);

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this selected video/s?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.videoService.httpDeleteVideo(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }

  cutString(title: string) {
    return title.slice(0, 100);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
