<div class="panel panel-bordered panel-box-shadow">
  <div class="panel-heading">
    <h3 class="panel-title">Login Report</h3>
  </div>
  <div class="panel-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Client</th>            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userlog of userlogs">
            <td>{{userlog.created}}</td>
            <td>{{userlog.firstname}} {{userlogs.lastname}}</td>
            <td>{{userlog.client}}</td>            
          </tr>
                              
        </tbody>
      </table>
    </div>

    <div class="clearfix"></div>
    <!-- <button class="btn btn-outline btn-round btn-2">Export to CSV <i class="icon feather icon-chevron-right"></i></button> -->
  </div>
  </div>