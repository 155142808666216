<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Styles</h2>
    <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
      <i class="icon feather icon-trash"></i><span>Delete Selected</span>
    </button>
    <button routerLink="new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add Style</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="groupBox col-md-4">
    <form [formGroup]="selectGroupForm">
      <label for="sgf_group_id">Filter by Group</label>
      <select id="sgf_group_id" formControlName="group_id" #groupID class="form-control" (change)="onChangeGroup(groupID.value)">
        <option value="" disabled>Select group</option>
        <option value="0">All</option>
        <option *ngFor="let group of groups" [value]="group.ID">{{ group.title }}</option>
      </select>
    </form>
  </div>

  <div class="col-md-12">
    <div class="table-box">
      <table id="datatable-styles" class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Group ID</th>
            <th>Title</th>
            <th>Group</th>
            <th>Client</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let style of styles | paginate: { itemsPerPage: 50, currentPage: page }">
            <td>{{ style?.ID }}</td>
            <td>{{ style?.group?.ID }}</td>
            <td>{{ slugTitle( (style.category ? style.category.slug : false) + '-' + style?.group?.title + '-' + (style.media_attribute ? style.media_attribute.slug : false) + '-' + style?.department?.title) | uppercase }}</td>
            <td>{{ style?.group?.title }}</td>
            <td>{{ style?.client_id === 0 ? 'All' : style?.client?.name }}</td>
            <td style="text-align: right"> 
              <button routerLink="{{ style.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button routerLink="{{ style.ID }}/edit/duplicate" type="button" class="btn-table" title="Duplicate"><i class="icon feather icon-copy"></i></button>
              <button (click)="onDelete(style.ID, style?.group?.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>