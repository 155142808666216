import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class ThirdPartyService {
    onUnsplashSearch = new Subject();
    onUnsplashDownload = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    httpUnsplashSearch(params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/thirdparty/unsplash`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onUnsplashSearch.next(response);
            },
            (response: any) => {
                this.onUnsplashSearch.next(response.error);
            }
        );
    }

    httpUnsplashDownload(data: any) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/thirdparty/unsplash/download`, data)
        .subscribe(
            (response: any) => {
                this.onUnsplashDownload.next(response);
            },
            (response: any) => {
                this.onUnsplashDownload.next(response.error);
            }
        );
    }
}