<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Help Center - Changelogs</h2>
    <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
      <i class="icon feather icon-trash"></i><span>Delete Selected</span>
    </button>
    <button routerLink="new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add Changelog</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Provider</th>
            <th>Title</th>
            <th>Group Name</th>
            <th>Content</th>
            <th>Visibility</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let changelog of changelogs">
            <td class="hidden">{{ changelog.ID }}</td>
            <td>{{ changelog.provider | uppercase }}</td>
            <td>{{ changelog.title }}</td>
            <td>{{ changelog.group?.title }}</td>
            <td>{{ htmlToPlaintext(changelog.content) }}</td>
            <td>{{ visibilityToText(changelog.is_hidden) }}</td>
            <td style="text-align: right">
              <button type="button" routerLink="{{ changelog.ID }}/edit" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button type="button" (click)="onDelete(changelog.ID)" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div #previewModal id="previewElement" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #previewElementModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <iframe width="560" height="315" src="" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>