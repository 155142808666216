import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-pros-builder-merge-fields',
  templateUrl: './admin-pros-builder-merge-fields.component.html',
  styleUrls: ['./admin-pros-builder-merge-fields.component.scss']
})
export class AdminProsBuilderMergeFieldsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
