import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { APP_CONFIG } from './app.config';
import { ChargebeeService } from './services/chargebee.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  customerPlans = {
    user: {
      subscriptionId: '',
      addons: []
    },
    client: {
      subscriptionId: '',
      addons: []
    },
  };

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private authService: AuthService,
    private chargebeeService: ChargebeeService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {

  }

  ngOnInit() {
    if (this.authService.checkAuthToken()) {
      this.authService.getAuthCookie();

      this.chargebeeService.httpGetCustomerPlans();
      const checkSubscription = this.chargebeeService.onGetCustomerPlans
      .subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.customerPlans = response.data;
            let planAddons = [];

            if (this.customerPlans.client.addons.length) {
              localStorage.setItem('isClientPro', 'true');
              const customerAddons = this.customerPlans.client.addons.map(addon => {
                return this.chargebeeService.formatPlanName(addon.itemPriceId);
              });

              planAddons = [...customerAddons];
            } else {
              localStorage.removeItem('isClientPro');
            }

            if (this.customerPlans.user.addons.length) {
              localStorage.setItem('isUserPro', 'true');
              const userAddons = this.customerPlans.user.addons.map(addon => {
                return this.chargebeeService.formatPlanName(addon.itemPriceId);
              });

              planAddons = [...planAddons, ...userAddons];
            } else {
              localStorage.removeItem('isUserPro');
            }

            localStorage.setItem('proAddons', JSON.stringify(planAddons));
            checkSubscription.unsubscribe();
          }
        }
      );
    }

    setTimeout(() => {
      if ( this.route.snapshot.queryParamMap.get('RH') === '1' ) {
        this.loadCss(true);
      } else {
        this.loadCss();
      }
    }, 100);
  }

  loadCss(isRh = false) {
    const style = document.createElement('link');
    const custom = document.createElement('link');
    const grapes = document.createElement('link');
    const favicon = document.createElement('link');

    grapes.type = custom.type = style.type = 'text/css';
    grapes.rel = custom.rel = style.rel = 'stylesheet';

    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';

    if ( environment.app === 'MyDesign3' ) {
      this.titleService.setTitle('MyDesign v3');
      style.href = '/assets/templates/mydesign.css';
      custom.href = '/assets/templates/mydesign-custom.css';
      grapes.href = '/assets/templates/mydesign-grapes.css';
      favicon.href = '/assets/templates/mydesign.ico';
    } else {
      this.titleService.setTitle('Designly');
      favicon.href = '/assets/templates/designly.ico';

      if (this.appConfig.SKIN === 'designly' ||
      this.appConfig.SKIN === 'idashboard' || this.appConfig.SKIN === 'uk') {
        style.href = '/assets/templates/designly.css';
        custom.href = '/assets/templates/designly-custom.css';
        grapes.href = '/assets/templates/designly-grapes.css';
      }

      if (this.authService.auth !== undefined && this.authService.auth !== null) {
        if ( typeof this.authService.auth !== 'undefined'
          && ( typeof this.authService.auth.brandid !== 'undefined' &&
          this.authService.auth.brandid === '6' ) || isRh ) {
            style.href = '/assets/templates/rh.css';
            custom.href = '/assets/templates/rh-custom.css';
            grapes.href = '/assets/templates/rh-grapes.css';
        }
      }

      if (isRh) {
        style.href = '/assets/templates/rh.css';
        custom.href = '/assets/templates/rh-custom.css';
        grapes.href = '/assets/templates/rh-grapes.css';
        this.titleService.setTitle('Designplus');
      }

      if (this.appConfig.SKIN === 'signifi') {
        style.href = '/assets/templates/signifi.css';
        custom.href = '/assets/templates/signifi-custom.css';
        grapes.href = '/assets/templates/signifi-grapes.css';
        this.titleService.setTitle('Signifi Design');
      }

      if (this.appConfig.SKIN === 'raywhite') {
        style.href = '/assets/templates/raywhite.css';
        custom.href = '/assets/templates/raywhite-custom.css';
        grapes.href = '/assets/templates/raywhite-grapes.css';
        this.titleService.setTitle('Designly');
      }

      if (this.appConfig.SKIN === 'prd') {
        style.href = '/assets/templates/prd.css';
        custom.href = '/assets/templates/prd-custom.css';
        grapes.href = '/assets/templates/prd-grapes.css';
        this.titleService.setTitle('Designly');
      }

      if (this.appConfig.SKIN === 'professionals') {
        style.href = '/assets/templates/professionals.css';
        custom.href = '/assets/templates/professionals-custom.css';
        grapes.href = '/assets/templates/professionals-grapes.css';
        this.titleService.setTitle('Professionals Marketing');
      }
    }

    document.getElementsByTagName('head')[0].appendChild(favicon);
    document.getElementsByTagName('head')[0].appendChild(grapes);
    document.getElementsByTagName('head')[0].appendChild(custom);
    document.getElementsByTagName('head')[0].appendChild(style);

    // tslint:disable-next-line:max-line-length
    console.log(`%c${environment.app} Notice: This is a browser feature intended for developers. Do not paste anything here.`, `color: red; font-size: 30px`);
  }

}
