import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdashboardService {
  onGetLoginUrl = new Subject();
  onLogin = new Subject();
  onGetListing = new Subject();
  onSyncAgents = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  httpGetLoginUrl() {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/idashboard/loginurl`)
    .subscribe(
      (response: any) => {
        this.onGetLoginUrl.next(response);
      },
      (response: any) => {
        this.onGetLoginUrl.next(response.error);
      }
    );
  }

  httpLogin(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/idashboard/token`, data)
    .subscribe(
      (response: any) => {
        this.onLogin.next(response);
      },
      (response: any) => {
        this.onLogin.next(response.error);
      }
    );
  }

  httpGetListing(propertyId: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/idashboard/listing/${propertyId}`)
    .subscribe(
      (response: any) => {
        this.onGetListing.next(response);
      },
      (response: any) => {
        this.onGetListing.next(response.error);
      }
    );
  }

  httpSyncAgents() {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/idashboard/sync/agents`)
    .subscribe(
      (response: any) => {
        this.onSyncAgents.next(response);
      },
      (response: any) => {
        this.onSyncAgents.next(response.error);
      }
    );
  }
}
