import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { FrontService } from '../front.service';
import { WebsiteService } from '../../services/website.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Website } from '../../models/website.model';
import { AuthService } from '../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-front-websites',
  templateUrl: './front-websites.component.html',
  styleUrls: ['./front-websites.component.scss']
})
export class FrontWebsitesComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[];
  type: String;

  websites: Website[];
  websitesSubscription: Subscription;

  params: any;

  private auth;
  constructor(
    private authService: AuthService,
    private frontService: FrontService,
    private websiteService: WebsiteService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.menus = [];
    this.frontService.loadMenu(this.menus);
    this.auth = this.authService.auth;

    this.route.params.subscribe(
      (params: Params) => {
        this.type = params['type'];

        if ( this.type !== 'undefined' ) {
          this.websiteService.httpGetWebsites({
            type: this.type
          });
          this.loading = true;

          const website_logs = {
            'office': this.auth.office,
            'suburb': this.auth.client.suburb,
            'postcode': this.auth.client.postcode,
            'first_name': this.auth.user.firstname,
            'last_name': this.auth.user.lastname,
            'mobile': this.auth.user.mobile,
            'email': this.auth.user.email,
            'type': this.type
          };

          this.websiteService.httpPostLog(website_logs);
        }

      }
    );

    this.websitesSubscription = this.websiteService.websiteGetAll.subscribe(
      (websites: any) => {
        if ( typeof websites !== 'undefined' && websites.status === 'success' ) {
          this.loading = false;
          this.websites = websites.data;
        }
      }
    );

    $('.view-img').magnificPopup({ type: 'image'});
  }

  viewImage(url) {
    $.magnificPopup.open({
      items: {
        src: url
      },
      type: 'image'
    });

    let zoom = 100;
    let max_height: any;
    $('.mfp-img').css('cursor', 'zoom-in');

    $('.mfp-img').on('click', function() {
      if ( zoom === 100 ) {
        max_height = $('.mfp-img').css('max-height');
        $('.mfp-img').css({
          'width': '100%',
          'max-height': 'unset',
          'cursor': 'grab'
        });
        zoom = 120;
      } else {
        zoom = 100;
        $('.mfp-img').css({
          'width': 'unset',
          'max-height': max_height,
          'cursor': 'zoom-in'
        });
      }
    });
  }

  formatTitle(type) {
    if ( type === 'agency-website' ) {
      return 'Agency Website';
    }

    if ( type === 'agent-only-website' ) {
      return 'Agent Only Website';
    }

    if ( type === 'lead-generating-website' ) {
      return 'Lead Generation Website';
    }

    return '';
  }

  onEnquire() {
    const params = {
      'field78498961': this.auth.office,
      'field78498962': this.auth.client.suburb,
      'field78498963': this.auth.client.postcode,
      'field78498964-first': this.auth.user.firstname,
      'field78498964-last': this.auth.user.lastname,
      'field78498965': this.auth.user.mobile,
      'field78498966': this.auth.user.email,
      'field78498967': this.formatTitle(this.type)
    };

    window.open('https://websites.formstack.com/forms/mydesign_website_lead?' + $.param(params), '_blank');
  }

  ngOnDestroy() {
    this.websitesSubscription.unsubscribe();
  }

}
