import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icons'
})
export class IconsPipe implements PipeTransform {

  transform(value: any, term: any): any {
    if(term === undefined){
      return value.sort(function compare(a,b) {
        return a.sort_order - b.sort_order;
      });
    }

  }

}
