import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Inject, Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { catchError, tap } from 'rxjs/operators';

import swal from 'sweetalert2';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private authService: AuthService,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ( String(req.url).includes('partica.online') ) {
            const partica_token = atob(localStorage.getItem('PARTICA_TOKEN'));
            const headers = req.headers.set('Authorization', 'Bearer ' + partica_token);

            const newReq = req.clone({
                headers: headers
            });

            return next.handle(newReq).takeUntil(this.authService.cancelHttp);
        } else if (String(req.url).includes('/auth/builder')) {
            return next.handle(req);
        } else if (String(req.url).includes('/environments/designly-')) {
            return next.handle(req);
        } else if (String(req.url).includes(this.appConfig.NEW_API_ENDPOINT)) {
            const newApiToken = atob(localStorage.getItem('md3Auth_newApiToken'));

            const headers = req.headers.set('Authorization', 'Bearer ' + newApiToken);

            const newReq = req.clone({
                headers: headers
            });
            return next.handle(newReq).pipe(
                tap( (event: any) => {
                    if ( event instanceof HttpResponse) {
                        return event;
                    }
                })
            );
        } else {
            const _token = this.authService.getToken();
            const _md_token = this.authService.getMDToken();

            const md_authorization = req.headers.get('MD-Authorization');

            let headers = req.headers.set('Authorization', 'Bearer ' + _token);

            if (!md_authorization && _md_token) {
                headers = headers.set('MD-Authorization', _md_token);
            }

            headers = headers.set('requestPath', String(String(this.router.url).includes('admin')));

            if (typeof _token !== 'undefined' && _token !== null) {
                const newReq = req.clone({
                    headers: headers
                });

                return next.handle(newReq).pipe(
                    tap( (event: any) => {
                        if ( event instanceof HttpResponse) {
                            return event;
                        }
                    })
                );
            }
        }

        return next.handle(req);
    }
}
