import { Pipe, PipeTransform } from '@angular/core';

declare var swal: any;
declare var jQuery: any;

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, term: string, field: string = ''): any {
    if (term === undefined) {
      return value;
    }

    let result = value;

    if (value.length === 0) {
      jQuery('#noresult').removeClass('hidden');
    }

    if (field === 'template') {
      if (term !== 'all') {
        if (term === 'enewsletter') {
          result = result.filter(function (val) {
            if (+val.category_id === 1) {
              jQuery('#noresult').addClass('hidden');
              return val.title;
            }
          });
        } else if (term === 'buyer-match') {
          result = result.filter(function (val) {
            if (+val.category_id === 2) {
              jQuery('#noresult').addClass('hidden');
              return val.title;
            }
          });
        } else if (term === 'quick-enquiry') {
          result = result.filter(function (val) {
            if (+val.category_id === 3) {
              jQuery('#noresult').addClass('hidden');
              return val.title;
            }
          });
        } else if (term === 'brochure') {
          result = result.filter(function (val) {
            if (+val.category_id === 4) {
              jQuery('#noresult').addClass('hidden');
              return val.title;
            }
          });
        } else if (term === 'property-guide') {
          result = result.filter(function (val) {
            if (+val.category_id === 5) {
              jQuery('#noresult').addClass('hidden');
              return val.title;
            }
          });
        } else if (term === 'social-media') {
          result = result.filter(function (val) {
            if (+val.category_id === 6) {
              jQuery('#noresult').addClass('hidden');
              return val.title;
            }
          });
        }
      }
    }

    if (field === 'agent') {
      if (term !== 'all') {
        result = result.filter(function (val) {
          if (val.user_agent_id === +term) {
            jQuery('#noresult').addClass('hidden');
            return val.title;
          }
        });
      }
    }

    if (field === 'date') {
      if (term !== 'all') {
        if (term === 'recent') {
          result = result.sort(function (a, b) {
            jQuery('#noresult').addClass('hidden');
            const date_a: any = new Date(a.created);
            const date_b: any = new Date(b.created);
            return date_b - date_a;
          });
        } else if (term === 'date-asc') {
          result = result.sort(function compare(a, b) {
            jQuery('#noresult').addClass('hidden');
            const date_a: any = new Date(a.created);
            const date_b: any = new Date(b.created);
            return date_a - date_b;
          });
        } else if (term === 'title-asc') {
          result = result.sort(function compare(a, b) {
            jQuery('#noresult').addClass('hidden');
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        } else if (term === 'title-desc') {
          result = result.sort(function compare(a, b) {
            jQuery('#noresult').addClass('hidden');
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
        }
      }
    }

    return result;
  }
}
