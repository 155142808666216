import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.checkAuthCookie()) {

      this.authService.getAuthCookie();

      if (this.authService.isUk()) {
        if (this.authService.auth.client.office_id !== '919') {
          this.router.navigate(['/templates']);
        }
      } else {
        if ( this.authService.auth.groupid !== '23428' && this.authService.getApp() === 'MyDesign3' ) {
          this.router.navigate(['/templates']);
        }

        if (this.authService.getApp() === 'Designly') {
          if (this.authService.auth.provider === 'vaultre' && this.authService.auth.client.office_id !== '608') {
            this.router.navigate(['/templates']);
          }

          if (this.authService.auth.provider === 'domain' && this.authService.auth.client.office_id !== '4') {
            this.router.navigate(['/templates']);
          }

          if (this.authService.auth.provider === 'idashboard' && this.authService.auth.client.office_id !== '1798') {
            this.router.navigate(['/templates']);
          }
        }
      }

      return true;
    }

    this.router.navigate(['/login']);

    return false;
  }
}
