import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Menu } from '../../models/menu.model';
import { StyleService } from '../../services/style.service';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit {
  menus: Menu[] = [];

    constructor(private adminService: AdminService,
                private styleService: StyleService
    ) { }

  ngOnInit() {
    this.adminService.loadMenu(this.menus);
  }

}
