import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ClientService } from '../../../services/client.service';
import { Params, ActivatedRoute,  Router } from '@angular/router';
import { Menu } from '../../../models/menu.model';
import { Client } from '../../../models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { StorageService } from '../../../services/storage.service';
import { APP_CONFIG } from '../../../app.config';

@Component({
  selector: 'app-client-gallery',
  templateUrl: './client-gallery.component.html',
  styleUrls: ['./client-gallery.component.scss']
})
export class ClientGalleryComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];
  sid: number;
  id: number;
  gid: number;
  type: string;
  client: Client;
  subscription: Subscription;
  subscriptionStorage: Subscription;
  subscriptionOperation: Subscription;

  files = [];
  tempImages = [];

  navigationType = 'link';
  navigationDefault = 'owned';

  title = 'My Library';

  path = '';
  base_path = '';
  folder = '';

  @ViewChild('itemFile', { static: true }) itemFile: ElementRef;
  @ViewChild('itemFolder', { static: true }) itemFolder: ElementRef;
  @ViewChild('uploadItemModalClose', { static: true }) uploadItemModalClose: ElementRef;
  @ViewChild('newFolderModalClose', { static: true }) newFolderModalClose: ElementRef;

  constructor(@Inject(APP_CONFIG) private appConfig, private adminService: AdminService,
    private clientService: ClientService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router) {}

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.type = params['type'];
        this.gid = +params['gid'];

        this.files = [];

        if (this.id) {
          this.sid = (this.gid) ? this.gid : this.id;

          this.clientService.httpGetClient(this.id);
          this.storageService.httpGetStorages(this.type, this.sid);
        }

        this.title = (this.type === 'owned') ? 'My Library' : 'Shared Library';

        this.path = ((this.type === 'owned') ? 'clients/' : 'groups/') + this.id;

        if (this.type === 'admin') {
          this.title = 'Admin Library';
          this.path = 'admin/' + this.id;
        }

        this.base_path = this.path;
      }
    );

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

    this.subscription = this.clientService.clientChanged.subscribe(
      (client: Client) => {
        this.client = client;

        if ( typeof this.client.ID !== 'undefined') {
          this.menus = [
            new Menu('My Library', '', [
              new Menu('Owned', 'clients/' + this.id + '/gallery/owned')
            ]),
            new Menu('Shared Library', '', [
              new Menu(this.client.group.title, 'clients/' + this.id + '/gallery/shared/' + this.client.group.ID),
            ]),
            new Menu('Admin Library', '', [
              new Menu('Hidden', 'clients/' + this.id + '/gallery/admin' ),
            ])
          ];

          this.adminService.loadMenu(this.menus);
        }
      }
    );

    this.subscriptionStorage = this.storageService.storagesChanged.subscribe(
      (storages: any[]) => {
        if (typeof storages !== 'undefined') {
          this.loading = false;

          this.files = storages;
        }
      }
    );

    this.subscriptionOperation = this.storageService.storageOperation.subscribe(
      (storages: any[]) => {
        this.loading = true;
        this.files = [];

        this.storageService.httpGetStorages(this.type, this.sid, { folder: this.folder });
      }
    );
  }

  showLink(item: any) {
    if (item.type === 'file') {
      alert(this.appConfig.S3_URL + item.path);
    }
  }
  getLink(item: any) {
    if (item.type === 'file') {
      return this.appConfig.S3_URL + item.path;
    }
  }

  openFolder(item: any) {
    if (item.type === 'dir') {
      this.storageService.httpGetStorages(this.type, this.sid, { folder: item.filename });
      this.path = item.path;
      this.folder = item.filename;
    }
  }

  openRoot() {
    this.path = this.base_path;
    this.folder = '';
    this.storageService.httpGetStorages(this.type, this.sid, this.path);
  }

  onUpload() {
    this.loading = true;

    const fileSingle = this.itemFile.nativeElement;

    let values = [];

    values['file'];

    for(let i=0; i < fileSingle.files.length; i++){
      this.tempImages.push(fileSingle.files[i]);
    }

    values['file'] = this.tempImages;
    values['type'] = 'file';
    values['path'] = this.path;
    
    this.storageService.httpPostStorage(values);

    setTimeout(
      () => {
        this.itemFile.nativeElement.value = '';
        this.uploadItemModalClose.nativeElement.click();
      }, 500
    );
  }

  onSubmit() {
    let values = [];
    this.loading = true;

    if (this.itemFolder.nativeElement.value) {
      values['name'] = this.itemFolder.nativeElement.value;
      values['type'] = 'dir';
      values['path'] = this.path;

      this.storageService.httpPostStorage(values);

      setTimeout(
        () => {
          this.itemFolder.nativeElement.value = '';
          this.newFolderModalClose.nativeElement.click();
        }, 500
      );
    }
  }

  onDelete(item: any) {
    const r = confirm('Are you sure you want to delete ' + item.basename);

    if (r == true) {
      let values = [];

      if (item.path) {
        this.loading = true;
        values['type'] = item.type;
        values['path'] = item.path;

        this.storageService.httpDeleteStorage(values);
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionStorage.unsubscribe();
  }

}
