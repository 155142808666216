import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoService } from '../../../../services/video.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Video } from '../../../../models/video.model';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { GroupService } from '../../../../services/group.service';
import { Group } from '../../../../models/group.model';
import { Menu } from '../../../../models/menu.model';
import { AdminService } from '../../../admin.service';


@Component({
  selector: 'app-training-videos-edit',
  templateUrl: './training-videos-edit.component.html',
  styleUrls: ['./training-videos-edit.component.scss']
})
export class TrainingVideosEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;
  backUrl = '../';

  menus: Menu[] = [];
  navigationType = 'link';
  navigationDefault = 'training-videos';

  video: Video;
  videoForm: FormGroup;
  groups: Group[];

  constructor(
    private adminService: AdminService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private router: Router,
    private groupService: GroupService
  ) { }

  ngOnInit() {
    this.menus = [
      new Menu('Training', '', [
        new Menu('Training Videos', 'help/training-videos'),
      ]),
      new Menu('Frequently asked questions', '', [
        new Menu('FAQ', 'help/faqs'),
        new Menu('FAQ Categories', 'help/faqscategory'),
      ]),
      new Menu('Changelogs', '', [
        new Menu('Changelogs', 'help/changelogs')
      ])
    ];
    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

    this.loading = true;
    this.groupService.httpGetGroupsNew();

    const videoId = this.route.snapshot.paramMap.get('id');

    if (videoId) {
      this.editMode = true;
      this.backUrl = '../../';
    }

    this.groupService.onGetGroups
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.groups = response.data;

              if (this.editMode) {
                this.loading = true;
                this.videoService.httpGetVideo(videoId);
              }
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.videoService.onGetVideo
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.video = response.data;

              this.initFormEdit();
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.videoService.onUpdateVideo
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              swal('Training Video updated', '', 'success');
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.videoService.onPostVideo
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.router.navigate([this.backUrl], {relativeTo: this.route});
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.videoForm = new FormGroup({
      'group_id': new FormControl([], Validators.required),
      'exclude_group_id': new FormControl([]),
      'title': new FormControl('', Validators.required),
      'description': new FormControl(''),
      'provider': new FormControl('', Validators.required),
      'sort': new FormControl(0, Validators.required)
    });
  }

  private initFormEdit() {
    this.videoForm.patchValue({
      title: this.video.title,
      description: this.video.description,
      provider: this.video.provider,
      sort: this.video.sort
    });

    if (this.video.group_whitelist === 'all') {
      this.videoForm.patchValue({
        group_id: ['all']
      });
    } else {
      const groupUids = this.video.groups.map((group: any) => group.group.UID);

      this.videoForm.patchValue({
        group_id: groupUids
      });
    }

    if (this.video.excluded_groups) {
      const groupUids = this.video.excluded_groups.map((group: any) => group.group.UID);

      this.videoForm.patchValue({
        exclude_group_id: groupUids
      });
    }
  }

  onSubmit() {
    const videoValues = this.videoForm.value;

    if (this.videoForm.valid) {
      if (this.editMode) {
        this.videoService.httpPutVideo(this.video.UID, videoValues);
      } else {
        this.videoService.httpPostVideo(videoValues);
      }
    }
    this.loading = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
