<div class="child-content-wrapper folder-management clearfix">
  <div class="col-md-12">
    <div class="heading">
      <h1 class="page-title pull-left">Folders</h1>
      <button class="btn btn-primary pull-right" type="button" data-bs-toggle="modal" data-bs-target="#newFolder" (click)="createFolder()"><i aria-hidden="true" class="icon feather icon-plus"></i> Create Folder</button>
    </div>
  </div>

  <div class="folder-list clearfix d-flex flex-wrap">
    <div class="col-md-4">
      <a routerLink="/templates">
        <div class="card mb-4">
          <div class="card-body container-fluid"><span class="icon feather icon-corner-up-left"></span> <span class="title">All Templates</span></div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a routerLink="/templates/folders/favourite">
        <div class="card mb-4">
          <div class="card-body container-fluid"><span class="icon feather icon-heart"></span> <span class="title">Favourite Templates</span></div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a routerLink="/templates/folders/group">
        <div class="card mb-4">
          <div class="card-body container-fluid"><span class="icon feather icon-share-2"></span> <span class="title">Group Shared Templates</span></div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a routerLink="/templates/folders/client">
        <div class="card mb-4">
          <div class="card-body container-fluid"><span class="icon feather icon-share-2"></span> <span class="title">Office Shared Templates</span></div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a routerLink="/templates/folders/quickdesign">
        <div class="card mb-4">
          <div class="card-body container-fluid"><span class="icon feather icon-bookmark"></span> <span class="title">Templates</span></div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a routerLink="/templates/folders/archived">
        <div class="card mb-4">
          <div class="card-body container-fluid"><span class="icon feather icon-share"></span> <span class="title">Archived Templates</span></div>
        </div>
      </a>
    </div>
    <div *ngFor="let folder of folders" class="col-md-4 custom-folder">
      <div class="card mb-4">
        <a routerLink="/templates/folders/{{ folder?.UID }}" class="link"></a>
        <div class="card-body container-fluid">
          <span class="icon feather icon-folder"></span> <span class="title">{{ folder?.name }}</span>
          <div class="btn-group folder-action">
            <button type="button" class="btn btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i class="icon feather icon-more-horizontal"></i></button>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" routerLink="/templates/folders/{{ folder?.UID }}">View</a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="editFolder(folder)" data-bs-toggle="modal" data-bs-target="#newFolder">Rename</a>
              <a *ngIf="!folder?.is_shared" class="dropdown-item" href="javascript:void(0)" (click)="shareFolder(folder)">Share</a>
              <a *ngIf="folder?.is_shared" class="dropdown-item" href="javascript:void(0)" (click)="shareFolder(folder, 0)">Unshare</a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="deleteFolder(folder?.UID)">Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="newFolder" class="modal fade edit-box" role="dialog" aria-labelledby="newFolderLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="newFolderLabel">Create Folder</h4>
      </div>
      <form [formGroup]="folderForm" (ngSubmit)="onCreateFolder()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="newFolderName" class="col-sm-2">Name</label>
            <div class="col-sm-8">
              <input formControlName="name" type="text" class="form-control" id="newFolderName" autocomplete="off" autofocus />
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!folderForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
