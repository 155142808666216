import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrontService } from '../../front.service';
import { Auth } from '../../../models/auth.model';
import { Subject } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { Router } from '@angular/router';
import { ChargebeeService } from '../../../services/chargebee.service';
import { ToastrService } from 'ngx-toastr';

declare const Chargebee: any;
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  authUser: Auth;

  cbInstance: any;
  selectedPage: string;

  constructor(
    public frontService: FrontService,
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService
  ) {
    if (!document.head.querySelector('#chargebee-script')) {
      const chargebeeScript = document.createElement('script');
      chargebeeScript.id = 'chargebee-script';
      chargebeeScript.src = 'https://js.chargebee.com/v2/chargebee.js';
      document.head.append(chargebeeScript);
    }
  }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;

    setTimeout(() => {
      window['Chargebee'].init({
        site: this.frontService.appConfig.CHARGEBEE_SITE_ID,
      });

      this.cbInstance = Chargebee.getInstance();
    }, 1000);

    this.chargebeeService.onCreateSession
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.cbInstance.setPortalSession(response.data);

            let firstOpen = true;
            this.cbInstance.createChargebeePortal().open(
              {
                visit: (section: string) => {
                  if (section === 'home') {
                    if (!firstOpen) {
                      this.cbInstance.closeAll();
                    }
                  }
                  firstOpen = false;
                }
              },
              {
                sectionType: Chargebee.getPortalSections()[this.selectedPage]
              }
            );
          } else {
            this.toastrService.error('You need to have a valid subscription to access this page.');
          }
        }
      }
    );
  }

  openPortal(customerType: string, page: string): void {
    this.loading = true;
    this.selectedPage = page;

    this.chargebeeService.httpCreateSession(customerType);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
