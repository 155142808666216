import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupService } from '../../../services/popup.service';
import { AdminService } from '../../admin.service';
import swal from 'sweetalert2';

declare var jQuery: any;

@Component({
  selector: 'app-admin-popups-dashboard',
  templateUrl: './admin-popups-dashboard.component.html',
  styleUrls: ['./admin-popups-dashboard.component.scss']
})
export class AdminPopupsDashboardComponent implements OnInit, OnDestroy {
  $destroy: Subject<boolean> = new Subject();
  loading = false;
  page = 1;

  popups = [];

  selectedRow = 0;
  selectedRowDataId = [];

  constructor(
    public adminService: AdminService,
    private popupService: PopupService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.popupService.httpGetAll();

    this.popupService.onGetAll
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            this.loading = false;
            this.popups = response.data;

            this.initDT();
          }
        }
      }
    );

    this.popupService.onDelete
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            this.popupService.httpGetAll();
            swal('Popup deleted successfully', '', 'success');

            this.initDT();
          }
        }
      }
    );
  }

  initDT() {
    setTimeout(
      () => {
        if ( ! jQuery.fn.DataTable.isDataTable( '#tbl_popup' ) ) {

          jQuery('#tbl_popup').DataTable({
            'pagingType': 'full_numbers',
            // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
            language: {
              search: '_INPUT_',
              searchPlaceholder: 'Search',
              'paginate': {
                'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
              }
            },
            'pageLength': 50,
            select: {
              'style': 'multiple',
            },
          });

          jQuery('#tbl_popup').DataTable().column(0).visible(false);

          const $this = this;

          $('#tbl_popup').on('select.dt', function ( e, dt, type, indexes ) {
            $this.selectedRow = $this.selectedRow + 1;
            const data = dt.rows('.selected' ).data();

            for (let i = 0; i < $this.selectedRow; i++) {
              if ($this.selectedRowDataId.includes(data[i][0])) {
                continue;
              } else {
                $this.selectedRowDataId.push(data[i][0]);
              }
            }
          });

          $('#tbl_popup').on('deselect.dt', function ( e, dt, type, indexes ) {
            $this.selectedRow = $this.selectedRow - 1;
            const data = dt.rows(indexes).data();
            const groupID = data[0][0];

            $this.selectedRowDataId.forEach((value, index) => {
                if (value === groupID) {
                  $this.selectedRowDataId.splice(index, 1);
                }
            });
          });

          jQuery('input[type=search]').addClass('btn-search');
          jQuery('input[type=search]').attr('placeholder','Search');
          jQuery('#box select').addClass('select_datatable');
          jQuery('select').append('<option selected value="-1">Filter</option>');
        }
      }, 1000
    );
  }

  onDelete(id: number) {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.popupService.httpDelete(id);
        $this.loading = true;
      }
    }).catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this data selected?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.popupService.httpDelete(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
