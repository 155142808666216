import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Input } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { Auth } from '../../../models/auth.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Support } from '../../../models/support.model';
import { SupportService } from '../../../services/support.service';
import { Subscription } from 'rxjs';

import * as html2canvas from 'html2canvas';
import { StorageService } from '../../../services/storage.service';

import { environment } from '../../../../environments/environment';

declare var swal: any;
declare var $: any;
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  loading = false;

  subscription: Subscription;
  subscriptionResponse: Subscription;
  supports: Support[] = [];

  menus: Menu[] = [];
  authUser: Auth;

  supportForm: FormGroup;
  support: Support;
  screenUploaded = false;

  builder_screenshots = [];


  @ViewChild('supportFormElement', { static: false }) supportFormElement: ElementRef;
  @ViewChild('fileAttached', { static: false }) fileAttached: ElementRef;
  constructor(
    private frontService: FrontService,
    private supportService: SupportService,
    private storageService: StorageService
  ) { }

  @Input() page: string;
  @Input() template: string;
  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    if ( this.page !== 'builder' ) {
      this.frontService.loadMenu(this.menus);
    }

    this.subscriptionResponse = this.supportService.supportOperation.subscribe(
      (support: Support) => {
        if ( typeof support.ID !== 'undefined' ) {
          this.loading = false;
          swal(
            'Success!',
            'Ticket support has been sent',
            'success'
          );

          this.builder_screenshots = [];
          this.supportForm.reset();

          if ( this.page === 'builder' ) {
            $('#supportModal').modal('hide');
          }
        }
      }
    );

    this.storageService.storageMigrateToS3Support
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.builder_screenshots.push(response.data.new_url);
          this.supportService.onProcessed.next(true);
        }
      }
    );

    this.supportService.onCapture
    .subscribe(
      (response: boolean) => {
        if ( response ) {
          this.captureScreen();
        }
      }
    );

    this.supportForm = new FormGroup({
      'firstname': new FormControl(this.authUser.user.firstname, Validators.required),
      'lastname': new FormControl(this.authUser.user.lastname, Validators.required),
      'email': new FormControl(this.authUser.user.email, Validators.required),
      'phone': new FormControl(''),
      'office': new FormControl(this.authUser.client.name, Validators.required),
      'issues1': new FormControl(''),
      'issues2': new FormControl(''),
      'issues3': new FormControl(''),
      'issues4': new FormControl(''),
      'issues5': new FormControl(''),
      'issues6': new FormControl(''),
      'information': new FormControl(''),
      'fileAttached': new FormControl('')
    });

  }

  onSubmit() {
    const supportValues = this.supportForm.value;

    const issues = [];

    if (supportValues['phone'] === null) {
      supportValues['phone'] = 'none';
    }

    if (supportValues.issues1) {
      issues.push('Login issue');
    }
    if (supportValues.issues2) {
      issues.push('Register account');
    }
    if (supportValues.issues3) {
      issues.push('Listings missing');
    }
    if (supportValues.issues4) {
      issues.push('General set-up issue');
    }
    if (supportValues.issues5) {
      issues.push('PDF or JPG Issue');
    }
    if (supportValues.issues6) {
      issues.push('I have a feature request');
    }


    if (issues.length > 0) {
      supportValues['issues'] = issues.join(', ');
    } else {
      supportValues['issues'] = '';
    }

    const screenshots = [];
    const thumbnail = this.fileAttached.nativeElement;
    if (thumbnail.files.length > 0) {
      for ( const thumb of thumbnail.files ) {
        screenshots.push(thumb);
      }
    }

    supportValues['screenshot'] = this.builder_screenshots.toString();
    const templateUrl = `${environment.htmlEndpoint}/templates/${this.storageService.template_id}/build`;
    supportValues['template_url'] = this.storageService.template_id === 0 ? 'Support Form' : templateUrl;

    const provider = String(this.authUser.provider).toUpperCase();
    supportValues['support_title'] = `${this.frontService.authService.getApp()}(${provider}) Support Request`;

    this.supportService.httpPostSupport(supportValues, screenshots);
  }

  captureScreen() {
    html2canvas(document.querySelector('body') as HTMLElement, {
      logging: false,
      proxy: ( environment.production ? `${environment.apiEndpoint}/settings/image` : '' )
    }) .then(canvas => {
      const migrateData = [];
      migrateData['support'] = 'yes';
      migrateData['file'] = canvas.toDataURL('image/jpeg');

      this.storageService.httpPostMigrateToS3(migrateData);
      $('#supportModal').modal('show');
    });
  }

  takeScreenshot() {
    this.supportService.onScreenshot.next(true);
  }
}