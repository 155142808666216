import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFilter'
})
export class StringFilterPipe implements PipeTransform {

  transform(value: any, term: any, page: any): any {
    if(term === undefined) return value;

    if ( page === 'clients' ) {
      return value.filter(function(val) {
        return val.company_name.toLowerCase().includes(term.toLowerCase());
      });
    }

    if ( page === 'groups' ) {
      return value.filter(function(val) {
        return val.title.toLowerCase().includes(term.toLowerCase());
      });
    }

    if ( page === 'styles' ) {
      return value.filter(function(val) {
        return val.category.toLowerCase().includes(term.toLowerCase());
      });
    }

  }

}
