import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  onGetEwayClientKey = new Subject();
  onSubscribe = new Subject();
  onGetWhmcsDetails = new Subject();
  onCreateClientProfile = new Subject();
  onGetInvoices = new Subject();
  onGetClientInvoices = new Subject();
  onGetHealth = new Subject();
  onUpdateSubscrition = new Subject();
  onCancelSubscription = new Subject();
  onCheckAccess = new Subject();
  onCheckPromoCode = new Subject();
  onUpdateCreditCard = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetEwayClientKey() {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/subscriptions/ewayclientkey`)
    .subscribe(
      (response: any) => {
        this.onGetEwayClientKey.next(response);
      },
      (response: any) => {
        this.onGetEwayClientKey.next(response.error);
      }
    );
  }

  httpGetWhmcsDetails(params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/subscriptions/whmcs/details`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetWhmcsDetails.next(response);
      },
      (response: any) => {
        this.onGetWhmcsDetails.next(response.error);
      }
    );
  }

  httpCreateClientProfile(data) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/subscriptions/whmcs/details`, data)
    .subscribe(
      (response: any) => {
        this.onCreateClientProfile.next(response);
      },
      (response: any) => {
        this.onCreateClientProfile.next(response.error);
      }
    );
  }

  httpSubscribe(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/subscriptions/subscribe`, data)
    .subscribe(
      (response: any) => {
        this.onSubscribe.next(response);
      },
      (response: any) => {
        this.onSubscribe.next(response.error);
      }
    );
  }

  httpGetInvoices() {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/subscriptions/whmcs/invoice/user`)
    .subscribe(
      (response: any) => {
        this.onGetInvoices.next(response);
      },
      (response: any) => {
        this.onGetInvoices.next(response.error);
      }
    );
  }

  httpGetClientInvoices() {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/subscriptions/whmcs/invoice/client`)
    .subscribe(
      (response: any) => {
        this.onGetClientInvoices.next(response);
      },
      (response: any) => {
        this.onGetClientInvoices.next(response.error);
      }
    );
  }

  httpGetHealth() {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/subscriptions/whmcs/health`)
    .subscribe(
      (response: any) => {
        this.onGetHealth.next(response);
      },
      (response: any) => {
        this.onGetHealth.next(response.error);
      }
    );
  }

  httpUpdateSubscription(data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/subscriptions/subscribe`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateSubscrition.next(response);
      },
      (response: any) => {
        this.onUpdateSubscrition.next(response.error);
      }
    );
  }

  httpCancelSubscription(data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/subscriptions/subscribe/cancel`, data)
    .subscribe(
      (response: any) => {
        this.onCancelSubscription.next(response);
      },
      (response: any) => {
        this.onCancelSubscription.next(response.error);
      }
    );
  }

  httpCheckAccess(params: any = {}) {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/subscriptions/checkaccess`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onCheckAccess.next(response);
      },
      (response: any) => {
        this.onCheckAccess.next(response.error);
      }
    );
  }

  httpCheckPromoCode(promoCode: string) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/subscriptions/promocode`, {
      promoCode
    })
    .subscribe(
      (response: any) => {
        this.onCheckPromoCode.next(response);
      },
      (response: any) => {
        this.onCheckPromoCode.next(response.error);
      }
    );
  }

  httpUpdateCreditCard(data: string) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/subscriptions/whmcs/cc`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateCreditCard.next(response);
      },
      (response: any) => {
        this.onUpdateCreditCard.next(response.error);
      }
    );
  }
}
