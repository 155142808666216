<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Training Video': 'Training Video' }}</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="videoForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-6">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Description</label>
            <div class="col-sm-6">
              <textarea formControlName="description" class="form-control" id="textarea" autocomplete="off"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-6">
              <ng-select formControlName="provider" [clearable]="false" [searchable]="false">
                <ng-option value="" disabled>Select a Provider</ng-option>
                <ng-option value="all">All</ng-option>
                <ng-option value="mydesktop">MyDesktop</ng-option>
                <ng-option value="vaultre">VaultRE</ng-option>
                <ng-option value="domain">Domain</ng-option>
                <ng-option value="idashboard">iDashboard</ng-option>
                <ng-option value="designly">Designly</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Group</label>
            <div class="col-sm-6">
              <ng-select formControlName="group_id" [clearable]="false" [searchable]="false" [multiple]="true">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option value="all">All</ng-option>
                <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Exclude Groups</label>
            <div class="col-sm-6">
              <ng-select formControlName="exclude_group_id" [clearable]="false" [searchable]="false" [multiple]="true">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="sort" class="col-sm-2">Sort</label>
            <div class="col-sm-6">
              <input formControlName="sort" type="number" class="form-control" id="sort" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10"> 
              <button [disabled]="!videoForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>