import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProgressService } from './progress.service';
import { Subject } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';

declare const ProgressBar: any;

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss']
})
export class ProgressLoaderComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();

  type = 'Properties';

  isActive = false;
  @ViewChild('progressContainer', { static: true }) progressContainer: ElementRef;
  constructor(
    private progressService: ProgressService,
    private globalService: GlobalService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    let fromColor = '#0F84CD';
    let toColor = '#0F84CD';

    if (this.authService.auth !== undefined && this.authService.auth !== null) {
      if ( typeof this.authService.auth !== 'undefined'
        && ( typeof this.authService.auth.brandid !== 'undefined' &&
        this.authService.auth.brandid === '6' )) {
          fromColor = '#FFB914';
          toColor = '#FFB914';
      }
    }

    if (this.globalService.appConfig.SKIN === 'signifi') {
      fromColor = '#3EC2CF';
      toColor = '#3EC2CF';
    }

    if (this.globalService.appConfig.SKIN === 'raywhite') {
      fromColor = '#FFE512';
      toColor = '#FFE512';
    }

    if (this.globalService.appConfig.SKIN === 'prd') {
      fromColor = '#FF0000';
      toColor = '#FF0000';
    }

    const bar = new ProgressBar.Circle(this.progressContainer.nativeElement, {
      color: '#000',
      strokeWidth: 8,
      trailWidth: 1,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        autoStyleContainer: false
      },
      from: { color: fromColor, width: 8 },
      to: { color: toColor, width: 8 },
      step: function(state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);

        const value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(`${value}%`);
        }
      }
    });

    bar.text.style.fontSize = '2rem';
    bar.text.style.color = '#000';
    bar.text.style.top = '42%';

    this.progressService.onShow
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response && typeof response.type !== 'undefined') {
          bar.animate(0);
        
          if (response.type === 'campaign') {
            this.type = 'Templates';
          }

          if (response.type === 'pricefinder') {
            this.type = 'Pricefinder';
          }

          if (response.type === 'masters') {
            this.type = 'Master Templates'
          }

          this.isActive = true;
        }
      }
    );

    this.progressService.onHide
    .takeUntil(this.destroy$)
    .subscribe(
      (response: boolean) => {
        if (response) {
          this.isActive = false;

          bar.animate(0);
        }
      }
    );

    this.progressService.onProgress
    .takeUntil(this.destroy$)
    .subscribe(
      (response: number) => {
        if (response) {
          bar.animate(response);
        }
      }
    );
  }

}
