<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Subgroups</h2>
    <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
      <i class="icon feather icon-trash"></i><span>Delete Selected</span>
    </button>
    <button routerLink="new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add Subgroup</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <table class="table table-hover" id="subgroup_table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Subgroup Title</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subgroup of subgroups | paginate: { itemsPerPage: 50, currentPage: page } ">
            <td>{{ subgroup.ID }}</td>
            <td>{{ subgroup.title }}</td>
            <td style="text-align: right">
              <button routerLink="{{ subgroup.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button (click)="onDelete(subgroup.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>