import { Component, OnInit} from '@angular/core';
import { FrontService } from '../front.service';
import { Menu } from '../../models/menu.model';
import { Auth } from '../../models/auth.model';
import { ClientService } from '../../services/client.service';
import { Client } from '../../models/client.model';

@Component({
  selector: 'app-brand-assets',
  templateUrl: './brand-assets.component.html',
  styleUrls: ['./brand-assets.component.scss']
})
export class BrandAssetsComponent implements OnInit {
  authUser: Auth;
  menus: Menu[] = [];
  client: Client;

  constructor(
    private frontService: FrontService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;

    this.client = this.authUser.client;

    let defaultMenus = [];
    if (this.client.is_account_master === 1) {
      defaultMenus = [
        new Menu('Logos', '/brand-assets/logos'),
        new Menu('Colours', '/brand-assets/colours'),
        // new Menu('Fonts', '/brand-assets/fonts'),
        new Menu('Icons', '/brand-assets/icons'),
        new Menu('Elements', '/brand-assets/elements'),
        new Menu('Images', '/brand-assets/images'),
        new Menu('Files', '/brand-assets/files'),
        new Menu('Website Links', '/brand-assets/website-links'),
        new Menu('Social Links', '/brand-assets/social-links'),
        new Menu('Disclaimers & Legal', '/brand-assets/disclaimers'),
      ];
    } else {
      defaultMenus = [
        new Menu('Social Links', '/brand-assets/social-links'),
        new Menu('Disclaimers & Legal', '/brand-assets/disclaimers')
      ];

      if (this.client.group.setting_menu_link === 1) {
        defaultMenus = [ ...[
          new Menu('Website Links', '/brand-assets/website-links')
        ], ...defaultMenus];
      }

      if (this.client.group.setting_menu_icon === 1) {
        defaultMenus = [ ...[
          new Menu('Icons', '/brand-assets/icons')
        ], ...defaultMenus];
      }

      if (this.client.group.setting_menu_font === 1) {
        defaultMenus = [ ...[
          new Menu('Fonts', '/brand-assets/fonts'),
        ], ...defaultMenus];
      }

      if (this.client.group.setting_menu_color === 1) {
        defaultMenus = [ ...[
          new Menu('Color Palettes', '/brand-assets/colours'),
        ], ...defaultMenus];
      }

      if (this.client.group.setting_menu_logo === 1) {
        defaultMenus = [ ...[
          new Menu('Logos', '/brand-assets/logos')
        ], ...defaultMenus];
      }

    }

    this.menus = [
      new Menu('Brand Assets', '', defaultMenus)
    ];

    this.frontService.loadMenu(this.menus);
  }

}
