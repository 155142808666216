import { Injectable, Inject } from '@angular/core';
import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

export class BlacklistService {
  blacklistGetAll = new Subject();
  blacklistGet = new Subject();
  blacklistPost = new Subject();
  blacklistPut = new Subject();
  blacklistDelete = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  httpGetBlacklists(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/blacklists',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.blacklistGetAll.next(response);
      },
      (response: any) => {
        this.blacklistGetAll.next(response.error);
      }
    );
  }

  httpGetBlacklist(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/blacklists/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.blacklistGet.next(response);
      },
      (response: any) => {
        this.blacklistGet.next(response.error);
      }
    );
  }

  httpPostBlacklist(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/blacklists',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.blacklistPost.next(response);
      },
      (response: any) => {
        this.blacklistPost.next(response.error);
      }
    );
  }

  httpPutBlacklist(id: number, data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/blacklists/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.blacklistPut.next(response);
      },
      (response: any) => {
        this.blacklistPut.next(response.error);
      }
    );
  }

  httpDeleteBlacklist(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/blacklists/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.blacklistDelete.next(response);
      },
      (response: any) => {
        this.blacklistDelete.next(response.error);
      }
    );
  }

}
