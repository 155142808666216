import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Menu } from '../../../models/menu.model';
import { Subscription } from 'rxjs/Subscription';
import { Template } from '../../../models/template.model';
import { TemplateService } from '../../../services/template.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientService } from '../../../services/client.service';
import { Client } from '../../../models/client.model';
import { GroupService } from '../../../services/group.service';
import { Group } from '../../../models/group.model';
import { SettingService } from '../../../services/setting.service';

declare var jQuery: any;

@Component({
  selector: 'app-analytic-template-usage',
  templateUrl: './analytic-template-usage.component.html',
  styleUrls: ['./analytic-template-usage.component.scss']
})
export class AnalyticTemplateUsageComponent implements OnInit, OnDestroy, AfterViewInit {
  menus: Menu[] = [];

  clientsSubscription: Subscription;
  groupsSubscription: Subscription;

  exportSubscription: Subscription;

  templates: Template[] = [];
  loading = false;
  clients: Client[];
  groups: Group[];

  exportForm: FormGroup;

  selection = 'all';

  constructor(private adminService: AdminService,
    private clientService: ClientService,
    private groupService: GroupService,
    private settingService: SettingService) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.loadMenu(this.menus);

    this.clientService.httpGetClients({}, 'list');
    this.groupService.httpGetGroups();

    this.clientsSubscription = this.clientService.clientsChanged.subscribe(
      (clients: any) => {
        if ( typeof clients !== 'undefined' && clients.length > 0 ) {
          this.loading = false;
          this.clients = clients;
        }
      }
    );

    this.groupsSubscription = this.groupService.groupsChanged.subscribe(
      (groups: any) => {
        if ( typeof groups !== 'undefined' && groups.length > 0 ) {
          this.groups = groups;
        }
      }
    );

    this.exportSubscription = this.settingService.settingExport.subscribe(
      (exportdata: any) => {
        if ( typeof exportdata !== 'undefined' && exportdata.status === 'success' ) {
          this.loading = false;
          const win = window.open(exportdata.data.link);
        }
      }
    );

    this.initExportForm();
  }

  initExportForm() {
    this.exportForm = new FormGroup({
      'selection': new FormControl('all', Validators.required),
      'select_group': new FormControl(''),
      'select_client': new FormControl(''),
      'type': new FormControl('template', Validators.required),
      'date_from': new FormControl(''),
      'date_to': new FormControl('')
    });

  }

  exportClick() {
    jQuery('table').tableToCSV();
  }

  onSelectionChange(event) {
    const selection = event.target.value;
    if ( selection === 'all' ) {
      this.selection = 'all';
      this.exportForm.get('select_client').clearValidators();
      this.exportForm.get('select_group').clearValidators();
    }

    if ( selection === 'group' ) {
      this.selection = 'group';
      this.exportForm.get('select_client').clearValidators();
      this.exportForm.get('select_group').setValidators(Validators.required);
    }

    if ( selection === 'client' ) {
      this.selection = 'client';
      this.exportForm.get('select_group').clearValidators();
      this.exportForm.get('select_client').setValidators(Validators.required);
    }

    this.exportForm.get('select_client').updateValueAndValidity();
    this.exportForm.get('select_group').updateValueAndValidity();
  }

  ngAfterViewInit() {
    jQuery('#date_from, #date_to').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd'
    });
  }

  onSubmit() {
    const exportValues = this.exportForm.value;

    exportValues['date_from'] = jQuery('#date_from').datepicker('getDate', true);
    exportValues['date_to'] = jQuery('#date_to').datepicker('getDate', true);

    this.settingService.httpExport(exportValues);
    this.loading = true;
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.clientsSubscription.unsubscribe();
    this.exportSubscription.unsubscribe();
    this.groupsSubscription.unsubscribe();
  }

}
