import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

import { Subscription } from 'rxjs/Subscription';

import { MyDesktopService } from '../../services/mydesktop.service';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit, OnDestroy {
  subscriptionClient: Subscription;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private myDesktopService: MyDesktopService) { }

  ngOnInit() {
    const qParams = this.route.snapshot.queryParams;

    this.subscriptionClient = this.myDesktopService.myDesktopAuthExist.subscribe(
      (clients: any[]) => {
        if (typeof clients !== 'undefined') {
          if (clients.length > 0) {
            if (!this.authService.checkAuthCookie() ) {
              setTimeout( () => {
                this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
              }, 1000);

              return;
            } else {
              this.authService.getAuthCookie();

              if (this.authService.auth.agentid !== qParams['ID'] || this.authService.auth.groupid !== qParams['officeid']) {
                this.authService.deleteAuthCookie();
                setTimeout( () => {
                  this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
                }, 1000);

                return;
              }
            }

            setTimeout( () => {
              this.router.navigate(['/templates']);
            }, 500);
          } else {
            window.location.replace('https://websites.formstack.com/forms/rwmd3reg');
          }
        }
    });

    if (typeof qParams['brandid'] !== 'undefined' && +qParams['brandid'] === 12) {
      const rwOfficeId = qParams['officeid'];

      this.myDesktopService.httpGetAuthExist({ 'office_id': rwOfficeId });
    } else {
      if (!this.authService.checkAuthCookie() ) {
        setTimeout( () => {
          this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
        }, 1000);

        return;
      } else {
        this.authService.getAuthCookie();

        if (this.authService.auth.agentid !== qParams['ID'] || this.authService.auth.groupid !== qParams['officeid']) {
          this.authService.deleteAuthCookie();
          setTimeout( () => {
            this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
          }, 1000);

          return;
        }
      }

      setTimeout( () => {
        this.router.navigate(['/templates']);
      }, 500);
    }

  }

  ngOnDestroy() {
    this.subscriptionClient.unsubscribe();
  }

}
