import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientService } from '../../../../services/client.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Client } from '../../../../models/client.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from '../../../../services/account.service';

import swal from 'sweetalert2';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {
  loading = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userForm: FormGroup;
  userId: number;
  clientId: number;
  editMode = false;

  account: any;
  client: Client;

  constructor(
    private clientService: ClientService,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.clientId = +params['id'];
        this.userId = +params['uid'];

        this.clientService.httpGetClient(this.clientId);
      }
    );

    this.clientService.clientChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (client: any) => {
        if (typeof client !== 'undefined' && typeof client.ID !== 'undefined') {
          if (client.provider !== 'designly') {
            this.router.navigateByUrl('/admin/clients');
            return false;
          }

          if (this.userId) {
            this.editMode = true;
            this.accountService.httpGetAccount(this.userId);
            this.loading = true;
          } else {
            this.loading = false;
          }

          this.client = client;
        }
      }
    );

    this.accountService.accountPost
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          swal('Success', 'Account Created Successfully', 'success');

          setTimeout(() => {
            this.router.navigateByUrl(`/admin/clients/${this.client.ID}/users`);
          }, 1500);
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error', response.message, 'error');
          this.loading = false;
        }
      }
    );

    this.accountService.accountGet
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.account = response.data;
          this.userForm.patchValue({
            'email': this.account.email,
            'password': '',
            'is_active': this.account.is_active,
            'firstname': this.account.user.firstname,
            'lastname': this.account.user.lastname,
            'mobile': this.account.user.mobile,
            'telephone': this.account.user.telephone,
            'fax': this.account.user.fax
          });

          this.userForm.get('password').clearValidators();
          this.userForm.get('password').updateValueAndValidity();
          this.loading = false;
        }
      }
    );

    this.accountService.accountPut
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          swal('Success', 'Account Updated Successfully', 'success');

          setTimeout(() => {
            this.router.navigateByUrl(`/admin/clients/${this.client.ID}/users`);
          }, 1500);
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error', response.message, 'error');
          this.loading = false;
        }
      }
    );

    this.userForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', Validators.required),
      'is_active': new FormControl('1'),
      'firstname': new FormControl('', Validators.required),
      'lastname': new FormControl('', Validators.required),
      'mobile': new FormControl('', Validators.required),
      'telephone': new FormControl(''),
      'fax': new FormControl('')
    });
  }

  onSubmit() {
    const userValues = this.userForm.value;

    if (this.userForm.valid) {
      if (this.editMode) {
        this.accountService.httpPutAccount(this.userId, userValues);
      } else {
        userValues['client_id'] = this.client.ID;
        this.accountService.httpPostAccount(userValues);
      }
      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
