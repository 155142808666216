<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Style': 'Style' }}</h2>

    <button [routerLink]="backUrl" type="button" class="btn-admin">
        <i class="icon feather icon-arrow-left"></i><span>Back</span>
      </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="styleForm" (ngSubmit)="onSubmit()" class="form-horizontal">
            <div class="form-group">
                <label for="category_id" class="col-sm-2">Category</label>
                <div class="col-sm-5">
                  <select formControlName="category_id" class="form-control" id="category_id" >
                    <option value="" disabled>Select a Category</option>
                    <option *ngFor="let mValue of categories" value="{{ mValue.id }}">{{ mValue.name }}</option>
                  </select>
                </div>
              </div>
          <div class="form-group">
            <label for="group_id" class="col-sm-2">Group</label>
            <div class="col-sm-5">
              <select formControlName="group_id" class="form-control" id="group_id" >
                <option value="" disabled>Select a Group</option>
                <option *ngFor="let mValue of groups" value="{{ mValue.ID }}">{{ mValue.title }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="client_id" class="col-sm-2">Client</label>
            <div class="col-sm-5">
              <select2 formControlName="client_id" [value]="s2ClientValue" required ngDefaultControl [data]="s2ClientData" [options]="s2ClientOptions" (valueChanged)="patchForm('client_id', $event.value)"  id="client_id"></select2>
            </div>
          </div>
          <div class="form-group">
            <label for="media_attribute_id" class="col-sm-2">Media Attribute</label>
            <div class="col-sm-5">
              <select formControlName="media_attribute_id" class="form-control" id="media_attribute_id" >
                <option value="" disabled>Select a Media Attribute</option>
                <option *ngFor="let mValue of mediaAttributes" value="{{ mValue.ID }}">{{ mValue.slug | uppercase }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="department_id" class="col-sm-2">Department</label>
            <div class="col-sm-5">
              <select formControlName="department_id" class="form-control" id="department_id" >
                <option value="" disabled>Select a Department</option>
                <option *ngFor="let mValue of departments" value="{{ mValue.ID }}">{{ mValue.title }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="style" class="col-sm-2">Inline CSS</label>
            <div class="col-sm-8">
              <textarea formControlName="style" rows="10" class="form-control" id="style"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="style" class="col-sm-2">External CSS URL</label>
            <div class="col-sm-8">
              <textarea formControlName="external_css" rows="4" class="form-control" id="external_css"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="style" class="col-sm-2">Inline JS</label>
            <div class="col-sm-8">
              <textarea formControlName="script" rows="10" class="form-control" id="script"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="style" class="col-sm-2">External JS URL</label>
            <div class="col-sm-8">
              <textarea formControlName="external_js" rows="4" class="form-control" id="external_js"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="style" class="col-sm-2">External Font URL</label>
            <div class="col-sm-8">
              <textarea formControlName="external_font" rows="4" class="form-control" id="external_font"></textarea>
            </div>
          </div>
          <div *ngIf="showCkeditorFont" class="form-group">
            <label class="col-sm-2">CKEDITOR Font Name</label>
            <div class="col-sm-8">
              <textarea formControlName="ckeditor_font_name" rows="4" class="form-control" id="ckeditor_font_name"></textarea>
            </div>
          </div>
          <div *ngIf="showCkeditorFont" class="form-group">
            <label class="col-sm-2">CKEDITOR Font Family</label>
            <div class="col-sm-8">
              <textarea formControlName="ckeditor_font_family" rows="4" class="form-control" id="ckeditor_font_family"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!styleForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>