import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../../models/user.model';

@Pipe({
  name: 'webbookAgent'
})
export class WebBookAgentPipe implements PipeTransform {

  transform(users: User[], term: string): any {
    if (term === undefined) {
        return users;
    }

    return users.filter(user => {
        const agentSearch = `${user.firstname} ${user.lastname} ${user.agent_id}`;

        return agentSearch.toLowerCase().includes(term.toLowerCase());
    });
  }

}
