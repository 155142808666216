import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ProgressService {
    onShow: Subject<boolean> = new Subject();
    onHide: Subject<boolean> = new Subject();
    onProgress: Subject<number> = new Subject();

    initProgress(data: any) {
        this.onShow.next(data);
    }

    hideProgress() {
        this.onHide.next(true);
    }

    progress(value) {
        this.onProgress.next(value);
    }
}
