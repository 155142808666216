<!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
<div class="height-100">
  <div class="tui-editor-backdrop">
    <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
    <p>This might take awhile. Saving Image...</p>
  </div>
  <div id="builder-html" #builderHtml></div>
</div>
<div #mdPropSearchModal id="mdPropSearch" class="modal fade edit-box preview app-media" role="dialog">
  <div class="modal-dialog modal-center modal-fullscreen" role="document">
    <div class="modal-content">
      <div class="modal-title">
          <button type="button" #mdPropSearchModalClose (click)="closeListingSearchModal()" class="btn property-close-modal"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="icon feather icon-x"></i> Close
        </button>
      </div>
      <div class="modal-body">
        <app-property-search [template]="template"></app-property-search>
      </div>
    </div>
  </div>
</div>

<div #mdLibrarySearchModal id="mdLibrarySearch" class="modal fade edit-box preview app-media app-media-new" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-dialog-custom" role="document">
    <div class="modal-content modal-content-custom">
      <div class="modal-body modal-body-custom">
        <button type="button" #mdLibrarySearchModalClose class="close close-outside close-outside-library" data-bs-dismiss="modal" aria-label="Close" (click)="closeLibraryModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <ng-container>
          <app-image-library-revamp libraryType="image"></app-image-library-revamp>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div #mdParticaSearchModal id="mdParticaSearch" class="modal fade edit-box preview app-media" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-dialog-custom" role="document">
    <div class="modal-content modal-content-custom">
      <div class="modal-body modal-body-custom">
        <button type="button" #mdParticaSearchModalClose class="close close-outside close-outside-library" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <ng-container *ngIf="showPartica">
          <app-partica page="builder"></app-partica>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div #mdPricefinderSearchModal id="mdPricefinderSearch" class="modal fade edit-box preview app-media" role="dialog">
  <div class="modal-dialog modal-center modal-fullscreen" role="document">
    <div class="modal-content">
      <form class="form-horizontal">
        <div class="modal-body">
          <div class="title-bar">
            <button type="button" class="pricefinder-back">
              <i class="icon feather icon-chevron-left" aria-hidden="true" data-bs-dismiss="modal" aria-label="Close"></i>
              Back
            </button>
            <p class="title">Suburb Search</p>
            <button type="button" #mdPricefinderSearchModalClose class="pricefinder-close-modal" data-bs-dismiss="modal" aria-label="Close"><i class="icon feather icon-x" aria-hidden="true"></i> Close</button>
          </div>
          <app-pricefinder></app-pricefinder>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="site-action">
  <button data-bs-toggle="modal" data-bs-target="#supportModal" type="button" class="site-action-toggle btn-raised btn btn-success btn-floating">
    <i style="font-size: 35px !important" class="icon feather icon-help-circle animation-scale-up" aria-hidden="true"></i>
  </button>
</div>
<!-- Modal -->
<div id="supportModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-xl">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close close-outside" data-bs-dismiss="modal">&times;</button>
        <app-support page="builder"></app-support>
      </div>
    </div>
  </div>
</div>

<div class="help-box help-box-control hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="/help/training-videos" target="_blank">
        <p><i class="fa fa-video-camera"></i> Watch Training Videos</p>
      </a>
    </li>
    <li>
      <a href="/help/faqs" target="_blank">
        <p><i class="fa fa-question"></i> Read our FAQ's</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="openSupportModal()">
        <p><i class="fa fa-comment"></i> Submit a support request</p>
      </a>
    </li>
  </ul>
</div>

<div class="help-box view-as-control hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="onView('code')">
        <p><i class="icon feather icon-code"></i> View HTML</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="onView('browser')">
        <p><i class="icon feather icon-globe"></i> View in Browser</p>
      </a>
    </li>
  </ul>
</div>

<ng-container *ngIf="frontService.authService.getApp() === 'Designly' || frontService.authService.getApp() === 'MyDesign3'">
  <div class="save-options hidden">
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
      <!-- <li *ngIf="template?.category_id !== 2 && template?.category_id !== 11 && template?.category_id !== 10"><a href="javascript:void(0)" (click)="saveQuickDesign()">Save as Quick Design</a></li> -->
      <li
        *ngIf="frontService.appConfig.SKIN !== 'signifi' && authUser?.brandid !== '6' && authUser?.brandid !== '4' && template?.category_id !== 2 && template?.category_id !== 11 && template?.category_id !== 10 && template?.category_id !== 14">
        <a href="javascript:void(0)" (click)="selectCampaign()">Save to Campaign</a></li>
      <li *ngIf="!template?.quickdesign && (!isVaultMail || template?.category_id !== 11) && authUser.user.is_group_admin && authUser.is_master"><a href="javascript:void(0)" (click)="saveToCustomCategory()">Save as Template</a></li>
      <li *ngIf="!template?.quickdesign && (!isVaultMail || template?.category_id !== 11) && !authUser.is_master"><a href="javascript:void(0)" (click)="saveToCustomCategory()">Save as Template</a></li>
      <li *ngIf="template?.quickdesign && (!isVaultMail || template?.category_id !== 11) && authUser.user.is_group_admin && authUser.is_master"><a href="javascript:void(0)" (click)="removeFromCustomCategory()">Remove as
          Template</a></li>
      <li *ngIf="template?.quickdesign && (!isVaultMail || template?.category_id !== 11) && !authUser.is_master"><a href="javascript:void(0)" (click)="removeFromCustomCategory()">Remove as
            Template</a></li>
      <ng-container *ngIf="authUser?.is_master && template?.category_id !== 14">
        <li *ngIf="!template?.is_master_template && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)" (click)="saveMasterTemplate('add')">Save
            as Master Template</a></li>
        <li *ngIf="template?.is_master_template && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)"
            (click)="saveMasterTemplate('remove')">Remove as Master Template</a></li>
      </ng-container>
      <li *ngIf="template?.category_id === 2 || template?.category_id === 11">
        <a href="javascript:void(0)" (click)="sendTestEmail()">
          <p>Send Test Email</p>
        </a>
      </li>
    </ul>
  </div>
</ng-container>

<div class="help-box highres-pdf hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="downloadPdf(true)">
        <p>For Print</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="downloadPdf(false)">
        <p>For Email</p>
      </a>
    </li>
  </ul>
</div>

<div class="help-box save-as-image hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="imageSaveAs('jpg')">
        <p>Save as JPG</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="imageSaveAs('png')">
        <p>Save as PNG</p>
      </a>
    </li>
  </ul>
</div>

<div class="emarketing-options hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="sendTestEmail()">
        <p>Send Test Email</p>
      </a>
    </li>
  </ul>
</div>

<div *ngIf="showScreenshot" class="take-screenshot" data-html2canvas-ignore="true">
  <h3>You're taking a screenshot for your support request</h3>
  <button (click)="onSupportCapture()" class="btn-admin btn-admin-primary">Capture</button>
  <button (click)="onSupportCancel()" class="btn-admin btn-admin-danger">Cancel</button>
</div>

<div id="selectCampaign" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Select Campaign</h4>
        <button type="button" class="close close-outside" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <ng-select [items]="s2CampaignData" [clearable]="false" bindLabel="text" bindValue="id" placeholder="Select files" [multiple]="true" [(ngModel)]="s2CampaignValue" [ngModelOptions]="{standalone: true}">
        </ng-select>
        <button (click)="onCampaignSave()" type="button" class="btn-admin btn-admin-primary mt-3">Save</button>
      </div>
    </div>
  </div>
</div>

<div id="sendTestEmail" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form [formGroup]="testEmailForm" (ngSubmit)="onSubmitTestEmail()">
      <div class="modal-header">
        <h4 class="modal-title mt-0">Send Test Email</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid ">
          <div class="row">
            <div class="col-lg-12">
                <p>Please note that sending a test email from Designly will not populate VaultRE/EA specific merge fields, for example $$greeting$$.</p>
                <label for="testEmail" class="form-label">Email</label>
                <input formControlName="email" required type="text" class="form-control" id="testEmail" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button [disabled]="!testEmailForm.valid" class="btn-admin btn-admin-primary">Send</button>
      </div>
      </form>
    </div>
  </div>
</div>


<div id="mdAgentsSearch" class="modal fade edit-box" role="dialog" aria-labelledby="mdAgentsSearchLabel">
  <div class="modal-dialog modal-center">
      <div class="modal-content p-0">
          <div class="modal-header border-bottom mb-2">
              <h4 class="modal-title m-0" id="mdAgentsSearchLabel">Insert agent/s</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="form-horizontal">
              <div class="modal-body">
                  <div class="row mb-4">
                      <div class="col-sm-12">
                          <input type="text" class="form-control" autocomplete="off" autofocus
                              placeholder="Search agents name or ID" [(ngModel)]="agentSearchFilter">
                      </div>
                  </div>
                  <div class="row mb-3">
                      <div class="col-sm-12">
                          <div class="agentListScroll">
                              <ul>
                                  <li *ngFor="let clientAgent of clientAgents | webbookAgent: agentSearchFilter">
                                      <div class="d-flex align-items-center">
                                          <div class="flex-shrink-0">
                                              <img [src]="clientAgent?.imageurl" class="agent-image rounded-circle">
                                          </div>
                                          <div class="flex-grow-1 ms-3">
                                              <h6>{{clientAgent?.firstname}} {{clientAgent?.lastname}}</h6>
                                              <p class="m-0">{{clientAgent?.agent_id}}</p>
                                          </div>
                                          <button *ngIf="checkAgentIfSelected(clientAgent?.ID); else elseNotSelected"
                                              class="btn btn-primary btn-author-actions"
                                              (click)="unselectAgent(clientAgent?.ID)">Unselect</button>

                                          <ng-template #elseNotSelected>
                                              <button type="button" class="btn btn-default btn-author-actions"
                                                  (click)="selectAgent(clientAgent)">Select</button>
                                          </ng-template>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer d-flex justify-content-between">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn-admin btn-admin-primary" (click)="insertAgents()">Insert</button>
              </div>
          </div>
      </div>
  </div>
</div>

<div #saveToCategory id="saveToCategory" class="modal fade edit-box" role="dialog" aria-labelledby="saveToCategoryLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #saveToCategoryModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="saveToCategoryLabel">Save as Template</h4>
      </div>
      <form [formGroup]="customCategoryForm" (ngSubmit)="onSaveToCustomCategory()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="saveToCategoryName" class="col-sm-4">Category<i class="text-danger">*</i></label>
            <div class="col-sm-8">
              <select #selectCustomCategory formControlName="reference" class="form-control" (change)="onSelectedCustomCategories(selectCustomCategory.value)">
                <option *ngIf="showReferenceOption('emails')" value="emails">Emails</option>
                <option *ngIf="showReferenceOption('printables')" value="printables">{{ quickDesignService.quickdesignCategoryLabels.printables }}</option>
                <option *ngIf="showReferenceOption('socialmedia')" value="socialmedia">Social Media</option>
                <option *ngIf="showReferenceOption('signboards')" value="signboards">Signboards</option>
                <option *ngIf="showReferenceOption('pricefinder')" value="pricefinder">Pricefinder</option>
                <option *ngFor="let customCategory of customCategories" [value]="customCategory.ID">
                  {{ customCategory?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex" [class.hidden]="quickDesignGroups?.length === 0">
            <label for="saveToCategoryName" class="col-sm-4">Folder</label>
            <div class="col-sm-8">
              <select formControlName="quick_design_group_id" class="form-control">
                <option value="">Select Folder</option>
                <option *ngFor="let quickDesignGroup of quickDesignGroups" value="{{ quickDesignGroup?.ID }}">
                  {{ quickDesignGroup?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="saveToCategoryIcon" class="col-sm-4">Icon</label>
            <div class="col-sm-8">
              <input #customCategoryIcon type="file" accept="image/*" class="form-control" id="saveToCategoryIcon" autocomplete="off" autofocus />
              <p>Maximum file size: 500KB</p>
            </div>
          </div>
          <div class="form-group">
            <label for="saveToCategoryDescription" class="col-sm-4">Description</label>
            <div class="col-sm-8">
              <textarea name="" id="saveToCategoryDescription" rows="30" class="form-control" formControlName="description"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!customCategoryForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="global-setting">
  <button class="btn"><i class="fa fa-cog"></i></button>
</div> -->
<ngx-loading class="main-loader" [show]="loading"></ngx-loading>
<app-progress-loader></app-progress-loader>