import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.scss']
})
export class WebsitesComponent implements OnInit {
  menus: Menu[];
  navigationType = 'link';
  navigationDefault = 'agency-website';

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.menus = [
      new Menu('Types', '', [
        new Menu('Agency Website', 'websites/agency-website/dashboard'),
        new Menu('Agent Only Website', 'websites/agent-only-website/dashboard'),
        new Menu('Lead Generating Website', 'websites/lead-generating-website/dashboard')
      ]),
      new Menu('Logs', '', [
        new Menu('Agency Website', 'websites/agency-website/logs'),
        new Menu('Agent Only Website', 'websites/agent-only-website/logs'),
        new Menu('Lead Generating Website', 'websites/lead-generating-website/logs')
      ])
    ];

    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });
  }

}
