<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add FAQ': 'FAQ' }}</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="faqForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-6">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-6">
              <select formControlName="provider" class="form-control" id="provider">
                <option value="" disabled>Select a Provider</option>
                <option value="all">All Providers</option>
                <option value="mydesktop">MyDesktop</option>
                <option value="vaultre">VaultRE</option>
                <option value="idashboard">iDashboard</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Group</label>
            <div class="col-sm-6">
              <ng-select formControlName="group_id" [clearable]="false" [searchable]="false" [multiple]="true">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option value="all">All</ng-option>
                <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Exclude Groups</label>
            <div class="col-sm-6">
              <ng-select formControlName="exclude_group_id" [clearable]="false" [searchable]="false" [multiple]="true">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="category" class="col-sm-2">Category</label>
            <div class="col-sm-6">
              <select formControlName="faqs_category_id" class="form-control" id="category" autocomplete="off">
                <option value="" disabled>Select a Category</option>
                <option *ngFor="let faqsCategory of faqsCategories" [value]="faqsCategory?.UID">{{faqsCategory?.title}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Description</label>
            <div class="col-sm-6">
              <textarea data-enable-grammarly="false" formControlName="description" class="form-control" id="textarea"
                autocomplete="off"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!faqForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>