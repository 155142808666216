import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Menu } from '../../../models/menu.model';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Client } from '../../../models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { GroupService } from '../../../services/group.service';
import { Group } from '../../../models/group.model';
import { SubgroupService } from '../../../services/subgroup.service';
import { Subgroup } from '../../../models/subgroup.model';
import { Auth } from '../../../models/auth.model';

declare var jQuery: any;

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[];
  id: number;
  clientForm: FormGroup;
  client: Client;
  subscription: Subscription;
  subscriptionGroup: Subscription;
  subscriptionResponse: Subscription;

  color1 = '';
  color2 = '';
  color4 = '';
  color5 = '';
  color6 = '';
  color7 = '';
  color9 = '';
  color10 = '';

  subgroupsSubscription: Subscription;
  client_company_name = '';

  colorForm: FormGroup;

  groups: Group[];
  subgroups: Subgroup[];

  fragment;
  editMode = false;
  navigationType = 'fragment';
  navigationDefault = 'account-details';
  backUrl = '/admin/clients';

  client_account_active = 0;
  client_template_active = 0;

  @ViewChild('boxContent', { static: true }) boxContent: ElementRef;

  authUser: Auth;

  constructor(private adminService: AdminService,
              private clientService: ClientService,
              private groupService: GroupService,
              private route: ActivatedRoute,
              private router: Router,
              private subgroupService: SubgroupService
            ) {}

  ngOnInit() {
    this.authUser = this.adminService.authService.auth;

    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;

        if (this.editMode) {
          this.loading = true;
          this.menus = [
            new Menu('Account', '', [
              new Menu('Account Details', 'clients/' + this.id + '/edit/account-details'),
              new Menu('Colors', 'clients/' + this.id + '/edit/colors'),
              new Menu('Logo',  'clients/' + this.id + '/edit/logo'),
              new Menu('Hi-Res Logo', 'clients/' + this.id + '/edit/hires_logo')
            ]),
            new Menu('Group', '', [
              new Menu('Group Details', 'clients/' + this.id + '/edit/group-details')
            ]),
            new Menu('Designly Pro', '', [
              new Menu('Subscription', 'clients/' + this.id + '/edit/subscription-details')
            ])
          ];
      
          this.clientService.httpGetClient(this.id);
        } else {
          this.menus = [
            new Menu('Account', '', [
              new Menu('Account Details', 'clients/' + this.id + '/edit/account-details')
            ])
          ];
        }


        const frag = params.section;
        const boxContentChild = this.boxContent.nativeElement.children;

        for ( const boxChild of boxContentChild ) {
          if (boxChild.id === frag || (!frag && boxChild.id === this.navigationDefault)) {
            boxChild.className = 'edit-box';
          } else {
            boxChild.className = 'edit-box hidden';
          }
        }
      }
    );

    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': this.route,
      default: this.navigationDefault,
      type: this.navigationType
    });

    // this.route.fragment.subscribe(
    //   (frag: string) => {
    //     const boxContentChild = this.boxContent.nativeElement.children;

    //     for ( const boxChild of boxContentChild ) {
    //       if (boxChild.id === frag || (!frag && boxChild.id === this.navigationDefault)) {
    //         boxChild.className = 'edit-box';
    //       } else {
    //         boxChild.className = 'edit-box hidden';
    //       }
    //     }
    //   }
    // );

    this.groupService.httpGetGroups();
    this.subgroupService.httpGetSubgroups();

    this.subscription = this.clientService.clientChanged.subscribe(
      (client: Client) => {
        this.client = client;

        if ( this.editMode && typeof client !== 'undefined' ) {
          this.loading = false;

          this.client_account_active = client.is_account_active;
          this.client_template_active = client.is_template_active;

          this.initFormEdit();
          this.initColorFormEdit();
          this.initColorPickerEdit();
        }
      }
    );

    this.subscriptionGroup = this.groupService.groupsChanged.subscribe(
      (groups: Group[]) => {
        this.groups = groups;
      }
    );

    this.subgroupsSubscription = this.subgroupService.subgroupGetAll.subscribe(
      (subgroups: any) => {
        if ( typeof subgroups !== 'undefined' && subgroups.status === 'success' ) {
          this.subgroups = subgroups.data;
        }
      }
    );

    this.subscriptionResponse = this.clientService.clientOperation.subscribe(
      (client: Client) => {
        if ( typeof client.ID !== 'undefined'  ) {
          this.loading = false;

          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.initForm();
    this.initColorForm();
    // this.initColorPicker();
    this.initColorPickerEdit();

    this.imageReader();
  }

  imageReader() {
    function readURL(input) {

      if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const target: any = e.target;
            const content: string = target.result;

            const image_placement = jQuery(input).attr('data-action');
            jQuery(image_placement).attr('src', content);
          };
          reader.readAsDataURL(input.files[0]);
      }
    }

    jQuery('#image-primary').change(function(){
      readURL(this);
    });

    jQuery('#image-secondary').change(function(){
      readURL(this);
    });

    jQuery('#image-hires-primary').change(function(){
      readURL(this);
    });

    jQuery('#image-hires-secondary').change(function(){
      readURL(this);
    });
  }

  // initColorPicker() {
  //   jQuery('.asColorpicker1').asColorPicker();
  //   jQuery('.asColorpicker2').asColorPicker();
  //   jQuery('.asColorpicker4').asColorPicker();
  //   jQuery('.asColorpicker6').asColorPicker();
  //   jQuery('.asColorpicker7').asColorPicker();
  // }

  initColorPickerEdit() {
    if (typeof this.client.color_1 !== 'undefined' && this.client.color_1 !== '' && this.client.color_1) {
      this.color1 = this.client.color_1;
    } else {
      this.color1 = this.client.group.master.color_1;
    }

    if (typeof this.client.color_2 !== 'undefined' && this.client.color_2 !== '' && this.client.color_2) {
      this.color2 = this.client.color_2;
    } else {
      this.color2 = this.client.group.master.color_2;
    }

    if (typeof this.client.color_4 !== 'undefined' && this.client.color_4 !== '' && this.client.color_4) {
      this.color4 = this.client.color_4;
    } else {
      this.color4 = this.client.group.master.color_4;
    }

    if (typeof this.client.color_5 !== 'undefined' && this.client.color_5 !== '' && this.client.color_5) {
      this.color5 = this.client.color_5;
    } else {
      this.color5 = this.client.group.master.color_5;
    }

    if (typeof this.client.color_6 !== 'undefined' && this.client.color_6 !== '' && this.client.color_6) {
      this.color6 = this.client.color_6;
    } else {
      this.color6 = this.client.group.master.color_6;
    }

    if (typeof this.client.color_7 !== 'undefined' && this.client.color_7 !== '' && this.client.color_7) {
      this.color7 = this.client.color_7;
    } else {
      this.color7 = this.client.group.master.color_7;
    }

    if (typeof this.client.color_9 !== 'undefined' && this.client.color_9 !== '' && this.client.color_9) {
      this.color9 = this.client.color_9;
    } else {
      this.color9 = this.client.group.master.color_9;
    }

    if (typeof this.client.color_10 !== 'undefined' && this.client.color_10 !== '' && this.client.color_10) {
      this.color10 = this.client.color_10;
    } else {
      this.color10 = this.client.group.master.color_10;
    }
    // this.color1 = this.client.color_1;
    // this.color2 = this.client.color_2;
    // this.color4 = this.client.color_4;
    // this.color6 = this.client.color_6;
    // this.color7 = this.client.color_7;
    // this.color9 = this.client.color_9;
    // this.color10 = this.client.color_10;
  }

  private initForm() {
    this.clientForm = new FormGroup({
      'group_id': new FormControl('', Validators.required),
      'subgroup_id': new FormControl(0),
      'office_id': new FormControl('', Validators.required),
      'franchise_name': new FormControl(''),
      'franchise_code': new FormControl(''),
      'is_franchise_master': new FormControl(0),
      'is_account_master': new FormControl(0),
      'company_name': new FormControl('', Validators.required),
      'name': new FormControl('', Validators.required),
      'street': new FormControl('', Validators.required),
      'suburb': new FormControl('', Validators.required),
      'state': new FormControl('', Validators.required),
      'state_abbr': new FormControl(''),
      'postcode': new FormControl('', Validators.required),
      'country': new FormControl(''),
      'phone': new FormControl('', Validators.required),
      'fax': new FormControl(''),
      'email': new FormControl('', Validators.required),
      'is_local_search': new FormControl(0, Validators.required),
      'is_ghost': new FormControl('no', Validators.required),
      'provider': new FormControl('vaultre'),
      'crm_access_enabled': new FormControl(0, Validators.required),
      'rss_feed': new FormControl(''),
      'pricefinder_enabled': new FormControl(0, Validators.required),
      'enable_pro_ui': new FormControl(0),
      'pro_trial_period': new FormControl(0),
      'show_pro_builder': new FormControl(0),
    });
  }

  private initColorForm() {
    this.colorForm = new FormGroup({
      'color_1': new FormControl(),
      'color_2': new FormControl(),
      'color_3': new FormControl(),
      'color_4': new FormControl(),
      'color_5': new FormControl(),
      'color_6': new FormControl(),
      'color_7': new FormControl(),
      'color_8': new FormControl(),
      'color_9': new FormControl(),
      'color_10': new FormControl(),
    });
  }

  private initColorFormEdit() {
    this.colorForm.patchValue({
      'color_1': this.client.color_1,
      'color_2': this.client.color_2,
      'color_3': this.client.color_3,
      'color_4': this.client.color_4,
      'color_5': this.client.color_5,
      'color_6': this.client.color_6,
      'color_7': this.client.color_7,
      'color_8': this.client.color_8,
      'color_9': this.client.color_9,
      'color_10': this.client.color_10
    });
  }

  private initFormEdit() {
    this.client_company_name = this.client.company_name;
    this.clientForm.patchValue({
      'group_id': this.client.group_id,
      'subgroup_id': this.client.subgroup_id,
      'office_id': this.client.office_id,
      'franchise_name': this.client.franchise_name,
      'franchise_code': this.client.franchise_code,
      'is_franchise_master': this.client.is_franchise_master,
      'is_account_master': this.client.is_account_master,
      'company_name': this.client.company_name,
      'name': this.client.name,
      'street': this.client.street,
      'suburb': this.client.suburb,
      'state': this.client.state,
      'state_abbr': this.client.state_abbr,
      'postcode': this.client.postcode,
      'country': this.client.country,
      'phone': this.client.phone,
      'fax': this.client.fax,
      'email': this.client.email,
      'is_local_search': this.client.is_local_search,
      'provider': this.client.provider,
      'is_ghost': this.client.is_ghost,
      'crm_access_enabled': this.client.crm_access_enabled,
      'rss_feed': this.client.rss_feed,
      'pricefinder_enabled': this.client.pricefinder_enabled,
      'enable_pro_ui': this.client.enable_pro_ui,
      'pro_trial_period': this.client.pro_trial_period,
      'show_pro_builder': this.client.show_pro_builder,
    });
  }

  onSubmit() {
    const clientValues = this.clientForm.value;
    clientValues['version'] = 3;

    this.loading = true;
    if ( this.editMode) {
      this.clientService.httpPutClient(this.id, clientValues);
    } else {
      clientValues['is_account_active'] = 1;
      clientValues['is_template_active'] = 1;
      this.clientService.httpPostClient(clientValues);
    }

  }

  onReset() {
    const clientValues = this.clientForm.value;
    clientValues['version'] = 0;

    this.clientService.httpPutClient(this.id, clientValues);
  }

  onAccountActive() {
    const clientValues = this.clientForm.value;
    clientValues['is_account_active'] = (this.client.is_account_active === 1) ? 0 : 1;

    this.clientService.httpPutClient(this.id, clientValues);
  }

  onTemplateActive() {
    const clientValues = this.clientForm.value;
    clientValues['is_template_active'] = (this.client.is_account_active === 1) ? 0 : 1;

    this.clientService.httpPutClient(this.id, clientValues);
  }

  saveColor() {
    let color_1 = this.color1;
    let color_2 = this.color2;
    let color_4 = this.color4;
    let color_5 = this.color5;
    let color_6 = this.color6;
    let color_7 = this.color7;
    let color_9 = this.color9;
    let color_10 = this.color10;

    if (color_1 === '' || color_1 === '#' || color_1 === null) {
      if (this.client.color_1 === '' || this.client.color_1 === '#') {
        color_1 = '';
      } else {
        color_1 = this.client.color_1;
      }
    } else {
        color_1 = color_1;
    }

    if (color_2 === '' || color_2 === '#' || color_2 === null) {
      if (this.client.color_2 === '' || this.client.color_2 === '#') {
        color_2 = '';
      } else {
        color_2 = this.client.color_2;
      }
    } else {
        color_2 = color_2;
    }

    if (color_4 === '' || color_4 === '#' || color_4 === null) {
      if (this.client.color_4 === '' || this.client.color_4 === '#') {
        color_4 = '';
      } else {
        color_4 = this.client.color_4;
      }
    } else {
        color_4 = color_4;
    }

    if (color_5 === '' || color_5 === '#' || color_5 === null) {
      if (this.client.color_5 === '' || this.client.color_5 === '#') {
        color_5 = '';
      } else {
        color_5 = this.client.color_5;
      }
    } else {
      color_5 = color_5;
    }

    if (color_6 === '' || color_6 === '#' || color_6 === null) {
      if (this.client.color_6 === '' || this.client.color_6 === '#') {
        color_6 = '';
      } else {
        color_6 = this.client.color_6;
      }
    } else {
        color_6 = color_6;
    }

    if (color_7 === '' || color_7 === '#' || color_7 === null) {
      if (this.client.color_7 === '' || this.client.color_7 === '#') {
        color_7 = '';
      } else {
        color_7 = this.client.color_7;
      }
    }else {
        color_7 = color_7;
    }

    if (color_9 === '' || color_9 === '#' || color_10 === null) {
      if (this.client.color_9 === '' || this.client.color_9 === '#') {
        color_9 = '';
      } else {
        color_9 = this.client.color_9;
      }
    }else {
      color_9 = color_9;
    }

    if (color_10 === '' || color_10 === '#' || color_10 === null) {
      if (this.client.color_10 === '' || this.client.color_10 === '#') {
        color_10 = '';
      } else {
        color_10 = this.client.color_10;
      }
    }else {
      color_10 = color_10;
    }

    this.colorForm.patchValue({
      'color_1': (color_1),
      'color_2': (color_2),
      'color_4': (color_4),
      'color_5': (color_5),
      'color_6': (color_6),
      'color_7': (color_7),
      'color_9': (color_9),
      'color_10': (color_10),
    });

    const colorFormValue = this.colorForm.value;

    this.clientService.httpPutClient(this.id, colorFormValue);

    this.color1 = color_1;
    this.color2 = color_2;
    this.color4 = color_4;
    this.color5 = color_5;
    this.color6 = color_6;
    this.color7 = color_7;
    this.color9 = color_9;
    this.color10 = color_10;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionGroup.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }

  uploadLogo(event: Event, type) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.clientService.httpPutLogo(this.id, type, target.files[i]);
    }
  }

  onGhostClientChange(value) {
    if (value === 'yes') {
      this.clientForm.patchValue({
        'office_id': 'ghost',
        'provider': this.authUser.provider
      });
    }

    if (value === 'no') {
      this.clientForm.patchValue({
        'office_id': ''
      });
    }
  }

  onProviderChange(value) {
    if (value === 'designly') {
      this.clientForm.patchValue({
        'office_id': 'internal'
      });
    } else {
      this.clientForm.patchValue({
        'office_id': ''
      });
    }
  }

  onEnterColor(event) {
    if (event.key === 'Enter') {
      const color_1 = jQuery('.asColorpicker1').val();
      const color_2 = jQuery('.asColorpicker2').val();
      const color_4 = jQuery('.asColorpicker4').val();
      const color_5 = jQuery('.asColorpicker5').val();
      const color_6 = jQuery('.asColorpicker6').val();
      const color_7 = jQuery('.asColorpicker7').val();
      const color_9 = jQuery('.asColorpicker9').val();
      const color_10 = jQuery('.asColorpicker10').val();

      if (color_1.includes('#')) {
        jQuery('.asColorpicker1').val(color_1);
      } else {
        if (color_1 === '') {
          jQuery('.asColorpicker1').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker1').val(color_1);
        } else {
          jQuery('.asColorpicker1').val('#' + color_1);
        }
      }

      if (color_2.includes('#')) {
        jQuery('.asColorpicker2').val(color_2);
      } else {
        if (color_2 === '') {
          jQuery('.asColorpicker2').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker2').val(color_2);
        } else {
          jQuery('.asColorpicker2').val('#' + color_2);
        }
      }

      if (color_4.includes('#')) {
        jQuery('.asColorpicker4').val(color_4);
      } else {
        if (color_4 === '') {
          jQuery('.asColorpicker4').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker4').val(color_4);
        } else {
          jQuery('.asColorpicker4').val('#' + color_4);
        }
      }

      if (color_5.includes('#')) {
        jQuery('.asColorpicker5').val(color_5);
      } else {
        if (color_5 === '') {
          jQuery('.asColorpicker5').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker5').val(color_5);
        } else {
          jQuery('.asColorpicker5').val('#' + color_5);
        }
      }

      if (color_6.includes('#')) {
        jQuery('.asColorpicker6').val(color_6);
      } else {
        if (color_6 === '') {
          jQuery('.asColorpicker6').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker6').val(color_6);
        } else {
          jQuery('.asColorpicker6').val('#' + color_6);
        }
      }

      if (color_7.includes('#')) {
        jQuery('.asColorpicker7').val(color_7);
      } else {
        if (color_7 === '') {
          jQuery('.asColorpicker7').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker7').val(color_7);
        } else {
          jQuery('.asColorpicker7').val('#' + color_7);
        }
      }

      if (color_9.includes('#')) {
        jQuery('.asColorpicker9').val(color_9);
      } else {
        if (color_9 === '') {
          jQuery('.asColorpicker9').val('');
        } else if (color_9.includes('rgb')) {
          jQuery('.asColorpicker9').val(color_9);
        } else {
          jQuery('.asColorpicker9').val('#' + color_9);
        }
      }

      if (color_10.includes('#')) {
        jQuery('.asColorpicker10').val(color_10);
      } else {
        if (color_10 === '') {
          jQuery('.asColorpicker10').val('');
        } else if (color_10.includes('rgb')) {
          jQuery('.asColorpicker10').val(color_10);
        } else {
          jQuery('.asColorpicker10').val('#' + color_10);
        }
      }


    }
  }

  rgbToHex(color: any) {
    let values = color.replace('rgb(', '').replace(')', '');

    if (color.includes('rgba')) {
      values = color.replace('rgba(', '').replace(')', '');
    }

    const val = values.split(',');

    // tslint:disable-next-line: no-bitwise
    return '#' + ((1 << 24) + (Number(val[0]) << 16) + (Number(val[1]) << 8) + Number(val[2])).toString(16).slice(1);
  }
}
