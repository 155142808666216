<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">{{ group?.title }} - Vendor Reports</h2>

        <button (click)="add()" data-bs-toggle="modal" data-bs-target="#uploadTemplateModal" type="button" class="btn-admin">
            Upload Vendor Report
        </button>
    </div>
</div>
<div class="edit-box">
    <div class="row">
        <div class="col-md-12">
            <div class="edit-box-content">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th style="text-align: right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vendorReport of vendorReports">
                            <td>{{ vendorReport?.UID }}</td>
                            <td>{{ vendorReport?.name }}</td>
                            <td style="text-align: right">
                                <button (click)="edit(vendorReport)" data-bs-toggle="modal" data-bs-target="#uploadTemplateModal" type="button" class="btn-table" title="Edit"><i
                                        class="icon feather icon-edit"></i></button>
                                <a target="_blank" href="{{adminService.appConfig.S3_URL}}vendor-reports/{{vendorReport?.UID}}/index.html" type="button" class="btn-table" title="View"><i
                                        class="icon feather icon-search"></i></a>
                                <button (click)="onDelete(vendorReport?.UID)" type="button" class="btn-table"
                                    title="Delete"><i class="icon feather icon-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="uploadTemplateModal" tabindex="-1" role="dialog" aria-labelledby="uploadTemplateModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="uploadTemplateModalLabel">Upload Vendor Report</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" name="vendorReportName" [(ngModel)]="vendorReportName" placeholder="Enter name">
                    </div>
                    <div class="form-group">
                        <label for="file">File</label>
                        <input #vendorReportFile type="file" id="vendorReportFile" (change)="onFileChanged($event)">
                        <small id="emailHelp" class="form-text text-muted">Upload Zip file containing all files required by the vendor report</small>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Close</button>
                    <button (click)="onUpload()" [disabled]="vendorReportName === '' || !selectedFile" type="button" class="btn btn-primary" data-bs-dismiss="modal">Upload</button>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>