<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Help Center - Training Videos</h2>
    <button type="button" class="btn btn-danger table-btn-selected" *ngIf="selectedRow != 0" (click)="onDeleteSelected()">
      <i class="icon feather icon-trash"></i><span>Delete Selected</span>
    </button>
    <button routerLink="new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add Training Videos</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th data-priority="1">Provider</th>
            <th data-priority="3">Groups</th>
            <th>Excluded Groups</th>
            <th data-priority="2">Title</th>
            <th>Description</th>
            <th>Sort</th>
            <th style="text-align: right" data-priority="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let video of videos">
            <td class="hidden">{{ video?.UID }}</td>
            <td>{{ video?.provider }}</td>
            <td>
              <span *ngIf="video?.group_whitelist === 'all'">ALL</span>
              <ng-container *ngIf="video?.group_whitelist === 'selected'">
                <ng-container *ngFor="let group of video?.groups">
                  <span class="badge badge-primary">{{group?.group?.title}}</span><br />
                </ng-container>
              </ng-container>
            </td>
            <td>
              <span *ngIf="!video?.excluded_groups?.length">None</span>
              <ng-container *ngIf="video?.excluded_groups?.length">
                <ng-container *ngFor="let group of video?.excluded_groups">
                  <span class="badge badge-danger">{{group?.group?.title}}</span><br />
                </ng-container>
              </ng-container>
            </td>
            <td>{{ video?.title }}</td>
            <td>{{ cutString(video?.description) }}....</td>
            <td>{{ video?.sort }}</td>
            <td style="text-align: right">
              <button type="button" routerLink="{{ video?.UID }}/edit" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button type="button" routerLink="{{ video?.UID }}/videos" class="btn-table" title="Videos"><i class="icon feather icon-video"></i></button>
              <button type="button" (click)="onDelete(video?.UID)" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>