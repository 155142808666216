<div class="container-login">
    <div class="login-wrap">
      <div class="_login">
        <div class="logo-holder">
            <img src="/assets/img/designly-logo-login.svg" class="logo">
        </div>
        <h4>Sign into your Designly Account</h4>
        <ng-container *ngIf="!authCode">
            <p class="sub-heading">In order to utilise Designly we need to connect your iDashboard account</p>
            <button type="button" (click)="getLoginUrl()" class="btn btn-primary theme-btn-default signin-btn">Sign In</button>
        </ng-container>
        <p *ngIf="loginFailed" class="text-danger"><strong>Login failed.</strong> Please try again later.</p>
        <p class="sub-heading problem-link-holder"><a class="login-link" target="_blank"
                href="https://websites.formstack.com/forms/designly_support_form">Problems logging in?</a></p>
      </div>
    </div>
    <div class="login-bg" style="background-image: url('/assets/img/designly-login-background.jpg');">
      <div class="login-bg-text">Designly is the all-in-one design platform for creating emarketing emails, window cards, property guides and more</div>
    </div>
</div>
<!-- <div class="height-100" id="sign-form-box">
    <div class="login-section">
        <div class="logo-holder">
            <img src="/assets/img/designly-logo-inverted.svg" class="logo">
        </div>
        <ng-container *ngIf="!authCode">
            <p class="sub-heading">In order to utilise Designly we need to connect your iDashboard account</p>
            <button type="button" (click)="getLoginUrl()" class="btn btn-primary form-control theme-btn-default signin-btn">Sign In</button>
        </ng-container>
        <p *ngIf="loginFailed" class="text-danger"><strong>Login failed.</strong> Please try again later.</p>
        <p class="sub-heading problem-link-holder"><a class="login-link" target="_blank"
                href="https://websites.formstack.com/forms/designly_support_form">Problems logging in?</a></p>
    </div>
</div> -->
<ngx-loading [show]="loading"></ngx-loading>