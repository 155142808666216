import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { Subscription } from 'rxjs/Subscription';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import slugify from 'slugify';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Auth } from '../../models/auth.model';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss'],
})
export class SidebarAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription1: Subscription;
  menus: Menu[];
  currentRoute: ActivatedRoute;
  defaultSubMenu = '';
  menuType = '';
  urlFragment = '';

  @ViewChild('sidebarBox', { static: true }) sidebarBox: ElementRef;
  @ViewChild('sidebarSubBox', { static: true }) sidebarSubBox: ElementRef;

  authUser: Auth;

  constructor(
    public adminService: AdminService,
    private router: Router,
    private mScrollbarService: MalihuScrollbarService
  ) {}

  ngOnInit() {
    this.authUser = this.adminService.authService.auth;

    this.subscription1 = this.adminService.menuChanged
    .subscribe((menus: Menu[]) => {
      this.menus = menus;
      if (!this.adminService.hasMenu()) {
        this.sidebarBox.nativeElement.className = 'col-12 px-0 height-100';
        this.sidebarSubBox.nativeElement.className = 'hidden';
      } else {
        this.sidebarBox.nativeElement.className = 'col-4 px-0 height-100';
        this.sidebarSubBox.nativeElement.className = 'col-8 px-0 height-100';
      }
    });
  }

  nagivateLink(url) {
    this.router.navigate([], { relativeTo: this.currentRoute, fragment: url });
    this.urlFragment = url;
  }

  slugLink(link: string) {
    return slugify(link.trim());
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar('#malihusb', { axis: 'y', theme: 'minimal', scrollInertia: 600 });
  }

  onLogout() {
    localStorage.clear();
    window.location.href = '/login';
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.mScrollbarService.destroy('#malihusb');
  }
}
