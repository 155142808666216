import { Component, OnInit} from '@angular/core';
import { FrontService } from '../front.service';
import { Menu } from '../../models/menu.model';
import { Auth } from '../../models/auth.model';
import { ClientService } from '../../services/client.service';
import { Client } from '../../models/client.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  authUser: Auth;
  menus: Menu[] = [];
  client: Client;

  constructor(
    private frontService: FrontService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;

    this.client = this.authUser.client;

    let defaultMenus = [];
    defaultMenus = [
      new Menu('Office Details', '/settings/office-details'),
      // new Menu('My Details', '/settings/my-details'),
      new Menu('Manage Users', '/settings/manage-users'),
      new Menu('Manage Designs', '/settings/manage-designs'),
      // new Menu('Agent Access', '/settings/agent-access')
    ];

    if (!this.frontService.authService.isUk() && (this.authUser.client.group.enable_pro_ui || this.authUser.client.enable_pro_ui ||
      this.authUser.is_master)) {
      defaultMenus = [...defaultMenus, ...[
        // new Menu('My Integrations', '/settings/my-integrations'),
        new Menu('Office Integrations', '/settings/integrations'),
        new Menu('Subscriptions', '/settings/subscriptions'),
        new Menu('Billing', '/settings/billing'),
        // new Menu('Email preferences', '/settings/email-preferences'),
      ]];
    }

    this.menus = [
      new Menu('Settings', '', defaultMenus)
    ];

    this.frontService.loadMenu(this.menus);
  }
}
