import { HttpParams,   HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class StorageService {
  storagesChanged = new Subject<any[]>();
  storageChanged = new Subject<any>();
  storageOperation = new Subject<any>();

  storagesSubChanged = new Subject<any>();
  storageSubOperation = new Subject<any>();
  movestoragesChanged = new Subject<any>();
  movestoragesOperation = new Subject<any>();
  storageMigrateToS3 = new Subject<any>();
  storageMigrateToS3Support = new Subject();

  onUpload = new Subject();
  onUploadBase64 = new Subject();

  private storages = [];
  private subStorages = [];
  private moveStorages = [];

  public template_id = 0;

  headers = new Headers();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.headers.append('Authorization', `Bearer ${this.authService.getToken()}`);
  }

  setStorages(storages: any[]) {
    this.storages = storages;
    this.storagesChanged.next(this.storages.slice());
  }

  setSubStorages(subStorages: any[]) {
    this.subStorages = subStorages;
    this.storagesSubChanged.next(this.subStorages.slice());
  }

  setMoveStorages(moveStorages: any[]) {
    this.moveStorages = moveStorages;
    this.movestoragesChanged.next(this.moveStorages.slice());
  }

  getStorages() {
    return this.storages.slice();
  }

  getClient(id: number) {
    return this.storages[id];
  }

  httpGetStorages(type: string, id: number, params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    let queryString = httpParams.toString();

    if (queryString) {
      queryString = '?' + queryString;
    }

    return this.httpClient.get(
      this.appConfig.API_ENDPOINT + '/storages/' + type + '/' + id + queryString
    )
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              return response.data;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any[]) => {
        this.setStorages(response);
      }
    );
  }

  httpGetSubStorages(type: string, id: number, params: any = {}) {

    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    let queryString = httpParams.toString();

    if (queryString) {
      queryString = '?' + queryString;
    }

    return this.httpClient.get(
      this.appConfig.API_ENDPOINT + '/storages/' + type + '/' + id + queryString
    )
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              return response.data;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any[]) => {
        this.setSubStorages(response);
      }
    );
  }

  httpGetMoveStorages(type: string, id: number, params: any = {}) {

    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    let queryString = httpParams.toString();

    if (queryString) {
      queryString = '?' + queryString;
    }

    return this.httpClient.get(
      this.appConfig.API_ENDPOINT + '/storages/' + type + '/' + id + queryString
    )
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              return response.data;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any[]) => {
        this.setMoveStorages(response);
      }
    );
  }

  httpPostStorage(data: any) {

    const formData = new FormData();

    const file = data['file'];

    if ( typeof file !== 'undefined' ) {
      for (let i = 0; i < data.file.length; i++) {
        formData.append('file[]', file[i], file[i].name);
      }
    }

    formData.append('name', data['name']);
    formData.append('type', data['type']);
    formData.append('path', data['path']);

    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/storages`, formData)
    .subscribe(
      (response: any) => {
        this.storageOperation.next(response);
      },
      (response: any) => {
        this.storageOperation.next(response.error);
      }
    );
  }

  httpPostMigrateToS3(data: any) {
    const formData = new FormData();

    formData.append('file', data['file']);

    if ( data['support'] === 'yes' ) {
      formData.append('support', data['support']);
    } else {
      formData.append('template_id', data['template_id']);
      formData.append('client_id', data['client_id']);
    }

    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/storages/migrate`, formData)
    .subscribe(
      (response: any) => {
        if (data['support'] === 'yes') {
          this.storageMigrateToS3Support.next(response);
        } else {
          this.storageMigrateToS3.next(response);
        }
      },
      (response: any) => {
        if (data['support'] === 'yes') {
          this.storageMigrateToS3Support.next(response.error);
        } else {
          this.storageMigrateToS3.next(response.error);
        }
      }
    );
  }

  httpDeleteStorage(data: any) {

    const formData = new FormData();

    formData.append('type', data['type']);
    formData.append('path', data['path']);

    return this.httpClient.post(
      this.appConfig.API_ENDPOINT + '/storages/delete',
      formData
    )
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              return response.data;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any) => {
        this.storageOperation.next(response);
      }
    );
  }

  httpMoveStorage(data: any) {
    const formData = new FormData();

    formData.append('type', data['type']);
    formData.append('path', data['path']);
    formData.append('move_to_path', data['move_to_path']);

    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/storages/move',
      formData
    );

    return this.httpClient.request<any>(req)
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              return response.data;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any) => {
        this.storageOperation.next(response);
      }
    );
  }

  httpUpload(file: File): void {
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.httpClient.post(`${this.appConfig.API_ENDPOINT}/storages/upload`, formData)
    .subscribe(
      (response: any) => {
        this.onUpload.next(response);
      },
      (response: any) => {
        this.onUpload.next(response.error);
      }
    );
  }

  httpUploadBase64(data: any): void {
    this.httpClient.post(`${this.appConfig.API_ENDPOINT}/storages/upload64`, data)
    .subscribe(
      (response: any) => {
        this.onUploadBase64.next(response);
      },
      (response: any) => {
        this.onUploadBase64.next(response.error);
      }
    );
  }
}
