import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Menu } from '../../models/menu.model';

@Component({
  selector: 'app-setting-admin',
  templateUrl: './setting-admin.component.html',
  styleUrls: ['./setting-admin.component.scss']
})
export class SettingAdminComponent implements OnInit {
  menus: Menu[] = [];

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.loadMenu(this.menus);
  }

}
