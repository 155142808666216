<div class="panel panel-bordered panel-box-shadow">
  <div class="panel-heading">
    <h3 class="panel-title">Template Report</h3>
  </div>
  <div class="panel-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Template title</th>
            <th>Template Type</th>
            <th>date created</th>
            <th>Status</th>
            <th>Usage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let analytic of analytics">
            <td>{{ analytic.title }}</td>
            <td>{{ analytic.category_name }}</td>
            <td>{{ analytic.created }}</td>
            <td *ngIf="analytic.is_active == 0">Inactive</td>
            <td *ngIf="analytic.is_active == 1">Active</td>
            <td>10</td>
          </tr>               
        </tbody>
      </table>
    </div>

    <div class="clearfix"></div>
    <button class="btn btn-outline btn-round btn-2" (click)="exportClick()">Export to CSV <i class="icon feather icon-chevron-right"></i></button>
  </div>
</div>