import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'library'
})
export class LibraryPipe implements PipeTransform {

  transform(value: any, term: any): any {

    // tslint:disable-next-line: curly
    if (term === undefined) return value;

    return value.filter(function(val){
        return val.name.toLowerCase().includes(term.toLowerCase());
    });

  }

}
