import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { CookieService } from 'ng2-cookies';

import { Auth } from '../models/auth.model';
import { Router } from '@angular/router';
import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

import * as jwt_decode from 'jwt-decode';
@Injectable()
export class AuthService {
  isRedirected = false;
  public auth: Auth;
  public cancelHttp = new Subject<any>();

  constructor(@Inject(APP_CONFIG) private appConfig,
              public cookieService: CookieService,
              private router: Router
            ) {}

  setAuthStorage(auth: any, ignoreMultiple = false, loginAs = false, isMail = false, isCorporate = false) {
    this.deleteAuthCookie();

    const _token = jwt_decode(auth.token);

    localStorage.setItem('md3Auth__token', auth.token);

    if (isMail) {
      localStorage.setItem('md3Auth__expiry', auth.expires);
    } else {
      localStorage.setItem('md3Auth__expiry', auth.expires);
    }

    localStorage.setItem('md3Auth__internal', _token.sub.internal);
    localStorage.setItem('md3Auth__ID', _token.sub.ID);
    localStorage.setItem('md3Auth__version', _token.sub.version);
    localStorage.setItem('md3Auth__active', _token.sub.active);

    let apiToken = '';

    if (_token.sub.provider === 'mydesktop') {
      apiToken = _token.sub.mdt;
    } else {
      apiToken = _token.sub.external_token;
    }

    let newApiToken = '';

    if (typeof _token.sub.new_api_token !== 'undefined' && _token.sub.new_api_token) {
      newApiToken = _token.sub.new_api_token;
    }

    localStorage.setItem('md3Auth_token',  apiToken);
    localStorage.setItem('md3Auth_multiple', _token.sub.mul);
    localStorage.setItem('md3Auth_agentid', btoa(_token.sub.agent_id));
    localStorage.setItem('md3Auth_brandid', btoa(_token.sub.brand_id));
    localStorage.setItem('md3Auth_groupid', btoa(_token.sub.office_id));
    localStorage.setItem('md3Auth_first', btoa(_token.sub.first));
    localStorage.setItem('md3Auth_office', btoa(_token.sub.office));
    localStorage.setItem('md3Auth_pftoken', btoa(_token.sub.pf_token));
    localStorage.setItem('md3Auth_provider', btoa(_token.sub.provider));
    localStorage.setItem('md3Auth_newApiToken', btoa(newApiToken));

    if ( _token.sub.provider === 'vaultre' ) {
      localStorage.setItem('vaultLocation', _token.sub.location);
    }

    if (_token.sub.provider === 'domain') {
      localStorage.setItem('isDomainMulti', _token.sub.domain_multi);
    }

    if ( typeof _token.sub.first_template !== 'undefined' ) {
      localStorage.setItem('md3_first_template_id', _token.sub.first_template);
    }

    localStorage.setItem('md3Auth__tmp', '0');
    if ( typeof _token.sub.tmp !== 'undefined' && _token.sub.tmp ) {
      localStorage.setItem('md3Auth__tmp', '1');
    }

    if ( loginAs ) {
      localStorage.setItem('isAdmin', 'yes');
    }

    if ( isCorporate ) {
      localStorage.setItem('is_corporate', 'yes');
    }

    localStorage.setItem('currentVersion', 'live.designlypro.v1');

    this.auth = auth;
  }

  setAuthProfile(auth: any) {
    const client =  JSON.parse(auth.client);

    localStorage.setItem('md3Auth_client', btoa(auth.client));
    localStorage.setItem('md3Auth_user', btoa(auth.user));
    localStorage.setItem('md3Auth_office', btoa(client.name));

    localStorage.setItem('md3Auth__ID', client.ID);
    localStorage.setItem('md3Auth__version', client.version);
    localStorage.setItem('md3Auth__active', client.is_account_active);

    // this.auth = auth;
  }

  setAuthSwitch(auth: any) {
    const client =  JSON.parse(auth.client);

    localStorage.setItem('md3Auth_token',  btoa(auth.token));

    localStorage.setItem('md3Auth_client', btoa(auth.client));
    localStorage.setItem('md3Auth_user', btoa(auth.user));

    localStorage.setItem('md3Auth_agentid', btoa(auth.agentid));
    localStorage.setItem('md3Auth_brandid', btoa(auth.brandid));
    localStorage.setItem('md3Auth_groupid', btoa(auth.groupid));
    localStorage.setItem('md3Auth_office', btoa(auth.office));
    localStorage.setItem('md3Auth_pftoken', btoa(auth.pftoken));
    localStorage.setItem('md3Auth_first', btoa(auth.first));

    localStorage.setItem('md3Auth__ID', client.ID);
    localStorage.setItem('md3Auth__version', client.version);
    localStorage.setItem('md3Auth__active', client.is_account_active);
  }

  setAuthCookie(auth: Auth, ignoreMultiple = false) {
    localStorage.setItem('md3Auth_agentid', btoa(auth.agentid));
    localStorage.setItem('md3Auth_brandid', btoa(auth.brandid));
    localStorage.setItem('md3Auth_groupid', btoa(auth.groupid));
    localStorage.setItem('md3Auth_office', btoa(auth.office));
    localStorage.setItem('md3Auth_pftoken', btoa(auth.pftoken));
    localStorage.setItem('md3Auth_token', btoa(auth.token));
    localStorage.setItem('md3Auth_client', btoa(auth.client));
    localStorage.setItem('md3Auth_user', btoa(auth.user));
    localStorage.setItem('md3Auth_first', btoa(auth.first));
    if ( !ignoreMultiple ) {
      localStorage.setItem('md3Auth_multiple', btoa(auth.multiple));
    }
    this.auth = auth;
  }

  getAuthCookie() {
    try {
      let auth: Auth;

      const __token = localStorage.getItem('md3Auth__token');
      const _expiry = localStorage.getItem('md3Auth__expiry');
      const _ID = localStorage.getItem('md3Auth__ID');
      const _version = localStorage.getItem('md3Auth__version');
      const _active = localStorage.getItem('md3Auth__active');
      const _tmp = localStorage.getItem('md3Auth__tmp');

      const agentid = atob(localStorage.getItem('md3Auth_agentid'));
      const brandid = atob(localStorage.getItem('md3Auth_brandid'));
      const groupid = atob(localStorage.getItem('md3Auth_groupid'));
      const office = atob(localStorage.getItem('md3Auth_office'));
      const pftoken = atob(localStorage.getItem('md3Auth_pftoken'));
      const token = atob(localStorage.getItem('md3Auth_token'));
      const provider = atob(localStorage.getItem('md3Auth_provider'));
      const newApiToken = atob(localStorage.getItem('md3Auth_newApiToken'));

      let multiple = [];
      if ( provider === 'mydesktop' ) {
        multiple = JSON.parse(atob(localStorage.getItem('md3Auth_multiple')));
      }

      const client = (localStorage.getItem('md3Auth_client')) ? JSON.parse(atob(localStorage.getItem('md3Auth_client'))) : [];
      const user = (localStorage.getItem('md3Auth_user')) ? JSON.parse(atob(localStorage.getItem('md3Auth_user'))) : [];
      const first = (localStorage.getItem('md3Auth_first')) ? atob(localStorage.getItem('md3Auth_first')) : '0';

      const is_master = (client && client.is_account_master === 1) ? true : false;
      const is_admin = (user && user.is_admin) ? user.is_admin : false;
      const is_group_admin = (user && user.is_group_admin) ? user.is_group_admin : false;
      const can_share = (is_master || is_admin) ? true : false;

      const is_subgroup = ( client.subgroup_id !== 0 ) ? true : false;

      const isUserPro = localStorage.getItem('isUserPro') === 'true' ? true : false;
      const isClientPro = localStorage.getItem('isClientPro') === 'true' ? true : false;

      const proAddons = localStorage.getItem('proAddons') ? JSON.parse(localStorage.getItem('proAddons')) : [];

      auth = {
        provider: provider,
        agentid: agentid,
        brandid: brandid,
        groupid: groupid,
        office: office,
        pftoken: pftoken,
        token: token,
        client: client,
        user: user,
        multiple: multiple,
        first: first,
        is_master: is_master,
        is_admin: is_admin,
        is_group_admin: is_group_admin,
        can_share: can_share,
        is_subgroup: is_subgroup,
        __token: __token,
        _expiry: _expiry,
        _ID: _ID,
        _version: _version,
        _active: _active,
        _tmp: _tmp,
        newApiToken: newApiToken,
        isUserPro,
        isClientPro,
        proAddons
      };

      this.auth = auth;
    } catch (err) {
      if ( !this.isRedirected ) {
        localStorage.clear();
        this.isRedirected = true;
        this.router.navigateByUrl('/login');
        console.log(err);
      }
      return false;
    }

  }

  updateAuthCookieField(name: string, data: string) {
    localStorage.setItem('md3Auth_' + name, btoa(data));

    if ( name === 'client' || name === 'user' || name === 'multiple') {
      this.auth[name] = JSON.parse(data);
    } else {
      this.auth[name] = data;
    }
  }

  checkAuthCookie() {
    return this.checkAuthToken();
  }

  deleteAuthCookie() {
    let designlyEnvironment = null;
    if (!this.appConfig.PRODUCTION) {
      designlyEnvironment = localStorage.getItem('designly_environment');
    }
    localStorage.clear();

    if (designlyEnvironment) {
      localStorage.setItem('designly_environment', designlyEnvironment);
    }
    this.auth = null;
  }

  checkAuthToken() {
    const _token = localStorage.getItem('md3Auth__token');
    const token = localStorage.getItem('md3Auth_token');

    if ( !_token || typeof _token === 'undefined' ) {
      return false;
    }

    if ( !token || typeof token === 'undefined' ) {
      return false;
    }

    const __token = jwt_decode(_token);

    if ( typeof __token.exp === 'undefined' ) {
      return false;
    }

    const date = new Date(0);
    date.setUTCSeconds(__token.exp);

    const exp_date = date;

    if (typeof exp_date === 'undefined' || !(exp_date.valueOf() > new Date().valueOf()) ) {
        return false;
    }

    return true;
  }

  getToken() {
    const token = localStorage.getItem('md3Auth__token');

    return token;
  }

  getMDToken() {
    try {
      const token = atob(localStorage.getItem('md3Auth_token'));

      return token;
    } catch (err) {
      if ( !this.isRedirected ) {
        localStorage.clear();
        this.isRedirected = true;
        // this.router.navigateByUrl('/login');
        console.log(err);
      }
      return false;
    }
  }

  cancelRequest() {
    this.cancelHttp.next();
  }

  getApp() {
    return this.appConfig.APP;
  }

  isSignifi() {
    return this.appConfig.SKIN === 'signifi' ? 'yes' : 'no';
  }

  isRaywhite() {
    return this.appConfig.SKIN === 'raywhite' ? 'yes' : 'no';
  }

  isUk() {
    return this.appConfig.SKIN === 'uk';
  }

  getDomainMulti() {
    return localStorage.getItem('isDomainMulti');
  }

  updatePreviousData(auth) {
    try {
      const previousAuth = this.cookieService.get('previous_auth');
      const future = new Date();
      const expiry = new Date(future.setDate(future.getDate() + 30));

      if (previousAuth === '') {
        const authList = [auth];

        this.cookieService.set('previous_auth', JSON.stringify(authList), expiry);
        return true;
      }
      const decodedAuth = JSON.parse(previousAuth);
      const authIndex = decodedAuth.findIndex(el => el.ID === auth.ID);

      if (authIndex >= 0) {
        decodedAuth.splice(authIndex, 1);
        decodedAuth.unshift(auth);

        this.cookieService.set('previous_auth', JSON.stringify(decodedAuth), expiry);
        return true;
      }

      if (decodedAuth.length === 4) {
        decodedAuth.pop();
      }

      decodedAuth.unshift(auth);
      this.cookieService.set('previous_auth', JSON.stringify(decodedAuth), expiry);
      return true;
    } catch (e) {
      console.log(e);
      this.cookieService.delete('previous_auth');
      return false;
    }
  }

  getPreviousAuth() {
    try {
      const previousAuth = this.cookieService.get('previous_auth');

      if (previousAuth === '') {
        return [];
      }

      return JSON.parse(previousAuth);
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  clearCookies() {
    this.cookieService.deleteAll();
  }
}
