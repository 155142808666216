import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Icon } from '../models/icon.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';
import { Auth } from '../models/auth.model';

@Injectable()
export class IconService {
  iconsChanged = new Subject<Icon[]>();
  iconChanged = new Subject<Icon>();
  iconOperation = new Subject<Icon>();

  private icons: Icon[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setIcons(icons: Icon[]) {
    this.icons = icons;
    this.iconsChanged.next(this.icons.slice());
  }

  getIcons() {
    return this.icons.slice();
  }

  getIdIndex(id: number) {
    return this.icons.findIndex(c => c.ID === id);
  }

  httpGetIcons(params: any = {}, columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/icons2',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Icon[]) => {
        this.setIcons(response);
      }
    );
  }

  httpGetIcon(id: number) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/icons2/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Icon) => {
        this.iconChanged.next(response);
      }
    );
  }

  httpPostIcon(category: any, type: any, sort_order: any, img: any) {
    const formData: FormData = new FormData();
    const file = img['file'];

    if ( typeof file !== 'undefined' ) {
      formData.append('file', file, file.name);
    }

    formData.append('category_type', category);
    formData.append('icon_type', type);
    formData.append('sort_order', sort_order);

    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/icons2',
      formData
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.iconOperation.next(response);
      }
    );
  }

  httpPutIcon(id: number, category: any, type: any, sort_order: any, img: any) {
    const formData: FormData = new FormData();
    const file = img['file'];

    if ( typeof file !== 'undefined' ) {
      formData.append('file', file, file.name);
    }

    formData.append('category_type', category);
    formData.append('icon_type', type);
    formData.append('sort_order', sort_order);
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/icons2/' + id,
      formData
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.iconOperation.next(response);
      }
    );
  }

  httpDeleteIcon(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/icons2/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetIcons();
      }
    );
  }
}
