import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class DesignsTypeService {
  onGetAll = new Subject();
  onGet = new Subject();
  onStore = new Subject();
  onUpdate = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }


  getAll(params:any ={}) {
      return this.httpClient.get(
        `${this.appConfig.NEW_API_ENDPOINT}/designs/types`,
        {
          params,
        }
      );
  }
  httpGetAll(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/designs/types`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetAll.next(response);
      },
      (response: any) => {
        this.onGetAll.next(response.error);
      }
    );
  }

  httpGet(uuid: string) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/designs/types/${uuid}`)
    .subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  httpStore(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/designs/types`, data)
    .subscribe(
      (response: any) => {
        this.onStore.next(response);
      },
      (response: any) => {
        this.onStore.next(response.error);
      }
    );
  }

  httpUpdate(uuid: string, data: any) {
    return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/designs/types/${uuid}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdate.next(response);
      },
      (response: any) => {
        this.onUpdate.next(response.error);
      }
    );
  }
}
