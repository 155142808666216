import { Injectable, Inject } from '@angular/core';
import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

export class CampaignService {
  campaignGetAll = new Subject();
  campaignGet = new Subject();
  campaignPost = new Subject();
  campaignPut = new Subject();
  campaignDelete = new Subject();
  campaignPostTemplate = new Subject();
  campaignBuildTemplates = new Subject();
  campaignExport = new Subject();
  campaignPostListing = new Subject();
  campaignOnDeleteListing = new Subject();
  campaignGenerateTemplates = new Subject();
  campaignOnGenerateFinish: Subject<boolean> = new Subject<boolean>();
  campaignRemoveListingFromCampaign = new Subject();

  campaignGetShared = new Subject();
  campaignPostShared = new Subject();

  public listing: any;
  public isCustom = false;
  public propId;
  public campaignId;

  public campaignCategory = 'Sales';
  public vaultStatus = 'listing';

  public backUrl = null;

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  httpGetCampaigns(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/campaigns',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignGetAll.next(response);
      },
      (response: any) => {
        this.campaignGetAll.next(response.error);
      }
    );
  }

  httpGetCampaign(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/campaigns/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignGet.next(response);
      },
      (response: any) => {
        this.campaignGet.next(response.error);
      }
    );
  }

  httpPostCampaign(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/campaigns',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignPost.next(response);
      },
      (response: any) => {
        this.campaignPost.next(response.error);
      }
    );
  }

  httpPutCampaign(id: number, data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/campaigns/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignPut.next(response);
      },
      (response: any) => {
        this.campaignPut.next(response.error);
      }
    );
  }

  httpDeleteCampaign(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/campaigns/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignDelete.next(response);
      },
      (response: any) => {
        this.campaignDelete.next(response.error);
      }
    );
  }

  httpDeleteListingFromCustomCampaign(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/campaigns/property/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignOnDeleteListing.next(response);
      },
      (response: any) => {
        this.campaignOnDeleteListing.next(response.error);
      }
    );
  }

  httpPostCampaignTemplate(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/campaigns/template',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignPostTemplate.next(response);
      },
      (response: any) => {
        this.campaignPostTemplate.next(response.error);
      }
    );
  }

  httpBuildTemplates(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/campaigns/build',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignBuildTemplates.next(response);
      },
      (response: any) => {
        this.campaignBuildTemplates.next(response.error);
      }
    );
  }

  httpExportCampaign(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/campaigns/export/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignExport.next(response);
      },
      (response: any) => {
        this.campaignExport.next(response.error);
      }
    );
  }

  httpPostCampaignListing(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/campaigns/listing',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignPostListing.next(response);
      },
      (response: any) => {
        this.campaignPostListing.next(response.error);
      }
    );
  }

  httpGenerateCampaignTemplates(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/campaigns/templates',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignGenerateTemplates.next(response);
      },
      (response: any) => {
        this.campaignGenerateTemplates.next(response.error);
      }
    );
  }

  httpRemoveListingFromCampaign(id: number, parent_id: number, template_id: number) {
    const req = new HttpRequest(
      'DELETE',
      `${this.appConfig.API_ENDPOINT}/campaigns/remove/${id}/${parent_id}/${template_id}`
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignRemoveListingFromCampaign.next(response);
      },
      (response: any) => {
        this.campaignRemoveListingFromCampaign.next(response.error);
      }
    );
  }

  httpGetCampaignShared(client_id: number) {
    const req = new HttpRequest(
      'GET',
      `${this.appConfig.API_ENDPOINT}/campaigns/shared/${client_id}`,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignGetShared.next(response);
      },
      (response: any) => {
        this.campaignGetShared.next(response.error);
      }
    );
  }

  httpShareCampaign(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/campaigns/share',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.campaignPostShared.next(response);
      },
      (response: any) => {
        this.campaignPostShared.next(response.error);
      }
    );
  }

}
