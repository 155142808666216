import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Menu } from '../../models/menu.model';


@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  menus: Menu[];
  navigationType = 'link';
  navigationDefault = 'enewsletter';

  constructor(private adminService: AdminService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.menus = [
      new Menu('Elements', '', [
        new Menu('Email', 'templates/elements/enewsletter'),
        new Menu('Buyer Match', 'templates/elements/buyer-match'),
        new Menu('Quick Enquiry',  'templates/elements/quick-enquiry'),
        new Menu('Brochure',  'templates/elements/brochure'),
        new Menu('Property Guide',  'templates/elements/property-guide'),
        new Menu('Social Media',  'templates/elements/social-media'),
        new Menu('Email Signature',  'templates/elements/email-signature'),
        new Menu('DL Card',  'templates/elements/dl-card'),
        new Menu('Signboard',  'templates/elements/sign-board'),
        new Menu('PDF Flyers',  'templates/elements/property-match'),
        new Menu('Match Properties',  'templates/elements/match-listings'),
        new Menu('Buyer Match Vault',  'templates/elements/buyer-match-vault'),
        new Menu('Property Alerts',  'templates/elements/property-alerts'),
        new Menu('Pricefinder',  'templates/elements/pricefinder'),
        new Menu('WebBooks',  'templates/elements/webbooks'),
        new Menu('Owner Reports',  'templates/elements/owner-reports'),
        new Menu('Proposals',  'templates/elements/proposals')
      ]),
      new Menu('Blocks', '', [
        new Menu('Email', 'templates/blocks/enewsletter'),
        new Menu('Buyer Match', 'templates/blocks/buyer-match'),
        new Menu('Quick Enquiry',  'templates/blocks/quick-enquiry'),
        new Menu('Brochure',  'templates/blocks/brochure'),
        new Menu('Property Guide',  'templates/blocks/property-guide'),
        new Menu('Social Media',  'templates/blocks/social-media'),
        new Menu('Email Signature',  'templates/blocks/email-signature'),
        new Menu('DL Card',  'templates/blocks/dl-card'),
        new Menu('Signboard',  'templates/blocks/sign-board'),
        new Menu('PDF Flyers',  'templates/blocks/property-match'),
        new Menu('Match Properties',  'templates/blocks/match-listings'),
        new Menu('Buyer Match Vault',  'templates/blocks/buyer-match-vault'),
        new Menu('Property Alerts',  'templates/blocks/property-alerts'),
        new Menu('Pricefinder',  'templates/blocks/pricefinder'),
        new Menu('WebBooks',  'templates/blocks/webbooks'),
        new Menu('Owner Reports',  'templates/blocks/owner-reports'),
        new Menu('Proposals',  'templates/blocks/proposals')
      ]),
      new Menu('Masters', '', [
        new Menu('Email', 'templates/masters/enewsletter'),
        new Menu('Buyer Match', 'templates/masters/buyer-match'),
        new Menu('Quick Enquiry',  'templates/masters/quick-enquiry'),
        new Menu('Brochure',  'templates/masters/brochure'),
        new Menu('Property Guide',  'templates/masters/property-guide'),
        new Menu('Social Media',  'templates/masters/social-media'),
        new Menu('Email Signature',  'templates/masters/email-signature'),
        new Menu('DL Card',  'templates/masters/dl-card'),
        new Menu('Signboard',  'templates/masters/sign-board'),
        new Menu('PDF Flyers',  'templates/masters/property-match'),
        new Menu('Match Properties',  'templates/masters/match-listings'),
        new Menu('Buyer Match Vault',  'templates/masters/buyer-match-vault'),
        new Menu('Property Alerts',  'templates/masters/property-alerts'),
        new Menu('Pricefinder',  'templates/masters/pricefinder')
      ])
    ];

    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

  }

}
