import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { Subscription } from 'rxjs';
import { UserLog } from '../../../models/user-log.model';
import { UserLogService } from '../../../services/user-log.service';
import { Auth } from '../../../models/auth.model';


@Component({
  selector: 'app-analytics-login-report',
  templateUrl: './analytics-login-report.component.html',
  styleUrls: ['./analytics-login-report.component.scss']
})

export class AnalyticsLoginReportComponent implements OnInit, OnDestroy {
  menus: Menu[] = [];
  subscription: Subscription;
  userlogs: UserLog[] = [];

  constructor(private frontService: FrontService, private userLogService: UserLogService) { }

  authUser: Auth;
  
  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.frontService.loadMenu(this.menus);
    
    this.userLogService.httpGetUserLogs(
      {
        group_id: this.authUser.client.office_id
      },
      '',
      ''
    );

    this.subscription = this.userLogService.userlogsChanged.subscribe(
      (userlogs: UserLog[]) => {
        this.userlogs = userlogs;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
