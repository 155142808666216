import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class DesignService {
  onGetAll = new Subject();
  onGet = new Subject();
  onStore = new Subject();
  onUpdate = new Subject();
  onDelete = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetAll(params: any = {}, columns: string = '', order: string = '') {

    if ( columns ) params.select = columns;
    if ( order ) params.order = order;

    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/designs`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetAll.next(response);
      },
      (response: any) => {
        this.onGetAll.next(response.error);
      }
    );
  }

  httpGet(uuid: string) {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/designs/${uuid}`)
    .subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  store(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/designs`, data);
  }
  httpStore(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/designs`, data)
    .subscribe(
      (response: any) => {
        this.onStore.next(response);
      },
      (response: any) => {
        this.onStore.next(response.error);
      }
    );
  }

  httpUpdate(uuid: string, data: any) {
    return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/designs/${uuid}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdate.next(response);
      },
      (response: any) => {
        this.onUpdate.next(response.error);
      }
    );
  }

  httpDeleteTemplate(uuid: string) {
    return this.httpClient.delete(`${this.appConfig.NEW_API_ENDPOINT}/designs/${uuid}`)
    .subscribe(
      (response: any) => {
          this.onDelete.next(response.data);
      },
      (response: any) => {
          this.onDelete.next(response.error);
      }
    );
  }
}
