import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FaqCategoryService } from '../../../services/faqcategory.service';
import { FaqCategory } from '../../../models/faqcategory.model';
import swal from 'sweetalert2';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  faqsCategories: FaqCategory[] = [];

  constructor(
    private faqCategoryService: FaqCategoryService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.faqCategoryService.httpGetFaqsCategoriesPublic();

    this.faqCategoryService.publicOnGetFaqsCategories
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.faqsCategories = response.data;
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
