import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agent'
})
export class AgentPipe implements PipeTransform {

  transform(value: any, term: any): any {
    // tslint:disable-next-line:curly
    if (term === undefined) return value;

    // tslint:disable-next-line:curly
    if (term === 'all') return value;

    return value.filter(function(val) {
      return val.agent_name.toLowerCase().includes(term.toLowerCase());
    });

  }

}
