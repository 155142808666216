import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Menu } from '../../models/menu.model';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  menus: Menu[];

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.menus = [];
    this.adminService.loadMenu(this.menus);
  }

}
