<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Master Template': 'Master Template - ' + master_title }}</h2>

    <button [routerLink]="backUrl" type="button" class="btn-admin">
        <i class="icon feather icon-arrow-left"></i><span>Back</span>
      </button>
  </div>
</div>
<div class="edit-box-new">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="masterForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-5">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="description" class="col-sm-2">Description</label>
            <div class="col-sm-5">
              <textarea formControlName="description" rows="3" class="form-control" id="description"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-5">
              <ng-select formControlName="provider" [clearable]="false" [searchable]="false" [multiple]="true">
                <ng-option value="" disabled>Select a Provider</ng-option>
                <ng-option value="all">All</ng-option>
                <ng-option value="mydesktop">MyDesktop</ng-option>
                <ng-option value="vaultre">VaultRE</ng-option>
                <ng-option value="domain">Domain</ng-option>
                <ng-option value="idashboard">iDashboard</ng-option>
                <ng-option value="designly">Designly</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="group_id" class="col-sm-2">Group</label>
            <div class="col-sm-5">
              <ng-select formControlName="group_id" [clearable]="false" [searchable]="true" [multiple]="false">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option *ngFor="let group of groups" [value]="group?.ID">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="client_id" class="col-sm-2">Client</label>
            <div class="col-sm-5">
                <ng-select formControlName="client_id" [clearable]="false" [searchable]="true" [multiple]="false">
                  <ng-option value="" disabled>Select a Client</ng-option>
                  <ng-option [value]="0">All</ng-option>
                  <ng-option *ngFor="let client of clients" [value]="client?.ID">{{client?.name ? client?.name : client?.company_name}} ({{client?.office_id}})</ng-option>
                </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="group_id" class="col-sm-2">Subgroup Name</label>
            <div class="col-sm-5">
              <ng-select formControlName="subgroup_id" [clearable]="false" [searchable]="false" [multiple]="false">
                <ng-option value="" disabled>Select a Sub Group</ng-option>
                <ng-option [value]="0">All</ng-option>
                <ng-option *ngFor="let subgroup of subgroups" [value]="subgroup?.ID">{{subgroup?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="media_attribute_id" class="col-sm-2">Media Attribute</label>
            <div class="col-sm-5">
              <ng-select formControlName="media_attribute_id" [clearable]="false" [searchable]="false" [multiple]="false">
                <ng-option value="" disabled>Select a Media Attribute</ng-option>
                <ng-option *ngFor="let mediaAttribute of mediaAttributes" [value]="mediaAttribute?.ID">
                  <span *ngIf="mediaAttribute.size === 'custom' && !mediaAttribute.custom_label">
                    {{mediaAttribute?.slug | uppercase}}
                  </span>
                  <span *ngIf="mediaAttribute.size === 'custom' && mediaAttribute.custom_label">
                    {{mediaAttribute?.medium | uppercase}}-{{mediaAttribute?.orientation | uppercase}}-{{mediaAttribute?.custom_label | uppercase}}
                  </span>
                  <span *ngIf="mediaAttribute.size !== 'custom'">
                    {{mediaAttribute?.slug | uppercase}}
                  </span>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="department_id" class="col-sm-2">Department</label>
            <div class="col-sm-5">
              <select formControlName="department_id" class="form-control" id="department_id" >
                <option value="" disabled>Select a Department</option>
                <option *ngFor="let mValue of departments" value="{{ mValue.ID }}">{{ mValue.title }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="icon" class="col-sm-2">Icon</label>
            <div class="col-sm-5">
              <input #fileIcon type="file" class="form-control" id="icon" accept="image/*">
              <input #iconName formControlName="icon" type="hidden" autocomplete="off">
              <img class="form-img-icon" [src]="iconName.value">
            </div>
          </div>
          <div class="form-group">
            <label for="thumbnail-image" class="col-sm-2">Thumbnail Image</label>
            <div class="col-sm-5">
              <input #thumbnailImage type="file" class="form-control" id="thumbnail_image" accept="image/*">
              <input #imageName formControlName="thumbnail_image" type="hidden" autocomplete="off">
              <img class="form-img-thumbnail" [src]="imageName.value">
            </div>
          </div>
          <div class="form-group">
            <label for="enable_contenteditable" class="col-sm-2">Enable Auto<strong>Content Editable</strong></label>
            <div class="col-sm-3">
              <select formControlName="enable_contenteditable" class="form-control" id="enable_contenteditable">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="enable_ckeditor" class="col-sm-2">Enable <strong>CKEDITOR</strong></label>
            <div class="col-sm-3">
              <select formControlName="enable_ckeditor" class="form-control" id="enable_ckeditor">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="puppeteer" class="col-sm-2">Enable <strong>Puppeteer</strong></label>
            <div class="col-sm-3">
              <select formControlName="puppeteer" class="form-control" id="puppeteer">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="is_active" class="col-sm-2">Status</label>
            <div class="col-sm-3">
              <select formControlName="is_active" class="form-control" id="is_active">
                <option value="" disabled>Select a Status</option>
                <option value="1">Active</option>
                <option value="0">No</option>
                <option value="2">Coming Soon</option>
                <option value="3">Master Only</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="show_element" class="col-sm-2">Elements Of</label>
            <div class="col-sm-3">
              <select formControlName="show_element" class="form-control" id="show_element">
                <option value="" disabled>Select an Option</option>
                <option value="0">Group & Client</option>
                <option value="1">Group Only</option>
                <option value="2">Client Only</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="validation_code" class="col-sm-2">Validation Code</label>
            <div class="col-sm-5">
              <textarea formControlName="validation_code" rows="4" class="form-control" id="validation_code"></textarea>
              <p><small>#element:1:1:Notification Title</small></p>
              <p>
                <small>(#element|.element): element selector</small><br>
                <small>(0|&gt;n|&lt;n): Minimum number of element (-1 mean element should not exist)</small><br>
                <small>(0|&lt;n): Maximum number of element</small><br>
                <small>(string): Notification Title</small>
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!masterForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
