import { Injectable, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { APP_CONFIG } from '../app.config';
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';

@Injectable()
export class ParticaService {
  onCheckAccount = new Subject();
  onGetArticles = new Subject();
  onGetArticle = new Subject();
  onGetCart = new Subject();
  onPostCart  = new Subject();
  onDeleteCartItem = new Subject();
  onCheckPaymentSource = new Subject();
  onPostPaymentSource = new Subject();
  onPostCheckout = new Subject();
  onGetPurchasedArticles = new Subject();
  onGetPurchasedArticle = new Subject();
  onGetSsoToken = new Subject();
  onGetStreams = new Subject();
  onSubscribe = new Subject();
  onGetUserStreams = new Subject();

  onParticaError = new Subject();

  onBuilderInsertArticle = new Subject();
  onBuilderModalClose = new Subject();
  onBuilderModalOpen = new Subject();

  onGetRss = new Subject();
  onAddFeed = new Subject();
  onGetFeeds = new Subject();
  onUpdateFeed = new Subject();
  onDeleteFeed = new Subject();

  public particaLoaded = false;

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) { }

  httpCheckAccount() {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/partica/checkaccount',
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onCheckAccount.next(response);
      },
      (response: any) => {
        this.onCheckAccount.next(response.error);
      }
    );
  }

  httpGetArticles(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.PARTICA_ENDPOINT + '/particastore/search',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetArticles.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpGetArticle(params) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/syndicationarticle/preview', {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetArticle.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httGetCart(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/shoppingcart',
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetCart.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpPostCart(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'POST',
      this.appConfig.PARTICA_ENDPOINT + '/shoppingcart/item?' + httpParams.toString(),
      params
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetCart.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpDeleteCartItem(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'DELETE',
      this.appConfig.PARTICA_ENDPOINT + '/shoppingcart/item?' + httpParams.toString()
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetCart.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpCheckPaymentSource(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/payment/haspaymentsource',
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onCheckPaymentSource.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpPostPaymentSource(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'POST',
      this.appConfig.PARTICA_ENDPOINT + '/payment/createsource?' + httpParams.toString(),
      params
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onPostPaymentSource.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpPostCheckout(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'POST',
      this.appConfig.PARTICA_ENDPOINT + '/shoppingcart/checkout?' + httpParams.toString(),
      params
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onPostCheckout.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpGetPurchasedArticles(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/partner/feeds',
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetPurchasedArticles.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpGetPurchasedArticle(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/partner/article',
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetArticle.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpGetSsoToken(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/payment/ssotoken',
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetSsoToken.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpGetStreams() {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/partica/streams'
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetStreams.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpSubscribeStream(params: any) {
    params['partnerId'] = this.appConfig.PARTICA_PARTNERID;
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'POST',
      this.appConfig.PARTICA_ENDPOINT + '/partner/subscribe?' + httpParams.toString(),
      params
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onSubscribe.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  httpGetUserStreams(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.PARTICA_ENDPOINT + '/partner/subscriptions',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if ( typeof response.type !== 'undefined' && response.type === 0 ) {
            return;
          }

          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onGetUserStreams.next(response);
      },
      (response: any) => {
        this.onParticaError.next(response.error);
      }
    );
  }

  sortByKey(array, key, reverse = false) {
    return array.sort(function(a, b) {
        const x = a[key]; const y = b[key];
        if ( reverse ) {
          return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        } else {
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }
    });
  }

  httpGetRss(owned = false, params: any = {}) {
    let httpParams = new HttpParams();

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/partica/rss/${owned}`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetRss.next(response);
      },
      (response: any) => {
        this.onGetRss.next(response.error);
      }
    );
  }

  httpAddFeed(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/partica`, data)
    .subscribe(
      (response: any) => {
        this.onAddFeed.next(response);
      },
      (response: any) => {
        this.onAddFeed.next(response.error);
      }
    );
  }
  
  httpGetFeeds() {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/partica`)
    .subscribe(
      (response: any) => {
        this.onGetFeeds.next(response);
      },
      (response: any) => {
        this.onGetFeeds.next(response.error);
      }
    );
  }

  httpUpdateFeed(ID: number, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/partica/${ID}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateFeed.next(response);
      },
      (response: any) => {
        this.onUpdateFeed.next(response.error);
      }
    );
  }

  httpDeleteFeed(ID: number) {
    return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/partica/${ID}`)
    .subscribe(
      (response: any) => {
        this.onDeleteFeed.next(response);
      },
      (response: any) => {
        this.onDeleteFeed.next(response.error);
      }
    );
  }
}
