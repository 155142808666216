import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FrontService } from '../front.service';
import { ClientService } from '../../services/client.service';
import { Params, ActivatedRoute,  Router } from '@angular/router';
import { Menu } from '../../models/menu.model';
import { Client } from '../../models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { StorageService } from '../../services/storage.service';
import { Auth } from '../../models/auth.model';
//import { ImageLibraryService } from '../imagelibrary.service';
import * as copy from 'copy-to-clipboard';

declare var swal: any;
declare var introJs: any;
declare var _bugHerd: any;

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  loading = false;
  menus: Menu[] = [];

  authUser: Auth;
  user = {
    ID: 21,
    client_id: 5,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  supportLink = '';

  @ViewChild('itemFile', { static: false }) itemFile: ElementRef;
  @ViewChild('itemFolder', { static: false }) itemFolder: ElementRef;
  @ViewChild('uploadItemModalClose', { static: false }) uploadItemModalClose: ElementRef;
  @ViewChild('newFolderModalClose', { static: false }) newFolderModalClose: ElementRef;
  @ViewChild('imageLibraryModalClose', { static: false }) imageLibraryModalClose: ElementRef;

  constructor(private frontService: FrontService,
    private clientService: ClientService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router) {}


  ngOnInit() {
    this.frontService.loadMenu(this.menus);
  }


}
