<div class="row height-100" id="sign-form-box">
  <div class="col-xs-12 col-sm-6 col-md-3 box">
    <img src="/assets/img/mydesign-white.svg" class="logo">
    <div class="heading">MyDesign Admin Login</div>
    <form [formGroup]="signinForm" (ngSubmit)="onSignin()">
      <div class="form-group">
        <input formControlName="username" type="text" class="form-control" id="username" placeholder="Username">
      </div>
      <div class="form-group">
        <input formControlName="password" type="password" class="form-control" id="password" placeholder="Password">
      </div>
      <button type="submit" class="btn btn-primary form-control theme-btn-default">Sign In</button>
    </form>
  </div>
</div>