import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CampaignService } from '../../../services/campaign.service';
import { DomainService } from '../../../services/domain.service';
import { AuthService } from '../../../services/auth.service';
import { Auth } from '../../../models/auth.model';
import { MyDesktopService } from '../../../services/mydesktop.service';
import { VaultService } from '../../../services/vault.service';
import { FrontService } from '../../front.service';

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss']
})
export class CampaignViewComponent implements OnInit {
  id: number;
  authUser: Auth;

  constructor(
    private frontService: FrontService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private domainService: DomainService,
    private mydesktopService: MyDesktopService,
    private vaultService: VaultService
  ) { }

  ngOnInit() {
    if (this.frontService.appConfig.SKIN === 'md3') {
      return window.location.href = '/templates';
    }

    this.campaignService.campaignCategory = this.route.snapshot.queryParamMap.get('category');
    this.campaignService.vaultStatus = this.route.snapshot.queryParamMap.get('status');
    this.authUser = this.authService.auth;

    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];

        if ( params['type'] === 'listing' ) {
          if ( this.authUser.provider === 'domain' ) {
            this.domainService.httpGetListing(this.id, {
              campaign: 'yes',
              campaignType: params['type'],
              campaignCategory: this.campaignService.campaignCategory
            });
          }

          if ( this.authUser.provider === 'mydesktop' ) {
            this.mydesktopService.httpNewGetListing(this.id, {
              campaign: 'yes',
              campaignType: params['type'],
              campaignCategory: this.campaignService.campaignCategory
            });
          }

          if ( this.authUser.provider === 'vaultre' ) {
            this.vaultService.httpGetListing(this.id, {
              campaign: 'yes',
              campaignType: params['type'],
              campaignCategory: this.campaignService.campaignCategory,
              classification: 'residential',
              status: this.campaignService.vaultStatus
            });
          }

          this.campaignService.isCustom = false;
        } else {
          this.campaignService.isCustom = true;
          this.campaignService.campaignId = params['type'];
          this.campaignService.propId = this.id;

          this.campaignService.httpGetCampaign(params['type']);

          if ( this.authUser.provider === 'domain' ) {
            this.domainService.httpGetListing(this.id, {
              campaign: 'yes',
              campaignType: 'custom'
            });
          }

          if ( this.authUser.provider === 'mydesktop' ) {
            this.mydesktopService.httpNewGetListing(this.id, {
              campaign: 'yes',
              campaignType: 'custom'
            });
          }

          if ( this.authUser.provider === 'vaultre' ) {
            this.vaultService.httpGetListing(this.id, {
              campaign: 'yes',
              campaignType: 'custom',
              classification: 'residential',
              status: this.campaignService.vaultStatus
            });
          }
        }
      }
    );
  }

}
