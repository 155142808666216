<div id="suburbFilter" class="row center">
  <div class="search">
    <div class="input-group">
      <div class="position-relative input-group-btn search-panel" data-search="property-status">
        <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown">
          <span class="search_by">
            <ng-container *ngIf="priceFinderPropertyStatus">{{ priceFinderPropertyStatus | titlecase }} </ng-container>
            <ng-container *ngIf="!priceFinderPropertyStatus">Filter by</ng-container>
          </span>
          <span class="icon feather icon-chevron-down"></span>
        </button>
        <ul class="dropdown-menu" role="menu">
          <li><a (click)="onSelectPropertyStatus('sales')">Sales</a></li>
          <!-- <li><a (click)="onSelectPropertyStatus('rent')">Rent</a></li> -->
          <li class="divider"></li>
          <li><a (click)="onSelectPropertyStatus('all')">All</a></li>
        </ul>
      </div>
      <input [formControl]="priceFinderInput" type="text" class="form-control" name="suburb" placeholder="Search..." autocomplete="off" />
      <span class="input-group-btn">
        <button class="btn btn-default search-button" type="submit"><i class="icon feather icon-search"></i></button>
      </span>
    </div>
    <div class="search-result list-group" *ngIf="priceFinderSuburbs">
      <a *ngFor="let priceFinderSuburb of priceFinderSuburbs" class="list-group-item list-group-item-action" (click)="onSelectSuburb(priceFinderSuburb)">{{ priceFinderSuburb?.display }}</a>
    </div>
  </div>
  <div class="result" [class.show]="priceFinderSearchResult">
    <div class="container">
      <div class="sub-filter property-types">
        <p>Property Types</p>
        <button class="btn" [class.active]="priceFinderPropertyType === 'house'" data-proptype="house" (click)="selectPriceFinderPropertyType('house')">House</button>
        <button class="btn" [class.active]="priceFinderPropertyType === 'unit'" data-proptype="unit" (click)="selectPriceFinderPropertyType('unit')">Unit</button>
      </div>
      <div [class.hidden]="priceFinderPropertyType === 'unit'">
        <div class="property-heading row">
          <div class="col-md-6">
            <p><span class="title">Recent House Sales</span> <span class="badge badge-info">{{selectedListings.length}}
                / {{ maxSelectedListings }}</span></p>
          </div>
          <div class="col-md-6 pricefinder-sort">
            <select #priceFinderSortHouse class="form-control" (change)="onPriceFinderSortChange(priceFinderSortHouse.value)">
              <option value="-date">Sold Date (Descending)</option>
              <option value="date">Sold Date (Ascending)</option>
              <option value="-price">Price (Descending)</option>
              <option value="price">Price (Ascending)</option>
            </select>
          </div>
          <div class="col-md-12">
            <p>Select your desired properties to insert into your report</p>
          </div>
        </div>
        <div class="property-lists propertyListScroller">
          <ul class="blocks blocks-100 blocks-xlg-5 blocks-lg-5 blocks-md-5 blocks-ms-2 blocks-xs-2">
            <li *ngFor="let priceFinderRecentHouseSale of priceFinderRecentHouseSales" (click)="selectPriceFinderSaleProperty(priceFinderRecentHouseSale)" [class.selected]="checkIfPriceFinderSaleSelected(priceFinderRecentHouseSale)">
              <div class="media-item">
                <div class="info-wrap">
                  <p class="title">{{priceFinderRecentHouseSale?.price?.display}}</p>
                </div>
                <div class="image-wrap">
                  <div class="img-overlay"></div>
                  <img class="image" src="{{ appConfig?.API_ENDPOINT }}/pricefinder/property/{{ priceFinderRecentHouseSale?.image?.id }}/image/thumbnail" onerror="this.src='assets/img/placeholder.png'" alt="{{ priceFinderRecentHouseSale?.streetDisplay }}" />
                </div>
                <div class="info-wrap">
                  <p class="title">{{priceFinderRecentHouseSale?.streetDisplay}}</p>
                  <ul class="list-inline">
                    <li class="list-inline-item">BED {{priceFinderRecentHouseSale?.features?.bedrooms}}</li>
                    <li class="list-inline-item">BATH {{priceFinderRecentHouseSale?.features?.bathrooms}}</li>
                    <li class="list-inline-item">CAR {{priceFinderRecentHouseSale?.features?.carParks}}</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div [class.hidden]="priceFinderPropertyType === 'house'">
        <div class="property-heading row">
          <div class="col-md-6">
            <p><span class="title">Recent Unit Sales</span> <span class="badge badge-info">{{selectedListings.length}} /
                {{ maxSelectedListings }}</span></p>
          </div>
          <div class="col-md-6 pricefinder-sort">
              <select #priceFinderSortUnit class="form-control" (change)="onPriceFinderSortChange(priceFinderSortUnit.value)">
                <option value="-date">Sold Date (Descending)</option>
                <option value="date">Sold Date (Ascending)</option>
                <option value="-price">Price (Descending)</option>
                <option value="price">Price (Ascending)</option>
              </select>
            </div>
          <div class="col-md-12">
            <p>Select your desired properties to insert into your report</p>
          </div>
        </div>
        <div class="property-lists propertyListScroller">
          <ul class="blocks blocks-100 blocks-xlg-5 blocks-lg-5 blocks-md-5 blocks-ms-2 blocks-xs-2">
            <li *ngFor="let priceFinderRecentUnitSale of priceFinderRecentUnitSales" (click)="selectPriceFinderSaleProperty(priceFinderRecentUnitSale)" [class.selected]="checkIfPriceFinderSaleSelected(priceFinderRecentUnitSale)">
              <div class="media-item">
                <div class="info-wrap">
                  <p class="title">{{priceFinderRecentUnitSale?.price?.display}}</p>
                </div>
                <div class="image-wrap">
                  <div class="img-overlay"></div>
                  <img class="image" src="{{ appConfig?.API_ENDPOINT }}/pricefinder/property/{{ priceFinderRecentUnitSale?.image?.id }}/image/thumbnail" onerror="this.src='assets/img/placeholder.png'" alt="{{ priceFinderRecentUnitSale?.streetDisplay }}" />
                </div>
                <div class="info-wrap">
                  <p class="title">{{ priceFinderRecentUnitSale?.streetDisplay }}</p>
                  <ul class="list-inline">
                    <li class="list-inline-item">BED {{priceFinderRecentUnitSale?.features?.bedrooms}}</li>
                    <li class="list-inline-item">BATH {{priceFinderRecentUnitSale?.features?.bathrooms}}</li>
                    <li class="list-inline-item">CAR {{priceFinderRecentUnitSale?.features?.carParks}}</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="property-actions">
        <button type="button" class="btn btn-default" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onGenerateReport()">Generate Report</button>
      </div>
    </div>
  </div>
</div>
<ngx-loading class="main-loader" [show]="loading"></ngx-loading>
