import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { APP_CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    @Inject(APP_CONFIG) public appConfig,
    public authService: AuthService
  ) { }
}
