import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { GroupService } from '../../../services/group.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Group } from '../../../models/group.model';
import { Subject } from 'rxjs';
import { VendorReportService } from '../../../services/vendorreport.service';
import swal from 'sweetalert2';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-groups-vendor-reports',
  templateUrl: './groups-vendor-reports.component.html',
  styleUrls: ['./groups-vendor-reports.component.scss']
})
export class GroupsVendorReportsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  loading = false;
  editMode = false;

  group: Group;
  vendorReports = [];

  vendorReportName = '';
  selectedFile: File;
  selectedUuid = '';

  @ViewChild('vendorReportFile', {static: true}) vendorReportFile: ElementRef;
  constructor(
    public adminService: AdminService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private vendorReportService: VendorReportService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        const groupId = +params['id'];

        this.loading = true;
        this.groupService.httpGetGroup(groupId);
      }
    );

    this.groupService.groupChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (group: Group) => {
        if (typeof group !== 'undefined' && typeof group.ID !== 'undefined') {
          this.group = group;

          this.vendorReportService.httpGetAll({
            group_id: this.group.ID
          });
        }
      }
    );

    this.vendorReportService.onGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          this.vendorReports = response.data;
        }
      }
    );

    this.vendorReportService.onUpload
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Vendor Report upload successful', '', 'success');

          this.selectedFile = null;
          this.vendorReportName = '';
          this.vendorReportFile.nativeElement.value = '';

          this.vendorReportService.httpGetAll({
            group_id: this.group.ID
          });
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal('There is a problem uploading your vendor report. Try again.', '', 'error');
        }
      }
    );

    this.vendorReportService.onUpdate
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Vendor Report update successful', '', 'success');

          this.selectedFile = null;
          this.vendorReportName = '';
          this.vendorReportFile.nativeElement.value = '';

          this.vendorReportService.httpGetAll({
            group_id: this.group.ID
          });
        }

        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal('There is a problem updating your vendor report. Try again.', '', 'error');
        }
      }
    );

    this.vendorReportService.onDelete
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.vendorReportService.httpGetAll({
            group_id: this.group.ID
          });
        }

        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal('There is a problem deleting your vendor report. Try again.', '', 'error');
        }
      }
    );
  }

  onFileChanged(event: any) {
    if (event.target.files.length > 1) {
      swal('You can only upload 1 file at a time', '', 'error');

      this.vendorReportFile.nativeElement.value = '';
      this.selectedFile = null;
      return false;
    }

    const file = event.target.files[0];
    if (file.type !== 'application/zip') {
      swal('Invalid file extension', '', 'error');

      this.vendorReportFile.nativeElement.value = '';
      this.selectedFile = null;
      return false;
    }

    this.selectedFile = file;
  }

  onUpload() {
    if (this.vendorReportName === '' || !this.selectedFile) {
      swal('Invalid', '', 'error');

      return false;
    }

    const vendorReportFormValues = {
      group_id: this.group.ID,
      name: this.vendorReportName
    };

    if (this.editMode) {
      this.vendorReportService.httpUpdate(this.selectedUuid, vendorReportFormValues, this.selectedFile);
    } else {
      this.vendorReportService.httpUpload(vendorReportFormValues, this.selectedFile);
    }

    this.loading = true;
  }

  add() {
    this.editMode = false;

    this.selectedFile = null;
    this.vendorReportName = '';
    this.vendorReportFile.nativeElement.value = '';
  }

  edit(vendorReport: any) {
    this.editMode = true;
    this.vendorReportName = vendorReport.name;
    this.selectedUuid = vendorReport.UID;

    this.selectedFile = null;
    this.vendorReportFile.nativeElement.value = '';
  }

  onDelete(UID: string) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.vendorReportService.httpDelete(UID);
      }
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
