import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Faq } from '../models/faq.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { FaqCategory } from '../models/faqcategory.model';

@Injectable()
export class FaqCategoryService {
  faqsCategoryChanged = new Subject<Faq[]>();
  faqChanged = new Subject<Faq>();
  faqOperation = new Subject<Faq>();

  // New Subscriptions
  onGetFaqsCategories = new Subject();
  onGetFaqsCategory = new Subject();
  onPostFaqsCategory = new Subject();
  onPutFaqsCategory = new Subject();
  onDeleteFaqsCategory = new Subject();
  publicOnGetFaqsCategories = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) {}

  httpGetFaqsCategories(params: any = {}, columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }
    if (order) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/faqscategory`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetFaqsCategories.next(response);
      },
      (response: any) => {
        this.onGetFaqsCategories.next(response.error);
      }
    );
  }

  httpGetFaqsCategoriesPublic() {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/public/faqscategories`)
    .subscribe(
      (response: any) => {
        this.publicOnGetFaqsCategories.next(response);
      },
      (response: any) => {
        this.publicOnGetFaqsCategories.next(response.error);
      }
    );
  }

  httpGetFaqsCategory(UID: string) {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/faqscategory/${UID}`)
    .subscribe(
      (response: any) => {
        this.onGetFaqsCategory.next(response);
      },
      (response: any) => {
        this.onGetFaqsCategory.next(response.error);
      }
    );
  }

  httpPostFaqsCategory(data: FaqCategory) {
    this.httpClient.post(`${this.appConfig.API_ENDPOINT}/faqscategory`, data)
    .subscribe(
      (response: any) => {
        this.onPostFaqsCategory.next(response);
      },
      (response: any) => {
        this.onPostFaqsCategory.next(response.error);
      }
    );
  }

  httpPutFaqsCategory(UID: string, data: FaqCategory) {
    this.httpClient.put(`${this.appConfig.API_ENDPOINT}/faqscategory/${UID}`, data)
    .subscribe(
      (response: any) => {
        this.onPutFaqsCategory.next(response);
      },
      (response: any) => {
        this.onPutFaqsCategory.next(response.error);
      }
    );
  }

  httpDeleteFaqsCategory(UID: string) {
    this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/faqscategory/${UID}`)
    .subscribe(
      (response: any) => {
        this.onDeleteFaqsCategory.next(response);
      },
      (response: any) => {
        this.onDeleteFaqsCategory.next(response.error);
      }
    );
  }
}
