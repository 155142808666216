import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProTheme } from '../../../../models/pro-theme.model';
import { ProThemeService } from '../../../../services/pro-theme.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-admin-pros-themes-dashboard',
  templateUrl: './admin-pros-themes-dashboard.component.html',
  styleUrls: ['./admin-pros-themes-dashboard.component.scss']
})
export class AdminProsThemesDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  proThemes: ProTheme[] = [];
  product: string;

  constructor(
    private proThemeService: ProThemeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.paramMap
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (params: ParamMap) => {
        this.product = params.get('product');

        if (this.product) {
          this.proThemeService.httpGetAll({
            product: this.product
          });
        }
      }
    );

    this.proThemeService.onGetAll
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.proThemes = response.data;
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Themes',
                type: 'error'
              });
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
