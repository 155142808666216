<div class="panel panel-bordered panel-box-shadow">
  <div class="panel-heading">
    <h3 class="panel-title">Top Used Templates</h3>
  </div>
  <div class="panel-body">
    <ul class="list-unstyled lists-item">

      <li class="margin-bottom-15" *ngFor="let template of templates; let i = index">
        <small class="gray4-color">Market Report</small>
        <p class="margin-0 darkblue-color">{{ template.title }}</p>
        <small class="gray4-color">Updated by john Doe • 12 August 2017</small>
      </li>
       
    </ul>
  </div>
</div>