import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';

@Injectable()
export class AuthResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private globalService: GlobalService,
    private authService: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.globalService.httpGetAuthRefresh(this.authService.auth.provider).map(auth => {
      if ( typeof auth !== 'undefined') {
        if (auth.status === 'success') {
          if (auth.data) {
            this.authService.setAuthProfile(auth.data);
            this.authService.getAuthCookie();
            return auth.data;
          }
        } else if (auth.status === 'unauthorized') {
          this.authService.deleteAuthCookie();
          this.router.navigate(['/login']);
          return null;
        } else {
          return null;
        }
      }
    }).catch(error => {
      if (error.status === 401) {
        if (this.authService.auth.provider === 'domain') {
          this.authService.deleteAuthCookie();
          this.router.navigate(['/domain/login']);
        } else {
          this.authService.deleteAuthCookie();
          this.router.navigate(['/login']);
        }
        return Observable.of(null);
      } else {
        return Observable.of(null);
      }
    });
  }
}
