import { Component, OnInit, OnDestroy } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { SubgroupService } from '../../../services/subgroup.service';
import { Subscription } from 'rxjs/Subscription';
import { Subgroup } from '../../../models/subgroup.model';

import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-subgroups-dashboard',
  templateUrl: './subgroups-dashboard.component.html',
  styleUrls: ['./subgroups-dashboard.component.scss']
})
export class SubgroupsDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];
  page = 1;
  

  subgroups: Subgroup[];

  selectedRow = 0;
  selectedRowDataId = [];

  dataTableHtml: any;

  subgroupsSubscription: Subscription;
  subgroupDeleteSubscription: Subscription;

  constructor(private adminService: AdminService, private subgroupService: SubgroupService) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.loadMenu(this.menus);

    this.subgroupService.httpGetSubgroups();

    this.subgroupsSubscription = this.subgroupService.subgroupGetAll.subscribe(
      (subgroups: any) => {
        if ( typeof subgroups !== 'undefined' && subgroups.status === 'success' ) {
          this.loading = false;
          this.subgroups = subgroups.data;

          this.initDT();
        }
      }
    );

    this.subgroupDeleteSubscription = this.subgroupService.subgroupDelete.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {

          Swal(
            'Deleted!',
            'Subgroup has been deleted.',
            'success'
          );

          this.subgroupService.httpGetSubgroups();
          this.loading = false;

          if ($.fn.DataTable.isDataTable('table')) {
            this.dataTableHtml.destroy();
          }

          this.initDT();

        }
      }
    );

    this.initDT();
  }

  initDT() {
      setTimeout(
        () => {
          if ( ! $.fn.DataTable.isDataTable( 'table' ) ) {

            this.dataTableHtml = $('#subgroup_table').DataTable({
                searching: true,
                info: false,
                responsive: true,
                'pagingType': 'full_numbers',
                'processing': true,
                'pageLength': 50,
                'paging':   false,
                select: {
                  'style': 'multiple',
                },
              });

              const $this = this;

              $('#subgroup_table').on('select.dt', function ( e, dt, type, indexes ) {
                $this.selectedRow = $this.selectedRow + 1;
                const data = dt.rows('.selected' ).data();

                for (let i = 0; i < $this.selectedRow; i++) {
                  if ($this.selectedRowDataId.includes(data[i][0])) {
                    continue;
                  } else {
                    $this.selectedRowDataId.push(data[i][0]);
                  }
                }
              });

              $('#subgroup_table').on('deselect.dt', function ( e, dt, type, indexes ) {
                $this.selectedRow = $this.selectedRow - 1;
                const data = dt.rows(indexes).data();
                const groupID = data[0][0];

                $this.selectedRowDataId.forEach((value, index) => {
                    if (value === groupID) {
                      $this.selectedRowDataId.splice(index, 1);
                    }
                });
              });
            }
        }, 1000
      );
  }

  onDelete(id) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.subgroupService.httpDeleteSubgroup(id);
        $this.loading = true;
        Swal(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    }).catch(Swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    Swal({
      title: 'Are you sure you want to delete this selected subgroup/s?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.subgroupService.httpDeleteSubgroup(element);
        });

        $this.initDT();
      }
    }).catch(Swal.noop);
  }

  ngOnDestroy() {
    this.subgroupsSubscription.unsubscribe();
    this.subgroupDeleteSubscription.unsubscribe();

    if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
      this.dataTableHtml.destroy();
    }
  }

}
