import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminService } from '../../../admin.service';
import { DesignsTypeService } from '../../../../services/designs-type.service';
import { takeUntil } from 'rxjs/operators';
import { DesignsType } from '../../../../models/designs-type.model';

@Component({
  selector: 'app-admin-pros-builder-types-dashboard',
  templateUrl: './admin-pros-builder-types-dashboard.component.html',
  styleUrls: ['./admin-pros-builder-types-dashboard.component.scss']
})
export class AdminProsBuilderTypesDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  designsTypes: DesignsType[] = [];

  constructor(
    private adminService: AdminService,
    private designsTypesService: DesignsTypeService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.designsTypesService.httpGetAll();

    this.designsTypesService.onGetAll
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.data !== 'undefined') {
            this.designsTypes = response.data;
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
