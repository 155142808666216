import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { Subject } from 'rxjs';
import { BlacklistService } from '../../../services/blacklist.service';
import { AdminService } from '../../admin.service';

import Swal from 'sweetalert2';
declare var jQuery: any;

@Component({
  selector: 'app-blacklists-dashboard',
  templateUrl: './blacklists-dashboard.component.html',
  styleUrls: ['./blacklists-dashboard.component.scss']
})
export class BlacklistsDashboardComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject;
  loading = false;
  menus: Menu[] = [];
  page = 1;

  blacklists: any;

  selectedRow = 0;
  selectedRowDataId = [];

  constructor(
    private adminService: AdminService,
    private blacklistService: BlacklistService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.loadMenu(this.menus);

    this.blacklistService.httpGetBlacklists();

    this.blacklistService.blacklistGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.blacklists = response.data;

          this.initDT();
        }
      }
    );

    this.blacklistService.blacklistDelete
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;

          Swal(
            'Deleted',
            '',
            'success'
          );
          this.initDT();
          this.blacklistService.httpGetBlacklists();
        }
      }
    );

    this.initDT();
  }

  initDT() {
    setTimeout(
      () => {
        if ( ! jQuery.fn.DataTable.isDataTable( '#tbl_blacklist' ) ) {

          jQuery('#tbl_blacklist').DataTable({
            'pagingType': 'full_numbers',
            // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
            language: {
              search: '_INPUT_',
              searchPlaceholder: 'Search',
              'paginate': {
                'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
              }
            },
            'pageLength': 50,
            select: {
              'style': 'multiple',
            },
          });

          jQuery('#tbl_blacklist').DataTable().column(0).visible(false);

          const $this = this;

          $('#tbl_blacklist').on('select.dt', function ( e, dt, type, indexes ) {
            $this.selectedRow = $this.selectedRow + 1;
            const data = dt.rows('.selected' ).data();

            for (let i = 0; i < $this.selectedRow; i++) {
              if ($this.selectedRowDataId.includes(data[i][0])) {
                continue;
              } else {
                $this.selectedRowDataId.push(data[i][0]);
              }
            }
          });

          $('#tbl_blacklist').on('deselect.dt', function ( e, dt, type, indexes ) {
            $this.selectedRow = $this.selectedRow - 1;
            const data = dt.rows(indexes).data();
            const groupID = data[0][0];

            $this.selectedRowDataId.forEach((value, index) => {
                if (value === groupID) {
                  $this.selectedRowDataId.splice(index, 1);
                }
            });
          });

          jQuery('input[type=search]').addClass('btn-search');
          jQuery('input[type=search]').attr('placeholder','Search');
          jQuery('#box select').addClass('select_datatable');
          jQuery('select').append('<option selected value="-1">Filter</option>');
        }
      }, 1000
    );
  }

  onDelete(id) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.blacklistService.httpDeleteBlacklist(id);
        $this.loading = true;
        Swal(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    }).catch(Swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    Swal({
      title: 'Are you sure you want to delete this data selected?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.blacklistService.httpDeleteBlacklist(element);
        });

        $this.selectedRow = 0;
      }
    }).catch(Swal.noop);
  }

}
