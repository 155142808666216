<div class="child-content-wrapper clients-table">
  <div class="page-content container-fluid settings">
    <div class="col-md-12 heading">
      <h2>Corporate Access</h2>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-box-shadow">
          <div class="panel-body container-fluid">
            <table id="datatable-clients" class="datatable-clients table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width: 100%">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Office</th>
                  <th style="text-align: right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr *ngFor="let client of clients">
                      <td>{{ client.name ? client.name : client?.company_name }}</td>
                      <td>{{ client.franchise_code }}</td>
                      <td>{{ client.office_id }}</td>
                      <td>{{ client.is_franchise_master ? 'Yes' : 'No' }}</td>
                      <td>{{ client.is_account_master ? 'Yes' : 'No' }}</td>
                      <td><span class="label label-success">{{ client.is_account_active ? 'Active' : 'Inactive' }}</span></td>
                      <td style="text-align: right">
                        <button routerLink="{{ client.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                        <button (click)="openGallery(client.ID, client.group_id, client)" type="button" class="btn-table" title="Gallery"><i class="icon feather icon-image"></i></button>
                        <button *ngIf="client?.users.length > 0" (click)="loginAs(client)" type="button" class="btn-table" title="Login As"><i class="icon feather icon-log-in"></i></button>
                        <button (click)="onDelete(client.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
                      </td>
                    </tr> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- End Modal -->
<ngx-loading [show]="loading"></ngx-loading>
