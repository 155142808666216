import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrontService } from '../../front/front.service';
import { EnvironmentService } from '../../services/environment.service';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
@Component({
  selector: 'app-central-login',
  templateUrl: './central-login.component.html',
  styleUrls: ['./central-login.component.scss']
})
export class CentralLoginComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();

  constructor(
    public frontService: FrontService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit() {
    this.environmentService.onGet
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response && typeof response !== 'undefined' && typeof response.apiEndpoint !== 'undefined') {
          localStorage.setItem('designly_environment', JSON.stringify(response));

          window.location.reload();
        } else {
          swal('Error changing skin', '', 'error');
        }
      }
    );
  }

  changeSkin(skin: string) {
    const environmentUrl = `https://s3-ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/environments/${skin}.json`;

    this.environmentService.httpGet(environmentUrl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
