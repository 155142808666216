import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Menu } from '../models/menu.model';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from '../app.config';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminService {
  menuChanged = new Subject<Menu[]>();
  routeData = new Subject<any>();
  fullPageContent = false;

  private menus: Menu[] = [];

  constructor(@Inject(APP_CONFIG) public appConfig, public authService: AuthService) {}

  loadMenu(menus) {
    this.menus = menus;
    this.menuChanged.next(menus);
  }

  hasMenu() {
    this.fullPageContent = false;

    return this.menus.length > 0 ? true : false;
  }
}
