import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Video } from '../models/video.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class VideoService {
  onGetVideos = new Subject();
  onGetVideo = new Subject();
  onPostVideo = new Subject();
  onUpdateVideo = new Subject();
  onDeleteVideo = new Subject();
  onPostVideoItem = new Subject();
  onGetVideoItem = new Subject();
  onUpdateVideoItem = new Subject();
  onDeleteVideoItem = new Subject();

  publicOnGetVideos = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) {}

  httpGetVideos(params: any = {}, columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    if (order) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/videos`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetVideos.next(response);
      },
      (response: any) => {
        this.onGetVideos.next(response.error);
      }
    );
  }

  httpGetVideosPublic() {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/public/videos`)
    .subscribe(
      (response: any) => {
        this.publicOnGetVideos.next(response);
      },
      (response: any) => {
        this.publicOnGetVideos.next(response.error);
      }
    );
  }

  httpGetVideo(UID: string) {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/videos/${UID}`)
    .subscribe(
      (response: any) => {
        this.onGetVideo.next(response);
      },
      (response: any) => {
        this.onGetVideo.next(response.error);
      }
    );
  }

  httpPostVideo(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/videos`, data)
    .subscribe(
      (response: any) => {
        this.onPostVideo.next(response);
      },
      (response: any) => {
        this.onPostVideo.next(response.error);
      }
    );
  }

  httpPutVideo(UID: string, data: any) {
    this.httpClient.put(`${this.appConfig.API_ENDPOINT}/videos/${UID}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateVideo.next(response);
      },
      (response: any) => {
        this.onUpdateVideo.next(response.error);
      }
    );
  }

  httpDeleteVideo(UID: string) {
    this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/videos/${UID}`)
    .subscribe(
      (response: any) => {
        this.onDeleteVideo.next(response);
      },
      (response: any) => {
        this.onDeleteVideo.next(response.error);
      }
    );
  }

  httpGetVideoItem(UID: string, ITEMUID: string) {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/videos/${UID}/items/${ITEMUID}`)
    .subscribe(
      (response: any) => {
        this.onGetVideoItem.next(response);
      },
      (response: any) => {
        this.onGetVideoItem.next(response.error);
      }
    );
  }

  httpPostVideoItem(UID: string, data: any) {
    this.httpClient.post(`${this.appConfig.API_ENDPOINT}/videos/${UID}/items`, data)
    .subscribe(
      (response: any) => {
        this.onPostVideoItem.next(response);
      },
      (response: any) => {
        this.onPostVideoItem.next(response.error);
      }
    );
  }

  httpPutVideoItem(UID: string, ITEMUID: string, data: any) {
    this.httpClient.put(`${this.appConfig.API_ENDPOINT}/videos/${UID}/items/${ITEMUID}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateVideoItem.next(response);
      },
      (response: any) => {
        this.onUpdateVideoItem.next(response.error);
      }
    );
  }

  httpDeleteVideoItem(UID: string, ITEMUID: string) {
    this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/videos/${UID}/items/${ITEMUID}`)
    .subscribe(
      (response: any) => {
        this.onDeleteVideoItem.next(response);
      },
      (response: any) => {
        this.onDeleteVideoItem.next(response.error);
      }
    );
  }
}
