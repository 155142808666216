<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Logs - {{ formatTitle(type) }}</h2>
  </div>
</div>
<div id="box" class="row">
  <div class="col-lg-12 pb-4 text-left">
    Filter by Date <input type="text" id="date_from" data-toggle="datepicker" class="datePicker"> - <input type="text" id="date_to" class="datePicker"> &nbsp; <button (click)="onFilter()" class="btn btn-theme-0 pr-4 pl-4 btn-sm">Filter</button>&nbsp; <button (click)="onReset()" class="btn btn-theme-0 pr-4 pl-4 btn-sm">Reset</button>
  </div>
  <div class="col-md-12">
    <div class="table-box">
      <table id="datatable-logs" class="table table-hover">
        <thead>
          <tr>
            <th>Office</th>
            <th>Suburb</th>
            <th>Postcode</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let websitelog of websiteLogs">
            <td>{{ websitelog.office }}</td>
            <td>{{ websitelog.suburb }}</td>
            <td>{{ websitelog.postcode }}</td>
            <td>{{ websitelog.first_name }}</td>
            <td>{{ websitelog.last_name }}</td>
            <td>{{ websitelog.mobile }}</td>
            <td>{{ websitelog.email }}</td>
            <td>{{ websitelog.created }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>