<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 heading">
          <h4 class="pull-left">Vault Whitelisting</h4>
        </div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="20%">Type</th>
            <th>IDs</th>
            <th width="10%" style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vault Whitelist - Franchise ID</td>
            <td>{{ setting.vault_allowed_franchise_id }}</td>
            <td style="text-align: right">
              <button data-bs-target="#settingVaultFranchiseID" data-bs-toggle="modal" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
            </td>
          </tr>
          <tr>
            <td>Vault Whitelist - Account ID</td>
            <td>{{ setting.vault_allowed_account_id }}</td>
            <td style="text-align: right">
              <button data-bs-target="#settingVaultAccountID" data-bs-toggle="modal" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="settingVaultFranchiseID" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="settingWhiteListOfficeIDLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #settingWhiteListOfficeIDModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="settingWhiteListOfficeIDLabel">Add Whitelisting - Franchise ID</h4>
      </div>
      <form (ngSubmit)="onSubmit('vault_allowed_franchise_id')" [formGroup]="settingVaultFranchiseIDForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="title" class="col-sm-2">Franchise ID</label>
            <div class="col-sm-10">
              <textarea rows="6" formControlName="vault_allowed_franchise_id" class="form-control" id="vault_allowed_franchise_id" ></textarea>
              <small>(,) comma separated e.g. 12345, 56789</small>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!settingVaultFranchiseIDForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div id="settingVaultAccountID" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="settingBlackListBrandIDLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #settingBlackListBrandIDModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="settingBlackListBrandIDLabel">Add Whitelisting - Account ID</h4>
      </div>
      <form (ngSubmit)="onSubmit('vault_allowed_account_id')" [formGroup]="settingVaultAccountIDForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="title" class="col-sm-2">Account ID</label>
            <div class="col-sm-10">
              <textarea rows="6" formControlName="vault_allowed_account_id" class="form-control" id="vault_allowed_account_id" ></textarea>
              <small>(,) comma separated e.g. 12345, 56789</small>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!settingVaultAccountIDForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>